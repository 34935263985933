import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../models/Account";
import { ChannelType } from "../../models/Channel";
import services from "../../services";
import {ChannelRowSchema} from "../../services/channelService"
import { RootState } from "../index";

export const fetchChannels = createAsyncThunk(
  "channels/fetch",
  async ({ id }: Account) => {
    return services.channelService.getChannels(id);
  }
);

export const channelSlice = createSlice({
  name: "channels",
  initialState: {
    channels: [] as ChannelType[],
    channelErrors: [] as String[] | null,
    importChannelsResult: null as String | null,
  },
  reducers: {
    setChannels: (
      state,
      { payload: channels }: PayloadAction<ChannelType[]>
    ) => {
      state.channels = channels;
    },
    setChannelErrors: (
      state,
      { payload: channelErrors }: PayloadAction<String[] | null>
    ) => {
      state.channelErrors = channelErrors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChannels.fulfilled, (state, action) => {
      state.channels = action.payload;
    });

    builder.addCase(importChannels.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.channelErrors = action.payload!;
      }
    });
    builder.addCase(importChannels.fulfilled, (state, action) => {
      let importedChannels = action.payload;
      state.importChannelsResult = `Successfully imported ${importedChannels.length} new channels.`;
    });
  },
});

export const importChannels = createAsyncThunk<
ChannelType[], 
{
  channelSchemaArray: ChannelRowSchema[]
},
{
  rejectValue: String[] //return a list of errors on failure
}
>(
"channeltypes/import",
async (channelSchemaArray, { rejectWithValue }) => {
  try {
    let result = await services.channelService.importChannels(channelSchemaArray.channelSchemaArray);
    return result;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    let formattedErrors = [] as String[];
    let errorData = error.response.data;

    //This is the validation error returned by the Platform API using the tsoa library.
    if (errorData.message == "Validation Failed") {
      //This returns a "details" object which contains both the row number and attribute, as well as 
      //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
      let details = errorData.details;
      Object.keys(details).forEach(key => {
        //The key contains both the row# and the failed attribute
        //The attribute "message" contains the error message.
        //We attempt to format these in a single string.
        let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
        let colName = key.substring(key.indexOf(".") + 4);
        let rowNumberInt = parseInt(rowNumber) + 1;

        let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
        formattedErrors.push(errorMessage);
      })
    } else if (errorData.message == "Entity Not Found") {
      formattedErrors.push(errorData.details);
    } else if (errorData.message == "Could Not Process Request") {
      formattedErrors.push(errorData.details);
    }

    return rejectWithValue(formattedErrors);

  }

}
); 

export const selectChannels = (state: RootState): ChannelType[] =>
  state.channels.channels;

  export const channelErrors = (state: RootState): String[] | null =>
  state.channels.channelErrors;

export const { setChannels } = channelSlice.actions;

export default channelSlice.reducer;

