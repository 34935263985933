import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomKeywordData, CustomKeywordChannel } from "../../models/CustomKeyword";
import services from "../../services";
import { CustomKeywordRowSchema } from "../../services/customKeywordService";
import { RootState } from "../index";

//Creates multiple custom reports from a csv or xlsx file
// @params
// customReportSchemaArray (An array of CustomReportRowSchema objects representing the rows in the file)
export const importCustomKeywords = createAsyncThunk<
  CustomKeywordData[], //we expect this to return a list of locations
  {
    customKeywordSchemaArray: CustomKeywordRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "custom-keywords/import",
  async (customKeywordSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.customKeywordService.importCustomKeywords(customKeywordSchemaArray.customKeywordSchemaArray);
      return result;
    } catch (error: any) {
      //console.log( error );
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message == "Validation Failed") {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;

          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message == "Entity Not Found") {
        formattedErrors.push(errorData.details);
      } else if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);

    }

  }
);

export const getCustomKeywordChannels = createAsyncThunk(
  "custom-keywords/custom-channels",
  async () =>{
    return await services.customKeywordService.getCustomKeywordChannels();
  }
);



export const customKeywordSlice = createSlice({
  name: "customKeyword",
  initialState: {
    keywordErrors: [] as String[] | null,
    importKeywordsResult: null as String | null,
    customChannels: [] as CustomKeywordChannel[]
  },
  reducers: {
    setKeywordErrors: (
      state,
      { payload: keywordErrors }: PayloadAction<String[] | null>
    ) => {
      state.keywordErrors = keywordErrors;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(importCustomKeywords.fulfilled, (state, action) => {
      let importedKeywords = action.payload;
      state.importKeywordsResult = `Successfully imported ${importedKeywords.length} rows.`;
    });

    builder.addCase(importCustomKeywords.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.keywordErrors = action.payload!;
      }
    });

    builder.addCase(getCustomKeywordChannels.fulfilled, (state, action) => {
      state.customChannels = action.payload;
    });
  },
});

export const { setKeywordErrors } = customKeywordSlice.actions;

export const keywordErrors = (state: RootState): String[] | null =>
  state.customKeyword.keywordErrors

export const importKeywordsResult = (state: RootState): String | null =>
  state.customKeyword.importKeywordsResult

export const selectCustomKeywordChannels = (state: RootState): CustomKeywordChannel[] => state.customKeyword.customChannels

export default customKeywordSlice.reducer;
