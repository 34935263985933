import type { AdminManagerFranchise, AdminManagerLocation, AdminManagerUserAccount } from "../../models/AdminManager"

export const getTestFranchises = (): AdminManagerFranchise[] => {
  const count = 4
  const franchises: AdminManagerFranchise[] = []
  for (let i = 1; i <= count; i++) {
    const newFranchise: AdminManagerFranchise = {
      name: `Franchise ${i > 9 ? '' : '0'}${i > 99 ? '' : '0'}` + i,
      locations: [],
      userAccounts: [],
      isActive: Math.random() > 0.5
    }
    for (let j = 1; j <= 5; j++) {
      const newLocation: AdminManagerLocation = { id: `${j}${j}${j}`, name: `location ${j}`, owner: `owner ${j}`, isActive: Math.random() > 0.5 }
      newFranchise.locations.push(newLocation)
    }
    for (let j = 1; j <= 5; j++) {
      const newUserAccount: AdminManagerUserAccount = {
        name: `user account ${j}`, email: `example${j}@email.com`, isActive: Math.random() > 0.5,
        locations: []
      }
      newUserAccount.locations.push(newFranchise.locations[Math.floor(Math.random() * newFranchise.locations.length)])
      newUserAccount.locations.push(newFranchise.locations[Math.floor(Math.random() * newFranchise.locations.length)])
      newUserAccount.locations.push(newFranchise.locations[Math.floor(Math.random() * newFranchise.locations.length)])
      newFranchise.userAccounts.push(newUserAccount)
    }
    franchises.push(newFranchise)
  }
  return franchises
}