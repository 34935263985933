import styles from './SidePanelEventDetails.module.css'
import closeIcon from '../../../../assets/icons/cancel.svg'
import { Button, Form, Input, Modal, Select } from "antd"

import { Event } from "../../../../models/Event";
import { useEffect, useState } from "react";
import { UsStates } from "../../../account/BillingAddress/UsStates";
import { CountryEnum } from "../../../../models/Location";
import { selectWhiteLabel } from "../../../../store/WhiteLabel/WhiteLabelStore";

import { ReactComponent as IconSchedule } from "../../../../assets/icons/step3schedule.svg";
import { ReactComponent as IconLocation } from "../../../../assets/icons/step1location.svg";


interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  event: Event;
}

const SidePanelEventDetails: React.FC<Props> = ({
  visible,
  setVisible,
  event }) => {
  const [viewState, setViewState] = useState(false);
  const closeModal = () => {
    setVisible(false);
  }

  let dateString;
  if( event.other == "Created Automatically"){
    //The event was created when purchasing a campaign and it corresponds to a channel.
    if( event.alwaysOn ){
      dateString = "Always On";
    }else{
      dateString = `${event.startDate.toLocaleDateString()} - ${event.endDate!.toLocaleDateString()}`
    }
  }else{
    //The default event created in any other, manual ways.
    dateString = `${event.startDate.toLocaleDateString()}`;
  }

  return (
    <>
      <div className={styles[visible ? 'side-panel-container' : 'side-panel-container-hidden']+ " z-[100] res:fixed res:top-0 res:left-0 res:bottom-0 res:right-0"}>
        <div className={styles['side-panel']+ " res:w-full res:basis-full"}>

        
          <img src={closeIcon} width={30} height={30} alt='close icon' className={styles['close-img']} onClick={closeModal} />
          {visible}
          
          <h2 className=' res:text-xl' style={{marginBottom: "32px" }}> {event.title} </h2>
          
          <div >
            <div style={{ width: event.imageUrl ? "50%" : "100%" }}>
              <h2>Details</h2>
              <p>{event.details}</p>
            </div>
            {event.imageUrl && (
              <img alt={"event"} style={{ width: "40%" }} src={event.imageUrl} />
            )}
          </div>

          <hr style={{ margin: "30px 0px" }} />
            <div>
              <div className='flex justify-start'>
                <IconSchedule className={styles['event-icon']} width={"25px"} height={"25px"} />
                <div className='ml-3'>
                  <h4>Schedule</h4>
                  <p>{dateString}</p>
                </div>
              </div>

              <div className='flex justify-start mt-3'>
                <IconLocation className={styles['event-icon']} width={"25px"} height={"25px"} />
                <div className='ml-3'>
                  <h4>Targeting</h4>
                  <p>
                    {event.location}
                  </p>
                </div>
              </div>
            </div>


        </div>
      </div>
    </>
    
  )
}

export default SidePanelEventDetails
