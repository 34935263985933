import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import "./Billing.css";
import { MonthlyInvoices } from "models/MonthlyInvoices";
import BillingChart from "./BillingChart";
import {ExportOutlined} from "@ant-design/icons";
import { selectMonthlyInvoicesList, fetchMonthlyInvoicesList, selectLoading } from "../../../store/MonthlyInvoices/MonthlyInvoiceStore";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { WhiteLabel } from "../../../models/WhiteLabel";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "../../../assets/icons/loader-onmiChannel.svg";

//Renders a MonthlyInvoices object
const renderMonthlyInvoices = ( monthlyInvoices : MonthlyInvoices, whiteLabel : WhiteLabel ) => {
  if( monthlyInvoices.invoices === undefined ){
    return <div>
        <p className="text-base text-black">No invoices found for this month</p>
      </div>
  }else{
    const renderedList = monthlyInvoices.invoices.map((invoice) => (
      <div className="grid grid-cols-6 items-center w-full mb-4">
        <div className="col-span-3 ">{invoice.name}</div>
        <div className={ !whiteLabel.agency ? "col-span-2 text-right" : "col-span-3 text-right"}>${invoice.price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
        { !whiteLabel.agency && (
          <div className="text-right">
            <a target="_blank" href={invoice.paymentPage}>
              <ExportOutlined
                style={{ color: whiteLabel.primaryColor, fontSize: "20px" }}
              />
            </a>
          </div>
        )}
      </div>
    ))
    return renderedList;
  }
}

const Billing = () => {
  const whiteLabel = useSelector(selectWhiteLabel);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const monthlyInvoicesList = useSelector(selectMonthlyInvoicesList);

  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth() ;
  const [month, setMonth] = useState(mesActual);
  const [currentMonthInvoices, setCurrentMonthInvoices] = useState({} as MonthlyInvoices);

  const nextMonth = ( ) => {
    if ( month+1 <= mesActual ) {
      setMonth(month+1)
    }
  }
  const previuosMonth = ( ) => {
    if ( month-1 > mesActual-5) {
      setMonth(month-1)
    }
  }
  var nameMonth = new Date(month, month).toLocaleString('en-EN', { month: 'long' })

  // Function to find an invoice object by month
  const findInvoiceByMonth = (targetMonth : string) => {
    return monthlyInvoicesList.find((monthlyInvoices) => monthlyInvoices.month === targetMonth);
  };

  //Fetches the proper MonthlyInvoices object according to the choosen month's name.
  useEffect(() => {
    const foundMonthlyInvoices = findInvoiceByMonth(nameMonth);
    if (foundMonthlyInvoices) {
      setCurrentMonthInvoices( foundMonthlyInvoices );
    } else {
      //defaults to an empty object if not found.
      setCurrentMonthInvoices({} as MonthlyInvoices);
    }
  }, [nameMonth]);

  //For debug testing only, prints the current MonthlyInvoices
  useEffect(() => {
    console.log('Current Month Invoices:', currentMonthInvoices);
  }, [currentMonthInvoices]);

  //For debug testing only, prints the current MonthlyInvoices
  useEffect(() => {
    if(monthlyInvoicesList.length > 0 ){
      const foundMonthlyInvoices = findInvoiceByMonth(nameMonth);
      if (foundMonthlyInvoices) {
        setCurrentMonthInvoices( foundMonthlyInvoices );
      } else {
        //defaults to an empty object if not found.
        setCurrentMonthInvoices({} as MonthlyInvoices);
      }
    }
  }, [monthlyInvoicesList]);

  useEffect(() => {
    console.log('fetching');
    dispatch(fetchMonthlyInvoicesList())
  },[]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
        <div className="container">
          <div className="text-2xl mb-3">Billing history</div>
          

          <div className="grid res:grid-cols-1 grid-cols-5 gap-x-7 items-start">
            <div className="res:col-span-1 col-span-2 ">
              <div className="flex justify-between">
                <div className="selectMonth h-fit">
                { month-1 <= mesActual-5 ? (
                    <div className="arrowPrev pointer-events-none">
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.2]  rotate-[90deg] " />
                    </div>
                  ) : (
                    <div className="arrowPrev" onClick={ previuosMonth }>
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.6]  rotate-[90deg] " />
                    </div>
                  ) }
                  
                  <div className="text-[15px] capitalize mx-5 w-[80px] text-center"> { nameMonth } </div>
                  { month+1 > mesActual ? (
                    <div className="arrowNext pointer-events-none">
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.2]  rotate-[170deg] " />
                    </div>
                  ) : (
                    <div className="arrowNext" onClick={ nextMonth }>
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.6]  rotate-[170deg] " />
                    </div>
                  ) }
                  
                </div>

                { currentMonthInvoices.totalSpent !== undefined && (
                  <div className="total ml-auto text-right">
                    <div className="text-[18px]"> Total </div>
                    <div className="text-[15px]"> ${currentMonthInvoices.totalSpent.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} </div>
                  </div>  
                )}
              </div>
              
              <div className={'col-span-2 dividerCustom w-full my-5'}>
                  <label>Invoices</label>
                  <div></div>
              </div>

              <div className="w-full flex-wrap flex">
                {renderMonthlyInvoices(currentMonthInvoices, whiteLabel)}
              </div>


            </div>

            { monthlyInvoicesList.length > 0 && (
              <div className="res:col-span-1 col-span-3  bg-white p-4 shadow-sm">
                <BillingChart monthlyInvoices={monthlyInvoicesList}></BillingChart>
              </div>
            )}
            

          </div>

          
          
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <LoaderIcon className="loader-icon" />
          {/* <CircularProgress color="inherit" /> */}
        </Backdrop>
    </div>
  );

  
};

export default Billing;
