import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomPackage } from "../../models/CustomPackage";
import services from "../../services";
import { RootState } from "../index";

export const fetchPackages = createAsyncThunk("packages/fetch", async () => {
  return services.customPackageService.getCustomPackages();
});

export const packageSlice = createSlice({
  name: "packages",
  initialState: {
    packages: [] as CustomPackage[],
  },
  reducers: {
    setCustomPackages: (
      state,
      { payload: packages }: PayloadAction<CustomPackage[]>
    ) => {
      state.packages = packages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      state.packages = action.payload;
    });
  },
});

export const selectCustomPackages = (state: RootState): CustomPackage[] =>
  state.packages.packages;

export const { setCustomPackages } = packageSlice.actions;

export default packageSlice.reducer;
