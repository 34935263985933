import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectEvents } from "../../../store/Events/EventStore";
import "./styles.css";
import { Event } from "../../../models/Event";
import { EventModal } from "../../eventCalendar/modals/EventModal/EventModal";
import { useNavigate } from "react-router-dom";
import { addDaysToDate } from "../../../utility/time";
import SidePanelEventDetails from "../../eventCalendar/modals/SidePanelEventDetails/SidePanelEventDetails";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { selectSelectedSubMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
import { selectUserAccount } from "../../../store/Account/AccountStore";
import { Tier, UserRole } from "../../../models/User";

const EventsDashboard = ( {openExtraVar}: any ) => {
  const events: Event[] = useSelector(selectEvents);
  const [eventToDisplay, setEventToDisplay] = useState<Event | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const whiteLabel = useSelector( selectWhiteLabel );
  const selectedSubMenu = useSelector( selectSelectedSubMenu);
  const user = useSelector(selectUserAccount);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openEventDetails = (event: Event) => {
    setEventToDisplay(event);
    setIsModalVisible(true);
  };


  const isCurrentOrFutureEvent = (event: Event) => {
    const today = new Date();
    const endDate = event.endDate
      ? event.endDate
      : new Date(addDaysToDate(event.startDate, 1));
    if (event.startDate >= today || endDate >= today) {
      return true;
    } else {
      return false;
    }
  };

  const renderEventsMenu = () => {
    return <>
      <div
        onClick={() => {
          navigate(AvailableRoutes.EVENT);
          dispatch(setSelectedSubMenu("Planner"));
        }}
        className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
        style={{
          backgroundColor:
            selectedSubMenu === "Planner"
              ? whiteLabel.activeColor
              : "transparent",
        }}
        key={"Planner"}
        >
        <p>{"Planner"}</p>
      </div>

{/*      {(
        (user?.type === UserRole.Franchisor || user?.type === UserRole.Agency) && user.tier === Tier.Plus
      ) && (
      <div
        onClick={() => {
          navigate(AvailableRoutes.KEYWORD_HELPER);
          dispatch(setSelectedSubMenu("Keyword helper"));
        }}
        className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
        style={{
          backgroundColor:
            selectedSubMenu === "Keyword helper"
              ? whiteLabel.activeColor
              : "transparent",
        }}
        key={"Keyword helper"}
        >
        <p>{"Keyword helper"}</p>
      </div>
      )}*/}

      {(user?.type === UserRole.Agency) && (
        <div
          onClick={() => {
            navigate(AvailableRoutes.KEYWORD_HELPER);
            dispatch(setSelectedSubMenu("Optimization"));
          }}
          className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
          style={{
            backgroundColor:
              selectedSubMenu === "Optimization"
                ? whiteLabel.activeColor
                : "transparent",
          }}
          key={"Optimization"}
          >
          <p>{"Optimization"}</p>
        </div>
      )}

      {(user?.type === UserRole.Franchisor || user?.type === UserRole.Agency) && (
        <div
          onClick={() => {
            navigate(AvailableRoutes.MEDIA);
            dispatch(setSelectedSubMenu("Media library"));
          }}
          className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
          style={{
            backgroundColor:
              selectedSubMenu === "Media library"
                ? whiteLabel.activeColor
                : "transparent",
          }}
          key={"Media library"}
          >
          <p>{"Media library"}</p>
        </div>
      )}

      {(user?.type === UserRole.Agency) && (
        <div
          onClick={() => {
            navigate(AvailableRoutes.BUDGET_TRACKER_APPROVALS);
            dispatch(setSelectedSubMenu("Budgets"));
          }}
          className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
          style={{
            backgroundColor:
              selectedSubMenu === "Budgets"
                ? whiteLabel.activeColor
                : "transparent",
          }}
          key={"Budgets"}
          >
          <p>{"Budgets"}</p>
        </div>
      )}

      {(user?.type === UserRole.Franchisor || user?.type === UserRole.Agency) && (
        <div
          onClick={() => {
            navigate(AvailableRoutes.LOCATION_MANAGER);
            dispatch(setSelectedSubMenu("Locations"));
          }}
          className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
          style={{
            backgroundColor:
              selectedSubMenu === "Locations"
                ? whiteLabel.activeColor
                : "transparent",
          }}
          key={"Locations"}
          >
          <p>{"Locations"}</p>
        </div>
      )}
      
    </>

  };

  return (
    <>
      {/*events.map(renderEventsMenu)*/}
      {renderEventsMenu()}
      {eventToDisplay && (
        <SidePanelEventDetails visible={isModalVisible} setVisible={setIsModalVisible} event={eventToDisplay}  />
      )}
    </>
  );
};

export default React.memo(EventsDashboard);
