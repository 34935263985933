import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import "./styles.css";

const Guides = () => {
  const navigate = useNavigate();
  const whiteLabel = useSelector( selectWhiteLabel );

  return (
    <div className="container-auth res:w-full ">
      <div>
        <IconArrowLeft
          className="primary-icon cursor-pointer"
          onClick={() => navigate("/login")}
        />
        <div className="text-header"></div>
          <div className="video-block">
            <h3>Choosing your campaign</h3>
              <iframe
                width="400"
                height="224"
                src={`https://www.youtube.com/embed/kxzGwJQ-vGc`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Choosing your campaign"
              />
          </div>
          <div className="video-block">
            <h3>Choosing your audience</h3>
              <iframe
                width="400"
                height="224"
                src={`https://www.youtube.com/embed/0SpuzVem1js`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Choosing your audience"
              />
          </div>
      </div>
    </div>
  );
};

export default Guides;
