import { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import sharedStyles from "../shared/styles.module.css";
import { selectQRCampaigns, fetchQRCampaigns, setQRCampaignToEdit, selectQRCampaignToEdit, deleteQRCampaign } from "../../../store/QRCampaign/QRCampaignsStore";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../../assets/icons/orange-back-arrow.svg";
import { QRCampaign } from "../../../models/QRCampaign";
import { AdminAvailableRoutes } from "pages/AdminRoutes";

import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";

export const DeleteQRCampaignPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const qrCampaignToEdit = useSelector(selectQRCampaignToEdit);

    const onFinish = (values: any) => {
        
        dispatch(
            deleteQRCampaign({
                id: qrCampaignToEdit?.id!,
                name: qrCampaignToEdit?.name!,
                promotion: qrCampaignToEdit?.promotion!,
                startDate: qrCampaignToEdit?.startDate!,
                endDate: qrCampaignToEdit?.endDate!,
            })
        );
        navigate(AdminAvailableRoutes.QR_MANAGER);
        
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return <div >
        <IconArrowLeft
            className="primary-icon cursor-pointer"
            onClick={() => navigate( AdminAvailableRoutes.QR_MANAGER )}
        />
        <h1 className="text-3xl mt-4 mb-6">Delete QR Campaign</h1>
        <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="container-form"
            layout="vertical"
        >
            <p><b>Delete</b> the QR Campaign <b>{qrCampaignToEdit?.name}</b>?</p>

            <Input.Group compact>
                <Form.Item style={{ marginTop: 50 }}>
                    <Button
                        htmlType="submit"
                        className="inverted-general-button pointer-hover"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '25px' }}
                        onClick={() => navigate(AdminAvailableRoutes.DETAILS_QR_CAMPAIGN)}
                    >
                        <IconArrowBack />
                        BACK
                    </Button>
                </Form.Item>
                <Form.Item style={{ marginTop: 50 }}>
                    <Button
                        htmlType="submit"
                        className="general-button pointer-hover"
                        style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        DELETE
                        <IconArrowRight/>
                    </Button>
                </Form.Item>
            </Input.Group>
        </Form>
    </div>
}

export default DeleteQRCampaignPage