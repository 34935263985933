import { Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectUserAccount } from "../../../store/Account/AccountStore";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  selectAgreedToTerms,
  selectAlertAgreedToTerms,
  selectSelectedLocation,
  setAgreedToTerms,
  setAlertAgreedToTerms,
  setPaymentMethod,
  selectIsBudgetModeFixed,
  selectNewBudget,
  selectMonthlyBudget,
  selectedMonth
} from "../../../store/campaignForm/CampaignForm";
import BillingAddress from "../../account/BillingAddress";
import { AddCardForm } from "../../payment/AddCardForm";
import TargetZoneMap from "../targetZone/TargetZoneMap";
import "./ReviewAndPay.scss";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { IMonthlyBudgetDetail, getMonthIndexByCode } from "../../../models/MonthlyBudget";
import { getMoneyStringFromNumber } from "../../../utility/string";

const showDemo = process.env.REACT_APP_APP_SHOW_DEMO_MATERIAL === "true";

const ReviewAndPay = () => {
  const navigate = useNavigate();
  const selectedLocaiton = useSelector(selectSelectedLocation);
  const dispatch = useDispatch();
  const account = useSelector(selectUserAccount);
  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewCard, setIsNewCard] = useState(false);
  const [, setIsFormVisible] = useState(false);
  const alertAgreeded = useSelector(selectAlertAgreedToTerms);
  const termsAgreeded = useSelector(selectAgreedToTerms);
  const [changeBillingAddress, setChangeBillingAddress] = useState(false);
  const isBudgetModeFixed = useSelector(selectIsBudgetModeFixed);
  const campaignMonthlyBudgetCode = useSelector(selectedMonth);
  const monthlyBudget = useSelector(selectMonthlyBudget) as IMonthlyBudgetDetail[];
  const budgetForMonth = monthlyBudget[getMonthIndexByCode(campaignMonthlyBudgetCode)];
  const newBudget = useSelector( selectNewBudget );

  const paymentMethodChange = (value: string) => {
    if (value === "newCard") {
      setIsNewCard(true);
    } else {
      dispatch(setPaymentMethod(value));
      setIsNewCard(false);
    }
  };

  const whiteLabel = useSelector(selectWhiteLabel);

  useEffect(() => {
    if (selectedLocaiton === null) {
      navigate(AvailableRoutes.LOCATIONS);
    }
  }, [selectedLocaiton]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsNewCard(false);
    setChangeBillingAddress(false);
  }, [account]);

  useEffect(() => {
    if (isBudgetModeFixed) {
      dispatch(setPaymentMethod('preapproved'));
    }
  }, []);

  const openCreateEventModal = () => {
    setIsModalVisible(true);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const manageTerms = () => {
    dispatch(setAgreedToTerms(!termsAgreeded));
    dispatch(setAlertAgreedToTerms(false))
  };

  return (
    <div className="review-and-pay-container res:!flex-col">
      <div className="review-and-pay-container-left-side res:order-1 res:h-52">
        {window.google !== undefined && (
          <TargetZoneMap displayButtons={false} canAddPointLocation={false} />
        )}
      </div>
      <div className="review-and-pay-container-right-side res:order-2">
        {showDemo && (
          <div className="billing-addres-container">
            <div className="review-and-pay-title">Billing Address</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="review-and-pay-content">
                {account?.address1}
                <br />
                {account?.city}, {account?.state} {account?.postalCode}
                <br />
                {account?.country}
              </div>
              <div>
                <u
                  style={{ color: "#0096FF" }}
                  onClick={() => setChangeBillingAddress(!changeBillingAddress)}
                >
                  Change
                </u>
              </div>
            </div>
            {changeBillingAddress && <BillingAddress showTitle={false} />}
          </div>
        )}
        <div className="payment-method-container">
          <div className="review-and-pay-title">Payment Method</div>
          {
            isBudgetModeFixed ?
                  <>
                    <Select id="payment" style={{ fontSize: "16px", width: "100%" }} defaultValue={`preapproved`}>
                      <Option key={`preapproved`} value={`preapproved`}>Pre-approved budget</Option>
                    </Select>
                    { budgetForMonth.isDefined === false && (
                      <div className="flex items-start mt-3">
                        <ExclamationCircleOutlined className="w-5 mr-3" style={{ fontSize: "18px", color: whiteLabel.primaryColor }} />
                        <p className="w-full">Your new budget of ${getMoneyStringFromNumber(newBudget)} will be submitted for review, you will receive a notification when it is approved.</p>
                      </div>
                    )}
                  </>
                :
              <>
                <Select
                    id="payment"
                    onChange={paymentMethodChange}
                    style={{ fontSize: "16px", width: "100%" }}
                >
                  {account?.paymentMethods?.map((method) => {
                    return (
                        <Option
                            key={`review payment dropdown card:${method.id}`}
                            value={method.cardId}
                        >
                          {method.cardBrand} ending in {method.last4}
                        </Option>
                    );
                  })}
                  <Option value={"newCard"}>New Card</Option>
                </Select>
                {isNewCard && (
                    <div style={{ marginTop: "35px" }}>
                      <AddCardForm
                          onClose={() => setIsFormVisible(true)}
                          showBackButton={false}
                      />
                    </div>
                )}
              </>
          }

        </div>
        <div style={{ marginTop: "35px" }}>
          <input type="checkbox" id="checkbox" onChange={manageTerms} />
          <label
            htmlFor="checkbox"
            className="res:mt-0"
            style={{ marginLeft: "4px", fontSize: "16px" }}
          >
            I agree to the{" "}
            <u onClick={openCreateEventModal}>terms of purchase</u>
          </label>

          { alertAgreeded && ( 
              <div> <span style={{ color: "#FF5566", fontSize: "14px"}}> Please agree to the terms of purchase </span> </div>
          )}
          <Modal
            title={<h2>Terms and Conditions</h2>}
            visible={isModalVisible}
            onCancel={onCancel}
            footer={null}
            width={"60vw"}
            destroyOnClose
          >
            <div dangerouslySetInnerHTML={{ __html: whiteLabel.purchaseTerms}}></div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ReviewAndPay;
