import { LatLonPair } from "../store/campaignForm/CampaignForm";
import RestService from "./restService";
import {Source} from "../models/Source";

export interface IDemographicSelectionOptions {
  gender?: string,
  age21_39?: boolean,
  age40_49?: boolean,
  age50_80?: boolean,
  age80?: boolean,
  income60?: boolean,
  income60_100?: boolean,
  income100?: boolean,
  negativeCoordinates?: LatLonPair[]
  negativeCenter?: LatLonPair,
  negativeRadiusInMiles?: number,
}

export interface IMetadataItem {
  id: number;
  attribute_code: string;
  options: string[];
}

export default class AudienceService {
  constructor(protected readonly api: RestService) {}

  async getAudienceInPolygon(
    coordinates: LatLonPair[],
    options: IDemographicSelectionOptions
  ): Promise<GetEstimatedAudienceResponse> {
    const {
      gender,
      age21_39,
      age40_49,
      age50_80,
      age80,
      income60,
      income60_100,
      income100,
      negativeCoordinates,
      negativeCenter,
      negativeRadiusInMiles
    } = options;
    // expects [[lat, lon], [lat, lon]]
    const coordsStrings = coordinates.map((coord): number[] => {
      return [coord.lat, coord.lng];
    });
    const locationsParam = encodeURIComponent(JSON.stringify(coordsStrings));

    const negativeCoordsStrings = negativeCoordinates?.map((coord): number[] => {
      return [coord.lat, coord.lng];
    });
    const negativeLocationsParam = "&negativeLocations=" + encodeURIComponent(JSON.stringify(negativeCoordsStrings));
    const negativeRadiusInMilesParam = `&negativeRadiusInMiles=${negativeRadiusInMiles}`;
    const negativeCenterParam = `&negativeLat=${negativeCenter?.lat}&negativeLng=${negativeCenter?.lng}`;

    const genderQuery = gender ? "&gender=" + gender : "";
    const age21_39Query = age21_39 ? "&age21_39=" + age21_39 : "";
    const age40_49Query = age40_49 ? "&age40_49=" + age40_49 : "";
    const age50_80Query = age50_80 ? "&age50_80=" + age50_80 : "";
    const age80Query = age80 ? "&age80=" + age80 : "";
    const income60Query = income60 ? "&income60=" + income60 : "";
    const income60_100Query = income60_100 ? "&income60_100=" + income60_100 : "";
    const income100Query = income100 ? "&income100=" + income100 : "";

    let negativeAreaParam = '';
    if(negativeRadiusInMiles && negativeRadiusInMiles > 0){
      negativeAreaParam = negativeRadiusInMilesParam + negativeCenterParam;
    }else if(negativeCoordinates?.length ?? 0 > 2){
      negativeAreaParam = negativeLocationsParam;
    }
    
    return await this.api.get(`/data/byPolygon?locations=${locationsParam}${negativeAreaParam}${genderQuery}${age21_39Query}${age40_49Query}${age50_80Query}${age80Query}${income60Query}${income60_100Query}${income100Query}`);
  }

  async getAudienceInCircle(
    center: LatLonPair,
    radiusInMiles: number,
    options: IDemographicSelectionOptions
  ): Promise<GetEstimatedAudienceResponse> {
    const {
      gender,
      age21_39,
      age40_49,
      age50_80,
      age80,
      income60,
      income60_100,
      income100,
      negativeCenter,
      negativeRadiusInMiles,
      negativeCoordinates
    } = options;

    const genderQuery = gender ? "&gender=" + gender : "";
    const age21_39Query = age21_39 ? "&age21_39=" + age21_39 : "";
    const age40_49Query = age40_49 ? "&age40_49=" + age40_49 : "";
    const age50_80Query = age50_80 ? "&age50_80=" + age50_80 : "";
    const age80Query = age80 ? "&age80=" + age80 : "";
    const income60Query = income60 ? "&income60=" + income60 : "";
    const income60_100Query = income60_100 ? "&income60_100=" + income60_100 : "";
    const income100Query = income100 ? "&income100=" + income100 : "";

    const negativeRadiusInMilesParam = `&negativeRadiusInMiles=${negativeRadiusInMiles}`;
    const negativeCenterParam = `&negativeLat=${negativeCenter?.lat}&negativeLng=${negativeCenter?.lng}`;

    const negativeCoordsStrings = negativeCoordinates?.map((coord): number[] => {
      return [coord.lat, coord.lng];
    });
    const negativeLocationsParam = "&negativeLocations=" + encodeURIComponent(JSON.stringify(negativeCoordsStrings));

    let negativeAreaParam = '';
    if(negativeRadiusInMiles && negativeRadiusInMiles > 0){
      negativeAreaParam = negativeRadiusInMilesParam + negativeCenterParam;
    }else if(negativeCoordinates?.length ?? 0 > 2){
      negativeAreaParam = negativeLocationsParam;
    }

    return await this.api.get(
      `/data/byCircle?radiusInMiles=${radiusInMiles}&lat=${center.lat}&lng=${center.lng}${negativeAreaParam}${genderQuery}${age21_39Query}${age40_49Query}${age50_80Query}${age80Query}${income60Query}${income60_100Query}${income100Query}`
    );
  }

  /**
   * Retrieve source list
   */
  async getSourceList(): Promise<string[]> {
    return await this.api.get('/source/list');
  }

  /**
   * Retrieve queries by source
   * @param source
   */
  async getAudiencesBySource(source: string) {
    return await this.api.get('/query/source/$1'.replace('$1', source));
  }

  /**
   * Retrieve fields with options
   *
   * @param source
   */
  async getMetadata(source: string): Promise<IMetadataItem[]> {
    return await this.api.get('/metadata/source/$1'.replace('$1', source));
  }

  /**
   * Create new filter
   *
   * @param payload
   * @param source
   */
  async createNewFilter(payload: object, source: string) {
    return await this.api.post('/query/source/$1'.replace('$1', source), payload);
  }
}

export interface GetEstimatedAudienceResponse {
  total: number;
}
