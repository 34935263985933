import "./ReportsPage.scss";
import { Button, Form, Input, Select, Table, Typography } from "antd";
import type { SelectProps } from "antd";

import DatePicker from "../datepicker/DatePicker";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { selectViewFormat, setViewFormat } from "../../store/Reports/ReportsStore";
import { useDispatch, useSelector } from "react-redux";
import { FranchiseGAnalyticsRow } from "../../services/franchiseService";
import type { ColumnsType } from 'antd/es/table';
import { Column } from '@ant-design/plots';
import { useEffect, useState } from "react";
import { getConversionEvents, getLandingReports, selectConversionEvents, selectLandingReport } from "../../store/Franchise/FranchiseStore";
import { addDaysToDate, formatTimeMinSec } from "../../utility/time";
import { setSelectedMainMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
const { Text } = Typography;

export const ReportsLanding = (): JSX.Element => {
    const dispatch = useDispatch();

    const viewFormat = useSelector(selectViewFormat);
    const conversionEvents = useSelector(selectConversionEvents);
    const landingReport = useSelector(selectLandingReport);
    const [graphData, setGraphData] = useState([] as any);

    const [form] = Form.useForm();
    const onFinish = (e: any) => {
        dispatch(getLandingReports({ startDate: e.startDate, endDate: e.endDate, orderBy: e.orderBy, conversion: e.conversion }));
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    }
    const onOrderChange = (value: string) => {
        form.setFieldsValue({ orderBy: value });
    };
    const onConversionChange = (value: string) => {
        form.setFieldsValue({ conversion: value });
    };

    useEffect(() => {
        if (landingReport.error) console.log(landingReport.error);
        form.setFieldsValue({ startDate: new Date(landingReport.lastStartDate || addDaysToDate(new Date(), -28)) });
        form.setFieldsValue({ endDate: new Date(landingReport.lastEndDate || new Date()) });
        form.setFieldsValue({ orderBy: landingReport.lastOrderBy || 'sessions' });
        form.setFieldsValue({ conversion: landingReport.lastConversion || [] });
        if (landingReport.landing) {
            let gd: any = [];
            for (let i = 0; i < landingReport.landing.length; i++) {
                const item = landingReport.landing[i];
                gd.push({
                    type: 'Sessions',
                    name: item.name.length > 20 ? (item.name.slice(0, 20) + '...') : item.name,
                    value: item.sessions,
                });
                gd.push({
                    type: 'Conversions',
                    name: item.name.length > 20 ? (item.name.slice(0, 20) + '...') : item.name,
                    value: item.conversions,
                });
            }
            setGraphData(gd);
        }
    }, [landingReport]);

    useEffect(() => {
        dispatch(getConversionEvents());
        dispatch(getLandingReports({}));

        dispatch( setSelectedMainMenu("report") );
        dispatch(setSelectedSubMenu("Landing pages"));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const columns: ColumnsType<FranchiseGAnalyticsRow> = [
        {
            title: 'Page',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: 'Sessions',
            dataIndex: 'sessions',
            key: 'sessions',
        },
        {
            title: 'Users',
            dataIndex: 'users',
            key: 'users',
        },
        {
            title: 'Time per session',
            dataIndex: 'aet',
            key: 'aet',
            render: (val => formatTimeMinSec(val))
        },
        {
            title: 'Conversions',
            dataIndex: 'conversions',
            key: 'conversions',
        }
    ];

    const PerformanceChart = () => {
        const config = {
            data: graphData,
            isGroup: true,
            xField: 'name',
            yField: 'value',
            seriesField: 'type',
            xAxis: {
                label: {
                    autoRotate: true,
                    ellipsis: true,
                }
            },
            theme: {
                colors10: [
                    '#4285F4',
                    '#30CEB3',
                ]
            }
        };
        return <Column {...config} />;
    };

    return <div className="reports-page-container res:mb-[180px]">
        <div>
            <div className="report-header">
                <div className="report-title">Landing pages</div>
            </div>
            <div>
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="container-form"
                    layout="vertical"
                >
                    <div className="report-form-container">
                        <Input.Group compact className="res:!grid res:grid-cols-2 res:gap-3 res:before:!hidden res:items-end">
                            <Form.Item
                                label="From"
                                name="startDate"
                                className="res:!w-full res:!m-0"
                                style={{ width: "160px" }}
                                rules={[{ required: true }]}
                            >
                                <DatePicker
                                    use12Hours={true}
                                    showTime={false}
                                    showHour={true}
                                    showMinute={true}
                                    showSecond={false}
                                    className="res:py-3"
                                    style={{ borderRadius: "4px", width: "100%" }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="To"
                                className="res:!w-full res:!m-0"
                                name="endDate"
                                style={{ marginLeft: "16px", width: "160px" }}
                            >
                                <DatePicker
                                    use12Hours={true}
                                    showTime={false}
                                    showHour={true}
                                    showMinute={true}
                                    showSecond={false}
                                    className="res:py-3"
                                    style={{ borderRadius: "4px", width: "100%" }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Sort by"
                                className="res:!w-full res:!m-0"
                                name="orderBy"
                                style={{ marginLeft: "16px", width: "160px" }}
                            >
                                <Select
                                    showArrow
                                    style={{ borderRadius: "4px", width: "100%" }}
                                    onChange={onOrderChange}
                                    options={[
                                        { label: 'Sessions', value: 'sessions' },
                                        { label: 'Users', value: 'users' },
                                        { label: 'Conversions', value: 'conversions' },
                                        { label: 'Time per session', value: 'aet' }
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Conversion/Goal"
                                name="conversion"
                                className="res:!w-full res:!m-0"
                                style={{ marginLeft: "16px", width: "180px" }}
                            >
                                <Select
                                    mode="multiple"
                                    className="custom-tag-select res:leading-[2.75rem]"
                                    placeholder="No filters, show all"
                                    allowClear
                                    showArrow
                                    style={{ borderRadius: "4px", width: "100%" }}
                                    onChange={onConversionChange}
                                    options={
                                        conversionEvents ?
                                            conversionEvents.map(ev => {
                                                return { label: ev[0].toUpperCase() + ev.slice(1), value: ev }
                                            })
                                        :
                                            []
                                    }
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    
                                    className="res:!w-full res:!m-0 report-apply-button pointer-hover"
                                    onClick={() => {
                                    }}
                                >
                                    APPLY
                                    <IconArrowRight />
                                </Button>
                            </Form.Item>
                        </Input.Group>
                    </div>
                </Form>
            </div>
            <div className="report-company-selector" style={{ marginBottom: '25px' }}>
                <Button className={viewFormat == 'Table' ? 'active' : ''} shape="round" onClick={() => {
                    dispatch(setViewFormat('Table'));
                }}>
                    Table
                </Button>

                { ( landingReport.landing != undefined && landingReport?.landing.length > 1)  && 
                    <Button className={viewFormat == 'Graph' ? 'active' : ''} shape='round' onClick={() => {
                        dispatch(setViewFormat('Graph'));
                    }}>
                        Graph
                    </Button>
                }
            </div>
            <div className="report-body">
                {viewFormat == 'Table' ?
                    <Table className="res:!overflow-x-scroll report-alltable" columns={columns} pagination={false} dataSource={landingReport?.landing} />
                    :
                    <PerformanceChart />
                }
            </div>
        </div>
    </div>
}
