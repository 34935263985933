import { Calendar, dateFnsLocalizer, SlotInfo } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./EventCalendar.css";
import React, { Children, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdCampaigns,
  selectAdCampaigns,
} from "../../store/AdCampaigns/AdCampaignStore";
import { fetchEvents, selectEvents } from "../../store/Events/EventStore";
import { Event } from "../../models/Event";
import { AdCampaign } from "../../models/AdCampaign";
import { EventModal } from "./modals/EventModal/EventModal";
import { CalendarDayModal } from "./modals/CalendarDayModal/CalendarDayModal";
import { AdCampaignModal } from "./modals/AdCampaignModal/AdCampaignModal";
import { NewCampaignChannel } from "../../models/CampaignChannel";
import {
  selectNewCampaignChannels,
  selectNewCampaignCustomPackage,
} from "../../store/campaignForm/CampaignForm";
import { appColors } from "../../assets/variables/colors";
import { addDaysToDate } from "../../utility/time";
import { NewCampaignCustomPackage } from "../../models/CampaignPackage";
import SidePanelEventDetails from "./modals/SidePanelEventDetails/SidePanelEventDetails";
import { setSelectedMainMenu } from "store/Navigation/NavigationStore";

const locales = {
  "en-US": enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface EventCalendarProps {
  defaultStartDate?: Date | null
}

export const EventCalendar = (props:EventCalendarProps): JSX.Element => {
  const {defaultStartDate} = props;
  const [defaultDate, setDefaultDate] = useState(defaultStartDate);
  const campaigns: AdCampaign[] = useSelector(selectAdCampaigns);
  const [campaignsToShow, setCampaignsToShow] = useState<AdCampaign[]>([]);
  const events: Event[] = useSelector(selectEvents);
  const [eventsToShow, setEventsToShow] = useState<Event[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = window.innerWidth < 768;
  // console.log('is it mobile?');
  // console.log(isMobile);

  const newCampaignChannels: NewCampaignChannel[] = useSelector(
    selectNewCampaignChannels
  );
  const newCampaignPackage: NewCampaignCustomPackage | null = useSelector(
    selectNewCampaignCustomPackage
  );

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openEventDetails = (event: Event) => {
    setEventToDisplay(event);
    setIsModalVisible(true);
  };

  const dispatch = useDispatch();

  const [eventToDisplay, setEventToDisplay] = useState<Event | null>(null);
  const [campaignToDisplay, setCampaignToDisplay] = useState<AdCampaign | null>(
    null
  );
  const [selectedSlot, setSelectedSlot] = useState<{
    start: Date;
    end: Date;
  } | null>(null);
  const [newCChannels, setNewCChannels] = useState<NewCampaignChannel[]>([]);
  const [newCPackages, setNewCPackages] = useState<NewCampaignCustomPackage[]>(
    []
  );
  useEffect(() => {
    setDefaultDate(defaultStartDate);
  }, [defaultStartDate]);
  useEffect(() => {
    //Don't fetch the campaign objects anymore.
    //dispatch(fetchAdCampaigns({ id: "accId" }));
    if (events.length === 0) {
      dispatch(fetchEvents({ id: "accId" }));
    }

    dispatch( setSelectedMainMenu("event") );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const channelsToShow = newCampaignChannels
      .filter((channel) => channel.readyToDisplay)
      .map((channel) => {
        if (channel.endDate === undefined) {
          const year = channel.startDate.getFullYear();
          return {
            ...channel,
            endDate: new Date(
              year + 1,
              channel.startDate.getMonth(),
              channel.startDate.getDate()
            ),
          };
        }
        return channel;
      });
    setNewCChannels(channelsToShow);
  }, [newCampaignChannels]);

  useEffect(() => {
    if (newCampaignPackage) {
      setNewCPackages([newCampaignPackage]);
    } else {
      setNewCPackages([]);
    }
  }, [newCampaignPackage]);

  useEffect(() => {
    const campaignsWithEndDates = campaigns.map((campaign) => {
      if (campaign.endDate === undefined || campaign.endDate === null) {
        const year = campaign.startDate.getFullYear();
        return {
          ...campaign,
          endDate: new Date(
            year + 1,
            campaign.startDate.getMonth(),
            campaign.startDate.getDate()
          ),
        };
      }
      return campaign;
    });
    setCampaignsToShow(campaignsWithEndDates);
  }, [campaigns]);

  useEffect(() => {
    const eventsWithEndDates = events.map((event) => {
      
      if (event.endDate === undefined || event.endDate === null) {
        if( event.alwaysOn ){
          //Adds a Year to "always on" events created automatically.
          //Always on events have no endDate defined.
          const nextYear = addDaysToDate(event.startDate, 365);
          return {
            ...event,
            endDate: new Date(
              nextYear.getFullYear(),
              nextYear.getMonth(),
              nextYear.getDate()
            ),
          };
        }else{
          const nextDay = addDaysToDate(event.startDate, 1);
          return {
            ...event,
            endDate: new Date(
              nextDay.getFullYear(),
              nextDay.getMonth(),
              nextDay.getDate()
            ),
          };
        }
        
      }
      
      return event;
    });
    setEventsToShow(eventsWithEndDates);
  }, [events]);

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const CustomCellBackground = ({ children, value }: any) => {
    const bgColor: string =
      value.getMonth() !== today.getMonth() ? "#e6e6e6" : "#F4F4F4";
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: bgColor,
        border: "none",
        margin: "2px",
        borderRadius: "5px",
      },
    });
  };

  const CustomDateTitle = ({ label, date }: any) => {
    if (date < today) {
      return <div style={{ color: "#9999" }}>{label}</div>;
    }
    return <div>{label}</div>;
  };

  const CustomHeaderLabel = ({ label }: any) => {
    return (
      <div style={{ border: "none", margin: "2px", borderRadius: "5px" }}>
        {label}
      </div>
    );
  };

  const eventStyleGetter = (
    calendarEvent:
      | AdCampaign
      | Event
      | NewCampaignChannel
      | NewCampaignCustomPackage
  ) => {
    let backgroundColor = undefined;

    const adCampaign = calendarEvent as AdCampaign;
    if (adCampaign.campaignChannels !== undefined) {
      // if (adCampaign.channel === Channel.Facebook) {
      backgroundColor = "#6F5B87";
      // } else if (adCampaign.channel === Channel.Digital) {
      //   backgroundColor = "#00BCD4";
      // } else if (adCampaign.channel === Channel.Email) {
      //   backgroundColor = "#F44336";
      // } else if (adCampaign.channel === Channel.DirectMail) {
      //   backgroundColor = "#9C27B0";
      // } else if (adCampaign.channel === Channel.GrandOpening) {
      //   backgroundColor = "#9C27B0";
      // }
    } else {
      const newChannel = calendarEvent as NewCampaignChannel;
      const newPackage = calendarEvent as NewCampaignCustomPackage;
      if (
        newChannel.readyToDisplay !== undefined ||
        newPackage.customPackage !== undefined
      ) {
        backgroundColor = appColors.SECONDARY_PURPLE;
      } else {
        // no channel means we have an Event type event
        const event = calendarEvent as Event;
        if (event.details !== undefined) {
          backgroundColor = "#009051";
        }
      }
    }
    const style = {
      backgroundColor: backgroundColor,
    };
    return {
      style: style,
    };
  };

  const eventTitleGetter = (
    calendarEvent:
      | AdCampaign
      | Event
      | NewCampaignChannel
      | NewCampaignCustomPackage
  ): string => {
    const newChannel = calendarEvent as NewCampaignChannel;
    const newPackage = calendarEvent as NewCampaignCustomPackage;
    if (
      newChannel.readyToDisplay !== undefined ||
      newPackage.customPackage !== undefined
    ) {
      return newChannel.channelType?.title ?? newPackage.customPackage.title;
    } else {
      const cEvent = calendarEvent as AdCampaign;
      if (cEvent.receiptNumber !== undefined) {
        return cEvent.title;
      } else {
        return cEvent.title;
      }
    }
  };

  const setCalendarEventToDisplay = (
    calendarEvent:
      | AdCampaign
      | Event
      | NewCampaignChannel
      | NewCampaignCustomPackage
  ): void => {
    const adCampaign = calendarEvent as AdCampaign;
    if (adCampaign.campaignChannels !== undefined) {
      setCampaignToDisplay(adCampaign);
      setEventToDisplay(null);
      setSelectedSlot(null);
    } else {
      const newChannel = calendarEvent as NewCampaignChannel;
      // we do nothing if it's a new channel for now
      if (newChannel.readyToDisplay === undefined) {
        // no channel means we have an Event type event
        const event = calendarEvent as Event;
        if (event.details !== undefined) {
          openEventDetails(event)
          setCampaignToDisplay(null);
          setSelectedSlot(null);
        }
      }
    }
  };

  const onDaySelect = (slot: SlotInfo): void => {
    setSelectedSlot({ start: new Date(slot.start), end: new Date(slot.end) });
  };

  return (
    <>
      {eventToDisplay && (
          <SidePanelEventDetails visible={isModalVisible} setVisible={setIsModalVisible}  event={eventToDisplay}  />

        // <EventModal
        //   event={eventToDisplay}
        //   closeModal={() => setEventToDisplay(null)}
        // />
      )}
      {campaignToDisplay && (
        <AdCampaignModal
          campaign={campaignToDisplay}
          closeModal={() => setCampaignToDisplay(null)}
        />
      )}
      {selectedSlot && (
        <CalendarDayModal
          slot={selectedSlot}
          closeModal={() => setSelectedSlot(null)}
          selectCalendarEvent={setCalendarEventToDisplay}
        />
      )}
      <Calendar
        selectable={true}
        localizer={localizer}
        titleAccessor={eventTitleGetter}
        events={[
          ...eventsToShow,
          ...newCChannels,
          ...newCPackages,
          ...campaignsToShow,
        ]}
        date={defaultDate != null ? defaultDate : new Date()}
        eventPropGetter={eventStyleGetter}
        startAccessor="startDate"
        endAccessor="endDate"
        style={{ height: "100%" }}
        onNavigate={(date) =>{
          setDefaultDate(date);
        }}
        onSelectEvent={setCalendarEventToDisplay}
        views={["month"]}
        defaultView="month"
        onSelectSlot={onDaySelect}
        doShowMoreDrillDown
        onShowMore={(events, date) =>
          onDaySelect({
            start: date,
            end: new Date(addDaysToDate(date, 1)),
          } as SlotInfo)
        }
        components={{
          dateCellWrapper: CustomCellBackground,
          month: {
            dateHeader: CustomDateTitle,
          },
          header: CustomHeaderLabel,
        }}
      />
    </>
  );
};
