
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import services from "../../services";
import { RootState } from "../index";
import { QRPromCode } from "../../models/QRCode";
import type { INewPromCodeBatch, INewClaimPromCode } from "../../services/QRPromCodesService";

import Cookies from 'js-cookie';

/*export const validatePromCode = createAsyncThunk(
  "qrPromCodes/fetch",
  async (code: string) => {
    return services.qrPromCodesService.validatePromCode(
      code,
    );
  }
);*/

export const validatePromCode = createAsyncThunk<
  QRPromCode,
  {
    code: string,
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "qrPromCodes/fetch",
  async({code}, {rejectWithValue}) => {
    try{
      let result = await services.qrPromCodesService.validatePromCode(code);
      return result;
    }catch (error: any) {
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);
    }
  }
)

export const createPromCodeBatch = createAsyncThunk(
  "qrPromCodes/create",
  async (newCodeBatch: INewPromCodeBatch) => {
    return services.qrPromCodesService.generatePromCodeBatch(
      newCodeBatch,
    );
  }
);

//QR Extension; Creates a single promoCode based on a QRCampaign ID.
/*export const createPromoCode = createAsyncThunk(
  "qrPromCodes/create-single",
  async( {userEmail, qrCampaignId }: { userEmail: string, qrCampaignId: string } ) => {
    return services.qrPromCodesService.generatePromoCode(userEmail, qrCampaignId);
  }
);*/

export const createPromoCode = createAsyncThunk<
  QRPromCode,
  {
    userEmail: string,
    qrCampaignId: string,
    channel: string | null,
    code: string,
    fullPhoneNumber: string,
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "qrPromCodes/create-single",
  async({userEmail, qrCampaignId, channel, code, fullPhoneNumber}, {rejectWithValue}) => {
    try{
      //Checks if there is a cookie for this campaignID already, if there is, abort and throw the error.
      if( Cookies.get(qrCampaignId) != undefined ){
        return rejectWithValue(["You have reached your limit of promotional codes for this campaign."]);
      }
      
      let result = await services.qrPromCodesService.generatePromoCode(userEmail, qrCampaignId, channel, code, fullPhoneNumber);
      return result;
    }catch (error: any) {
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }
      return rejectWithValue(formattedErrors);
    }
  }
)

export const claimPromCode = createAsyncThunk<
  QRPromCode,
  {
    id: string,
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "qrPromCodes/claim",
  async({id}, {rejectWithValue}) => {
    console.log(id);
    try{
      let result = await services.qrPromCodesService.claimPromCode(id);
      return result;
    }catch (error: any) {
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);
    }
  }
)

/*export const claimPromCodeBatch = createAsyncThunk(
  "qrPromCodes/update",
  async (newClaimCode: INewClaimPromCode) => {
    return services.qrPromCodesService.claimPromCode(
      newClaimCode,
    );
  }
);*/

export const qrPromCodesSlice = createSlice({
  name: "qrPromCodes",
  initialState: {
    qrPromCodes: [] as QRPromCode[],
    createdQrPromoCode: null as QRPromCode | null,
    scannedQrPromoCode: null as QRPromCode | null,
    qrPromCodeIsLoading: false,
    createQRPromoCodeErrors: [] as String[],
    qrPromCodeRedeemed: false,
  },
  reducers: {
    setQRPromCodes: (
      state,
      { payload: codes }: PayloadAction<QRPromCode[]>
    ) => {
      state.qrPromCodes = codes;
    },
    setCreateQRPromoCodeErrors: (
      state,
      { payload: errors}: PayloadAction<String[]>
    ) =>{
      state.createQRPromoCodeErrors = errors;
    },
    setScannedQRPromoCode:(
      state,
      { payload: scannedQRPromoCode }: PayloadAction<QRPromCode | null>
    ) =>{
      state.scannedQrPromoCode = scannedQRPromoCode;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createPromCodeBatch.fulfilled, (state, action) => {
      state.qrPromCodes = action.payload;
    });

    builder.addCase( createPromoCode.fulfilled, (state, action)  => { 
      state.createdQrPromoCode = action.payload;
      state.qrPromCodeIsLoading = false;

      //The promo code was succesfully created and the campaign ID is available to verify.
      //Adds a cookie specific to this campaignID, to prevent the user from creating another promo code for 3 Days for this campaign.
      Cookies.set(action.payload.qrCampaignId, 'claimed', {expires: 3});
    });

    builder.addCase( createPromoCode.rejected, (state, action)  => { 
      console.log(action);
      state.qrPromCodeIsLoading = false;
      if( action.meta.rejectedWithValue ){
        state.createQRPromoCodeErrors = action.payload!;
      }
    });

    builder.addCase( validatePromCode.fulfilled, (state,action) =>{
      state.qrPromCodeIsLoading = false;
      state.scannedQrPromoCode = action.payload;
    });

    builder.addCase( validatePromCode.rejected, (state, action)  => { 
      state.qrPromCodeIsLoading = false;
      if( action.meta.rejectedWithValue ){
        state.createQRPromoCodeErrors = action.payload!;
      }
    });

    builder.addCase( claimPromCode.fulfilled, (state,action) =>{
      state.qrPromCodeIsLoading = false;
      state.qrPromCodeRedeemed = true;
      //Nothing to do with the payload...
    });

    builder.addCase( claimPromCode.rejected, (state, action)  => { 
      state.qrPromCodeIsLoading = false;
      if( action.meta.rejectedWithValue ){
        state.createQRPromoCodeErrors = action.payload!;
      }
    });
  },
});

export const selectQRPromCodeBatch = (state: RootState): QRPromCode[] =>
  state.qrPromCodes.qrPromCodes;

export const selectCreatedQRPromoCode = (state:RootState): QRPromCode | null =>
  state.qrPromCodes.createdQrPromoCode;

export const selectScannedQRPromoCode = (state:RootState): QRPromCode | null =>
  state.qrPromCodes.scannedQrPromoCode;

export const selectQRPromCodeIsLoading = (state: RootState): boolean =>
  state.qrPromCodes.qrPromCodeIsLoading;

export const selectQRPromCodeRedeemed = (state: RootState): boolean =>
  state.qrPromCodes.qrPromCodeRedeemed;

export const selectCreateQRPromoCodeErrors = (state: RootState): String[] =>
  state.qrPromCodes.createQRPromoCodeErrors;

export const { setCreateQRPromoCodeErrors, setScannedQRPromoCode } = qrPromCodesSlice.actions;

export default qrPromCodesSlice.reducer;