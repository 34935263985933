import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QRCampaign } from "../../models/QRCampaign";
import { RootState } from "../index";
import services from "../../services";

import { INewQRCampaign, IUpdateQRCampaign } from "services/QRCampaignService";

import Cookies from 'js-cookie';

export const fetchQRCampaigns = createAsyncThunk(
  "qr-campaigns/fetch",
  async () => {
    return services.qrCampaignService.getQRCampaigns();
  }
);

export const createQRCampaign = createAsyncThunk(
  "qr-campaigns/create",
  async ( createQRCampaign : INewQRCampaign ) =>{
    return services.qrCampaignService.createQRCampaign(createQRCampaign);
  }
);

export const updateQRCampaign = createAsyncThunk(
  "qr-campaigns/update",
  async( updateQRCampaign: IUpdateQRCampaign )=>{
    return services.qrCampaignService.updateQRCampaign(updateQRCampaign);
  }
);

export const deleteQRCampaign = createAsyncThunk(
  "qr-campaigns/delete",
  async( updateQRCampaign: IUpdateQRCampaign) =>{
    return services.qrCampaignService.deleteQRCampaign(updateQRCampaign);
  }
);

export const validateQRCampaign = createAsyncThunk(
  "qr-campaigns/validate",
  async( code: string ) => {
    return services.qrCampaignService.validateCampaignCode(code);
  }
);

export const sendSmsCode = createAsyncThunk<
  boolean,
  {
    countryCode: number,
    phoneNumber: string,
    qrCampaignId: string,
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "qr-campaigns/send-qr",
  async({countryCode, phoneNumber, qrCampaignId}, {rejectWithValue}) => {
    try{
      

      //Checks if there is a cookie for this campaignID already, if there is, abort and throw the error.
      //Doesn't even needs to send the SMS.
      if( Cookies.get(qrCampaignId) != undefined ){
        return rejectWithValue(["You have reached your limit of promotional codes for this campaign."]);
      }
      
      let result = await services.qrCampaignService.sendSmsCode(countryCode, phoneNumber, qrCampaignId);
      return result;

    }catch (error: any) {
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }else{
        //Generic error
        formattedErrors.push("Could not send the SMS Code. Please try again later.")
      }

      return rejectWithValue(formattedErrors);
    }
  }
)



export const QRCampaignSlice = createSlice({
  name: "qrCampaigns",
  initialState: {
    qrCampaigns: [] as QRCampaign[],
    qrCampaignsErrors: [] as String[] | null,
    qrCampaignToEdit: null as QRCampaign | null,

    validatedQRCampaign: null as QRCampaign | null,
    createdQRCampaign: null as QRCampaign | null,
    validationLoaded: false,
    smsCodeSent: false,
    smsCodeErrors: [] as String[],
  },
  reducers: {
    setQRCampaigns: (
      state,
      { payload: qrCampaigns }: PayloadAction<QRCampaign[]>
    ) => {
      state.qrCampaigns = qrCampaigns;
    },
    setQRCampaignsErrors: (
      state,
      { payload: qrCampaignsErrors }: PayloadAction<String[] | null>
    ) => {
      state.qrCampaignsErrors = qrCampaignsErrors;
    },
    setQRCampaignToEdit: (state, {payload: qrCampaign}: PayloadAction<QRCampaign>) => {
      state.qrCampaignToEdit = qrCampaign;
    },
    setCreatedQRCampaign: (state, {payload: qrCampaign}: PayloadAction<QRCampaign | null>) => {
      state.createdQRCampaign = qrCampaign;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQRCampaigns.fulfilled, (state, action) => {
      state.qrCampaigns = action.payload;
    });

    builder.addCase(createQRCampaign.fulfilled, (state,action) =>{
      state.createdQRCampaign = action.payload;
    })

    builder.addCase(updateQRCampaign.fulfilled, (state,action) =>{
      state.createdQRCampaign = action.payload;
    })

    builder.addCase( sendSmsCode.fulfilled, (state, action) =>{
      state.smsCodeSent = true;
      state.smsCodeErrors = [];
    })

    builder.addCase(validateQRCampaign.fulfilled, (state,action) =>{
      state.validatedQRCampaign = action.payload;
      state.validationLoaded = true;
    })
    
    builder.addCase(validateQRCampaign.rejected, (state, action) =>{
      state.validationLoaded = true;
    });

    builder.addCase( sendSmsCode.rejected, (state, action) =>{
      if( action.meta.rejectedWithValue ){
        state.smsCodeErrors = action.payload!;
      }
    });
  },
});

export const selectQRCampaigns = (state: RootState): QRCampaign[] =>
  state.qrCampaigns.qrCampaigns;

export const qrCampaignsErrors = (state: RootState): String[] | null =>
  state.qrCampaigns.qrCampaignsErrors;

export const selectValidatedQRCampaign = (state: RootState): QRCampaign | null =>
  state.qrCampaigns.validatedQRCampaign;

export const selectValidationLoaded = (state: RootState): boolean =>
  state.qrCampaigns.validationLoaded;

export const selectQRCampaignToEdit = (state: RootState): QRCampaign | null =>
  state.qrCampaigns.qrCampaignToEdit;

export const selectCreatedQRCampaign = (state: RootState): QRCampaign | null =>
  state.qrCampaigns.createdQRCampaign;

export const selectSmsCodeSent = (state:RootState): boolean =>
  state.qrCampaigns.smsCodeSent;

export const selectSmsCodeErrors = (state:RootState): String[] =>
  state.qrCampaigns.smsCodeErrors;

export const { setQRCampaigns, setQRCampaignToEdit, setCreatedQRCampaign } = QRCampaignSlice.actions;
  
export default QRCampaignSlice.reducer;
