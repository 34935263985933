import style from '../mediaManager/MediaManager.module.css'
import {useState} from "react";

interface AssignDataSourceListProps {
  label: string;
  options: string[];
  setAssignedDataSource: (value: string) => void;
}

const AssignDataSourceList = ({label, options, setAssignedDataSource }: AssignDataSourceListProps) => {

    const [selectedOption, setSelectedOption] = useState<string>('');

    const toggleSelection = (option: string) => {
        const currentOption = (selectedOption === option) ? '' : option;
        setSelectedOption(currentOption);
        setAssignedDataSource(currentOption);
    };

    return (
      <div>
        <p>{label}</p>
        <div className={style["column"]}>
          {options.map(option => (
            <div
                key={option}
                className={`${style["item"]} ${selectedOption === option ? style["selected"] : ''}`}
                onClick={() => toggleSelection(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    );
};
export { AssignDataSourceList };
