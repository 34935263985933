import { MonthlyInvoices } from "../models/MonthlyInvoices";
import RestService from "./restService";


export default class MonthlyInvoicesListService {
  constructor(protected readonly api: RestService) {}

  async getMonthlyInvoicesList(): Promise<MonthlyInvoices[]> {
    return await this.api.get<MonthlyInvoices[]>("/monthly-invoices");
  }
}
