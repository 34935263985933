import { Row, Col } from "antd";
import React from "react";
import { connect } from 'react-redux';
import './FileUploader.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ParseResult } from "papaparse";
import { EmailRowSchema } from "../../../services/EmailService";
import { importEmails, importErrors, importEmailsResult } from "../../../store/Email/EmailStore"
import { RootState } from "../../../store";

interface EmailUploaderProps {
    title: string,
    notifyErrors: Function,
    importEmails: Function,
    emailErrors: String[] | null,
    importEmailsResult: String | null
}

interface EmailUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class EmailUploader extends React.Component<EmailUploaderProps, EmailUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<EmailUploaderProps>): void {
        if( prevProps.emailErrors !== null){
            if( prevProps.emailErrors !== this.props.emailErrors){
                this.props.notifyErrors(
                    this.props.emailErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.emailErrors !== null ){
            if( prevProps.importEmailsResult !== this.props.importEmailsResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importEmailsResult);
            }
        }
    }

    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        //Verify if the file is not null.
        if( !event.target.files )
            return;

        //Verifies if the file is supported (csv or xlsx)
        let fileType = event.target.files[0].type;

        this.setState({
            fileTypeError: "The selected file is not valid. Only CSV and XLSX files are supported."
        });

        if(
            fileType === 'application/vnd.ms-excel' ||
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'text/csv'
        ){
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        }
    }

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        //This process is different depending on the file type.
        let fileJson = [];

        let selectedFile = this.state.selectedFile;

        if( selectedFile?.type === 'text/csv'){
            this.parseCsvFile(selectedFile).then((json) => {
                console.log('Trying to import the emails csv file...');
                console.log(json);
                this.props.importEmails(json);
            }).catch( (error: Error) => {

            });
        }else{
            this.parseExcelFile(selectedFile!).then((json) => {
                console.log('Trying to import the emails xlsx file...');
                console.log(json);
                this.props.importEmails(json);
            }).catch( (error: Error) => {

            });
        }
    }

    validateRow = (element: EmailRowSchema, rowNum: number, errors: string[]) => {
        //Check if each row has fields with actual values.
        //the csv library fills the nonexistent fields with empty strings
        if( element.to === "")
            errors.push("<b>Row #"+rowNum+"</b> is missing the 'To' cell.");
        if( element.cc === "")
            errors.push("<b>Row #"+rowNum+"</b> is missing the 'CC' cell.");
        if( element.firstname === "")
            errors.push("<b>Row #"+rowNum+"</b> is missing the 'firstname' cell.");
        if( element.location === "")
            errors.push("<b>Row #"+rowNum+"</b> is missing the 'location' Customer ID cell.");
        if( element.channel === "")
            errors.push("<b>Row #"+rowNum+"</b> is missing the channel Campaign ID cell.");
    }

    //Validates and obtains the data as JSON for a XLSX file
    parseExcelFile = (file: File) => {

        return new Promise<EmailRowSchema[]>( (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: "array"} );
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json<EmailRowSchema>(
                    worksheet,
                    {
                        //transforms de header names into camelCase.
                        raw: false,
                        header: [
                            'to',
                            'cc',
                            'firstname',
                            'location',
                            'channel',
                            'month',
                            'budget',
                            'percent',
                            'next_steps',
                            'template'
                        ],
                        range: 1,
                    }
                );

                //The library doesn't seem to support a method to validate
                //empty cells, so we validate this after processing.
                //empty cells are completely ignored by the library, so we need to
                //validate the existence of these missing attributes.
                let errors: string[] = [];
                json.forEach( (element, index) => {
                    this.validateRow(element, index+1, errors);
                });

                //Reject this promise and notify the parent about the errors
                //to display in the large alert window.
                if( errors.length > 0 ){

                    this.props.notifyErrors(
                        errors,
                        "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                    );

                    reject(Error('There were some errors in the file.'));
                }

                resolve(json);
            }
            reader.readAsArrayBuffer(file);
        });

    }

    //Validates and obtains data as JSON from a CSV file
    parseCsvFile = ( file: File ) => {
        return new Promise<EmailRowSchema[]>( (resolve, reject) => {
            Papa.parse( file, {
                header: true,
                transformHeader: ((header: string) => this.camelize(header)),
                complete: (results: ParseResult<EmailRowSchema> ) => {
                    //Need to adjust the header names, and we do so while
                    //manually validating each row.
                    let json = results.data;
                    let errors: string[] = [];
                    json.forEach( (element, index) => {
                        let rowNum = index+1;
                        this.validateRow(element, rowNum, errors);
                    });

                    //Reject this promise and notify the parent about the errors
                    //to display in the large alert window.
                    if( errors.length > 0 ){

                        this.props.notifyErrors(
                            errors,
                            "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                        );

                        reject(Error('There were some errors in the file.'));
                    }

                    resolve(json);

                },
                error: error => {
                    reject( Error( error.message ) );
                }
            });
        });
    }

    //TODO: Esto no debe estar aquí.
    camelize = (str: string) => {
        str = str.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
        {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    render(){

        let button;
        if( this.state.selectedFile ){
            button =
                <div>
                    <div onClick={this.onFileUpload}
                         className="primary-button pointer-hover p-[8px_25px]"
                    >
                        <div className="primary-button-text">UPLOAD</div>
                    </div>
                </div>
        }else{
            button =
                <div>
                    <input id={"file-uploader-franchise"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-franchise"}>
                        <div
                            className="primary-button pointer-hover p-[8px_25px]"
                        >
                            <div className="primary-button-text">IMPORT</div>
                        </div>
                    </label>
                </div>
        }

        return (
            <div className="file-upload-area mb-10">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col >
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }

                        { this.state.selectedFile == null &&
                            <a target="_blank" download={true} className="template-download" href="/templates/template_email.xlsx">Template</a>
                        }

                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        importEmails: ( emailSchemaArray: EmailRowSchema[] ) => dispatch(importEmails({emailSchemaArray: emailSchemaArray}) )
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        emailErrors: state.emails.importErrors,
        importEmailsResult: state.emails.importResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailUploader);