import { ChannelType } from "../models/Channel";
import RestService from "./restService";

export default class ChannelService {
  constructor(protected readonly api: RestService) {}

  async getChannels(accountId: string): Promise<ChannelType[]> {
    return await this.api.get<ChannelType[]>("/channeltypes");
  }

  async importChannels(ChannelSchemaArray: ChannelRowSchema[]): Promise<ChannelType[]> {
    const apiResponse = await this.api.post<ChannelType[]>("/channeltypes/import", ChannelSchemaArray);

    return apiResponse;
  }
}



export interface ChannelRowSchema {
  franchise: string,
  title: string,
  durationWeeks: number,
  investmentInDollarsPerTarget: number,
  setSpend: number,
  tooltip: string,
  sortOrder: number,
}

