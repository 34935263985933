import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appColors } from "../../../assets/variables/colors";
import {
  selectReportCompany,
  selectReportTitle,
  setReportTitle,
  selectPerformanceTitle,
  setPerformanceTitle,
} from "../../../store/Reports/ReportsStore";

import "./styles.css";
import { selectReportsLocationId } from "../../../store/Reports/ReportsStore";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { selectSelectedSubMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
import { selectUserAccount } from "../../../store/Account/AccountStore";
import { UserRole } from "../../../models/User";

import { selectCredentialValidation } from '../../../store/Franchise/FranchiseStore'

const ReportsDashboard = ({openExtraVar}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportTitle = useSelector(selectReportTitle);
  const performanceTitle = useSelector(selectPerformanceTitle);
  const selectedSubMenu = useSelector( selectSelectedSubMenu);
  const locationId = useSelector(selectReportsLocationId);
  const whiteLabel = useSelector( selectWhiteLabel );
  const reportCompany = useSelector(selectReportCompany);
  const user = useSelector(selectUserAccount);
  const credentialValidation = useSelector(selectCredentialValidation);

  if (locationId != '') {
    const repTitles = ['Impressions & clicks'];

    if ( reportCompany === 'Google' ) {
        repTitles.splice(1,0,'Keyword performance')
    }


    return <>{repTitles.map(title =>
      <div
        onClick={() => {
          dispatch(setReportTitle(title));
        }}
        className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
        style={{
          backgroundColor:
            reportTitle === title
              ? whiteLabel.activeColor
              : "transparent",
        }}
        key={title}
      >
        <p>{title}</p>
      </div>
    )}</>
  } else {
    const perfTitles = [ 'Performance overview', 'Active campaigns', 'Keywords & creative', 'Landing pages'];

    return <>
      <div

          onClick={() => {
            dispatch(setPerformanceTitle("Performance overview"));
            dispatch(setSelectedSubMenu("Performance overview"));
            navigate(AvailableRoutes.PERFORMANCE_OVERVIEW);
          }}
          className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
          style={{
            backgroundColor:
              selectedSubMenu === "Performance overview"
                ? whiteLabel.activeColor
                : "transparent",
          }}
          key={"Performance overview"}
        >
          <p>{"Performance overview"}</p>
      </div>

      
      <div
          onClick={() => {
            dispatch(setPerformanceTitle("SActive campaigns"));
            dispatch(setSelectedSubMenu("Active campaigns"));
            navigate(AvailableRoutes.ACTIVE_CAMPAIGNS);
          }}
          className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
          style={{
            backgroundColor:
              selectedSubMenu === "Active campaigns"
                ? whiteLabel.activeColor
                : "transparent",
          }}
          key={"Active campaigns"}
        >

          <p>{"Active campaigns"}</p>

      </div>
      
      
{/*       <div
          onClick={() => {
            dispatch(setPerformanceTitle("Keywords & creative"));
            dispatch(setSelectedSubMenu("Keywords & creative"));
            navigate(AvailableRoutes.KEYWORDS_CREATIVE);
          }}
          className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
          style={{
            backgroundColor:
              selectedSubMenu === "Keywords & creative"
                ? whiteLabel.activeColor
                : "transparent",
          }}
          key={"Keywords & creative"}
        >
          <p>{"Keywords & creative"}</p>
      </div> */}

      
      { credentialValidation.googleAnalytics && (
        <div
            onClick={() => {
              dispatch(setPerformanceTitle("Landing pages"));
              dispatch(setSelectedSubMenu("Landing pages"));
              navigate(AvailableRoutes.REPORTS_LANDING);
            }}
            className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
            style={{
              backgroundColor:
                selectedSubMenu === "Landing pages"
                  ? whiteLabel.activeColor
                  : "transparent",
            }}
            key={"Landing pages"}
          >
            <p>{"Landing pages"}</p>
        </div>
      )}

      
        <div
            onClick={() => {
              dispatch(setSelectedSubMenu("Spend & pacing"));
              navigate(AvailableRoutes.SPEND_PACING);
            }}
            className={`report-sidebar-item pointer-hover ${ openExtraVar? "res:flex" : "res:hidden" } `}
            style={{
              backgroundColor:
                selectedSubMenu === "Spend & pacing"
                  ? whiteLabel.activeColor
                  : "transparent",
            }}
            key={"Spend & pacing"}
          >
            <p>{"Spend & pacing"}</p>
        </div>
    </>
  }
};

export default React.memo(ReportsDashboard);
