import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectableLocations } from "../../../store/campaignForm/CampaignForm";
import "./styles.css";
import {
    getFranchiseeLocationsByEmail,
    getFranchisees,
    getUnregisteredFranchisees,
    selectFranchisees,
    selectUnregisteredFranchisees,
    selectUserAccount,
} from "../../../store/Account/AccountStore";
import { UserRole } from "../../../models/User";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { IUser } from "../../../models/User";
import { Location } from "../../../models/Location";
import { selectFranchiseLocations, selectLocations, getFranchiseeLocationsByMarketName } from "../../../store/Location/LocationStore";
import {Select } from 'antd';

export default function AccountSelection() {
    const user = useSelector(selectUserAccount);
    const dispatch = useDispatch();
    const franchisees = useSelector(selectFranchisees);
    const unregisteredFranchisees = useSelector(selectUnregisteredFranchisees);
    const franchiseLocations = useSelector(selectFranchiseLocations);
    const [selectedFranchiseeEmail, setSelectedFranchiseeEmail] = useState<null | string>(null);
    const [selectedLocationMarket, setSelectedLocationMarket] = useState<null | string>(null);
    const whiteLabel = useSelector(selectWhiteLabel);

    const [accountSource, setAccountSource] = useState("franchisee"); //"franchisee", "market"
    const [filterType, setFilterType] = useState("all"); //"all", "by"

    useEffect(() => {
        dispatch(getFranchisees());
        dispatch(getUnregisteredFranchisees());
    }, []);

    useEffect(() => {
        if( accountSource == "franchisee"){
            dispatch(getFranchiseeLocationsByEmail(selectedFranchiseeEmail));
        }else if( accountSource == "market"){
            console.log("Loading for market...");
            console.log(selectedLocationMarket);
            dispatch(getFranchiseeLocationsByMarketName(selectedLocationMarket));
        }
        
    }, [selectedFranchiseeEmail, selectedLocationMarket]);

    useEffect(() => {
        if (user?.type == UserRole.Franchisor || user?.type == UserRole.Agency) {
            if (filterType == "all") {
                setSelectedFranchiseeEmail(null);
                dispatch(setSelectableLocations("FRANCHISE"));
            } else if( accountSource ==  "franchisee") {
                dispatch(setSelectableLocations("FRANCHISEE"));
                preSelectFirstFranchisee();
            }else if( accountSource ==  "market") {
                dispatch(setSelectableLocations("MARKET"));
                preSelectFirstMarket();
            }
        } else {
            dispatch(setSelectableLocations("USER"));
        }
    }, [filterType, accountSource, user]);

    function preSelectFirstFranchisee() {
        var franchiseesSorted = [
            ...unregisteredFranchisees,
            ...franchisees.map(
                (franchisee): string => franchisee.email
            ),
        ].sort((a, b) => (a > b ? 1 : -1));

        setSelectedFranchiseeEmail(franchiseesSorted[0])
    }
    function preSelectFirstMarket() {
        var marketsSorted = [
            ...new Set(
                 franchiseLocations.map(
                     (location): string => location.marketName
                 ).filter((marketName) => marketName !== null && marketName !== "")
             ),
         ].sort((a, b) => (a > b ? 1 : -1))

        setSelectedLocationMarket(marketsSorted[0])
    }
    return (user?.type == UserRole.Franchisor || user?.type == UserRole.Agency) ?
        <div>
            <RadioGroup
                onChange={(e) => setFilterType(e.target.value)}
                value={filterType}
            >
                <FormControlLabel value="all" control={<Radio sx={{
                    color: whiteLabel.primaryColor,
                    "&.Mui-checked": {
                        color: whiteLabel.primaryColor,
                    }
                }} />} label="All Locations" />
                <div className="flex items-center justify-start">
                    <FormControlLabel className="" value="by" control={
                        
                        <Radio sx={{
                            color: whiteLabel.primaryColor,
                            "&.Mui-checked": {
                                color: whiteLabel.primaryColor,
                            }
                        }} />
                        
                    } label="By" />

                    <Select
                        showArrow
                        style={{ borderRadius: "4px", width: "100%" }}
                        value={accountSource}
                        onChange={ (value) => {setAccountSource(value)} }
                        options={[{ label: 'Owner', value: 'franchisee' }, { label: 'Market', value: 'market' }]}
                    />
                </div>
                
            </RadioGroup>
            {(filterType == "by"  && accountSource == 'franchisee') && <div className="franchisees-container">
                {[
                    // ...unregisteredFranchisees,
                    ...franchisees.map(
                        (franchisee): IUser => franchisee
                    ),
                ]
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((franchisee, index) =>
                        <div key={`franchisee selector: ${typeof franchisee === 'string' ? franchisee :`${franchisee.firstName} ${franchisee.lastName} `}`} 
                        // ${index == 1 ? 'active' : ''}
                        className={`franchisee-email ${(typeof franchisee === 'string' ? franchisee : franchisee.email) == selectedFranchiseeEmail ? 'active' : ''}  `} 
                        onClick={() => {setSelectedFranchiseeEmail(typeof franchisee === 'string' ? franchisee : franchisee.email);}}>
                            <span>{typeof franchisee === 'string' ? franchisee :`${franchisee.firstName} ${franchisee.lastName} `}</span>
                        </div>
                    )}
            </div>}
            {(filterType == "by"  && accountSource == 'market') && <div className="franchisees-container">
                {[
                   ...new Set(
                        franchiseLocations.map(
                            (location): string => location.marketName
                        ).filter((marketName) => marketName !== null && marketName !== "")
                    ),
                ]
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((marketName, index) =>
                        <div key={`franchisee selector: ${typeof marketName === 'string' ? marketName :`${marketName}`}`} 
                        // ${index == 1 ? 'active' : ''}
                        className={`franchisee-email ${(typeof marketName === 'string' ? marketName : marketName) == selectedLocationMarket ? 'active' : ''}  `} 
                        onClick={() => {setSelectedLocationMarket(typeof marketName === 'string' ? marketName : marketName);}}>
                            <span>{typeof marketName === 'string' ? marketName :`${marketName} `}</span>
                        </div>
                    )}
            </div>}
        </div>
        :
        <></>
        ;
}
