import {
  addDaysToDate,
  getHoursBetween2Dates,
  HOURS_IN_DAY,
} from "../utility/time";
import { ChannelType } from "./Channel";

export interface CampaignChannel {
  id: string;
  type: ChannelType;
  typeId: string;
  startDate: Date;
  endDate?: Date | null; // optional for 'always on' campaigns
}

export interface NewCampaignChannel {
  startDate: Date;
  endDate?: Date; // optional for 'always on' campaigns
  channelType: ChannelType;
  readyToDisplay: boolean; // if false, will not appear on calendar
  creativeId?: string;
}

export const getEstimatedNewCampaignChannelCost = (
  newCampaignChannel: NewCampaignChannel,
  totalAudience: number,
  adjustedSpendPercent: number
) => {
  const totalHours = getHoursBetween2Dates(
    newCampaignChannel.startDate,
    newCampaignChannel.endDate ??
      addDaysToDate(
        newCampaignChannel.startDate,
        newCampaignChannel.channelType.durationHours / HOURS_IN_DAY
      )
  );

  const costPerTargetPerHour =
    newCampaignChannel.channelType.investmentInDollarsPerTarget /
    newCampaignChannel.channelType.durationHours;

  const estimatedChannelCostForDuration =
    costPerTargetPerHour * totalAudience * totalHours;

  return (estimatedChannelCostForDuration * adjustedSpendPercent) / 100;
};
