import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "../index";

const initialState = {
    isOpen: false,
};

export const SupportFormSlice = createSlice({
    name: "supportForm",
    initialState,
    reducers: {
        setOpen: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.isOpen = currentState;
        }
    }
});

export const selectIsOpenState = (state: RootState): boolean => state.supportForm.isOpen;

export const {
    setOpen
} = SupportFormSlice.actions;

export default SupportFormSlice.reducer;