import "./CalendarDayModal.css";
import { Modal } from "antd";
import { Event } from "../../../../models/Event";
import { AdCampaign } from "../../../../models/AdCampaign";
import { useSelector } from "react-redux";
import { selectAdCampaigns } from "../../../../store/AdCampaigns/AdCampaignStore";
import { selectEvents } from "../../../../store/Events/EventStore";
import { CalendarDayCampaignTable } from "./CalendarDayCampaignTable";
import { CalendarDayEventTable } from "./CalendarDayEventTable";
import { NewCampaignChannel } from "../../../../models/CampaignChannel";
import { useEffect, useState } from "react";
import {
  selectNewCampaignChannels,
  selectNewCampaignCustomPackage,
} from "../../../../store/campaignForm/CampaignForm";
import { CalendarDayNewChannelTable } from "./CalendarDayNewChannelTable";
import { addDaysToDate, dateRangesOverlap } from "../../../../utility/time";
import { NewCampaignCustomPackage } from "../../../../models/CampaignPackage";
import { CalendarDayNewPackageTable } from "./CalendarDayNewPackageTable";

interface CalendarDayModalProps {
  slot: { start: Date; end: Date };
  closeModal: () => void;
  selectCalendarEvent: (calendarEvent: AdCampaign | Event) => void;
}

export const CalendarDayModal = ({
  closeModal,
  slot,
  selectCalendarEvent,
}: CalendarDayModalProps): JSX.Element => {
  const campaigns: AdCampaign[] = useSelector(selectAdCampaigns);
  const events: Event[] = useSelector(selectEvents);
  const newCampaignChannels: NewCampaignChannel[] = useSelector(
    selectNewCampaignChannels
  );
  const newCampaignPackage: NewCampaignCustomPackage | null = useSelector(
    selectNewCampaignCustomPackage
  );
  const [newCChannels, setNewCChannels] = useState<NewCampaignChannel[]>([]);
  const [newCPackages, setNewCPackages] = useState<NewCampaignCustomPackage[]>(
    []
  );
  const [eventsForDay, setEventsForDay] = useState<Event[]>([]);

  useEffect(() => {
    const channelsToShow = newCampaignChannels
      .filter((channel) => channel.readyToDisplay)
      .filter((channel) => {
        if (channel.endDate === undefined) {
          return channel.startDate < slot.end;
        }
        return channel.startDate < slot.end && channel.endDate > slot.start;
      })
      .map((channel) => {
        if (channel.endDate === undefined) {
          const year = channel.startDate.getFullYear();
          return {
            ...channel,
            endDate: new Date(
              year + 1,
              channel.startDate.getMonth(),
              channel.startDate.getDate()
            ),
          };
        }
        return channel;
      });
    setNewCChannels(channelsToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCampaignChannels]);

  useEffect(() => {
    if (newCampaignPackage) {
      setNewCPackages([newCampaignPackage]);
    } else {
      setNewCPackages([]);
    }
  }, [newCampaignPackage]);

  useEffect(() => {
    const eventsWithEnd = events.map((event) => {
      if (event.endDate === undefined || event.endDate === null) {
        const nextDay = addDaysToDate(event.startDate, 1);
        return {
          ...event,
          endDate: new Date(
            nextDay.getFullYear(),
            nextDay.getMonth(),
            nextDay.getDate()
          ),
        };
      }
      return event;
    });

    const eventsToDisplay: Array<Event> = eventsWithEnd.filter(
      (calendarEvent) => {
        if (
          calendarEvent.endDate === undefined ||
          calendarEvent.endDate === null
        ) {
          return calendarEvent.startDate < slot.end;
        }
        return (
          calendarEvent.startDate < slot.end &&
          calendarEvent.endDate > slot.start
        );
      }
    );
    setEventsForDay(eventsToDisplay);
  }, [events]); // eslint-disable-line react-hooks/exhaustive-deps

 
  const campaignsForDay: Array<AdCampaign> = campaigns.filter(
    (calendarEvent) => {
      if (
        calendarEvent.endDate === undefined ||
        calendarEvent.endDate === null
      ) {
        return calendarEvent.startDate < slot.end;
      }
      return (
        calendarEvent.startDate < slot.end && calendarEvent.endDate > slot.start
      );
    }
  );

  return (
    <Modal
      title={
        <h2>{`${slot.start.toLocaleDateString([], {
          weekday: "long",
        })} ${slot.start.toLocaleDateString([], {
          month: "short",
        })} ${slot.start.getDate()}`}</h2>
      }
      visible={true}
      onCancel={closeModal}
      footer={null}
      width={"60vw"}
    >
      <h2>Campaigns & Events</h2>
      {eventsForDay.length > 0 ? (
        <CalendarDayEventTable
          selectCalendarEvent={selectCalendarEvent}
          events={eventsForDay}
        />
      ) : (
        <p>No events occurring today</p>
      )}
      {newCChannels.length > 0 && (
        <>
          <h2>New Channels</h2>
          <CalendarDayNewChannelTable newChannels={newCChannels} />
        </>
      )}
      {newCPackages.length > 0 && (
        <>
          <h2>New Packages</h2>
          <CalendarDayNewPackageTable newPackages={newCPackages} />
        </>
      )}
    </Modal>
  );
};
