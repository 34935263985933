import 'antd-country-phone-input/dist/index.css';

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Divider } from "antd";
import { useParams } from "react-router-dom";
import services from "../../../services";

import "./styles.css";
import { selectValidatedQRCampaign, selectValidationLoaded, validateQRCampaign, selectSmsCodeSent, selectSmsCodeErrors, sendSmsCode } from "store/QRCampaign/QRCampaignsStore";
import { createPromoCode, selectQRPromCodeIsLoading, selectCreatedQRPromoCode, selectCreateQRPromoCodeErrors } from "store/QRPromCodes/QRPromCodesStore";

import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

import { generatePromQRCode } from "../../../utility/qrcodes";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { assetImage } from "../../../utility/whiteLabelManager";

interface ICodeState {
  isClaimed: boolean | undefined,
  exists: boolean | undefined
}

interface ICampaignCodeState {
    exists: boolean | undefined
}

const QRCampaign = () => {
  
	const dispatch = useDispatch()

	//1. The valid QR Campaign from store.
	const qrCampaign = useSelector(selectValidatedQRCampaign);
	const qrValidationLoaded = useSelector( selectValidationLoaded );
  const smsCodeSent = useSelector(selectSmsCodeSent);
  const sendSmsCodeErrors = useSelector( selectSmsCodeErrors );

	const qrPromoCodeIsLoading = useSelector( selectQRPromCodeIsLoading );
	const createdQRPromoCode = useSelector( selectCreatedQRPromoCode );
  const createQRPromoCodeErrors = useSelector( selectCreateQRPromoCodeErrors);
  const whiteLabel = useSelector( selectWhiteLabel );

  const [qrImage, setQRImage] = useState<string | undefined>(undefined);
  const [utmSource, setUtmSource] = useState<string | undefined>(undefined);
  const [fullPhoneNumber, setFullPhoneNumber] = useState<string>("");

  const email = "";
  const { qrPass } = useParams();

	//2. Send the email (agent) and the current qrCampaign in order to create a qrPromoCode
  const onFinish = (values: any) => {
    console.log(values);
    dispatch(
			createPromoCode({
				userEmail: values.email,
				qrCampaignId: qrCampaign?.campaignId!, //This cannot be undefined at this point. 
        channel: utmSource ?? null,
        code: values.code,
        fullPhoneNumber: fullPhoneNumber,
        //TODO: Needs to send the fullPhoneNumber along with the code.
      })
		)
  };

  //4. Send the SMS to the phone number.
  const onFinishSms = (values: any) =>{
    console.log(values);
    const parsedPhone = values.phone.phone.replace(/\D/g, '');
    setFullPhoneNumber(`+${values.phone.code.toString() + parsedPhone}`)
    dispatch(
      sendSmsCode({
        countryCode: values.phone.code,
        phoneNumber: parsedPhone,
        qrCampaignId: qrCampaign?.campaignId!,
      })
    )
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  //1. Check if QR Campaign code exists and is valid.
  useEffect(() => {
    // Parse the current URL's query string
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Get the value of the utm_campaign parameter
    const utmSourceParam = urlSearchParams.get('utm_source');

    if (utmSourceParam) {
      setUtmSource( utmSourceParam );
    }

    dispatch( validateQRCampaign( qrPass ?? 'none' ) )
  }, []);

  //3. Generate the actual QR image when a QRPromo is created.
  useEffect(() =>{
    if( createdQRPromoCode != null ){
      const generateQRImage = async() =>{
        const stringQRCode = await generatePromQRCode(
          createdQRPromoCode.id
        )
        setQRImage( stringQRCode );
      }
      generateQRImage()
    } 
  },[createdQRPromoCode])

  //Error message html
  let errorMessages : any;

  if( createQRPromoCodeErrors.length > 0 ){
    errorMessages = <div className="w-full py-2 px-4 bg-red-400">
      <p className="text-base text-red-900">{ createQRPromoCodeErrors[0] }</p>
    </div>
  }else{
    errorMessages = "";
  }

  //Error message html
  let errorMessagesSms : any;

  if( sendSmsCodeErrors.length > 0 ){
    errorMessagesSms = <div className="w-full py-2 px-4 bg-red-400">
      <p className="text-base text-red-900">{ sendSmsCodeErrors[0] }</p>
    </div>
  }else{
    errorMessagesSms = "";
  }

  //QR Code Image
  let qrImageHtml : any;
  if( qrImage != undefined ){
    qrImageHtml = <img src={qrImage} alt="" style={{ marginRight: 10 }} />
  }else{
    qrImageHtml = "";
  }

  

  const ContentSelector = (props: any) => {
    if( createdQRPromoCode != null){
      return(
        <div>
          <div className="text-header">Welcome back</div>
          <div className="w-full px-4 py-2 bg-green-700">
            <p className="text-base text-white">Thank you, here is your promo code.</p>
          </div>
          <div className="mt-6">
            <p className="text-xl text-black">{createdQRPromoCode.promotion}</p>

            <div className="mt-6 flex justify-center">
              { qrImageHtml }
            </div>

          </div>
        </div>
      );
    }
    if ( qrValidationLoaded && qrCampaign != null ) {
      return (
        <div>
          <div className="text-header">Welcome </div>

          { (smsCodeSent) && (
            <div>
              <p className="text-base text-black mt-5">
                Please provide an email address and the verification code sent to your phone to view your promotional offer. You can then redeem this offer at any participating location.
              </p>
              <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="container-form mt-6"
                  layout="vertical"
                  initialValues={{
                    email: email,
                  }}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "not valid email",
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter email"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Verification code"
                    name="code"
                    rules={[
                      {
                        type: "string",
                        message: "Please insert the code received on your phone",
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your verification code"
                    />
                  </Form.Item>

                  <Divider plain></Divider>

                  <Form.Item>
                    <Button
                      loading={qrPromoCodeIsLoading}
                      htmlType="submit"
                      type="primary"
                      className="button-register"
                      //disabled={ !smsCodeSent }
                    >
                      VIEW MY OFFER
                    </Button>
                  </Form.Item>
                  { errorMessages }

              </Form>
            </div>
            
          )}

          { smsCodeSent == false && (
            <div>
              <p className="text-base text-black mt-5">
                Please provide a valid phone number. You will receive a code required to view your offer.
              </p>
              <ConfigProvider
                locale={en}
              >
                <Form
                  name="basic"
                  onFinish={onFinishSms}
                  autoComplete="off"
                  className="container-form mt-6"
                  layout="vertical"
                >
                  <div className='grid grid-cols-4 items-end gap-x-3'>
                    <Form.Item
                      className='col-span-3'
                      label="Phone number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                        }
                      ]}
                      initialValue={{
                        short: 'mx',
                      }}
                    >
                      <CountryPhoneInput required className='py-0' placeholder='Enter phone' />
                    </Form.Item>

                    <Form.Item
                      className='col-span-1'
                    >
                      <Button
                        loading={qrPromoCodeIsLoading}
                        type="primary"
                        htmlType='submit'
                        className="button-submit"
                        disabled={ smsCodeSent }
                      >
                        SEND
                      </Button>
                    </Form.Item>
                  </div>
                  { errorMessagesSms }

                </Form>
              </ConfigProvider>
            </div>
          )}
					
          
        </div>
      )
    }else if( qrValidationLoaded && qrCampaign == null){
			return <div>
				<div className="text-header">Error 404: Campaign not found.</div>
			</div>
		}

    return <div>
      <div className="text-header">Loading...</div>
    </div>
  };

  return (
    <div>
      <div className="container">
        <div className="content res:flex res:flex-wrap res:h-[auto] ">
          <div className="auth-section res:order-2 res:flex-1 res:p-10">
            <div className="container-auth res:w-full">
              {<ContentSelector />}
            </div>
          </div>
          <div className="background-section res:order-1 res:flex res:justify-start res:px-10 res:flex-[0_0_100%] res:pr-0 res:h-fit">
            { whiteLabel.logoPath != ""
              ? <img className="brand-logo" src={ assetImage( whiteLabel.logoPath ) } alt="logo" />
              : <div className="text-background  res:text-[45px] res:leading-[50px] res:p-[36px]">JBecca</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCampaign;
