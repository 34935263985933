import { Alert } from "../models/Alert";
import { Location } from "../models/Location";
import { IConfirmUser, ICreateUser, IUpdateUser, IUser } from "../models/User";
import { IMonthlyBudget } from "../models/MonthlyBudget";
import RestService from "./restService";

export default class UserService {
  constructor(protected readonly api: RestService) { }

  async confirmuser(confirmationUser: IConfirmUser): Promise<void> {
    return await this.api.post("/user/confirmation", confirmationUser);
  }

  async getUser(): Promise<IUser> {
    return await this.api.get<IUser>("/user");
  }

  async updateUser(updateUser: IUpdateUser): Promise<IUser> {
    return await this.api.put<IUser>("/user", updateUser);
  }

  async createUser(creatuser: ICreateUser): Promise<IUser> {
    return await this.api.post<IUser>("/users", creatuser);
  }

  async addPaymentToUser(cardToken: string): Promise<IUser> {
    return await this.api.post<IUser>("/user/payments", { cardToken });
  }

  async removePaymentFromUser(cardToken: string): Promise<IUser> {
    return await this.api.delete<IUser>("/user/payments", { cardToken });
  }

  async deleteAlert(eventId: string) {
    const responseAlert = await this.api.delete<Alert>(
      `/user/alerts/${eventId}`,
      {
        eventId,
      }
    );
    return responseAlert;
  }

  async getFranchisees(): Promise<IUser[]> {
    const franchisees = await this.api.get<IUser[]>("/user/franchisees");
    return franchisees;
  }

  async getFranchiseeLocations(franchiseeId: string): Promise<Location[]> {
    const locations = await this.api.get<Location[]>(
      `/user/${franchiseeId}/location`
    );
    return locations;
  }

  async getUnregisteredFranchisees(): Promise<string[]> {
    const franchisees = await this.api.get<string[]>(
      "/user/franchisees/unregistered"
    );
    return franchisees;
  }

  async getFranchiseeLocationsByEmail(
    franchiseeEmail: string | null
  ): Promise<Location[]> {
    if (!franchiseeEmail) return [];
    const locations = await this.api.get<Location[]>(
      `/user/email/${franchiseeEmail}/location`
    );
    return locations;
  }

  /**
   * Switch user role
   *
   * @param userRole
   */
  async switchUserRole(userRole: string): Promise<IUser> {
    return await this.api.post('/user/switch-role', {"name": userRole});
  }
  
  /**
   * Switch user franchise
   *
   * @param userFranchise
   */
  async switchUserFranchise(franchise: string): Promise<string> {
    return await this.api.post('/user/switch-franchise', {"id": franchise});
  }



  /**
   * Fetch user monthly budget
   */
  async getMonthlyBudget(locationId: string): Promise<IMonthlyBudget> {
    return await this.api.get<IMonthlyBudget>(`/user/monthly-budget/${locationId}`);
  }
}
