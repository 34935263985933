import style from "./Demographics.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { setModalContent, getFiltersSelected, addFilter } from '../../../store/Demographics/DemographicsStore';
import { Col, Input } from "antd";
import { AudiencesColumns } from "./AudiencesColumns";
import { DisplayAudiencesValues } from "./DisplayAudiencesValues";
import { useEffect, useState, useContext } from "react";
import services from "../../../services";
import { IMetadataItem } from "../../../services/stiristaDataService";
import { SourceContext } from "./Demographics"

export const NewAudienceFilter = () => {

    const dispatch = useDispatch();
    const filters: string[] = useSelector(getFiltersSelected);
    const [options, setOptions] = useState<{name: string, values: string[]}[]>([] );
    const [filterName, setFilterName] = useState<string>('');

    const {selectedSource, setSelectedSource} = useContext(SourceContext);

    const handleModalContent = (content: string) => {
        const conditions = filters.map((filter) => {
            const [name, value] = filter.split('=');
            return {
                type: 'AND',
                operator: "=",
                field: name,
                value: value
            };
        }).filter((condition) => {
            return condition.field.length > 0 && condition.value.length > 0
        });

        const payload = {
            name: filterName,
            fields: ['*'],
            conditions
        }

        services.audienceSourceService.createNewFilter(payload, selectedSource)
            .then((resp) => {
                console.log(resp);
            });

        dispatch(setModalContent(content));
    };

    useEffect(() => {

        services.audienceSourceService.getMetadata(selectedSource).then((resp: IMetadataItem[]) => {

            if (!Array.isArray(resp)) {
                setOptions([]);
            }

            const options = resp.map((item:IMetadataItem) => {
                return {
                    name: item.attribute_code,
                    values: item.options
                }
            });
            setOptions(options);
        });

    }, [selectedSource]);

    const [valueSelected, setValueSelected] = useState("");
    const [stringsFilters, setStringsFilters] = useState("");

    useEffect(() =>{
        console.log(filters);
    }, [filters]);

    const handleAddFilters = (filter: string) => {

        if(stringsFilters == "") {
            setStringsFilters(filter);
            dispatch(addFilter(filter));
        } else if(!stringsFilters.includes(filter)) {
            setStringsFilters(stringsFilters + " + " + filter);
            dispatch(addFilter(filter));
        }
    }

    return (
        <div>
            <h3>Audience Name</h3>
            <div className={style["selector"]}>
                <Input name="filter_name" onChange={(event) => {
                    setFilterName(event?.target?.value);
                }
                } />
            </div>
            <div className={style["selector-container-bottom"]}>
                <div className={style["selector"]}>
                    <AudiencesColumns options={options}></AudiencesColumns>
                </div>

                <div className={style["selector"]}>
                    <DisplayAudiencesValues setValueSelected={setValueSelected}></DisplayAudiencesValues>
                </div>
            </div>

            <div className={style["actions-between-bottom"]}>
                <Col span={5}>
                    <div>Conditions selected:</div>
                    <div>{ stringsFilters }</div>
                </Col>

                <Col span={5}>
                    <div className="admin-primary-button pointer-hover" onClick={() => handleAddFilters(valueSelected)}>
                        <div className="primary-button-text">ADD CONDITION</div>
                    </div>
                </Col>
            </div>


            <div className={style["actions-block"]}>
                <Col span={5}>
                    <div className="admin-primary-button pointer-hover" onClick={() => handleModalContent("Manage audiences")}>
                        <div className="primary-button-text">SAVE</div>
                    </div>
                </Col>
            </div>
        </div>
    )
}
