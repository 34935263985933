import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ChannelType } from "../../../models/Channel";
import { CreativeTemplate } from "../../../models/CreativeTemplate";
import {
  selectAlwaysOn,
  selectAdjustedSpendPercent,
  selectSelectedChannels,
  selectSelectedTemplates,
  selectTotalAudience,
  selectIsBudgetModeFixed,
  selectAvgChannelCostPercent,
  setSelectedChannels,
  setSelectedTemplates,
  setSetSpend,
} from "../../../store/campaignForm/CampaignForm";
import { getMoneyStringFromNumber } from "../../../utility/string";
import {
  HOURS_IN_A_WEEK,
  STANDARD_BILLING_PERIOD_WEEKS,
} from "../../../utility/time";
import "./styles.scss";

interface ChannelSidebarCardProps {
  channel: ChannelType;
}

const ChannelSidebarCard = ({ channel }: ChannelSidebarCardProps) => {
  const targetZoneAudience: number = useSelector(selectTotalAudience);
  const [investment, setInvestment] = useState<number>(0);
  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const selectedTemplates: CreativeTemplate[] = useSelector(
    selectSelectedTemplates
  );
  const alwaysOn = useSelector(selectAlwaysOn);
  const adjustedSpendPercent = useSelector(selectAdjustedSpendPercent);
  const dispatch = useDispatch();
  const isFixedBudgetMode = useSelector(selectIsBudgetModeFixed);
  const avgChannelPercent = useSelector(selectAvgChannelCostPercent);

  useEffect(() => {
    if (targetZoneAudience !== null) {
      /*if (alwaysOn) {
        const investmentFor2Weeks =
          (channel.investmentInDollarsPerTarget /
            channel.standardDurationInHours) *
          HOURS_IN_A_WEEK *
          STANDARD_BILLING_PERIOD_WEEKS *
          targetZoneAudience;
        setInvestment((investmentFor2Weeks * adjustedSpendPercent) / 100);
      }else */
      if( channel.setSpend != null ){
        setInvestment( channel.setSpend );
      }else {
        setInvestment(
          (channel.investmentInDollarsPerTarget * targetZoneAudience )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alwaysOn, adjustedSpendPercent]);

  const removeSelectedChannel = () => {
    let tempSelectedChannels: ChannelType[] = [];
    selectedChannels.forEach((selectedChannel) => {
      if (selectedChannel.title !== channel.title) {
        tempSelectedChannels.push(selectedChannel);
      }
    });
    dispatch(setSelectedChannels(tempSelectedChannels));
    
    if(channel.setSpend) dispatch(setSetSpend(null));

    const tempSelectedTemplates = selectedTemplates.filter(
      (selectedTemplate) => selectedTemplate.channelId !== channel.id
    );
    dispatch(setSelectedTemplates(tempSelectedTemplates));
  };

  return (
    <div className="sidebar-card">
      <div>
        <b>{channel.title}:</b> {isFixedBudgetMode ? avgChannelPercent + "%" : `$${getMoneyStringFromNumber(investment)}`}
      </div>
      <TrashIcon className="hidden" style={{ cursor: "pointer" }} onClick={removeSelectedChannel} />
    </div>
  );
};

export default ChannelSidebarCard;
