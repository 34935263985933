import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const MenuSlice = createSlice({
    name: "menu",
    initialState: {
        menuType: null as String | null,
    },
    reducers: {
        setMenuType: (state, {payload: menuType}: PayloadAction<String>) => {
            state.menuType = menuType;
        }

    }
});

export const selectMenuType = (state: RootState) => state.menuStore.menuType || 'default';

export const {setMenuType} = MenuSlice.actions;

export default MenuSlice.reducer;