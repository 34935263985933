import React, { useState } from "react";
import "./../../Layout/styles.css";
import "./styles.css";
import { AdminAvailableRoutes } from "../../../pages/AdminRoutes";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconMore } from "../../../assets/icons/more-vertical.svg";
import AdminHeaderLayout from "../Layout/HeaderLayout";
import AdminDashboardSideBar from "../dashboard/sidebar";

const AdminLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  return (
    <div>
      <AdminHeaderLayout>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            className="header-logo"
            onClick={() => navigate(AdminAvailableRoutes.ONBOARDING)}
            src="/JBeccaLogo.png"
            alt=""
          />
        </div>
      </AdminHeaderLayout>

      <div className="content-body">
        <div className="content-body-content">{children}</div>
        <div className="dashboard-sidebar ">
          <AdminDashboardSideBar />
        </div>

        <div className="dashboard-mobile-sidebar">
          <div className="mobile-sidebar-button">
            <div className="mobile-button-background">
              <div
                className="sidebar-dashboard-button pointer-hover"
                onClick={() => navigate(AdminAvailableRoutes.ONBOARDING)}
              >
                <div className="sidebar-dashboard-button-text">
                  PLAN MY CAMPAIGN
                </div>
                <IconArrowRight />
              </div>
              <IconMore
                className="mobile-more-icon"
                onClick={() => setShowMobileSidebar(!showMobileSidebar)}
              />
            </div>
          </div>
        </div>

        {showMobileSidebar && (
          <div className="mobile-sidebar-container">
            <div
              className="mobile-sidebar-oppacity"
              onClick={() => setShowMobileSidebar(false)}
            ></div>
            <div className="mobile-sidebar">
              <span>TODO SIDEBAR</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AdminLayout);
