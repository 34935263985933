import React, { useState, useEffect } from "react";
import { Form, Input, Button, Space, Radio, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAccount, updateUser } from "../../../store/Account/AccountStore";
import AlertMessage from "../../alertMessage/AlertMessage";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

import { CountryEnum } from "../../../models/Location";
import { UsStates } from "../BillingAddress/UsStates";

const AccountInformation: React.FC = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectUserAccount);
  const { Option } = Select;
  const navigate = useNavigate();
  const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
  const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);

  const whiteLabel = useSelector( selectWhiteLabel );

  //From the billing address module
  const [usSelected, setUsSelected] = useState(false);

  useEffect(() => {
    if(account?.country === CountryEnum.USA)
    setUsSelected(true);
    else
    setUsSelected(false);
  }, [account]);

  const handleCountryChange = (value: string) => {
    if(value === CountryEnum.USA)
    setUsSelected(true);
    else
    setUsSelected(false);
  }

  const renderOptions = (option: string) => {
    return <Option key={option} value={option}>{option}</Option>
  }

  const onFinish = (values: any) => {
    dispatch(updateUser({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      communicationPreference: values.communicationPreference,
      address1: values.address1,
      address2: values.address2,
      state: values.state,
      postalCode: values.postalCode,
      city: values.city,
      country: values.country,
    }));
    setOpenSuccessMsg(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    setOpenErrorMsg(true);
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h1 className="text-2xl">Account information</h1>
      {account && (
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="container-form account-form res:mt-4"
          layout="vertical"
          initialValues={{
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            phoneNumber: account.phoneNumber,
            communicationPreference: account.communicationPreference,
            address1: account.address1,
            address2: account.address2,
            state: account.state,
            postalCode: account.postalCode,
            city: account.city,
            country: account.country ? account.country : CountryEnum.USA,
          }}
        >
          <Input.Group compact>
            <Form.Item
              label="First Name"
              name="firstName"
              style={{ width: "46%" }}
            >
              <Input className="res:py-3" placeholder="Name" />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              style={{ marginLeft: "8%", width: "46%" }}
            >
              <Input className="res:py-3" placeholder="Last Name" />
            </Form.Item>
          </Input.Group>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "not valid email",
              },
            ]}
          >
            <Input className="res:py-3" placeholder="Enter email" disabled />
          </Form.Item>

          <Form.Item
            label="Address 1"
            name="address1"
            style={{ marginTop: 20, marginBottom: 40 }}
          >
            <Input className="res:py-3" placeholder="Address 1" />
          </Form.Item>

          <Form.Item
            label="Address 2"
            name="address2"
            required={false}
            style={{ marginTop: 20, marginBottom: 40 }}
          >
            <Input className="res:py-3" placeholder="Address 2" />
          </Form.Item>

          <Input.Group compact>
            <Form.Item
              label="Country"
              name="country"
              style={{width: "46%" }}
            >
              <Select onChange={handleCountryChange}>
                {Object.keys(CountryEnum).map(renderOptions)}
              </Select>
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              style={{ marginLeft: "8%", width: "46%" }}
            >
              {usSelected ?
                <Select >
                  {UsStates.map(renderOptions)}
                </Select>
                :
                <Input className=" res:py-3 " placeholder="State"/>
              }
            </Form.Item>
          </Input.Group>

          <Input.Group compact>
            <Form.Item label="City" name="city" style={{ width: "46%" }}>
              <Input className="res:py-3" placeholder="City" />
            </Form.Item>

            <Form.Item
              label="Postal Code"
              name="postalCode"
              style={{ marginLeft: "8%", width: "46%" }}
            >
              <Input className="res:py-3" placeholder="Postal Code" />
            </Form.Item>
          </Input.Group>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            style={{ marginTop: 20, marginBottom: 40 }}
          >
            <Input className="res:py-3" placeholder="Phone Number" />
          </Form.Item>

          <Form.Item
            name="communicationPreference"
            label={<p style={{ fontSize: "16px" }}>Communication Preference</p>}
            style={{ fontSize: 16 }}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="email">Email</Radio>
                <Radio value="SMS">SMS</Radio>
                <Radio value="both">Both</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item style={{ marginTop: 50 }}>
            <Button
              htmlType="submit"
              className="primary-button pointer-hover"
              style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >

            <div className="primary-button-text">SAVE</div>
            </Button>
          </Form.Item>
          <AlertMessage successMessage="Account Information was saved successfully!" openScsMsg={openSuccessMsg} onScsClose={() => setOpenSuccessMsg(false)} openErrMsg={openErrorMsg} onErrClose={() => setOpenErrorMsg(false)}/>
        </Form>
      )}
      <div
        className="change-password"
        onClick={() => navigate("/change-password")}
      >
        Change Password
      </div>
    </div>
  );
};

export default AccountInformation;
