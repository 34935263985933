import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../assets/icons/orange-back-arrow.svg";
import { CountryEnum, BudgetEnum } from "../../models/Location";
import { AvailableRoutes } from "../../pages/Routes";
import { createLocation, selectLocationToEdit, updateLocation } from "../../store/Location/LocationStore";
import { UsStates } from "../account/BillingAddress/UsStates";

interface EditLocationsProps {
    isEdit?: boolean;
}

const EditLocations = ( { isEdit }: EditLocationsProps ) => {
    const [usSelected, setUsSelected] = useState(false);
    const [standardSelected, setStandardSelected] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locationToEdit = useSelector(selectLocationToEdit);
    const { Option } = Select;

    useEffect(() => {
        if(isEdit && locationToEdit?.country === CountryEnum.USA)
        setUsSelected(true);
        else if (isEdit && locationToEdit?.country !== CountryEnum.USA)
        setUsSelected(false);
        else if (!isEdit)
        setUsSelected(true);
        else
        setUsSelected(false);
    }, [isEdit, locationToEdit]);

      useEffect(() => {
        if(isEdit && locationToEdit?.budgetMode === BudgetEnum.none)
        setStandardSelected(true);
        else if (isEdit && locationToEdit?.budgetMode !== BudgetEnum.none)
        setStandardSelected(false);
        else if (!isEdit)
        setStandardSelected(true);
        else
        setStandardSelected(false);
    }, [isEdit, locationToEdit]);
    
    const onFinish = (values: any) => {
        if(isEdit && locationToEdit !== null) {
            dispatch(
                updateLocation({
                    id: locationToEdit.id,
                    // owner: values.ownerEmail,
                    name: values.locationName,
                    address1: values.address1,
                    address2: values.address2,
                    city: values.city,
                    state: values.state,
                    postalCode: values.postalCode,
                    country: values.country,
                    externalId: values.externalId,
                    market: 'Edit Location',
                    budgetMode: values.budget,
                    // isRegion: true,
                })
            );
        } else {
            dispatch(
                createLocation({
                    owner: values.ownerEmail,
                    name: values.locationName,
                    address1: values.address1,
                    address2: values.address2,
                    city: values.city,
                    state: values.state,
                    postalCode: values.postalCode,
                    country: values.country,
                    externalId: values.externalId,
                    market: values.market ?? '-',
                    budgetMode: values.budgetMode,
                    // isRegion: null,
                    // scope: '-',
                    // scopeLabel: '-',
                })
            );
        };
        // navigate(AvailableRoutes.ACCOUNT_LOCATIONS)
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const handleCountryChange = (value: string) => {
        if(value === CountryEnum.USA)
        setUsSelected(true);
        else
        setUsSelected(false);
    };

    const handleBudgetChange = (value: string) => {
        if(value === BudgetEnum.none)
        setStandardSelected(true);
        else
        setStandardSelected(false);
    };


    const renderOptions = (state: string) => {
        return <Option key={state} value={state}>{state}</Option>
    };

  return <div className="">
    <h1 className="text-2xl">{ isEdit ? 'Edit Location' : 'Create Location'}</h1>

    <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="container-form"
        layout="vertical"
        initialValues={{
            ownerEmail: isEdit && locationToEdit !== null ? locationToEdit.ownerEmail : '',
            locationName: isEdit && locationToEdit !== null ? locationToEdit.name : '',
            address1: isEdit && locationToEdit !== null ? locationToEdit.address1 : '',
            address2: isEdit && locationToEdit !== null ? locationToEdit.address2 : '',
            proximity: isEdit && locationToEdit !== null ? locationToEdit.proximity : '',
            city: isEdit && locationToEdit !== null ? locationToEdit.city : '',
            state: isEdit && locationToEdit !== null ? locationToEdit.state : '',
            postalCode: isEdit && locationToEdit !== null ? locationToEdit.postalCode : '',
            country: isEdit && locationToEdit !== null ? locationToEdit.country : CountryEnum.USA,
            externalId: isEdit && locationToEdit !== null ? locationToEdit.externalId : '',
            market: isEdit && locationToEdit !== null ? locationToEdit.marketName : '',
            budgetMode: isEdit && locationToEdit !== null ? locationToEdit.budgetMode : '',
        }}
    >
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className="res:!w-full" style={{    width: '40vw'}}>
                <p className="res:!text-xl" style={{fontSize: '16px'}}><b>Location Address</b></p>

                <Form.Item
                    label="Owner"
                    name="ownerEmail"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Owner email" />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="locationName"
                    rules={[{ required: true }]}
                >
                    <Input className="res:py-3" placeholder="Location Name" />
                </Form.Item>

                <Form.Item
                    label="ID"
                    name="externalId"
                    rules={[{ required: true }]}
                >
                    <Input className="res:py-3" placeholder="Location ID" />
                </Form.Item>

                <Input.Group compact>
                    <Form.Item
                        label="Address 1"
                        name="address1"
                        style={{ width: "46%" }}
                        rules={[{ required: true }]}
                    >
                        <Input className="res:py-3" placeholder="Address 1" />
                    </Form.Item>

                    <Form.Item
                        label="Address 2"
                        name="address2"
                        style={{ marginLeft: "8%", width: "46%" }}
                        rules={[{ required: false }]}
                    >
                        <Input className="res:py-3" placeholder="Address 2" />
                    </Form.Item>
                </Input.Group>

                <Input.Group compact>
                    <Form.Item
                        label="City"
                        name="city"
                        style={{ width: "46%" }}
                        rules={[{ required: true }]}
                    >
                        <Input className="res:py-3" placeholder="City" />
                    </Form.Item>

                    <Form.Item
                        label="State"
                        name="state"
                        style={{ marginLeft: "8%", width: "46%" }}
                        rules={[{ required: true }]}
                    >
                        {usSelected ?
                            <Select>
                            {UsStates.map(renderOptions)}
                            </Select>
                            :
                            <Input className="res:py-3" placeholder="State"/>
                        }
                    </Form.Item>
                </Input.Group>

                <Input.Group compact>
                    <Form.Item
                        label="Postal Code"
                        name="postalCode"
                        style={{  marginLeft: "8%", width: "46%" }}
                        rules={[{ required: true }]}
                    >
                        <Input className="res:py-3" placeholder="Postal Code" />
                    </Form.Item>

                    <Form.Item
                        label="Country"
                        name="country"
                        style={{ width: "46%" }}
                        rules={[{ required: true }]}
                    >
                        <Select onChange={handleCountryChange}>
                        {Object.keys(CountryEnum).map(renderOptions)}
                        </Select>
                    </Form.Item>
                </Input.Group>

                <Input.Group compact>
                    <Form.Item
                        label="Market"
                        name="market"
                        style={{  marginLeft: "8%", width: "46%" }}
                        rules={[{ required: true }]}
                    >
                        <Input className="res:py-3" placeholder="Designated market area"/>
                    </Form.Item>

                    <Form.Item
                        label="Budget Mode"
                        name="budget"
                        style={{ width: "46%" }}
                        rules={[{ required: true }]}
                    >
                        <Select onChange={handleBudgetChange}>
                        {Object.keys(BudgetEnum).map(renderOptions)}
                        </Select>
                    </Form.Item>
                </Input.Group>
            </div>
        </div>

        <Input.Group className="res:!flex res:justify-between res:w-full" compact>
            <Form.Item className="res:basis-5/12" style={{ marginTop: 50 }}>
                <Button
                    htmlType="submit"
                    className="inverted-general-button pointer-hover res:!w-full"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '25px' }}
                    // onClick={() => navigate(AvailableRoutes.ACCOUNT_LOCATIONS)}
                >
                    <IconArrowBack />
                    BACK
                </Button>
            </Form.Item>
            <Form.Item className="res:basis-5/12" style={{ marginTop: 50 }}>
                <Button
                    htmlType="submit"
                    className="primary general-button pointer-hover res:!w-full"
                    style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    SAVE
                    <IconArrowRight/>
                </Button>
            </Form.Item>
        </Input.Group>
    </Form>
  </div>;
};

export default EditLocations;
