import React, {useEffect, useState} from "react";
import {Row, Col, Button, Drawer} from "antd";
import { useDispatch, useSelector } from "react-redux"
import styles from "../../purchase/channels/channelTemplateDrawer/channelTemplateDrawer.module.css";
import { ReactComponent as IconArrowRightBlack } from "../../../assets/icons/arrow-right-black.svg";
import { setOpen, selectIsOpenState } from "../../../store/PlatformSupport/SupportForm";

export const SupportForm  = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsOpenState);
    const [open, setModalOpen] = useState(false);
    
    /**
     * Close modal window
     */
    const handleModalClose = () => {
        setModalOpen(false);
        dispatch(setOpen(false));
    };

    /**
     * Opens or closes the modal on state change
     */
    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    return (
        <Drawer
            visible={open}
            width="min(100vw, 700px)"
            closable={false}
        >
            <div className={`${styles["templates-drawer-top-bar"]}  `}>
                <IconArrowRightBlack onClick={handleModalClose}/>
            </div>
            <div className={`${styles["templates-header"]} mt-8 `}>
                <div className="flex justify-center items-center h-full min-w-[80%]">
                    <div className="asana-embed-container ">
                        <link rel="stylesheet" href="https://form.asana.com/static/asana-form-embed-style.css" />
                        <iframe className="asana-embed-iframe !h-[80vh]" width="800" src="https://form.asana.com/?k=e7nIP42f-Tj27Kxo5Tb8Ag&d=1201669547830375&embed=true"></iframe>
                        <div className="asana-embed-footer">
                            <a rel="nofollow noopener" target="_blank" className="asana-embed-footer-link" href="https://asana.com/?utm_source=embedded_form">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

export default SupportForm;

