import "./styles.css";
//import FileUploader from "../fileuploader/FileUploader";
import LocationUploader from "../fileuploader/LocationUploader";
import FranchiseUploader from "../fileuploader/FranchiseUploader";
import ChannelUploader from "../fileuploader/ChannelUploader";
import AlertUploader from "../fileuploader/AlertUploader";
import BudgetsUploader from "../fileuploader/BudgetsUploader";
import { ReactComponent as IconCancel } from "../../../assets/icons/cancel.svg";
import { ReactNode } from "react";
import React from "react";
import EventsUploader from "../fileuploader/EventsUploader";

interface OnboardingPageState{
  errors: string[] | null,
  errorMessage: string | null,
}

class OnboardingPage extends React.Component<{},OnboardingPageState>{

  constructor(props: any){
    super(props);
    this.state = {
        errors: null,
        errorMessage: null,
    }
}

  notifyErrors = (errors: string[], message: string) => {
    this.setState({
      errors: errors,
      errorMessage: message
    });
  }

  closeErrorWindow = () => {
    this.setState({
      errors: null,
      errorMessage: null,
    })
  }

  render(){
    
    let errorBlock = <div></div>;
    if( this.state.errorMessage !== null ){
      
      let errorList = this.state.errors?.map((error, i) => {
        return <p dangerouslySetInnerHTML={{ __html: error }}></p>
      });

      errorBlock = 
        <div className="error-block">
          <span className="close-window">
            <IconCancel onClick={this.closeErrorWindow} title="Close window" scale={2} width={50} height={50} />
          </span>
          <h1>Import exceptions</h1>
          <div className="error-details">
            <p dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></p>
          </div>
          <div className="error-list">
            { errorList }
          </div>
          <div className="confirm-button">
            <div onClick={this.closeErrorWindow} className="primary-button pointer-hover">
              <div className="primary-button-text">OK</div>
            </div>
          </div>
          
        </div>
    }
    return (
      <>
        <div className="admin-page grid grid-rows-4 grid-flow-col ">
          <FranchiseUploader notifyErrors={this.notifyErrors} title="Import franchises"></FranchiseUploader>
          <LocationUploader notifyErrors={this.notifyErrors} title="Import locations"></LocationUploader>
          <ChannelUploader notifyErrors={this.notifyErrors} title="Import channels"></ChannelUploader>
          {/*<AlertUploader notifyErrors={this.notifyErrors} title="Import alerts"></AlertUploader>*/}
          {/*<BudgetsUploader notifyErrors={this.notifyErrors} title="Import budgets"></BudgetsUploader>*/}
          <EventsUploader notifyErrors={this.notifyErrors} title="Import events"></EventsUploader>
        </div>

        { errorBlock }
        
      </> 
    );
  }
}

export default OnboardingPage;
