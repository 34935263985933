import React, { useState } from "react";
import { Row, Col, Button, Tooltip } from "antd";
import { DemographicsFranchiseList} from "./DemographicsFranchiseList";
import { AssignDataSourceList } from "./AssignDataSourceList";
import style from "../mediaManager/MediaManager.module.css";
import { DemographicsFranchise } from "../../../models/Demographics";

// interface AssignDataSourcesProps {
//     franchises: DemographicsFranchise[];
// }

//export const AssignDataSources = ({franchises}: AssignDataSourcesProps) => {
export const AssignDataSources = () => {
    
    const [franchises, setFranchises] = useState<string[]>(["Franchise 001", "Franchise 002", "Franchise 003", "Franchise 004"]);
    const [options, setOptions] = useState<string[]>(["BQ_b2cdemo", "BQ_franchise_prospecting"]);
    //const [selectedFranchise, setSelectedFranchise] = useState<DemographicsFranchise>({id: 0, name: "", dataSource: "", audiences: [] });
    const [assignedFranchise, setAssignedFranchise] = useState<string>("");
    const [assignedDataSource, setAssignedDataSource] = useState<string>("");

    return (
        <div>
            <div className={style["selector-container"]}>
                <div className={style["selector"]}>
                    {/*<DemographicsFranchiseList franchises={franchises} setSelectedFranchise={setSelectedFranchise} selectedFranchise={selectedFranchise}/>*/}
                    <AssignDataSourceList label="Franchises" options={franchises} setAssignedDataSource={setAssignedFranchise} />
                </div>

                <div className={style["selector"]}>
                    <AssignDataSourceList label="Active source" options={options} setAssignedDataSource={setAssignedDataSource} />
                </div>
            </div>

            <div className={style["actions"]}>
                <Col span={10}>
                    <div className="primary-button pointer-hover">
                        <div className="primary-button-text">SAVE</div>
                    </div>
                </Col>
            </div>
        </div>
    )
};

export default AssignDataSources