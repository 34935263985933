import { WhiteLabel } from "../models/WhiteLabel";
import RestService from "./restService";

//The interface required by the Tables.
export interface WhiteLabelRow {
  key: string,
  id: string,
  domain: string,
  mailFrom: string,
}

//Interface for a new WhiteLabel
export interface INewWhiteLabel{
  domain: string,
  primaryColor: string,
  secondaryColor: string,
  activeColor: string,
  gradientColor: string,
  gradientOutlineColor: string,
  mailFrom: string,
  supportMail: string,
  supportText: string,
  purchaseTerms: string,
  orderSuccessText: string,
  logoPath: string | undefined,
  secondaryLogoPath: string | undefined,
  faviconPath: string | undefined,
  metaTitle: string,
  agency: boolean,
  ioEmail: string,
  primaryContact: string,
  billingConact: string,
}

export interface IUpdateWhiteLabel{
  id: string,
  domain: string,
  primaryColor: string,
  secondaryColor: string,
  activeColor: string,
  gradientColor: string,
  gradientOutlineColor: string,
  mailFrom: string,
  supportMail: string,
  supportText: string,
  purchaseTerms: string,
  orderSuccessText: string,
  logoPath: string | undefined,
  secondaryLogoPath: string | undefined,
  faviconPath: string | undefined,
  metaTitle: string,
  agency: boolean,
  ioEmail: string,
  primaryContact: string,
  billingContact: string
}

export interface WhiteLabelTable{
  table: WhiteLabel[],
  error: string | undefined,
}

export default class WhiteLabelService {
  constructor(protected readonly api: RestService) {}

  async getWhiteLabel(domain: string): Promise<WhiteLabel> {
    return await this.api.get<WhiteLabel>(`/white-label?domain=${domain}`);
  }

  async getWhiteLabelTable(): Promise<WhiteLabelTable>{
    const res = await this.api.get<any>(`/white-label/index`);
    if(res.details){
      return{
        table: [],
        error: res.details
      }
    }else{
      return{
        table: res.results,
        error: undefined,
      }
    }
  }

  async sendRegistrationWhiteLabelMail(email: string, mailFrom: string, sendgridId: string): Promise<boolean>{
    const res = await this.api.get<any>(`/white-label/send-registration-mail?email=${email}&mailFrom=${mailFrom}&sendgridId=${sendgridId}`);
    if(res.details){
      return true;
    }else{
      return false;
    }
  }

  async createWhiteLabel(createWhiteLabel: INewWhiteLabel): Promise<WhiteLabel> {
    console.log(createWhiteLabel);
    return await this.api.post<WhiteLabel>("/white-label", createWhiteLabel);
  }

  async updateWhiteLabel(updateWhiteLabel: IUpdateWhiteLabel): Promise<WhiteLabel> {
    return await this.api.put<WhiteLabel>("/white-label", updateWhiteLabel);
  }

  async deleteWhiteLabel(updateWhiteLabel: IUpdateWhiteLabel): Promise<boolean>{
    return await this.api.delete<boolean>("/white-label", updateWhiteLabel);
  }


}
