import "./styles.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import { Form, Table } from "antd";
// import {ExportOutlined, EditOutlined, SendOutlined} from "@ant-design/icons";

// import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { fetchApprovalBudgets, fetchMonthlyBudgetsList, selectApprovalBudgets, selectLoading, selectMonthlyBudgetsList } from "../../../store/Budgets/BusgetStore";

import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "../../../assets/icons/loader-onmiChannel.svg";
// import { MonthlyInsertionOrders } from "services/orderFormService";
// import { WhiteLabel } from "../../../models/WhiteLabel";
import type { ColumnsType } from 'antd/es/table';
import { BudgetApproval, MonthlyBudgetSplit, MonthlyBudgets } from "../../../models/Budget";
import { selectFranchiseLocations } from "../../../store/Location/LocationStore";
import { AvailableRoutes } from "pages/Routes";
import { useNavigate } from "react-router-dom";
import { ApproveModal } from "../modal/ApproveModal";

const BudgetTrackerApprovals = () => {
  const dispatch = useDispatch();
  // const whiteLabel = useSelector(selectWhiteLabel);
  const monthlyBudgetsList = useSelector(selectMonthlyBudgetsList);
  const franchiseLocations = useSelector( selectFranchiseLocations )
  const approvalBudgets = useSelector(selectApprovalBudgets);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();

  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth() ;
  const [month, setMonth] = useState(mesActual+1);
  const [selectedLocation, setSelectedLocation] = useState(undefined as undefined | string);
  

  const [form] = Form.useForm();
  const [currentMonthBudgets, setCurrentMonthBudgets] = useState({} as MonthlyBudgets);
  const [currentMonthApproval, setCurrentMonthApproval] = useState([] as BudgetApproval[]);
  const [allLocations, setAllLocations] = useState([] as any[])

  //* Modal
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState({} as BudgetApproval);
  
  // const renderMonthlyBudgetChannelSplit = () => {
  //   if( true ){
  //     return <div>
  //         <p className="text-base text-black">No Budgets found for this month</p>
  //       </div>
  //   }else{
  //   }
  // }

  // Function to find budget objects by month
  const findBudgetsByMonth = (targetMonth : string) => {
    return monthlyBudgetsList.find((monthlyBudgets) => monthlyBudgets.month === targetMonth);
  };

  const nextMonth = ( ) => {
    if ( month <= mesActual ) {
      setMonth(month+1)
    }
  }
  const previuosMonth = ( ) => {
    if ( month-1 > mesActual-11) {
      setMonth(month-1)
    }
  }
  var nameMonth = new Date(month, month).toLocaleString('en-EN', { month: 'long' });

  const handleApprovalLinkButton = (record: BudgetApproval) => {
    setRecord(record);
    setOpen(true);
  };

  const columns: ColumnsType<BudgetApproval> = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (val => val)
        // ellipsis: true,
    },
    {
        title: 'Budget',
        dataIndex: 'budget',
        key: 'budget',
        render: (val => `$${((val)).toLocaleString('en-US',{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`)
    },
    {
        title: 'Submitted',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (val => ((val)).toLocaleString('en-US').split(',')[0]) // a trick just to shows the M/D/Y
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (val => val)
    },
    {
      title: '',
      key: 'actions',
      align: 'center',
      render: (_, record) => record.status == 'New'  ? (
        <div
          role="button"
          style={{'width': '80%', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'padding': '0'}}
          className="primary general-link pointer-hover"
          onClick={() => handleApprovalLinkButton(record)}
        >
          <div className="text-center">Approve</div>
        </div>
      ) : '--',
    },
];

  //Fetches the proper MonthlyBudgets object according to the choosen month's name.
  useEffect(() => {
    
    if( approvalBudgets.length > 0 ){
      const foundMonthlyBudgets = findBudgetsByMonth(nameMonth);
      if (foundMonthlyBudgets) {
        setCurrentMonthBudgets( foundMonthlyBudgets );
      } else {
        //defaults to an empty object if not found.
        setCurrentMonthBudgets({} as MonthlyBudgets);
      }

      console.log('month: ' + nameMonth);

      const foundMonthlyApprovals = approvalBudgets.filter((budget) => budget.month === nameMonth);

      console.log(foundMonthlyApprovals);
      if (foundMonthlyApprovals) {

        setCurrentMonthApproval(foundMonthlyApprovals)
      }
      else {
        setCurrentMonthApproval([]);
      }
    }
  }, [nameMonth, approvalBudgets]);
  
  useEffect(() => {
    if(monthlyBudgetsList.length > 0 ){
      console.log(monthlyBudgetsList);
      const foundMonthlyBudgets = findBudgetsByMonth(nameMonth);
      if (foundMonthlyBudgets) {
        setCurrentMonthBudgets( foundMonthlyBudgets );
      } else {
        //defaults to an empty object if not found.
        setCurrentMonthBudgets({} as MonthlyBudgets);
      }
      const foundMonthlyApprovals = approvalBudgets.filter((budget) => budget.month === nameMonth);
      if (foundMonthlyApprovals) {
        setCurrentMonthApproval(foundMonthlyApprovals)
      }
      else {
        setCurrentMonthApproval([]);
      }
    }
  }, [monthlyBudgetsList]);
  
  useEffect(() =>{
      if(franchiseLocations.length > 0){
          //const staticLocations: any = [];
          const staticLocations: any = [{ label: 'All locations', value: 'all', key: 'all' }];
          

          const dynamicLocations = franchiseLocations.map((location) => {return {label: location.name, value: location.id}});

          // Sort only the dynamicLocations array
          dynamicLocations.sort((a, b) => {
            const labelA = a.label.toLowerCase();
            const labelB = b.label.toLowerCase();

            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
          });

          const allLocationsList = [...staticLocations, ...dynamicLocations];
          setAllLocations(allLocationsList);

          form.setFieldsValue({ location: allLocationsList[0] ? allLocationsList[0].value : null  });
          setSelectedLocation(allLocationsList[0] ? allLocationsList[0].value : undefined)
      }
  }, [franchiseLocations])

  //Fetch the list of monthlyInsertionOrder
  useEffect(() => {
    dispatch(fetchApprovalBudgets());
    dispatch(fetchMonthlyBudgetsList());
    
    console.log('fetching');
  },[]);// eslint-disable-line react-hooks/exhaustive-deps

  // const onLocationChange = (value: string) => {
  //     setSelectedLocation(value);
  // };

  var budgetSplits: MonthlyBudgetSplit[] = [];

  if (currentMonthBudgets.locationBudgets) {
      // if (selectedLocation === "all") {
          // Concatenate all budget splits

    //* ALL LOCATIONS
    budgetSplits = currentMonthBudgets.locationBudgets.reduce<MonthlyBudgetSplit[]>((allSplits, locationBudget) => {
      return allSplits.concat(locationBudget.budgetSplits);
    }, []);

      // } else {
      //     // Find the entry with matching id
      //     const locationBudget = currentMonthBudgets.locationBudgets.find(location => location.id === selectedLocation);
      //     if (locationBudget) {
      //         budgetSplits = locationBudget.budgetSplits;
      //     }
      // }
  }

  var monthlyBudgetTotal: number = 0;
  if(currentMonthBudgets.locationBudgets){
    // if( selectedLocation == "all"){
      //Fetch and sum the monthlyBudgets of all the locations in this month
    
      //* ALL LOCATIONS
      const allLocationBudgets = currentMonthBudgets.locationBudgets.map((locationBuget) => {return locationBuget.monthlyTotal});
      //sum all the values
      const budgetSum = allLocationBudgets.reduce((total, current) => total + current, 0);
      monthlyBudgetTotal = budgetSum;
    
      // }else{
    //   //Fetch the monthly budget only for the selected location in this month
    //   const locationBudget = currentMonthBudgets.locationBudgets.find(location => location.id === selectedLocation);
    //   if (locationBudget) {
    //       monthlyBudgetTotal = locationBudget.monthlyTotal;
    //   }
    // }
    
  }else{
    monthlyBudgetTotal = 0;
  }

  console.log(currentMonthApproval);
  console.log(approvalBudgets);

  return (
    <div>
        <div className="container">
          <div className="mb-8 text-2xl">Budget approvals</div>
          

          <div className="grid items-start grid-cols-5 res:grid-cols-1 gap-x-7">
            <div className="col-span-3 res:col-span-1 ">
              <div className="flex justify-between">
                <div className="selectMonth h-fit">
                { month-1 <= mesActual-11 ? (
                    <div className="pointer-events-none arrowPrev">
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.2]  rotate-[90deg] " />
                    </div>
                  ) : (
                    <div className="arrowPrev" onClick={ previuosMonth }>
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.6]  rotate-[90deg] " />
                    </div>
                  ) }
                  
                  <div className="text-[15px] capitalize mx-5 w-[80px] text-center"> { nameMonth } </div>
                  { month > mesActual ? (
                    <div className="pointer-events-none arrowNext">
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.2]  rotate-[170deg] " />
                    </div>
                  ) : (
                    <div className="arrowNext" onClick={ nextMonth }>
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.6]  rotate-[170deg] " />
                    </div>
                  ) }
                  
                </div>

                <div className="ml-auto text-right total">
                  <div className="text-[16px]">Monthly total</div>
                    <div className="text-[18px]"> ${Number(monthlyBudgetTotal).toLocaleString('en-US')}</div>
                  </div>  
                </div>

                {/* <Form
                  form={form}
                  name="basic"
                  autoComplete="off"
                >
                  <Form.Item
                    className="!ml-0"
                    name="location"
                    style={{  width: "183px" }}
                  >
                    <Select
                      showArrow
                      value={selectedLocation}
                      style={{ borderRadius: "4px", width: "100%" }}
                      onChange={ onLocationChange }
                      className="mb-[1px]"
                      options={allLocations}
                    />
                  </Form.Item>
                </Form> */}
              
              <div className={'col-span-2 dividerCustom w-full my-5'}>
                  <label>By location</label>
                  <div></div>
              </div>

              <div className="flex flex-wrap w-full">
                
              </div>


            </div>

            <div className="col-span-2 col-start-5 py-1 mb-3 res:col-start-1">
              <div
                style={{'width': '100%'}}
                className="primary-button pointer-hover res:w-1/4"
                onClick={() => navigate(AvailableRoutes.BUDGET_TRACKER)}
              >
                <div className="text-center sidebar-dashboard-button-text">VIEW ALLOCATIONS</div>
              </div>
            </div>

          </div>
          <div>
            <Table
              className="res:!overflow-x-scroll report-alltable"
              columns={columns}
              pagination={false}
              dataSource={currentMonthApproval}
            />
          </div>
          
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <LoaderIcon className="loader-icon" />
          {/* <CircularProgress color="inherit" /> */}
        </Backdrop>

        {/* Modal */}
        <ApproveModal open={open} closeModal={setOpen} record={record} />
    </div>
  );
};

export default BudgetTrackerApprovals;
