import { Row, Col } from "antd";
import React from "react";
import { connect } from 'react-redux';
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import './FileUploader.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ParseResult } from "papaparse";
import { CustomReportRowSchema } from "../../../services/customReportService";
import { importCustomReports } from "../../../store/CustomReport/CustomReportStore";
import { RootState } from "../../../store";

interface ReportUploaderProps {
    title: string,
    notifyErrors: Function,
    importReports: Function,
    reportErrors: String[] | null,
    importReportsResult: String | null
}

interface ReportUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class ReportUploader extends React.Component<ReportUploaderProps, ReportUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<ReportUploaderProps>): void {
        if( prevProps.reportErrors !== null){
            if( prevProps.reportErrors !== this.props.reportErrors){
                this.props.notifyErrors( 
                    this.props.reportErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.reportErrors !== null ){
            if( prevProps.importReportsResult !== this.props.importReportsResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importReportsResult);
            }
        }
    }
    
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        //Verify if the file is not null.
        if( !event.target.files )
            return;
        
        //Verifies if the file is supported (csv or xlsx)
        let fileType = event.target.files[0].type;
        if( 
            fileType === 'application/vnd.ms-excel' || 
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'text/csv'
        ){
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        }else{
            this.setState({
                fileTypeError: "The selected file is not valid. Only CSV and XLSX files are supported."
            });
        }
    }

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        //This process is different depending on the file type.
        let fileJson = [];

        let selectedFile = this.state.selectedFile;
        
        if( selectedFile?.type === 'text/csv'){
            this.parseReportsCSVFile(selectedFile).then((json) => {
                console.log('Trying to import the custom reports csv file...');
                console.log(json);
                this.props.importReports(json);
            }).catch( (error: Error) => {

            });
        }else{
            this.parseReportsExcelFile(selectedFile!).then((json) => {
                console.log('Trying to import the custom reports xlsx file...');
                console.log(json);
                this.props.importReports(json);
            }).catch( (error: Error) => {
                
            });
        }
    }

    //Validates and obtains the data as JSON for a XLSX file
    parseReportsExcelFile = (file: File) => {
        
        return new Promise<CustomReportRowSchema[]>( (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: "array"} );
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json<CustomReportRowSchema>(
                    worksheet,
                    {
                        //transforms de header names into camelCase.
                        raw: false,
                        header: ['channelName', 'franchiseName', 'locationName', 'campaignName', 'date', 'impressions', 'clicks', 'spend', 'conversions'],
                        range: 1,
                    }
                );
                
                //The library doesn't seem to support a method to validate
                //empty cells, so we validate this after processing.
                //empty cells are completely ignored by the library, so we need to
                //validate the existence of these missing attributes.
                let errors: string[] = [];
                json.forEach( (element, index) => {
                    let rowNum = index+1;
                    let length = Object.keys(element).length;
                    console.log(element);
                    if( 'channelName' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Channel Name cell.");
                    if( 'franchiseName' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Franchise Name cell.");
                    if( 'locationName' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Location Name cell.");
                    if( 'campaignName' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Campaign Name cell.");
                    if( 'date' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Date cell.");
                    if( 'impressions' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Impressions cell.");
                    if( 'clicks' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Clicks cell.");
                    if( 'conversions' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Conversions cell.");
                });

                //Reject this promise and notify the parent about the errors
                //to display in the large alert window.
                if( errors.length > 0 ){
                    console.log('reporting error');
                    this.props.notifyErrors( 
                        errors, 
                        "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                    );

                    reject(Error('There were some errors in the file.'));
                }

                resolve(json);
            }
            reader.readAsArrayBuffer(file);
        });

    }

    //Validates and obtains data as JSON from a CSV file
    parseReportsCSVFile = ( file: File ) => {
        return new Promise<CustomReportRowSchema[]>( (resolve, reject) => {
            Papa.parse( file, {
                header: true,
                //transformHeader: ((header: string) => this.camelize(header)),
                complete: (results: ParseResult<CustomReportRowSchema> ) => {
                    //Need to adjust the header names, and we do so while
                    //manually validating each row.
                    let json = results.data;
                    let errors: string[] = [];
                    console.log(json);
                    json.forEach( (element, index) => {
                        let rowNum = index+1;

                        //Check if each row has fields with actual values.
                        //the csv library fills the nonexistent fields with empty strings
                        if( element.channelName === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Channel Name cell.");
                        if( element.franchiseName === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Franchise Name cell.");
                        if( element.locationName === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Location Name cell.");
                        if( element.campaignName === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Campaign Name cell.");
                        if( element.date === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Date cell.");
                        if( element.impressions === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Impressions cell.");
                        if( element.clicks === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Clicks cell.");
                        if( element.spend === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Spend cell.");
                        if( element.conversions === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Conversions cell.");
                    });

                    //Reject this promise and notify the parent about the errors
                    //to display in the large alert window.
                    if( errors.length > 0 ){
                        this.props.notifyErrors( 
                            errors, 
                            "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                        );

                        reject(Error('There were some errors in the file.'));
                    }
                    
                    resolve(json);
                    
                },
                error: error => {
                    reject( Error( error.message ) );
                }
            });
        });
    }

    //TODO: Esto no debe estar aquí.
    camelize = (str: string) => {
        str = str.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
        {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    render(){
        
        let button;
        if( this.state.selectedFile ){
            button = 
                <div>
                    <div onClick={this.onFileUpload} className="primary-button pointer-hover p-[8px_25px]">
                        <div className="primary-button-text">UPLOAD</div>
                        <IconArrowRight />
                    </div>
                </div>
        }else{
            button = 
                <div>
                    <input id={"file-uploader-franchise"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-franchise"}>
                        <div className="primary-button pointer-hover">
                            <div className="primary-button-text">IMPORT</div>
                        </div>
                    </label>
                </div>
        }
        
        return (
            <div className="mb-10 file-upload-area">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col span={6}>
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }

                        { this.state.selectedFile == null && 
                            <a target="_blank" download={true} className="template-download" href="/templates/template_performance.xlsx">Template</a>
                        }

                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        importReports: ( reportArray: CustomReportRowSchema[] ) => dispatch(importCustomReports({customReportSchemaArray: reportArray}) )
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        reportErrors: state.customReport.reportErrors,
        importReportsResult: state.customReport.importReportsResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportUploader);