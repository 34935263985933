import "./ReportsPage.scss";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "assets/icons/loader-onmiChannel.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Form, Input, Select, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import DatePicker from "../datepicker/DatePicker";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import { selectCredentialValidation, getGoogleAdsKeywordCreativeReports, getFacebookAdsReports, getCustomChannelReports, selectFacebookAdsReports, selectGoogleAdsKeywordCreativeReports, selectCustomChannelReports, selectGoogleAdsLoading, setGoogleAdsLoading, selectCustomKeywordReports, getCustomKeywordReports, getCustomCreativeReports, selectCustomCreativeReports } from "../../store/Franchise/FranchiseStore";
//import { getCustomChannels, selectCustomChannels } from "../../store/CustomReport/CustomReportStore";
import { getCustomKeywordChannels, selectCustomKeywordChannels } from "../../store/CustomKeyword/CustomKeywordStore";
import { getCustomCreativeChannels, selectCustomCreativeChannels } from "../../store/CustomCreative/CustomCreativeStore";
import type { FranchiseCustomCreativesRow, FranchiseCustomKeywordsRow, FranchiseCustomReportsRow, FranchiseGadsRow } from "../../services/franchiseService";
import { AvailableRoutes } from "../../pages/Routes";
import { setReportsLocationId, setReportTitle,  selectReportCompany } from "../../store/Reports/ReportsStore";
import { addDaysToDate } from "../../utility/time";
import TendencyCard from "./OmniChannel/TendencyCard";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { setSelectedMainMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
import { selectLocations,selectFranchiseLocations, getGoogleAdsKeyWords } from "../../store/Location/LocationStore";
import { UserRole } from "../../models/User";
import type { FranchiseKeyWordPerformance } from "../../services/franchiseService";
import CustomTooltip from "../tooltip/tooltip";
export const KeyWordsCreative = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const availableLocations = useSelector(selectFranchiseLocations);
    const googleAdsReports = useSelector(selectGoogleAdsKeywordCreativeReports);
    const googleAdsLoading = useSelector(selectGoogleAdsLoading);
    const customKeywordReports = useSelector(selectCustomKeywordReports);
    const customCreativeReports = useSelector(selectCustomCreativeReports);
    const facebookAdsReports = useSelector(selectFacebookAdsReports);
    // const channelSelected = useSelector(selectReportCompany);
    const customChannels = useSelector(selectCustomKeywordChannels);
    const customCreativeChannels = useSelector(selectCustomCreativeChannels);
    const user = useSelector(selectUserAccount);
    const credentialValidation = useSelector(selectCredentialValidation);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [channelSelected, setChannelSelected] = useState("Google");

    //Page controls 
    const [firstPaint, setFirstPaint] = useState(true);
    const [pageG, setPageG] = useState(1);
    const [pageF, setPageF] = useState(1);
    const [pageTokens, setPageTokens] = useState([undefined] as (string | undefined)[]);
    const [pageTokenF, setPageTokenF] = useState(undefined as string | undefined);
    const [pageDirF, setPageDirF] = useState(undefined as string | undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [mode, setMode] = useState("keywords");
    const [allLocations, setAllLocations] = useState([] as any[])

    var dynamicLocations; 

    useEffect(() => {
        if ( credentialValidation.loaded && customChannels.length > 0) {
            if(mode == "keywords"){
                setChannelSelected('Google');

            }else if(mode == "creative"){
                const channel = customChannels.find(channel => channel.name === "Display");
                if(channel){
                    setChannelSelected(channel.id)
                    form.setFieldsValue({channel: channel.id});
                }else{
                    setLoading(false);
                }
            }
        }
    }, [credentialValidation, customChannels]);

    useEffect(() =>{
        if(availableLocations.length > 0){
            dynamicLocations = availableLocations
            .map((location, i) => ({
                label: `${location.name}`,
                value: location.id,
            }));
          
            dynamicLocations.sort((a, b) => {
                const labelA = a.label.toLowerCase();
                const labelB = b.label.toLowerCase();
            
                if (labelA < labelB) {
                    return -1;
                }
                if (labelA > labelB) {
                    return 1;
                }
                return 0;
            });
            setAllLocations(dynamicLocations);
            form.setFieldsValue({ location: dynamicLocations[0] ? dynamicLocations[0].value : null  });
        }
    }, [availableLocations])
    

    const [form] = Form.useForm();

    useEffect(() => {
        if (channelSelected == 'Google' && !firstPaint && !googleAdsLoading && allLocations.length > 0) {
            setLoading(true)
            if (pageG < 1) {
                setPageG(1);
            } else {
                form.setFieldsValue(
                { 
                    pageTokenG: pageTokens[pageG - 1], 
                    orderBy: googleAdsReports.lastOrderBy || 'impressions', 
                    dateRange: googleAdsReports.lastDateRange || 'mtd' 
                });
                onFinish(form.getFieldsValue());
            }
        }
    }, [pageG, firstPaint, googleAdsLoading, channelSelected, allLocations]);


    // if the channel is a custom channel 
    useEffect(() => {
        if ( credentialValidation.loaded && channelSelected != "" &&  channelSelected != 'Google' && channelSelected != 'Facebook' && allLocations.length > 0 ) {
            setLoading(true)
            form.setFieldsValue({ pageTokenG: undefined });
            form.setFieldsValue({ orderBy: customKeywordReports.lastOrderBy || 'ad_group' });
            form.submit();
        }
    }, [channelSelected, credentialValidation, allLocations, ]);



    useEffect(() => { 
        // if (googleAdsReports.error) console.log(googleAdsReports.error);
        if (googleAdsReports.keyWords == undefined || googleAdsReports.keyWords.length == 0) {
            const channel = customChannels.find(channel => channel.name === "Search");
            if(channel){
                setChannelSelected(channel.id)
                form.setFieldsValue({channel: channel.id});
            }else{
                setLoading(false);
            }
        }

        if (googleAdsReports.nextPageToken) {
            if (pageTokens.length <= pageG) {
                let pt = pageTokens.slice();
                pt.push(googleAdsReports.nextPageToken);
                setPageTokens(pt);
            } else if (pageTokens[pageG] !== googleAdsReports.nextPageToken) {
                let pt = pageTokens.slice();
                pt.splice(pageG, 1, googleAdsReports.nextPageToken);
                setPageTokens(pt);
            }
        }
        dispatch(setGoogleAdsLoading(false));
        setLoading(false)
    }, [googleAdsReports, pageTokens]);
    

    useEffect(() => {
        if (customKeywordReports.error) console.log(customKeywordReports.error);
        setLoading(false)
    }, [customKeywordReports]);

    useEffect(() => {
        if (customCreativeReports.error) console.log(customCreativeReports.error);
        setLoading(false)
    }, [customCreativeReports]);

    // Facebook channel 
    useEffect(() => {
        if (channelSelected == 'Facebook' && (facebookAdsReports.prevPageToken || facebookAdsReports.nextPageToken)) {
            form.setFieldsValue({ pageTokenF: pageTokenF });
            form.setFieldsValue({ pageDir: pageDirF });
            form.setFieldsValue({ startDate: new Date(facebookAdsReports.lastStartDate || addDaysToDate(new Date(), -28)) });
            form.setFieldsValue({ endDate: new Date(facebookAdsReports.lastEndDate || new Date()) });
            form.setFieldsValue({ orderBy: facebookAdsReports.lastOrderBy || 'name' });
            form.submit();
        }
    }, [pageF, pageTokenF, pageDirF, channelSelected]);

    useEffect(() => {
        if (facebookAdsReports.error) console.log(facebookAdsReports.error);
    }, [facebookAdsReports]);


    useEffect(() => {
        setLoading(true)
        dispatch(getCustomKeywordChannels());
        dispatch(getGoogleAdsKeywordCreativeReports({}));
        dispatch(setSelectedMainMenu("report") );
        dispatch(setSelectedSubMenu("Keywords & creative"));
        form.setFieldsValue({ orderBy: 'ad_group' });
        setFirstPaint(false);
    }, []);


    const onFinish = (e: any) => {
        if( channelSelected == '' ) {
            setLoading(false);
            return
        };

        if (e.location != null) {
            if( mode === "keywords"  ){
                if( channelSelected === "Google"  ){
                    dispatch(getGoogleAdsKeywordCreativeReports({ dateRange: e.dateRange, orderBy: e.orderBy, pageToken: e.pageTokenG, location: e.location }));
                }else{
                    dispatch(getCustomKeywordReports({channel: e.channel, location: e.location, orderBy: e.orderBy }))
                }
            }else if( mode === "creative"){
                if( channelSelected === "Google" ){
                    dispatch(getGoogleAdsKeywordCreativeReports({ dateRange: e.dateRange, orderBy: e.orderBy, pageToken: e.pageTokenG, location: e.location }));
                }else{
                    dispatch(getCustomCreativeReports({channel: e.channel, location: e.location, orderBy: e.orderBy }))
                }
            }
        }else{
            setLoading(false)
        }
    }

    const onFieldsChange = (changedFields : any, allFields : any) => {
        onFinish(form.getFieldsValue());
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    }

    const onOrderChange = (value: string) => {
        form.setFieldsValue({ orderBy: value });
    };

    const onLocationChange = (value: string) => {
        form.setFieldsValue({ location: value });
        if ( mode == "keywords" ){
            setChannelSelected("Google");
        }
        setLoading(true);
    };

    //Listeners
    const onDateRangeChange = (value: string) => {
        form.setFieldsValue({ dateRange: value });
        
    }

    const onModeChange = (value: string) => {
        setPageG(1);
        setMode(value)
        setPageTokens([undefined]); 

        //Sets the channel.
        if(value == "keywords"){
            setLoading(true)
            setChannelSelected("Google");

            
        }else if(value == "creative"){
            const channel = customChannels.find(channel => channel.name === "Display");
            if(channel) {
                setChannelSelected(channel.id);
                form.setFieldsValue({channel: channel.id});
            }
            form.submit();
        }
    }

    const columns: ColumnsType<FranchiseKeyWordPerformance> = [
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'word',
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            render: (val => `${((isNaN(val) ? 0 : val) * 100).toFixed(2)}%`)
        },
        {
            title: 'Spend',
            dataIndex: 'cost',
            key: 'cost',
            render: (val => `$${((isNaN(val) ? 0 : val) / 1000000).toLocaleString('en-US',{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`)
        }
    ];

    const customKeywordReportColumns: ColumnsType<FranchiseCustomKeywordsRow> = [
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'word',
        },
        {
            title: 'Ad group',
            dataIndex: 'adGroup',
            key: 'adGroup',
            render: (text, record, index) => text,
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            render: (val => `${((isNaN(val) ? 0 : val) * 100).toFixed(2)}%`)
        },
        {
            title: 'Spend',
            dataIndex: 'cost',
            key: 'cost',
            render: (val => `$${((val)).toLocaleString('en-US',{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`)
        }
    ];

    const customCreativeReportColumns: ColumnsType<FranchiseCustomCreativesRow> = [
        {
            title: 'Creative',
            dataIndex: 'creative',
            key: 'creative',
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            render: (val => `${((isNaN(val) ? 0 : val) * 100).toFixed(2)}%`)
        },
        {
            title: 'Spend',
            dataIndex: 'cost',
            key: 'cost',
            render: (val => `$${((val)).toLocaleString('en-US',{
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`)
        }
    ];

    return (
        <div className="reports-page-container res:mb-[180px]">
            <div>
                <div className="report-header">
                    <div className="report-title">Keywords & creative</div>
                </div>

                <div className="flex flex-wrap  items-center">
                    <Form
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFieldsChange={onFieldsChange}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="container-form w-full"
                        layout="vertical"
                    >
                        <div className="report-form-container">
                            <Input.Group compact className="res:!grid res:grid-cols-2 res:gap-x-3 res:items-end !flex">
                                
                                <Form.Item
                                    hidden
                                    name='pageTokenG'
                                    className="res:h-0 res:m-0 res:w-0"
                                />
                                <Form.Item
                                    hidden
                                    name='pageTokenF'
                                    className="res:h-0 res:m-0 res:w-0"
                                />
                                <Form.Item
                                    hidden
                                    name='pageDir'
                                    className="res:h-0 res:m-0 res:w-0"
                                />
                                <Form.Item
                                    label="Channel"
                                    name="channel"
                                    style={{  width: "130px"
                                    , display: "none" 
                                    }}
                                    
                                >
                                    <Select
                                        showArrow
                                        style={{ borderRadius: "4px", width: "100%" }}
                                        defaultValue={channelSelected}                                        
                                        className="mb-[1px]"
                                        options={[]}
                                    />
                                </Form.Item>
                                { 
                                    (channelSelected == "Google" || channelSelected == "Facebook") ? (
                                        <Form.Item
                                            name="dateRange"
                                            className="res:!w-full res:!ml-0 filter-input"
                                            label="Date interval"
                                            style={{ marginLeft: "16px", width: "160px", display: "none" }}
                                        >
                                            <Select
                                                showArrow
                                                
                                                style={{ borderRadius: "4px", width: "100%" }}
                                                onChange={onDateRangeChange}
                                                options={[
                                                { label: 'Last 14 days', value: 'fourteen' },
                                                { label: 'Month to date', value: 'mtd' },
                                                { label: 'Year to date', value: 'ytd' },
                                                // { label: 'Custom', value: 'custom' }
                                                ]}
                                            />
                                        </Form.Item>
                                    ) : <></>
                                }
                                

                                <Form.Item
                                    label="Sort by"
                                    className="res:!w-full res:!ml-0"
                                    name="orderBy"
                                    style={{ marginLeft: "16px", width: "160px" }}
                                >
                                    <Select
                                        showArrow
                                        style={{ borderRadius: "4px", width: "100%" }}
                                        defaultValue={'ad_group'}
                                        onChange={onOrderChange}
                                        options={[
                                            { label: 'Ad group', value: 'ad_group' },
                                            { label: 'Impressions', value: 'impressions' },
                                            { label: 'CTR', value: 'ctr' },
                                            { label: 'Spend', value: 'cost' },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Location"
                                    name="location"
                                    hidden={ allLocations.length == 0 ? true : false}
                                    style={{  marginLeft: "16px", width: "160px" }}
                                    
                                >
                                    <Select
                                        showArrow
                                        style={{ borderRadius: "4px", width: "100%" }}
                                        defaultValue={null}

                                        onChange={ onLocationChange }
                                        className="mb-[1px]"
                                        options={allLocations}
                                    />
                                </Form.Item>
                                <div className="!flex justify-between items-start gap-x-3 col-span-2 flex-wrap ml-auto h-fit">
                                    <CustomTooltip
                                        open={tooltipIsOpen}
                                        onMouseEnter={() => setTooltipIsOpen(true)}
                                        onMouseLeave={() => setTooltipIsOpen(false)}
                                        title={(<><p> The keywords report shows active keywords across all ad groups for your search campaigns. The creative report shows performance by asset type for your display campaigns.</p></>)}
                                        arrow
                                        >
                                        <InfoCircleOutlined
                                            style={{
                                            color: "#424242",
                                            fontSize: "25px",
                                            marginLeft: "25px",
                                            alignSelf: "center"
                                            }}
                                        />
                                    </CustomTooltip>
                                    <Form.Item className="ml-auto mb-0">
                                        <Button
                                            className={`bg-white border-[1px] w-28 cursor-pointer border-[--color-primary] mb-0 font-bold text-[--color-primary] border-solid rounded-[4px] text-center h-14 ${mode == "keywords" ? 'opacity-[1]' : 'opacity-[0.6]' }`}
                                            onClick={() => {
                                                onModeChange("keywords");
                                            }}
                                        >
                                            KEYWORDS
                                        </Button>
                                    </Form.Item>
                                    <Form.Item className="ml-auto mb-0">
                                        <Button
                                            className={`bg-white border-[1px] w-28 cursor-pointer border-[--color-primary] mb-0 font-bold text-[--color-primary] border-solid rounded-[4px] text-center h-14 ${mode == "creative" ? 'opacity-[1]' : 'opacity-[0.6]' }`}
                                            onClick={() => {
                                                onModeChange("creative");
                                            }}
                                        >
                                            CREATIVE
                                        </Button>
                                    </Form.Item>
                                </div>
                                
                                
                            </Input.Group>
                        </div>
                    </Form>
                </div>
                
                <div className="report-body">
                    {channelSelected == 'Google' ?
                        pageTokens.length > 1 ?
                            <div className="pagination-container">
                                <ul>
                                    <li key='1'>
                                        <Button type="text" className="prev" disabled={pageG <= 1} onClick={() => setPageG(pageG - 1)}>
                                        </Button>
                                    </li>
                                    <li key='2'>
                                        <span>{pageG}</span>
                                    </li>
                                    <li key='3'>
                                        <Button type="text" className="next" disabled={!pageTokens[pageG]} onClick={() => setPageG(pageG + 1)}>
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                            : null
                        :channelSelected == 'Facebook' ?
                            facebookAdsReports.hasPrevPage || facebookAdsReports.hasNextPage ?
                                <div className="pagination-container">
                                    <ul>
                                        <li key='1'>
                                            <Button type="text" className="prev" disabled={!facebookAdsReports.hasPrevPage} onClick={() => {
                                                setPageF(pageF - 1);
                                                setPageTokenF(facebookAdsReports.prevPageToken);
                                                setPageDirF('before');
                                            }}>
                                            </Button>
                                        </li>
                                        <li key='2'>
                                            <span>{pageF}</span>
                                        </li>
                                        <li key='3'>
                                            <Button type="text" className="next" disabled={!facebookAdsReports.hasNextPage} onClick={() => {
                                                setPageF(pageF + 1);
                                                setPageTokenF(facebookAdsReports.nextPageToken);
                                                setPageDirF('after');
                                            }}>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            : null
                        : null
                    }

                    {
                        mode === "keywords"
                        ?
                            channelSelected === "Google"
                            ?
                                <Table className="res:overflow-x-scroll report-alltable" columns={columns} pagination={false} dataSource={googleAdsReports.keyWords} />
                            :
                                <Table className="res:overflow-x-scroll report-alltable" columns={customKeywordReportColumns} pagination={false} dataSource={customKeywordReports.keywords} />
                        
                        :
                            channelSelected === "Google"
                            ?
                                <Table className="res:overflow-x-scroll report-alltable" columns={columns} pagination={false} dataSource={googleAdsReports.keyWords} />
                            :
                                <Table className="res:overflow-x-scroll report-alltable" columns={customCreativeReportColumns} pagination={false} dataSource={customCreativeReports.creatives} />
                    }
                    
                    
                    
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <LoaderIcon className="loader-icon" />
                        {/* <CircularProgress color="inherit" /> */}
                    </Backdrop>
                </div>
            </div>
        </div>
    );
};
