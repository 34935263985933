import { useState } from "react";
import style from './Demographics.module.css'
import { setAudience } from '../../../store/Demographics/DemographicsStore';
import { useDispatch } from 'react-redux';
import { DemographicsAudience } from '../../../models/Demographics';

interface AudiencesColumnsProps {
    options: { name: string; values: string[] }[];
}

export const AudiencesColumns = ({ options }: AudiencesColumnsProps) => {
    const dispatch = useDispatch();
    const [selectedSource, setSelectedSource] = useState<string>('');

    const handleAudience = (selectedAudience: DemographicsAudience) => {
        dispatch(setAudience(selectedAudience));
        setSelectedSource(selectedAudience.name);
    }

    return (
        <div>
            <div>Attributes</div>
            <div className={style["column"]}>
                {options.map(option => (
                    <div
                        key={option.name}
                        className={`${style["item"]} ${selectedSource === option.name ? style["selected"] : ''}`}
                        onClick={() => handleAudience(option)}>
                        {option.name}
                    </div>
                ))}
            </div>
        </div>
    )
}


