import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const reportSlice = createSlice({
  name: "reports",
  initialState: {
    reportIframe: undefined as string | undefined,
    reportTitle: '' as string,
    reportLocationId: '' as string,
    reportCompany: '' as string,
    performanceTitle: 'Campaign Performance' as string,
    viewFormat: 'Table' as 'Table' | 'Graph',
  },
  reducers: {
    setReportIframeToShow: (
      state,
      { payload: iframe }: PayloadAction<string | undefined>
    ) => {
      state.reportIframe = iframe;
    },
    setReportTitle: (
      state,
      { payload: title }: PayloadAction<string>
    ) => {
      state.reportTitle = title;
    },
    setReportsLocationId: (
      state,
      { payload: reportLocationId }: PayloadAction<string>
    ) => {
      state.reportLocationId = reportLocationId;
    },
    setReportCompany: (
      state,
      { payload: company }: PayloadAction<string>
    ) => {
      state.reportCompany = company;
    },
    setPerformanceTitle: (
      state,
      { payload: performanceTitle }: PayloadAction<string>
    ) => {
      state.performanceTitle = performanceTitle;
    },
    setViewFormat: (
      state,
      { payload: viewFormat }: PayloadAction<'Table' | 'Graph'>
    ) => {
      state.viewFormat = viewFormat;
    }
  },
});

export const selectPerformanceTitle = (
  state: RootState,
): string => state.reports.performanceTitle;

export const selectReportCompany = (
  state: RootState
): string => state.reports.reportCompany;

export const selectReportIframeToShow = (
  state: RootState
): string | undefined => state.reports.reportIframe;

export const selectReportTitle = (
  state: RootState
): string => state.reports.reportTitle;

export const selectReportsLocationId = (
  state: RootState
): string => state.reports.reportLocationId;

export const selectViewFormat = (
  state: RootState
): 'Table' | 'Graph' => state.reports.viewFormat;

export const { setReportIframeToShow, setReportTitle, setReportsLocationId, setReportCompany, setPerformanceTitle, setViewFormat } = reportSlice.actions;

export default reportSlice.reducer;
