import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "../index";

const initialState = {
    isOpen: false,
    isUploading: false,
    imagesFetched: false,
};

export const UploadSlice = createSlice({
    name: "fileUpload",
    initialState,
    reducers: {
        setOpen: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.isOpen = currentState;
        },
        setUploading: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.isUploading = currentState;
        },
        setImagesFetched: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.imagesFetched = currentState;
        }
    }
});

export const selectIsOpenState = (state: RootState): boolean => state.fileUpload.isOpen;
export const selectIsUploadingState = (state: RootState): boolean => state.fileUpload.isUploading;
export const selectImagesFetchedState = (state: RootState): boolean => state.fileUpload.imagesFetched;

export const {
    setOpen,
    setUploading,
    setImagesFetched
} = UploadSlice.actions;

export default UploadSlice.reducer;