import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdminUser } from "../../models/AdminUser";
import services from "../../services";
import { RootState } from "../index";


export const fetchAdminUser = createAsyncThunk("admin-users/fetch", async () => {
  return services.adminUserService.getAdminUser();
});

export const adminAccountSlice = createSlice({
  name: "accounts",
  initialState: {
    adminUserAccount: null as IAdminUser | null,
    adminUserLoaded: false,
  },
  reducers: {
    setAdminUser: (state, { payload: adminUser }: PayloadAction<IAdminUser | null>) => {
      state.adminUserAccount = adminUser;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminUser.fulfilled, (state, action) => {
      state.adminUserAccount = action.payload;
      state.adminUserLoaded = true;
    });
    builder.addCase(fetchAdminUser.rejected, (state, action) => {
      state.adminUserLoaded = true;
    });
  },
});

export const selectAdminUserAccount = (state: RootState): IAdminUser | null =>
  state.adminAccounts.adminUserAccount;

export const selectAdminUserLoaded = (state: RootState) : Boolean => state.adminAccounts.adminUserLoaded;

export const { setAdminUser } = adminAccountSlice.actions;

export default adminAccountSlice.reducer;
