import React, { useState, useEffect } from "react";
import {Row, Col, Select, Button} from "antd";
import services from "../../../services"
import {useSelector} from "react-redux";
import {selectChannels} from "../../../store/Channels/ChannelStore";
import { IImageResource } from "../../../services/mediaTemplateService";
import { setUploading, setImagesFetched} from "../../../store/PlatformSupport/Upload";
import {useDispatch} from "react-redux";

interface IMediaUploaderProps {
    title: string;
}

interface IMediaUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
    selectedChannelId: string | null,
    selectedFileType: string | null,
}

const allowedFileTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
];
const allowedVideoTypes = [
    'video/mp4',
    'video/mpeg',
    'video/quicktime',
];

export const MediaUploader  = (props: IMediaUploaderProps) => {
    const dispatch = useDispatch();
    
    const [state, setState] = useState<IMediaUploaderState>({
        selectedFile: null,
        selectedFileType: null,
        fileTypeError: null,
        selectedChannelId: null,
    });

    const [hideSelect, setHideSelect] = useState(true);

    var firstChannel: string = '';
    const channels = useSelector(selectChannels);

    /**
     * Preselect first channel as selected channel
     */
    useEffect(() => {
        if (!state.selectedChannelId && firstChannel !== '') {
            state.selectedChannelId = firstChannel;
        }
    }, [firstChannel, state]);

    /**
     * Upload meida file
     */
    const onFileUpload = () => {
        const selectedFile = state.selectedFile;
        const selectedChannelId = state.selectedChannelId;
        const selectedFileType = state.selectedFileType;
        console.log(selectedFile, selectedChannelId);
        console.log(selectedFileType);
        
        if (selectedFile && selectedChannelId && selectedFileType === "image") {
            dispatch( setUploading(true) );
            services.mediaTemplateService.importImage(selectedFile, selectedChannelId).then(() => {
                setState({
                    selectedFile: null,
                    fileTypeError: null,
                    selectedChannelId: null,
                    selectedFileType: null
                });
                setHideSelect(true);
                alert(`File ${state.selectedFile?.name} uploaded successfully.`);
                dispatch( setUploading(false) );
                dispatch( setImagesFetched(false) )
            });
        }
        if (selectedFile && selectedChannelId && selectedFileType === "video") {
            dispatch( setUploading(true) );
            services.mediaTemplateService.importVideo(selectedFile, selectedChannelId).then(() => {
                setState({
                    selectedFile: null,
                    fileTypeError: null,
                    selectedChannelId: null,
                    selectedFileType: null
                });
                setHideSelect(true);
                alert(`File ${state.selectedFile?.name} uploaded successfully.`);
                dispatch( setUploading(false) );
                dispatch( setImagesFetched(false) )
            });
        }
    }

    /**
     * On selected file changed event handler
     *
     * @param event
     */
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if( !event.target.files ) {
            return;
        }


        let fileType = event.target.files[0].type;

        if( allowedFileTypes.indexOf(fileType) >= 0){
            setHideSelect(false)
            setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
                selectedChannelId: state.selectedChannelId,
                selectedFileType: "image"
            });
        }else if ( allowedVideoTypes.indexOf(fileType) >= 0){
            setHideSelect(false)
            setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
                selectedChannelId: state.selectedChannelId,
                selectedFileType: "video"
            });
        } else {
            setState({
                selectedFile: state.selectedFile,
                fileTypeError: 'The selected file is not valid. Only PNG and JPEG files are supported.',
                selectedChannelId: state.selectedChannelId,
                selectedFileType: null,
            });
            return;
        }
        
    }

    /**
     * Handle channge change
     *
     * @param value
     */
    const handleChannelChange = (value: string) => {
        if (!value) {
            return;
        }
        setState({
            selectedFile: state.selectedFile,
            fileTypeError: null,
            selectedChannelId: value,
            selectedFileType: state.selectedFileType
        });
    }

    const onFileRemove = () => {
        setHideSelect(true)
        setState({
            selectedFile: null,
            fileTypeError: state.fileTypeError,
            selectedChannelId: state.selectedChannelId,
            selectedFileType: null
        });
    }

    return (
        <div className="file-upload-area">
            
            <Row>
                <Col span={6} className="res:flex-[0_0_50%] res:max-w-[50%]">
                    {
                        state.selectedFile ?
                            <div>
                                <div onClick={onFileUpload}
                                     className="primary-button w-[auto] pointer-hover"
                                >
                                    <div className="primary-button-text">ASSIGN</div>
                                </div>
                                {/* <div>
                                    {state.selectedFile.name}
                                    <Button  onClick={handleFileRemove}>REMOVE</Button>
                                </div> */}
                            </div>  
                            :
                            <div>
                                <input id={"file-uploader-location"} type="file" onChange={onFileChange} />
                                <label htmlFor={"file-uploader-location"} >
                                    <div
                                        className={` primary-button  w-[auto] pointer-hover `}
                                    >
                                        <div className="primary-button-text"  > { props.title != "" ? props.title : "IMPORT" } </div>
                                    </div>
                                </label>
                            </div>
                    }
                </Col>
                <Col offset={1} span={24} md={6} className="res:ml-0 res:mt-3">
                    { state.selectedFile !== null &&
                        <div>
                            <p className="file-upload-file-name">{state.selectedFile.name}</p>
                            <span onClick={onFileRemove} className="file-upload-remove-button">REMOVE</span>
                        </div>
                    }
                    {
                        state.fileTypeError !== null &&
                        <div>
                            <p className="error">{state.fileTypeError}</p>
                        </div>
                    }

                </Col>
            </Row>

            <Row className={` ${ hideSelect ? "hidden" : "" }  mt-4 `}>
                <Col span={6} className="res:flex-[0_0_50%] res:max-w-[50%]">
                    <div className="mb-2">
                        Assign to channel
                    </div>
                    <Select style={{ fontSize: "16px", width: "100%" }} defaultValue={ channels.length > 0 ? channels[0].id : "" }  onChange={handleChannelChange}>
                        {
                            channels.map((channel,index) => {
                                if(index == 0){
                                    firstChannel = channel.id
                                } 
                                return(
                                    <Select.Option key={channel.id} value={channel.id}>{channel.title}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Col>
            </Row>
        </div>
    );
}

export default MediaUploader;

