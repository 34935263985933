import { CustomKeywordData, CustomKeywordChannel } from "../models/CustomKeyword";
import RestService from "./restService";

//The expected data in the custom reports file. All attributes are required.
//Used when importing rows from csv or xlsx
export interface CustomKeywordRowSchema {
  channelName: string,
  franchiseName: string,
  locationName: string,
  campaignName: string,
  keyword: string,
  impressions: string,
  ctr: string,
  spend: string,
  adGroup: string,
}

export default class CustomKeywordService {
  constructor(protected readonly api: RestService) { }

  async importCustomKeywords(customKeywordSchemaArray: CustomKeywordRowSchema[]): Promise<CustomKeywordData[]> {
    console.log(customKeywordSchemaArray);
    const apiResponse = await this.api.post<CustomKeywordData[]>("/custom-keywords/import", customKeywordSchemaArray);
    return apiResponse;
  }

  async getCustomKeywordChannels(): Promise<CustomKeywordChannel[]>{
    const apiResponse = await this.api.get<CustomKeywordChannel[]>("/custom-keywords/custom-channels");
    return apiResponse;
  }
  
}


