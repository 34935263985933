import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/mulish";
import App from "./App";
import AdminApp from "./AdminApp";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import awsconfig from "./awsConfig";
import Amplify from "aws-amplify";
import { rootReducer } from "./store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { composeWithDevTools } from '@redux-devtools/extension';
import TagManager from 'react-gtm-module';

import "./index.css";
Amplify.configure(awsconfig);

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey || "");

const store = createStore(rootReducer,
  {},
  composeWithDevTools(
    applyMiddleware(reduxThunk)
  )
);

const tagManagerArgs = {
  gtmId: 'GTM-NT4VDNZH'
}
TagManager.initialize(tagManagerArgs)

//Check if the route starts with /admin, if so return the admin component instead.
const currentPathname = window.location.pathname;
var mainComponent;

if (currentPathname.startsWith('/plat-admin')) {
  mainComponent = <AdminApp />;
} else {
  mainComponent = <App />;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        {mainComponent}
      </Elements>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
