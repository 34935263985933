import React, { useState } from "react";
import { ReactComponent as IconArrowDown } from "../../../assets/icons/arrow-down.svg";
import "./styles.css";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { AdminAvailableRoutes } from "../../../pages/AdminRoutes";
import { useSelector } from "react-redux";
import { signOut } from "../../../store/Auth/action";
import CustomTooltip from "../../tooltip/tooltip";

const settings = ["My Account", "Sign Out"];

const AdminHeaderLayout = ({ children }: any) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const manageSettings = (setting: string) => {
    switch (setting) {
      case "My Account":
        break;
      case "Sign Out":
        signOut();
        break;
      default:
        console.log("check you settings");
    }
  };

  return (
    <div className="content-header">
      {children}
      <div className="content-header-sidebar-welcome">
        <div
          className="content-header-sidebar-welcome-text"
          style={{ marginRight: "15px" }}
        >
          Welcome back
        </div>

        <Box sx={{ flexGrow: 0 }}>
          <CustomTooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="profile-photo"
                src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80"
              />
              <IconArrowDown />
            </IconButton>
          </CustomTooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography
                  textAlign="center"
                  onClick={() => manageSettings(setting)}
                >
                  {setting}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
    </div>
  );
};

export default React.memo(AdminHeaderLayout);
