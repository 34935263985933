import "./CalendarDayModal.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { intervalToDuration } from "date-fns";
import { NewCampaignChannel } from "../../../../models/CampaignChannel";
import { useSelector } from "react-redux";
import { selectTotalAudience } from "../../../../store/campaignForm/CampaignForm";

interface CalendarDayNewChannelTableProps {
  newChannels: NewCampaignChannel[];
}

export const CalendarDayNewChannelTable = ({
  newChannels,
}: CalendarDayNewChannelTableProps): JSX.Element => {
  const audienceSize = useSelector(selectTotalAudience);
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Duration</TableCell>
          <TableCell>Audience Size</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {newChannels.map((newChannel) => {
          let displayDuration: string = "Always On";
          if (newChannel.endDate !== undefined) {
            const duration: Duration = intervalToDuration({
              start: newChannel.startDate,
              end: newChannel.endDate,
            });
            if (duration.days && duration.days <= 7) {
              displayDuration = `${duration.days}d`;
            } else if (duration.days) {
              const days = duration.days % 7;
              const weeks = (duration.days - days) / 7;
              displayDuration = `${weeks}w ${days}d`;
            }
          }
          return (
            <TableRow
              key={`new channel for day table:${newChannel.channelType.id}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {newChannel.channelType.title}
              </TableCell>
              <TableCell>{displayDuration}</TableCell>
              <TableCell>{audienceSize?.toLocaleString() ?? ""}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
