import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

const Request = () => {
  const navigate = useNavigate();
  const whiteLabel = useSelector( selectWhiteLabel );

  return (
    <div className="container-auth res:w-full ">
      <div>
        <IconArrowLeft
          className="primary-icon cursor-pointer"
          onClick={() => navigate("/login")}
        />
        <div className="text-header"></div>
          <div className="asana-embed-container">
            <link rel="stylesheet" href="https://form.asana.com/static/asana-form-embed-style.css" />
            <iframe className="asana-embed-iframe" height="600" width="800" src="https://form.asana.com/?k=e7nIP42f-Tj27Kxo5Tb8Ag&d=1201669547830375&embed=true"></iframe>
            <div className="asana-embed-footer">
              <a rel="nofollow noopener" target="_blank" className="asana-embed-footer-link" href="https://asana.com/?utm_source=embedded_form">
              </a>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Request;
