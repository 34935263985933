import { QRPromCode } from "../models/QRCode";
import RestService from "./restService";

export interface INewPromCodeBatch {
  size: number;
  promotion: string;
  notes: string;
  expirationDate: Date;
}

export interface INewClaimPromCode {
    userEmail: string;
    qrCodeId: string;
}

export interface INewPromoCodeFromCampaign{
  userEmail: string;
  qrCampaignId: string;
  channel: string | null;
  code: string | null;
  fullPhoneNumber: string;
}

export default class QRPromCodesService {
  constructor(protected readonly api: RestService) { }

  async validatePromCode(
    code: string,
  ): Promise<QRPromCode> {

    const response = await this.api.get<QRPromCode>("/qrPromCodes/" + code);
    return {
      ...response
    };
  }

  async generatePromCodeBatch(
    newCodeBatch: INewPromCodeBatch
  ): Promise<QRPromCode[]> {

    const response = await this.api.post<QRPromCode[]>("/qrPromCodes/", newCodeBatch);
    return [
      ...response
    ];
  }

  //QR Extension; Creates a single promoCode based on a QRCampaign ID.
  async generatePromoCode(
    userEmail: string,
    qrCampaignId: string,
    channel: string | null,
    code: string,
    fullPhoneNumber: string,
  ): Promise<QRPromCode>{
    
    const newPromoCodeFromCampaign : INewPromoCodeFromCampaign = {userEmail: userEmail, qrCampaignId: qrCampaignId, channel: channel, code: code, fullPhoneNumber: fullPhoneNumber }

    const response = await this.api.post<QRPromCode>("/qrPromCodes/single", newPromoCodeFromCampaign);
    
    return response;
  }

  /*async claimPromCode(
    newClaimCode: INewClaimPromCode,
  ): Promise<QRPromCode> {

    const response = await this.api.put<QRPromCode>("/qrPromCodes/", newClaimCode);
    return {
      ...response
    };
  }*/

  async claimPromCode(
    id: string
  ):Promise<QRPromCode>{
    const response = await this.api.put<QRPromCode>("/qrPromCodes/claim", { id: id });
    return response;
  }
}
