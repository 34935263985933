import styles from './Chart.module.css'
import { FranchiseOmniChannelDate, FranchiseOmniChannelRow, CPLDate, FranchiseOmniChannelLocation } from 'services/franchiseService';
import { useState, useEffect } from 'react';
import GoogleGraphChart from './GoogleGraphsChart';
import CPLChart from './CPLChart';
import { useDispatch, useSelector } from "react-redux";
import { selectUserAccount } from "../../../store/Account/AccountStore";
import { UserRole } from "../../../models/User";
import { selectBenchmark, selectTendency, setBenchmark, selectFranchise } from 'store/Franchise/FranchiseStore'
import { selectLocations, selectFranchiseLocations } from 'store/Location/LocationStore'
import { Form, Select } from 'antd';  

interface CustomChannelOptions {
  label: string;
  value: string;
}

interface OmniChartProps {
  channelReports: FranchiseOmniChannelRow[];
  customChannelOptions: CustomChannelOptions[];
  defaultChannel: String; //The default channel to show when "all" is selected.
  selectedChannel: String; //The actual currently selected channel.
}

export const OmniChart = (props: OmniChartProps) => {

  const benchmark = useSelector(selectBenchmark);
  const metricSelected = useSelector(selectTendency);
  const dispatch = useDispatch();


  const franchise = useSelector( selectFranchise );
  const locations = useSelector( selectLocations );
  const allLocations = useSelector( selectFranchiseLocations )
  const [selectedMetric, setSelectedMetric] = useState('impressions');
  const [selectedSecondMetric, setSelectedSecondMetric] = useState('clicks');
  const [selectedChannel, setSelectedChannel] = useState(props.defaultChannel);
  // const [cplDates, setCplDates] = useState<Array<CPLDate[]>>([]);
  const [systemReports, setSystemReports] = useState([] as FranchiseOmniChannelLocation[])
  // const [selectedBenchmark, setSelectedBenchmark] = useState('my-trends');

  const user = useSelector(selectUserAccount);

  useEffect( () =>{
    let newLocationReports = [
      ...props.channelReports[0].locationReports
    ]

    if( props.channelReports[0].locationReports.length > 4 ) {
      const mergedReports = mergeLocationReports(props.channelReports[0].locationReports)
      console.log( props.channelReports[0].locationReports )
      console.log(mergedReports);

      newLocationReports = mergedReports;
    }

    if( benchmark == "system") {
      newLocationReports = [
        ...newLocationReports,
        props.channelReports[0].systemReport
      ];
      setSystemReports(newLocationReports)

    }else{
      setSystemReports([])
    }
  }, [benchmark]);

  // useEffect( () => {
  //   console.log(systemReports)
  // }, [systemReports] )


  const  mergeLocationReports = (reports: any) => {
    const mergedDates: FranchiseOmniChannelDate[] = reports.reduce((acc:any, report:any) => {
      report.dates.forEach((date: any) => {
        const existingDate = acc.find((d:any) => d.date === date.date);
        if (existingDate) {
          existingDate.clicks += date.clicks;
          existingDate.impressions += date.impressions;
          existingDate.pmConversions += date.pmConversions;
          existingDate.spend += date.spend;
          existingDate.ctr = existingDate.impressions ? (existingDate.clicks / existingDate.impressions) : 0;
          existingDate.cpc = existingDate.clicks ? (existingDate.spend / existingDate.clicks) : 0;
          existingDate.cpl = existingDate.pmConversions ? (existingDate.spend / existingDate.pmConversions) : 0;
        } else {
          acc.push({ ...date });
        }
      });
      return acc as FranchiseOmniChannelDate[];
    }, []);
    return [{
      key: "mergedReports",
      name: "All Locations",
      dates: mergedDates
    }];
  };


  //First verifies how many reports are included here.
  let facebookDatesReport: FranchiseOmniChannelDate[] = [];
  let googleDatesReport: FranchiseOmniChannelDate[] = [];

  let facebookLocationsReport: FranchiseOmniChannelLocation[] = [];
  let googleLocationsReport: FranchiseOmniChannelLocation[] = [];

  props.channelReports.forEach(element => {
    if (element.name == "meta") {
      facebookDatesReport = element.dates;
      facebookLocationsReport = element.locationReports;
    }
    if (element.name == "google") {
      googleDatesReport = element.dates;
      googleLocationsReport = element.locationReports;
    }
  });


  //Listeners
  const onMetricChange = (value: string) => {
    setSelectedMetric(value);
  }
  const onMetricSecondChange = (value: string) => {
    setSelectedSecondMetric(value);
  }
  // const onBenchmarkChange = (value: string) =>{
  //   setSelectedBenchmark(value);
  // }

  const onChannelChange = (value: string) => {
    setSelectedChannel(value);
  }


  let dates: FranchiseOmniChannelDate[] = []; 
  let locationReports: FranchiseOmniChannelLocation[] = [];
  
  
  if ( (props.selectedChannel == "all" && selectedChannel == "meta") || props.selectedChannel == "facebook") {
    dates = facebookDatesReport;
    locationReports = facebookLocationsReport;
    // console.log('switching to meta / facebook');
  } else if ( (props.selectedChannel == "all" && selectedChannel == "google") || props.selectedChannel == "google") {
    dates = googleDatesReport;
    locationReports = googleLocationsReport;
    // console.log('switching to google');
  } else {
    
    let desiredChannelName : String = "";
    if( props.selectedChannel === "all"){
      desiredChannelName = selectedChannel;
    }else{
      desiredChannelName = props.selectedChannel;
    }
    
    const desiredChannel = props.channelReports.find(channel => channel.key == desiredChannelName);
    if (desiredChannel !== undefined) {
      dates = desiredChannel.dates;
      locationReports = desiredChannel.locationReports;
      // console.log('switching to '+desiredChannelName)
    } else {
      dates = [];
      locationReports = [];
      // console.log('No reports for this custom channel.');
    }
  }

  // If there are more than 4 campaigns, unify in a single line
  if (locationReports.length > 4) {
    locationReports = mergeLocationReports(locationReports)
  }


  return <div className={styles['chart-main-container'] + " res:w-[500px]"}>
    <div className='w-full grid grid-cols-4 gap-[8px] bg-transparent px-4 mb-5'>
{/*        <div className='col-span-1'> 
           <h3>Pacing</h3>

          <div className='flex flex-nowrap items-center'>
            <div className=' rounded-[50%] w-[40px] h-[40px] bg-[#0d9051] flex justify-center items-center  mr-3'>
              <span className='text-white'>87%</span> 
            </div>
            <span className=''>On pace</span>
          </div>
          
        </div> 
*/}
        <div className='col-span-3'> 
          <h3>Benchmarking</h3>

          <div className='grid grid-cols-3 gap-3 mt-2'>
            <div onClick={ () => {dispatch( setBenchmark("my-trends")) } } className={`bg-white border-[1px] cursor-pointer border-[--color-primary] font-bold text-[--color-primary] border-solid rounded-[4px] ${benchmark !== "my-trends" ? 'opacity-[0.6]' : ''} text-center py-2 hover:opacity-[1]`}> 
              {
                (user?.type === UserRole.Franchisee) ?  <>MY TRENDS</> : <>SYSTEM</>
              }
            </div>

{/*             { (user?.type === UserRole.Franchisee) && (
              <div onClick={ () => {dispatch( setBenchmark("system")) } } className={`bg-white border-[1px] cursor-pointer border-[--color-primary] font-bold text-[--color-primary] border-solid rounded-[4px] ${benchmark !== "system" ? 'opacity-[0.6]' : ''} text-center py-2 hover:opacity-[1]`}> SYSTEM </div>
            )}

            { (user?.type === UserRole.Franchisor || user?.type === UserRole.Agency) && (
              <div onClick={ () => {dispatch( setBenchmark("market")) } } className={`bg-white border-[1px] cursor-pointer border-[--color-primary] font-bold text-[--color-primary] border-solid rounded-[4px] ${benchmark !== "market" ? 'opacity-[0.6]' : ''} text-center py-2 hover:opacity-[1]`}> MARKET </div>
            )} */}
          </div>
        </div>
    </div>

    { ( benchmark == "system" && systemReports.length > 0) ? 
        <GoogleGraphChart 
        selectedMetrics={["impressions"]} 
        dates={dates} 
        locationReports={systemReports}
        selectedChannel={props.selectedChannel}
        ></GoogleGraphChart>
      : 
        <GoogleGraphChart 
        selectedMetrics={[metricSelected.toLowerCase()]} 
        dates={dates} 
        locationReports={locationReports}
        selectedChannel={props.selectedChannel}
        ></GoogleGraphChart>
        
    }
    
    <Form name='basic' className={styles['chart-controlers'] + " res:max-w-[87vw] res:px-3 res:flex res:flex-wrap res:justify-start hidden"}>
      {/* <Form.Item className={ props.selectedChannel != 'all' ? 'hidden' : '' } style={{marginBottom: 0}}>
        <Select
          showArrow
          value={selectedChannel.toString()}
          onChange={onChannelChange}
          options={props.customChannelOptions}
        >
        </Select>
      </Form.Item> */}

      {/* <span className={styles['chart-controler-divider'] + " res:w-full res:my-2" } ></span> */}

      <Form.Item style={{ marginBottom: 0 }}>
        <Select
          showArrow
          value={selectedMetric.toString()}
          onChange={onMetricChange}
          options={[
            { label: "Impressions", value: "impressions" },
            { label: "Clicks", value: "clicks" },
            { label: "CTR", value: "ctr" },
            { label: "Spend", value: "spend" },
            { label: "CPC", value: "cpc" },
          ]}
        >
        </Select>
      </Form.Item>

      <span className={styles['chart-controler-divider']}>VS</span>

      <Form.Item style={{ marginBottom: 0 }}>
        <Select
          showArrow
          value={selectedSecondMetric.toString()}
          onChange={onMetricSecondChange}
          options={[
            { label: "Impressions", value: "impressions" },
            { label: "Clicks", value: "clicks" },
            { label: "CTR", value: "ctr" },
            { label: "Spend", value: "spend" },
            { label: "CPC", value: "cpc" },
          ]}
        >
        </Select>
      </Form.Item>

    </Form>
  </div>
};

export default OmniChart
