const typeDefault = "@auth";
export const LOGIN = `${typeDefault}/login`;
export const REGISTER = `${typeDefault}/register`;
export const LOADING = `${typeDefault}/loading`;
export const ERROR = `${typeDefault}/error`;
export const CHANGE_PASSWORD = `${typeDefault}/change_password`;
export const PASSWORD_RECOVERY = `${typeDefault}/password_recovery`;
export const RESEND_CODE_VERIFICATION = `${typeDefault}/resend_code_verification`;
export const ACCOUNT_VERIFICATION = `${typeDefault}/account_verification`;
export const FORGOT_PASSWORD = `${typeDefault}/forgot_password`;
export const SIGN_OUT = `${typeDefault}/sign_out`;
export const SET_RESET_PASSWORD_SUCCESS = `${typeDefault}/set_reset_password_success`;
export const SET_REGISTERED_SUCCESS = `${typeDefault}/set_registered_success`;
export const CONFIRM_SIGN_UP = `${typeDefault}/confirm_sign_up`;
export const SET_CHANGED_PASSWORD_FALSE = `${typeDefault}/set_changed_password_false`;
