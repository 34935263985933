import "./UserPaymentMethods.css";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  addUserPayment,
  selectUserAccount,
} from "../../store/Account/AccountStore";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../assets/icons/orange-back-arrow.svg";
import { useState } from "react";
import AlertMessage from "../alertMessage/AlertMessage";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";

interface AddCardFormProps {
  onClose: () => void;
  showBackButton: boolean;
}

export const AddCardForm = ({ onClose, showBackButton }: AddCardFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const account = useSelector(selectUserAccount);
  const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
  const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);

  const CARD_OPTIONS: StripeCardElementOptions = {
    style: {
      base: {
        fontFamily: "Mulish, sans-serif",
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.85)",
      },
    },
  };

  const saveCard = async (values: any) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElementInfo = elements.getElement(CardElement);

    if (cardElementInfo) {
      const response = await stripe.createToken(cardElementInfo, {
        name: values.name,
        address_line1: account?.address1 ?? undefined,
        address_line2: account?.address2 ?? undefined,
        address_city: account?.city ?? undefined,
        address_state: account?.state ?? undefined,
        address_zip: account?.postalCode ?? undefined,
        address_country: account?.country ?? undefined,
      });

      if (response.error) {
        alert(response.error.message);
      } else {
        dispatch(addUserPayment(response.token.id));
        setOpenSuccessMsg(true);
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setOpenErrorMsg(true);
    console.log("Failed:", errorInfo);
  };

  const whiteLabel = useSelector( selectWhiteLabel );

  return (
    <Form
      name="basic"
      onFinish={saveCard}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="container-form"
      layout="vertical"
      style={{ maxWidth: "512px" }}
    >
      <Form.Item label="Name on Card" name="name" rules={[{ required: true }]}>
        <Input className="res:py-3" placeholder="Name" />
      </Form.Item>

      <div
        style={{
          backgroundColor: "white",
          padding: "4px 11px",
          border: "1px solid #d9d9d9",
          borderRadius: "5px",
        }}
      >
        <CardElement className="res:py-3" options={CARD_OPTIONS} />
      </div>

      <div
        hidden={showBackButton}
        style={{
          display: "flex",
          marginTop: "40px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Form.Item>
          <Button
            htmlType="submit"
            className="general-button pointer-hover"
            style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            SAVE
            <IconArrowRight/>
          </Button>
        </Form.Item>
      </div>
      <div
        hidden={!showBackButton}
        style={{
          display: "flex",
          marginTop: "40px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Form.Item className="res:basis-5/12">
          <Button
            className="inverted-general-button pointer-hover res:!w-full"
            onClick={onClose}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="primary-button-text">BACK</div>
          </Button>
        </Form.Item>
        <Form.Item className="res:basis-5/12">
          <Button
            htmlType="submit"
            className="primary-button pointer-hover res:!w-full"
            style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <div className="primary-button-text">SAVE</div>
          </Button>
        </Form.Item>
      </div>
      <AlertMessage successMessage="Your Credit card has been saved successfully!" openScsMsg={openSuccessMsg} onScsClose={() => setOpenSuccessMsg(false)} openErrMsg={openErrorMsg} onErrClose={() => setOpenErrorMsg(false)}/>
    </Form>
  );
};
