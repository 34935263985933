import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomCreativeData, CustomCreativeChannel } from "../../models/CustomCreative";
import services from "../../services";
import { CustomCreativeRowSchema } from "../../services/customCreativeService";
import { RootState } from "../index";

//Creates multiple custom reports from a csv or xlsx file
// @params
// customReportSchemaArray (An array of CustomReportRowSchema objects representing the rows in the file)
export const importCustomCreatives = createAsyncThunk<
  CustomCreativeData[], //we expect this to return a list of locations
  {
    customCreativeSchemaArray: CustomCreativeRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "custom-creatives/import",
  async (customCreativeSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.customCreativeService.importCustomCreatives(customCreativeSchemaArray.customCreativeSchemaArray);
      return result;
    } catch (error: any) {
      //console.log( error );
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message == "Validation Failed") {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;

          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message == "Entity Not Found") {
        formattedErrors.push(errorData.details);
      } else if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);

    }

  }
);

export const getCustomCreativeChannels = createAsyncThunk(
  "custom-creatives/custom-channels",
  async () =>{
    return await services.customCreativeService.getCustomCreativeChannels();
  }
);



export const customCreativeSlice = createSlice({
  name: "customCreative",
  initialState: {
    creativeErrors: [] as String[] | null,
    importCreativesResult: null as String | null,
    customChannels: [] as CustomCreativeChannel[]
  },
  reducers: {
    setCreativeErrors: (
      state,
      { payload: creativeErrors }: PayloadAction<String[] | null>
    ) => {
      state.creativeErrors = creativeErrors;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(importCustomCreatives.fulfilled, (state, action) => {
      let importedCreatives = action.payload;
      state.importCreativesResult = `Successfully imported ${importedCreatives.length} rows.`;
    });

    builder.addCase(importCustomCreatives.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.creativeErrors = action.payload!;
      }
    });

    builder.addCase(getCustomCreativeChannels.fulfilled, (state, action) => {
      state.customChannels = action.payload;
    });
  },
});

export const { setCreativeErrors } = customCreativeSlice.actions;

export const creativeErrors = (state: RootState): String[] | null =>
  state.customCreative.creativeErrors

export const importCreativesResult = (state: RootState): String | null =>
  state.customCreative.importCreativesResult

export const selectCustomCreativeChannels = (state: RootState): CustomCreativeChannel[] => state.customCreative.customChannels

export default customCreativeSlice.reducer;
