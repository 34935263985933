import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Alert } from "../../models/Alert";
import services from "../../services";
import {AlertRowSchema} from "../../services/alertService"
import { RootState } from "../index";


export const alertSlice = createSlice({
  name: "alerts",
  initialState: {
    alertErrors: [] as String[] | null,
    importAlertsResult: null as String | null,
  },
  reducers: {
    setAlertErrors: (
      state,
      { payload: alertErrors }: PayloadAction<String[] | null>
    ) => {
      state.alertErrors = alertErrors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importAlerts.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.alertErrors = action.payload!;
      }
    });
    builder.addCase(importAlerts.fulfilled, (state, action) => {
      let importedAlerts = action.payload;
      state.importAlertsResult = `Successfully imported ${importedAlerts.length} new alerts.`;
    });
  },
});

export const importAlerts = createAsyncThunk<
Alert[], 
{
  alertSchemaArray: AlertRowSchema[]
},
{
  rejectValue: String[] //return a list of errors on failure
}
>(
"alerts/import",
async (alertSchemaArray, { rejectWithValue }) => {
  try {
    let result = await services.alertService.importAlerts(alertSchemaArray.alertSchemaArray);
    return result;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    let formattedErrors = [] as String[];
    let errorData = error.response.data;

    //This is the validation error returned by the Platform API using the tsoa library.
    if (errorData.message == "Validation Failed") {
      //This returns a "details" object which contains both the row number and attribute, as well as 
      //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
      let details = errorData.details;
      Object.keys(details).forEach(key => {
        //The key contains both the row# and the failed attribute
        //The attribute "message" contains the error message.
        //We attempt to format these in a single string.
        let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
        let colName = key.substring(key.indexOf(".") + 4);
        let rowNumberInt = parseInt(rowNumber) + 1;

        let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
        formattedErrors.push(errorMessage);
      })
    } else if (errorData.message == "Entity Not Found") {
      formattedErrors.push(errorData.details);
    } else if (errorData.message == "Could Not Process Request") {
      formattedErrors.push(errorData.details);
    }

    return rejectWithValue(formattedErrors);

  }

}
); 


export const alertErrors = (state: RootState): String[] | null =>
  state.alerts.alertErrors;

export default alertSlice.reducer;

