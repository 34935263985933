import "./styles.css";
//import FileUploader from "../fileuploader/FileUploader";
import ReportUploader from "../fileuploader/ReportUploader";
import KeywordPerformanceUploader from "../fileuploader/KeywordPerformanceUploader";
import CreativePerformanceUploader from "../fileuploader/CreativePerformanceUploader";
import GoogleAdsCampaignUploader from "../fileuploader/GoogleAdsCampaignUploader";
import { ReactComponent as IconCancel } from "../../../assets/icons/cancel.svg";
import React from "react";

interface OtherReportsPageState{
  errors: string[] | null,
  errorMessage: string | null,
}

class OtherReportsPage extends React.Component<{},OtherReportsPageState>{
  
  constructor(props: any){
    super(props);
    this.state = {
        errors: null,
        errorMessage: null,
    }
}

  notifyErrors = (errors: string[], message: string) => {
    console.log('notifying');
    this.setState({
      errors: errors,
      errorMessage: message
    });
  }

  closeErrorWindow = () => {
    this.setState({
      errors: null,
      errorMessage: null,
    })
  }

  render(){
    
    let errorBlock = <div></div>;
    if( this.state.errorMessage !== null ){
      
      let errorList = this.state.errors?.map((error, i) => {
        return <p dangerouslySetInnerHTML={{ __html: error }}></p>
      });

      errorBlock = 
        <div className="error-block">
          <span className="close-window">
            <IconCancel onClick={this.closeErrorWindow} title="Close window" scale={2} width={50} height={50} />
          </span>
          <h1>Import Exceptions</h1>
          <div className="error-details">
            <p dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></p>
          </div>
          <div className="error-list">
            { errorList }
          </div>
          <div className="confirm-button">
            <div onClick={this.closeErrorWindow} className="primary-button pointer-hover">
              <div className="primary-button-text">OK</div>
            </div>
          </div>
          
        </div>
    }
    return (
      <>
        <div className="admin-page">
          <ReportUploader notifyErrors={this.notifyErrors} title="Campaign performance"></ReportUploader>
          {/*<GoogleAdsCampaignUploader notifyErrors={this.notifyErrors} title="New campaigns"></GoogleAdsCampaignUploader>*/}
          <CreativePerformanceUploader notifyErrors={this.notifyErrors} title="Creative performance"></CreativePerformanceUploader>
          <KeywordPerformanceUploader notifyErrors={this.notifyErrors} title="Keyword performance"></KeywordPerformanceUploader>
        </div>

        { errorBlock }
        
      </> 
    );
  }
}

export default OtherReportsPage;
