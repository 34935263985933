import { Button } from "antd";
import { appColors } from "../../assets/variables/colors";
import { EventCalendar } from "./EventCalendar";
import "./EventCalendar.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CreateEventModal } from "./modals/CreateEventModal/CreateEventModal";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { UserRole } from "../../models/User";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import SidePanelCreateEvent from "./modals/SidePanelCreateEvent/SidePanelCreateEvent";
import { setSelectedMainMenu, setSelectedSubMenu } from "../../store/Navigation/NavigationStore";

export const EventCalendarPage = (): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  
  const user = useSelector(selectUserAccount);

  const whiteLabel = useSelector( selectWhiteLabel );

  const openCreateEventModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch( setSelectedMainMenu("event") );
    dispatch(setSelectedSubMenu("Planner"));
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="event-calendar-page-container res:!p-3 mb-[180px]">
      <div style={{ height: "36px", marginBottom: "50px" }} className=" res:flex res:flex-wrap res:items-center">
        <InfoCircleOutlined style={{ color: appColors.PRIMARY_BLUE }} />
        <Button
          style={{
            marginLeft: "30px",
            backgroundColor: whiteLabel.primaryColor,
            borderRadius: "4px",
            borderWidth: "0px",
            color: "#ffffff",
          }}
          className=" res:!ml-3 res:!px-2 "
          onClick={openCreateEventModal}
        >
          NEW EVENT
        </Button>
      </div>
      <div className="event-calendar mobile-calendar ">
        <EventCalendar />
      </div>
      <SidePanelCreateEvent visible={isModalVisible} setVisible={setIsModalVisible} onCancel={handleCancel} isEdit={false} />
    </div>
  );
};
