import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Confirmation.css";
import { AvailableRoutes } from "../../../pages/Routes";
import { ReactComponent as IconReviewAndPayStep } from "../../../assets/icons/review-and-pay-step.svg";
import HeaderLayout from "../../Layout/HeaderLayout";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { assetImage } from "../../../utility/whiteLabelManager";
import { getLogoForSubdomain } from "../../../utility/subdomain";
import services from "../../../services/index";
import { selectUserAccount } from "../../../store/Account/AccountStore"
import { selectFranchiseLocations} from "../../../store/Location/LocationStore";
import { selectFranchise } from "../../../store/Franchise/FranchiseStore";

const ConfirmationLayout = ({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const account = useSelector(selectUserAccount);
  const locations = useSelector(selectFranchiseLocations);
  const franchise = useSelector(selectFranchise);

  const renderHeader = () => {
    switch (location.pathname) {
      case AvailableRoutes.CONFIRMATION:
      default:
        return (
          <div className="stepper">
            <div className="step-title res:text-left res:text-2xl">Your media order has been placed.</div>
          </div>
        );
    }
  };

  const whiteLabel = useSelector(selectWhiteLabel);

    useEffect(() => {
        services.dataLayerService.registerPageView();
    });

  return (
    <div style={{ height: "100%" }}>
      <HeaderLayout>
        <img
          className="header-logo"
          onClick={() => navigate(AvailableRoutes.ALERT)}
          src={ assetImage( whiteLabel.secondaryLogoPath ) }
          alt=""
        />
      </HeaderLayout>

      <div className="content-body-content" style={{ height: "100vh" }}>
        {renderHeader()}
        {children}
      </div>
    </div>
  );
};

export default React.memo(ConfirmationLayout);
