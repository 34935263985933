import {
  LOADING,
  ERROR,
  LOGIN,
  REGISTER,
  RESEND_CODE_VERIFICATION,
  // ACCOUNT_VERIFICATION,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  SIGN_OUT,
  SET_RESET_PASSWORD_SUCCESS,
  SET_REGISTERED_SUCCESS,
  CONFIRM_SIGN_UP,
} from "./types";

const INITIAL_STATE = {
  user: null,
  credentials: {
    email: null,
    password: null,
  },
  resetPasswordSuccess: false,
  isRegisteredSuccess: false,
  loading: false,
  error: null,
  isConfirmedSignUp: false,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN:
      window.localStorage.setItem(
        "idToken",
        action.payload.signInUserSession.idToken.jwtToken
      );
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SIGN_OUT:
      return {
        ...state,
        user: null,
        loading: false,
        error: null,
      };
    case REGISTER:
      return {
        ...state,
        user: action.payload.user,
        isRegisteredSuccess: true,
        loading: false,
        error: null,
      };
    case SET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
      };
    case SET_REGISTERED_SUCCESS:
      return {
        ...state,
        isRegisteredSuccess: false,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RESEND_CODE_VERIFICATION:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CONFIRM_SIGN_UP:
      return {
        ...state,
        isConfirmedSignUp: true,
        loading: false,
        error: null,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
