/**
 * Set url param value
 * @param params
 */
export  function updateURLParams(params: { [key: string]: string }) {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Update the search params
    Object.keys(params).forEach(key => {
        currentUrl.searchParams.set(key, params[key]);
    });

    // Use history.pushState() to update the URL without reloading the page
    window.history.pushState({}, '', currentUrl.toString());
}

/**
 * Retrieve param value
 * @param paramName
 */
export function getParamValue(paramName: string)
{
    const currentUrl = new URL(window.location.href);
    return currentUrl.searchParams.get(paramName);
}