import React, {createContext, useState} from "react";
import { Row, Col, Button, Tooltip } from "antd";
import styles from "./Demographics.module.css";
import SideModal from "../shared/SideModal";
import AssignDataSources from "./AssignDataSources";
import AssingAudiences from "./AssignAudiences";
import { useDispatch, useSelector } from "react-redux";
import { selectDemographicsFranchises, selectModalContent, setModalContent } from "../../../store/Demographics/DemographicsStore";
import { AdminManagerFranchise, AdminManagerUserAccount } from "../../../models/AdminManager";
import { DemographicsFranchise } from "../../../models/Demographics";
import sharedStyles from "../shared/styles.module.css";
import { ManageAudiences } from "./ManageAudiences";
import { NewAudienceFilter } from "./NewAudienceFilter";

export const SourceContext = createContext({
  selectedSource: '',
  setSelectedSource: function(value:string) {

  }});

export const Demographics = () => {
  const [selectedSource, setSelectedSource] = useState<string>('');
  const franchises: DemographicsFranchise[] = useSelector(selectDemographicsFranchises);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItemf, setSelectedItemf] = useState<AdminManagerFranchise>();
  const modalContent: string = useSelector(selectModalContent);
  const dispatch = useDispatch();

  const handleModalContent = (content: string) => {
    dispatch(setModalContent(content));
    setIsOpen(!isOpen);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalContent("");
  }
  
  return <SourceContext.Provider value={{selectedSource, setSelectedSource}}>
    <div>
      <div className={styles["container"]}>
        <h1>Data sources</h1>
        <h3>Assign a demographic data source to your franchises</h3>

        <Col span={5}>
          <div className="primary-button pointer-hover" onClick={() => handleModalContent("Data sources")}>
              <div className="primary-button-text">ASSIGN</div>
          </div>
        </Col>
      </div>

{/*      <div className={styles["container"]}>
        <h1>Manage audiences</h1>
        <h3>Create new custom audiences or edit existing ones</h3>

        <Col span={5}>
          <div className="primary-button pointer-hover" onClick={() => handleModalContent("Manage audiences")}>
              <div className="primary-button-text">MANAGE</div>
          </div>
        </Col>
      </div>*/}

      <div className={styles["container"]}>
        <h1>Publish audiences</h1>
        <h3>Make custom audiences available to franchises</h3>

        <Col span={5}>
          <div className="primary-button pointer-hover" onClick={() => handleModalContent("Publish audiences")}>
              <div className="primary-button-text">PUBLISH</div>
          </div>
        </Col>
      </div>

      <SideModal title={modalContent}
        isOpen={isOpen} onClose={closeModal} >
        <div>
          {/*{modalContent === 'Data sources' ? <AssignDataSources franchises={franchises}></AssignDataSources> : null}*/}
          {modalContent === 'Data sources' ? <AssignDataSources></AssignDataSources> : null}
          {modalContent === 'Manage audiences' ? <ManageAudiences></ManageAudiences> : null}
          {/*{modalContent === 'Publish audiences' ? <AssingAudiences franchises={franchises}></AssingAudiences> : null}*/}
          {modalContent === 'Publish audiences' ? <AssingAudiences></AssingAudiences> : null}
          {modalContent === 'New audience' ? <NewAudienceFilter></NewAudienceFilter> : null}
        </div>
      </SideModal>
    </div>
  </SourceContext.Provider>
};

export default Demographics