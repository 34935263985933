import React, {useEffect, useState} from "react";
import {Row, Col, Button, Drawer, Input} from "antd";
import services from "../../../services"
import styles from "../../purchase/channels/channelTemplateDrawer/channelTemplateDrawer.module.css";
import { ReactComponent as IconArrowRightBlack } from "../../../assets/icons/arrow-right-black.svg";
import {setOpen, selectIsOpenState} from "../../../store/PlatformSupport/Upload";
import {useDispatch, useSelector} from "react-redux";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";


interface IFileUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
    description: string
}

const allowedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
];
const defaultState = {
    selectedFile: null,
    fileTypeError: null,
    description: '',
};

export const FileUpload  = () => {
    const dispatch = useDispatch();
    const whiteLabel = useSelector( selectWhiteLabel );
    const isOpen = useSelector(selectIsOpenState);
    const [open, setModalOpen] = useState(false);
    const [state, setState] = useState<IFileUploaderState>(defaultState);

    /**
     * Update modal state
     */
    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    /**
     * Upload meida file
     */
    const onFileUpload = () => {
        if (state.selectedFile && state.description.length > 0) {
            services.fileItemService.upload(state.selectedFile, state.description).then(() => {
                alert(`File ${state.selectedFile?.name} uploaded successfully.`);
                setState(defaultState);
            });
        }
    }

    /**
     * On selected file changed event handler
     *
     * @param event
     */
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if( !event.target.files ) {
            return;
        }

        let fileType = event.target.files[0].type;
        console.log(fileType);
        if (allowedFileTypes.indexOf(fileType) < 0) {

            setState({
                selectedFile: state.selectedFile,
                fileTypeError: 'The selected file is not valid. Only PNG and JPEG files are supported.',
                description: '',
            });
            return;
        }

        setState({
            selectedFile: event.target.files[0],
            fileTypeError: null,
            description: '',
        });
    }

    /**
     * Process description change
     *
     * @param event
     */
    const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if( !event.target.value ) {
            return;
        }

        setState({
            selectedFile: state.selectedFile,
            fileTypeError: null,
            description: event.target.value,
        });
    }

    /**
     * Clear selected file
     */
    const handleFileRemove = () => {
        setState({
            selectedFile: null,
            fileTypeError: null,
            description: ''
        });
    }

    /**
     * Process modal popup close
     */
    const handleModalClose = () => {
        setModalOpen(false);
        dispatch(setOpen(false));
    };

    return (
        <Drawer
            visible={open}
            width="min(100vw, 700px)"
            closable={false}
        >
            <div className={`${styles["templates-drawer-top-bar"]}  `}>
                <IconArrowRightBlack onClick={handleModalClose}/>
            </div>
            <div className={`${styles["templates-header"]} mb-5 `}>
                Upload a file
            </div>
            <div className="file-upload-area">
                <Row className="justify-center">
                    <Col span={7} className="res:flex-[0_0_50%] res:max-w-[50%]">
                        {
                            state.selectedFile ?
                                <div>
                                    <div onClick={onFileUpload}
                                         className="primary-button pointer-hover w-full text-center"
                                    >
                                        <div className="primary-button-text ">UPLOAD</div>
                                    </div>
                                    <div className="mt-4">
                                        {state.selectedFile.name}
                                        <Button className=" border-0  [&>*]:underline decoration-blue-400 text-blue-400 m-0 p-0" onClick={handleFileRemove}>REMOVE</Button>
                                    </div>
                                    <div className="mt-5">
                                        <label><span style={{color: whiteLabel.primaryColor}}>*</span> Description</label>
                                        <Input placeholder="File Short Description" onChange={onDescriptionChange} />
                                    </div>
                                </div>
                                :
                                <div>
                                    <input id={"file-uploader-location"} type="file" onChange={onFileChange} />
                                    <label htmlFor={"file-uploader-location"}>
                                        <div
                                            className="primary-button  w-[auto] pointer-hover"
                                        >
                                            <div className="primary-button-text w-full text-center">SELECT FILE</div>
                                        </div>
                                    </label>
                                </div>
                        }
                    </Col>
                </Row>
            </div>
        </Drawer>
    );
}

export default FileUpload;

