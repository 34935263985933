import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../../assets/icons/orange-back-arrow.svg";
import { useAuth } from "../../../hooks/useAuth";
import AlertMessage from "../../alertMessage/AlertMessage";

const errorMessageMapping: ErrorMessage = {
  invalidCredential: "Incorrect password",
};

const ChangePassword: React.FC = () => {
  const { user, changePassword, setError, loading, error } = useAuth();
  const [isChangedPassword, setIsChangedPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
  const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);
  
  const onFinish = (values: any) => {
    if (
      !values.currentPassword ||
      !values.newPassword ||
      !values.confirmNewPassword
    ) {
      setError({ message: "Fields are missing" });
    } else {
      changePassword({
        user,
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      });

      setSubmitted(true);
      setOpenSuccessMsg(true);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setOpenErrorMsg(true);
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!error && submitted && !loading) {
      setIsChangedPassword(true);
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  return (
    <div>
      <h1>Change Password</h1>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="container-form"
        layout="vertical"
        style={{ width: "80%" }}
      >
        <Form.Item label="Current Password" name="currentPassword">
          <Input.Password
            placeholder="Current password"
            className={error && "error-input"}
          />
        </Form.Item>

        <Form.Item label="New Password" name="newPassword">
          <Input.Password
            placeholder="New Password"
            className={error && "error-input"}
          />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmNewPassword"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm New Password"
            className={error && "error-input"}
          />
        </Form.Item>

        {error && (
          <div className="error-message">
            {error.message === "Incorrect username or password."
              ? errorMessageMapping.invalidCredential
              : error.message}
          </div>
        )}

        {isChangedPassword && (
          <div className="success-message">
            Thank you for registering, please check your email
          </div>
        )}
        <Input.Group compact>
          <Form.Item style={{ marginTop: 50 }}>
            <Button
              className="inverted-general-button pointer-hover"
              onClick={() => navigate("/account-information")}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '25px' }}
              loading={loading}
            >
              <IconArrowBack />
              Back
            </Button>
          </Form.Item>
          <Form.Item style={{ marginTop: 50 }}>
            <Button
              htmlType="submit"
              className="primary general-button pointer-hover"
              loading={loading}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              Save
              <IconArrowRight />
            </Button>
          </Form.Item>
        </Input.Group>
        <AlertMessage successMessage="Your password has been changed successfully!" openScsMsg={openSuccessMsg} onScsClose={() => setOpenSuccessMsg(false)} openErrMsg={openErrorMsg} onErrClose={() => setOpenErrorMsg(false)}/>
      </Form>
    </div>
  );
};
interface ErrorMessage {
  invalidCredential: string;
}

export default ChangePassword;
