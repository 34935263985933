import { Chart } from "react-google-charts";
import { FranchiseOmniChannelDate, FranchiseOmniChannelLocation } from "services/franchiseService";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "store/WhiteLabel/WhiteLabelStore";

interface GraphProps{
    dates: FranchiseOmniChannelDate[],
    locationReports: FranchiseOmniChannelLocation[],
    selectedMetrics: string[],
    selectedChannel: String;
}


export const GoogleGraphChart = (props: GraphProps) =>{

    const whiteLabel = useSelector(selectWhiteLabel);

    // Function to transform FranchiseOmniChannelLocation data to Google Charts data format
    function transformData(locations: FranchiseOmniChannelLocation[], metrics: string[] ): any[] {

        // Initialize an empty array to store all dates
        const allDates: Date[] = [];

        locations.forEach(location => {
            location.dates.forEach(dateObj => {
                // Convert date string to JavaScript Date object and push it to allDates array
                const [year, month, day] = dateObj.date.split('-');
                // Create a new JavaScript Date object with components
                allDates.push(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));
            });
        });

        // Sort the dates array
        allDates.sort((a, b) => a.getTime() - b.getTime());

        // Construct the header row for Google Charts data
        const headerRow = ["Date"];

        if (metrics.length > 1) {
            metrics.forEach(metric => {
                headerRow.push(metric);
            })
        }else{
            locations.forEach(location => {
                headerRow.push(location.name); // Add location names to header row
            });    
        }

        // Initialize the data array with the header row
        const data = [headerRow];

        // Iterate over each unique date
        allDates.forEach(date => {
            const formattedDate = date.toISOString().split('T')[0]; 

            const rowData = [date] as any[];
            locations.forEach(location => {
                const dateData = location.dates.find(dateObj => dateObj.date === formattedDate);
                if (dateData) {
                    metrics.forEach(metric => {
                        //Insert the proper metric values
                        switch( metric ){
                            case "impressions":
                                rowData.push( dateData.impressions );
                                break;
                            case "clicks":
                                rowData.push( dateData.clicks )
                                break;
                            case "ctr":
                                var dataVar: any;
                                if ( dateData.ctr == 0 && props.selectedChannel != "google" && props.selectedChannel != "facebook" ){
                                    dataVar = Number( ((dateData.clicks/dateData.impressions)*100).toFixed(2))
                                }else { 
                                    dataVar = Number(((isNaN(dateData.ctr) ? 0 : dateData.ctr) * 100).toFixed(2)) 
                                }
                                rowData.push(dataVar)
                                break;
                            case "spend":                                
                                rowData.push(Number(((isNaN(dateData.spend) ? 0 : dateData.spend) / 1000000).toFixed(2)))
                                break;
                            case "cpc":
                                var dataVar: any;
                                if ( dateData.cpc == 0 && props.selectedChannel != "google" && props.selectedChannel != "facebook" ){
                                    dataVar = Number( ((dateData.spend/dateData.clicks)/1000000).toFixed(2) )
                                }else { 
                                    dataVar = Number(((isNaN(dateData.cpc) ? 0 : dateData.cpc) / 1000000).toFixed(2)) 
                                }
                                rowData.push(dataVar)
                                // rowData.push( Number( ((isNaN(dateData.cpc) ? 0 : dateData.cpc) / 1000000).toFixed(2) ))
                                break;
                            case "conversions":
                                rowData.push( Number(((isNaN(dateData.pmConversions) ? 0 : dateData.pmConversions)).toFixed(2)) )
                                break;

                            case "cpa":
                            case "cpl":
                                let cplData = Number((dateData.cpl / 1000000).toFixed(2));
                                rowData.push( isNaN(cplData) ? 0 : cplData );
                                break;
                        }
                    });
                    
                } else {
                    // If data for the current date is not available for this location, push null values
                    rowData.push(0);
                }
            });
            data.push(rowData);
        });

        return data;
    }

    //Build the columns
    const columns = [
        {
          type: "date",
          label: "Month"
        },
        ...props.selectedMetrics.map(metric => ({
          type: "number",
          label: metric
        }))
    ];

    const chartData = transformData(props.locationReports, props.selectedMetrics);

    // Define a color palette with distinct, visually distinguishable colors
    const colorPalette = [
      "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b",
      "#e377c2", "#7f7f7f", "#bcbd22", "#17becf", "#393b79", "#637939",
    ];
    
    const seriesColors = props.locationReports.map((_, index) => ({
      color: colorPalette[index % colorPalette.length],
    }));

    return (
        props.locationReports.length > 0 ? (
            <Chart
                chartType="LineChart"
                data={chartData}
                width="100%"
                height="400px"
                options={
                    {
                        hAxis: {
                            format: 'MMM dd'
                        },
                        series: seriesColors,
                        vAxes: [
                            ...props.selectedMetrics.map((metric,index) => ({
                                title: props.selectedMetrics[index],
                                minValue: 0
                            }))
                        ]
                    }
                }   
            >
            </Chart>
        ):
            <div>
                <p>No data available, check your API connections or make sure CSV data has been imported.</p>
            </div>
        
    )
}

export default GoogleGraphChart;
