import { SpendPacingReport } from "../models/SpendPacingReport";
import RestService from "./restService";


export default class SpendPacingReportService {
  constructor(protected readonly api: RestService) {}

  async getSpendPacingReport(monthNumber: number, year: number, locationId: string, userId?: string): Promise<SpendPacingReport> {
    const query: string[] = [];
    if (monthNumber != undefined) {
      query.push(`monthNumber=${monthNumber}`);
    }
    if (year) {
      query.push(`year=${year}`);
    }
    if (locationId) {
      query.push(`locationId=${locationId}`);
    }

    if (userId) {
      query.push(`userId=${userId}`);
    }

    return await this.api.get<SpendPacingReport>(`/spend-pacing-report${query.length ? '?' + query.join('&') : ''}`);
  }
}
