import { useEffect } from "react";
import React from "react";
import "./App.css";
import MainApp from "./pages/Account";
import AuthPage from "./pages/AuthPage";
import { useAuth } from "./hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, setUser } from "./store/Account/AccountStore";
import { getFranchise, setFranchise, getCredentialValidation, getAllFranchises } from "./store/Franchise/FranchiseStore";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { fetchWhiteLabel, selectWhiteLabel, setWhiteLabel, selectIsWhiteLabelLoaded } from "./store/WhiteLabel/WhiteLabelStore";
import { getCurrentDomain, getGlobalStyles, assetImage } from "./utility/whiteLabelManager";

import QRLanding from "./components/auth/QRLanding";
import QRCampaign from "components/auth/QRCampaign";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "./assets/icons/loader-main.svg";
import QRScan from "components/auth/QRScan";

const App = () => {
  const { currentUser, user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    currentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchUser());
      dispatch(getFranchise());
      dispatch(getCredentialValidation());
      dispatch(getAllFranchises());
    } else {
      dispatch(setUser(null));
      dispatch(setFranchise(null));
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  

  useEffect(() => {
    const initWhiteLabel = async() => {
      const domainName = await getCurrentDomain();
      //Fetch the WhiteLabel for the current domain.
      dispatch( fetchWhiteLabel( domainName ) );
    }
    initWhiteLabel();
  }, []);

  const whiteLabel = useSelector( selectWhiteLabel );
  const isWhiteLabelLoaded = useSelector( selectIsWhiteLabelLoaded );
  const GlobalStyles = getGlobalStyles( whiteLabel );

  useEffect(() => {
    document.title = whiteLabel.metaTitle;
    const favicon = document.getElementById("favicon");
    if( favicon !== null && whiteLabel.faviconPath != "" ){
      favicon.setAttribute('href', assetImage(whiteLabel.faviconPath) );
    }
  }, [whiteLabel]);

  return (
    <>
      <GlobalStyles />
      <Backdrop
        sx={{ backgroundColor: 'black', opacity: 1, color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        transitionDuration={{appear: 0, enter: 0, exit: 1000}}
        open={!isWhiteLabelLoaded}
      >
      </Backdrop>
      {
        <Router>
          <Routes>
            <Route path="/qr-landing" element={<QRCampaign />}></Route>
            <Route path="/scan-promotional-code" element={<QRScan />}></Route>
            <Route path="/qr-landing/:qrPass" element={<QRCampaign />}></Route>
            <Route path="*" element={user ? <MainApp /> : <AuthPage />}></Route>
          </Routes>
        </Router>
      }
    </>
  );
};

/** 
return (
    <>
      <GlobalStyles />
      {
        <Router>
          <Routes>
            <Route path="/qr-landing" element={<QRLanding />}></Route>
            <Route path="/qr-landing/:qrPass" element={<QRLanding />}></Route>
            <Route path="*" element={user ? <MainApp /> : <AuthPage />}></Route>
          </Routes>
        </Router>
      }
    </>
  );

*/

export default App;
