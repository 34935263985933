import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import services from "../../services";

export const imageUploaderAction = createAsyncThunk(
  "upload/image",
  async (file: File) => {
    //return await services.uploadService.imageUploaderAction(file);
    return null;
  }
)

const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    image: null as string | null,
    uploadStatus: 'idle' as 'idle' | 'loading' | 'succeeded' | 'failed',
    error: null as string | null | undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(imageUploaderAction.pending, (state) => {
      state.uploadStatus = 'loading';
    });
    builder.addCase(imageUploaderAction.fulfilled, (state, action) => {
      state.uploadStatus = 'succeeded';
      state.image = action.payload;
    });
    builder.addCase(imageUploaderAction.rejected, (state, action) => {
      state.uploadStatus = 'failed';
      state.error = action.error.message;
    });
  },
});

export const selectUploadStatus = (state: any) =>
  state.upload.uploadStatus;

export const selectImage = (state: any) =>
  state.upload.image;

export const selectUploadError = (state: any) =>
  state.upload.error;

export default uploadSlice.reducer;