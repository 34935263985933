export const AdminAvailableRoutes = {

  // DASHBOARD ROUTES
  ONBOARDING: "/plat-admin/onboarding",
  VERSIONS: "/plat-admin/experiences",
  CREATE_VERSION: "/plat-admin/experiences/create",
  EDIT_VERSION: "/plat-admin/experiences/edit",
  DELETE_VERSION: "/plat-admin/experiences/delete",  
  DIRECTORY: "/plat-admin/directory",
  MEDIA_MANAGER: "/plat-admin/media-manager",
  BULK_EMAILS: "/plat-admin/bulk-updates",
  DEMOGRAPHICS: "/plat-admin/demographics",
  PERFORMANCE_IMPORT: "/plat-admin/performance-import",
  QR_MANAGER: "/plat-admin/qr-manager",
  CREATE_QR_CAMPAIGN: "/plat-admin/qr-campaign/create",
  EDIT_QR_CAMPAIGN: "/plat-admin/qr-campaign/edit",
  DELETE_QR_CAMPAIGN: "/plat-admin/qr-campaign/delete",
  DETAILS_QR_CAMPAIGN: "/plat-admin/qr-campaign/details",
};
  
