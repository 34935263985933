import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "../index";

const initialState = {
    isOpen: false,
};

export const DownloadSlice = createSlice({
    name: "fileDownload",
    initialState,
    reducers: {
        setOpen: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.isOpen = currentState;
        }
    }
});

export const selectIsOpenState = (state: RootState): boolean => state.fileDownload.isOpen;

export const {
    setOpen
} = DownloadSlice.actions;

export default DownloadSlice.reducer;