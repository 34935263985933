import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import services from "../../services";
import { MonthlyInvoices } from "../../models/MonthlyInvoices";


export const fetchMonthlyInvoicesList = createAsyncThunk(
  "monthly-invoices/fetch",
  async () => {
    console.log('fetching invoices...');
    return services.monthlyInvoicesListService.getMonthlyInvoicesList();
  }
);



export const MonthlyInvoicesListSlice = createSlice({
  name: "monthlyInvoicesList",
  initialState: {
    list: [] as MonthlyInvoices[],
    loading: true,
  },
  reducers: {
    setMonthlyInvoicesList: (
      state,
      { payload: monthlyInvoicesList }: PayloadAction<MonthlyInvoices[]>
    ) => {
      state.list = monthlyInvoicesList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonthlyInvoicesList.fulfilled, (state, action) => {
      state.list = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchMonthlyInvoicesList.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const selectMonthlyInvoicesList = (state: RootState): MonthlyInvoices[] =>
  state.monthlyInvoicesList.list;

export const selectLoading = (state: RootState) : boolean =>
  state.monthlyInvoicesList.loading;

export const { setMonthlyInvoicesList } = MonthlyInvoicesListSlice.actions;
  
export default MonthlyInvoicesListSlice.reducer;
