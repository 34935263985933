import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { CustomPackage } from "../../../models/CustomPackage";
import {
  selectTotalAudience,
  setSelectedCustomPackage,
  setSelectedTemplates,
  setSetSpend
} from "../../../store/campaignForm/CampaignForm";
import { getMoneyStringFromNumber } from "../../../utility/string";
import "./styles.scss";

interface PackageSidebarCardProps {
  customPackage: CustomPackage;
}

const PackageSidebarCard = ({ customPackage }: PackageSidebarCardProps) => {
  const targetZoneAudience: number = useSelector(selectTotalAudience);
  const dispatch = useDispatch();

  const removeSelectedChannel = () => {
    dispatch(setSelectedCustomPackage(null));
    dispatch(setSetSpend(null));

    dispatch(setSelectedTemplates([]));
  };

  const estimatedSpend = customPackage.setSpend ? 
    customPackage.setSpend : 
    customPackage.costPerTarget * targetZoneAudience;
    
  return (
    <div className="sidebar-card">
      <div>
        <b>{customPackage.title}:</b> $
        {getMoneyStringFromNumber(estimatedSpend)}
      </div>
      <TrashIcon style={{ cursor: "pointer" }} onClick={removeSelectedChannel} />
    </div>
  );
};

export default React.memo(PackageSidebarCard);
