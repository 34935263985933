import React, { useEffect, useState } from "react"; 
import { Form, Input, Button } from "antd";
import { QRLandingCode } from "../../models/QRCode"; 
import { useDispatch, useSelector } from "react-redux";
import { selectQRLandingCodeBatch, createLandingCodeBatch } from "../../store/QRLandingCodes/QRLandingCodesStore";
import { generateLandingQRCode } from "../../utility/qrcodes";

const QRGenerator: React.FC = () => {
  const dispatch = useDispatch(); 
  const qrLandingCodeBatch: QRLandingCode[] = useSelector(selectQRLandingCodeBatch);
  const [testQRs, setTestQR] = useState<string[]>([]);
  const [areLoadingQRCodes, setAreLoadingQRCodes] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setAreLoadingQRCodes(true)
    const askQR = async () => {
      const { size  } = values; 
      dispatch(createLandingCodeBatch({size}));
    };

    askQR();
  };
  useEffect(()=>{
    const updateImgsInDOM = async ()=>{
      const newLandingCodes : string[] = []    
      for(let qrLandingCode of qrLandingCodeBatch){
        const stringQRLandingCode = await generateLandingQRCode(qrLandingCode.id)
        newLandingCodes.push(stringQRLandingCode)
      } 
      setTestQR(newLandingCodes);
      setAreLoadingQRCodes(false)
    }
    updateImgsInDOM();
  },[qrLandingCodeBatch])

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <div>
      <h1>QR batch generator</h1>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="container-form"
        layout="vertical">
        <Form.Item
          label="Size"
          name="size"
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Input type="number" />
        </Form.Item>  
        <Form.Item style={{ marginTop: 0 }}>
          <Button
          
          loading={areLoadingQRCodes}
            htmlType="submit"
            className="general-button pointer-hover"
            style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Generate new URL batch
          </Button>
        </Form.Item>
      </Form>
      {
        testQRs.map((qrURL, index) => {
          return <img key={index} src={qrURL} alt="" style={{ marginRight: 10 }} />
        })
      }
    </div >
  );
};

export default QRGenerator;