/** 
 * This store manages the data related to the WhiteLabel module.
 * The initial state contains the default styling using for the application,
 * the default logo and email config.
 * 
 * This store provides the methods to fetch the configuration for the logged account.
*/

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WhiteLabel } from "../../models/WhiteLabel";
import services from "../../services";
import { RootState } from "../index";
import { WhiteLabelTable, INewWhiteLabel, IUpdateWhiteLabel } from "../../services/whiteLabelService";



export const fetchWhiteLabel = createAsyncThunk("white-labels/fetch", async ( domainName: string ) => {
  return services.whiteLabelService.getWhiteLabel(domainName);
});

export const fetchWhiteLabelTable = createAsyncThunk(
  "white-labels/index",
  async () => {
    return await services.whiteLabelService.getWhiteLabelTable();
  }
)

export const createWhiteLabel = createAsyncThunk(
  "white-labels/create",
  async (createWhiteLabel: INewWhiteLabel) => {
    return services.whiteLabelService.createWhiteLabel(createWhiteLabel);
  }
);

export const sendRegistrationWhiteLabelMail = createAsyncThunk("white-labels/send-registration-mail", async ({email, mailFrom, sendgridId} : {email: string, mailFrom: string, sendgridId: string}) => {
  return await services.whiteLabelService.sendRegistrationWhiteLabelMail(email, mailFrom, sendgridId);
});


export const updateWhiteLabel = createAsyncThunk(
  "white-labels/update",
  async (updateWhiteLabel: IUpdateWhiteLabel) => {
    return services.whiteLabelService.updateWhiteLabel(updateWhiteLabel);
  }
);

export const deleteWhiteLabel = createAsyncThunk(
  "white-labels/delete",
  async(updateWhiteLabel: IUpdateWhiteLabel) => {
    return services.whiteLabelService.deleteWhiteLabel( updateWhiteLabel );
  }
);


export const whiteLabelSlice = createSlice({
  name: "whitelabel",
  initialState: {
    whiteLabel: {
      domain: "jbeccaplatforms.com",
      primaryColor: "#3f3f46",
      secondaryColor: "#ffdebf",
      activeColor: "#ffdebf",
      gradientColor: "linear-gradient(20deg,#ffdebf,#ffdebf 45%)",
      gradientOutlineColor: "linear-gradient(20deg,#3f3f46,#3f3f46 80%)",
      logoPath: "",
      faviconPath: "favicon.ico",
      secondaryLogoPath: "",
      metaTitle: "Portal",
      mailFrom: "no-reply@jbeccaplatforms.com",
      primaryContact: "no-reply@jbeccaplatforms.com",
      billingContact: "no-reply@jbeccaplatforms.com",
      supportMail: "support@jbeccaplatforms.com",
      supportText: "Register for the platform using your brand email address. If your Franchisor is not currently registered please conntact us to start the onboarding process.",
      purchaseTerms: "Could not load the terms of purchase.",
      orderSuccessText: "Thank you for your purchase. Please check your email for confirmation and proofs for your approval. If you have any questions please email us at <b>support@jbeccaplatforms.com</b>",
      accountRegistrationTemplate_id: "",
      agency: false,
    } as WhiteLabel,
    whiteLabelTable: {} as WhiteLabelTable,
    whiteLabelToEdit: null as WhiteLabel | null,
    updateWhiteLabelResult: null as String | null,
    isWhiteLabelLoaded: false,
  },
  reducers: {
    setWhiteLabel: (state, { payload: whiteLabel }: PayloadAction<WhiteLabel>) => {
      state.whiteLabel = whiteLabel;
    },
    setWhiteLabelToEdit: (state, {payload: whiteLabel}: PayloadAction<WhiteLabel>) => {
      state.whiteLabelToEdit = whiteLabel;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWhiteLabel.fulfilled, (state, action) => {
      state.whiteLabel = action.payload;
      state.isWhiteLabelLoaded = true;
    });

    builder.addCase(fetchWhiteLabel.rejected, (state, action) => {
      state.isWhiteLabelLoaded = true;
    });

    builder.addCase(fetchWhiteLabelTable.fulfilled, (state, action) => {
      state.whiteLabelTable = action.payload;
    });

    builder.addCase(createWhiteLabel.fulfilled, (state, action) => {
      let newWhiteLabel = action.payload;
      state.updateWhiteLabelResult = `Successfully created ${newWhiteLabel.domain}'s white label.`;
    });
  },
});

export const selectWhiteLabel = (state: RootState): WhiteLabel =>
  state.whitelabels.whiteLabel;

export const selectIsWhiteLabelLoaded = (state: RootState): boolean =>
  state.whitelabels.isWhiteLabelLoaded;

export const selectWhiteLabelTable = (state: RootState) : WhiteLabelTable => state.whitelabels.whiteLabelTable;

export const selectWhiteLabelToEdit = (state: RootState): WhiteLabel | null =>
  state.whitelabels.whiteLabelToEdit;

export const selectUpdateWhiteLabelResult = (state: RootState): String | null =>
  state.whitelabels.updateWhiteLabelResult;

export const updateWhiteLabelResult = (state: RootState): String | null =>
  state.whitelabels.updateWhiteLabelResult;

export const { setWhiteLabel, setWhiteLabelToEdit } = whiteLabelSlice.actions;

export default whiteLabelSlice.reducer;
