import { useSelector } from "react-redux";
import { ChannelType } from "../../../models/Channel";
import {
  selectSelectedLocation,
  selectAlwaysOn,
  selectAdjustedSpendPercent,
  selectCurrentEstimatedCost,
  selectNewCampaignChannels,
  selectSelectedChannels,
  selectTotalAudience,
  selectWeeklySpendEstimate,
  selectZipModeEnabled,
  selectPrimaryZips,
  selectSecondaryZips,
  selectIsBudgetModeFixed,
  selectMonthlyBudget,
  selectedMonth,
  selectNewBudget,
} from "../../../store/campaignForm/CampaignForm";
import "./ScheduleCampaign.scss";
import { getMoneyStringFromNumber } from "../../../utility/string";
import { NewCampaignChannel } from "../../../models/CampaignChannel";
import { IMonthlyBudgetDetail, getMonthIndexByCode } from "../../../models/MonthlyBudget";

const ScheduleSidebar = () => {
  const selectedLocation = useSelector(selectSelectedLocation);
  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const selectedNewCampaignChannels: NewCampaignChannel[] = useSelector(
    selectNewCampaignChannels
  );
  // TODO: Possibly display packages here?
  // const selectedPackage: CustomPackage | null = useSelector(
  //   selectSelectedCustomPackage
  // );
  const estimatedCost: number = useSelector(selectCurrentEstimatedCost);
  const weeklySpend: number = useSelector(selectWeeklySpendEstimate);
  const targetZoneAudience: number = useSelector(selectTotalAudience);
  const usesZipMode = useSelector(selectZipModeEnabled);
  const selectedPrimaryZips = useSelector(selectPrimaryZips);
  const selectedSecondaryZips = useSelector(selectSecondaryZips);

  const alwaysOn: boolean = useSelector(selectAlwaysOn);
  const adjustedSpendPercent = useSelector(selectAdjustedSpendPercent);

  const percentSpend = (estimatedCost * adjustedSpendPercent) / 100;
  const isFixedBudgetMode = useSelector(selectIsBudgetModeFixed);
  const monthCode = useSelector(selectedMonth);
  const monthlyBudget = useSelector(selectMonthlyBudget) as IMonthlyBudgetDetail[];
  const monthlyBudgetCost  = (monthlyBudget[getMonthIndexByCode(monthCode)].value || 0) as number;
  const newBudget = useSelector( selectNewBudget );

  //If the budget for this month is defined, then use the monthlyBudgetCost. Otherwise use the newBudget that was
  //defined in the Channels step.
  const chosenBudget = monthlyBudget[getMonthIndexByCode(monthCode)].isDefined ? monthlyBudgetCost : newBudget;


  const renderChannelList = (): JSX.Element => {
    return (
      <>
        {selectedNewCampaignChannels.map((channel) => {
          return (
            <div
              key={`${channel.channelType.id} channel for sidebar list`}
              style={{
                width: "100%",
              }}
            >
              <p style={{ fontWeight: '1000', margin: '0', fontSize: '16px' }}><b>{channel.channelType.title}</b></p>
              <p>{alwaysOn ? 'Starts ' + channel.startDate?.toLocaleDateString() : channel.startDate?.toLocaleDateString() + ' to ' + channel.endDate?.toLocaleDateString()}</p>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <div className="res:pb-3">
        <div className="target-container res:mt-0">
          <span className="target-title">Buying For:</span>
          <span>{selectedLocation?.name}</span>
        </div>

        { usesZipMode
          ?

            <div className="target-container">
              <div className="target-title">Audience Size:</div>

              { ( selectedPrimaryZips.length + selectedSecondaryZips.length ).toString() + ' zips' }
            </div>

          :
            ( !!targetZoneAudience && (
              <div className="target-container">
                <div className="target-title">Audience Size:</div>

                {targetZoneAudience.toLocaleString()}
              </div>
            ))
        }
        
        <div className="target-container">
          <div className="target-title">Budget:</div>$
          {getMoneyStringFromNumber(isFixedBudgetMode ? (chosenBudget) : estimatedCost)}
        </div>
        
        <div className="target-container">
          <span className="target-title">{alwaysOn ? 'Weekly Spend:' : 'Percent Spend:'}</span>
          <span>{adjustedSpendPercent}%</span>
        </div>

        <div className="target-container">
          <span className="target-title">Subtotal:</span>
          <span>${getMoneyStringFromNumber(isFixedBudgetMode ? (chosenBudget) : percentSpend)}</span>
        </div>
        
      </div>
      <div style={{ width: "100%", alignItems: "center" }} className="sidebar-container res:hidden">
        {selectedNewCampaignChannels.length !== 0 && (
          <div className="schedule-sidebar-container">
            {renderChannelList()}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleSidebar;
