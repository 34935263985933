import Api from "../../services/Api";
import { Auth } from "aws-amplify";

import { sendRegistrationWhiteLabelMail } from "../WhiteLabel/WhiteLabelStore";

import {
  LOGIN,
  REGISTER,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  SIGN_OUT,
  LOADING,
  ERROR,
  SET_RESET_PASSWORD_SUCCESS,
  SET_REGISTERED_SUCCESS,
  CONFIRM_SIGN_UP,
} from "./types";

export const loading = () => (dispatch: any) => dispatch({ type: LOADING });

export const setResetPasswordSuccess = () => (dispatch: any) =>
  dispatch({ type: SET_RESET_PASSWORD_SUCCESS });

export const setRegisteredSuccess = () => (dispatch: any) =>
  dispatch({ type: SET_REGISTERED_SUCCESS });

export const currentUser = () => async (dispatch: any) => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    if (user.username) {
      return dispatch({
        type: LOGIN,
        payload: user,
      });
    }
  } catch (error) {
    console.log("error currentUser up:", error);
  }
};

export const signOut = () => async (dispatch: any) => {
  dispatch({
    type: LOADING,
  });

  try {
    await Auth.signOut();

    return dispatch({
      type: SIGN_OUT,
    });
  } catch (error) {
    console.log("error signing up:", error);
    return dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

export const setError = (value: string) => async (dispatch: any) => {
  dispatch({
    type: ERROR,
    payload: value,
  });
};

export const login =
  ({ email, password }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: LOADING,
    });

    try {
      const user = await Auth.signIn({
        username: String(email).toLowerCase(),
        password,
      });

      return dispatch({
        type: LOGIN,
        payload: user,
      });
    } catch (error) {
      console.log("error signing up:", error);
      return dispatch({
        type: ERROR,
        payload: error,
      });
    }
  };

export const changePassword =
  ({ user, oldPassword, newPassword }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: LOADING,
    });

    try {
      const data = await Auth.changePassword(user, oldPassword, newPassword);

      return dispatch({
        type: CHANGE_PASSWORD,
        payload: data,
      });
    } catch (error) {
      console.log("error signing up:", error);
      return dispatch({
        type: ERROR,
        payload: error,
      });
    }
  };

export const register =
  ({ _firstname, _lastname, email, password, account_code, domain, appName, mailFrom, sendgridId }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: LOADING,
    });
    try {
      const validateResponse = await Api.validateEmailAndCode({email: email, code: account_code});
      if( validateResponse.status !== 200 ){
        //something failed.
        const errorMessage = validateResponse.data.details ?? 'Undefined error';
        throw({ message: errorMessage});
      }else{
        const { user } = await Auth.signUp({
          username: String(email).toLowerCase(),
          password,
          attributes: {
            //firstname,
            //lastname,
            email: String(email).toLowerCase(),
            'custom:domain': domain,
            'custom:appName': appName,
            'custom:mailFrom': mailFrom,
            //account_code,
          },
        });
  
        if( sendgridId != "" && sendgridId != undefined ){
          dispatch( sendRegistrationWhiteLabelMail({ email: user.getUsername(), mailFrom: mailFrom, sendgridId: sendgridId}));
        }
  
        return dispatch({
          type: REGISTER,
          payload: user,
        });
      }
    } catch (error) {
      console.log("error signing up:", error);
      return dispatch({
        type: ERROR,
        payload: error,
      });
    }
  };

export const confirmRegistration =
  ({ email, code }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: LOADING,
    });

    try {
      await Auth.confirmSignUp(String(email).toLowerCase(), code);

      return dispatch({
        type: CONFIRM_SIGN_UP,
      });
    } catch (error) {
      console.log("error confirm sign up:", error);
      return dispatch({
        type: ERROR,
        payload: error,
      });
    }
  };

export const recoveryPassword =
  ({ email, password, code }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: LOADING,
    });

    try {
      const data = await Auth.forgotPasswordSubmit(
        String(email).toLowerCase(),
        code,
        password
      );

      return dispatch({
        type: SET_RESET_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error password recover:", error);
      return dispatch({
        type: ERROR,
        payload: error,
      });
    }
  };

export const createUser =
  ({ firstName, lastName, email, type, account_code }: any) =>
  async (dispatch: any) => {
    try {
      await Api.register({
        firstName,
        lastName,
        email: String(email).toLowerCase(),
        // billingAddress,
        type,
        extFranchiseId: account_code,
      });
    } catch (error) {
      console.log("error signing up:", error);
    }
  };

export const forgotPassword =
  ({ email, domain, appName, mailFrom }: any) =>
  async (dispatch: any) => {
    dispatch({
      type: LOADING,
    });

    try {
      const data = await Auth.forgotPassword(
        String(email).toLowerCase(), 
        {
          'domain': domain,
          'appName': appName,
          'mailFrom': mailFrom,
        }
      );

      return dispatch({
        type: FORGOT_PASSWORD,
        payload: data,
      });
    } catch (error) {
      console.log("error signing up:", error);
      return dispatch({
        type: ERROR,
        payload: error,
      });
    }
  };
