import RestService from "./restService";

export interface IImageResource {
  asset_id: string;
  url: string;
  secure_url: string;
  display_name: string;
  resource_type: string;
}
/*export interface IVideoResource {
  asset_id: string;
  url: string;
  secure_url: string;
  display_name: string;
}*/

interface IResponse {
  resources: IImageResource[];
}
/*interface IVideoResponse {
  resources: IVideoResource[];
}*/

export default class MediaTemplateService {

  /**
   * Define class dependencies
   *
   * @param api
   */
  constructor(protected readonly api: RestService) { }

  /**
   * Import image
   *
   * @param file
   * @param selectedChannelId
   */
  async importImage(file: File, selectedChannelId: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('channel', selectedChannelId);
    return await this.api.post(`/media-template/import/${selectedChannelId}`, formData, {
        'Content-Type': 'multipart/form-data'
      }
    );
  }

  /**
   * Import video
   *
   * @param file
   * @param selectedChannelId
   */
  async importVideo(file: File, selectedChannelId: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('channel', selectedChannelId);
    return await this.api.post(`/media-template/import-video/${selectedChannelId}`, formData, {
        'Content-Type': 'multipart/form-data'
      }
    );
  }

  /**
   * Retrieve images
   */
  async getImages(channelId: string):Promise<IImageResource[]> {
    const result:IResponse = await this.api.get(`/media-template/images/${channelId}`);
    return result.resources;
  }

  /**
   * Retrieve videos
   */
  /*async getVideos(channelId: string):Promise<IVideoResource[]> {
    const result:IVideoResponse = await this.api.get(`/media-template/videos/${channelId}`);
    return result.resources;
  }*/
}
