import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconDailyOptimization } from "../../../assets/icons/dailyOptimization.svg";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconReport } from "../../../assets/icons/report.svg";
import { ReactComponent as IconSelectedReport } from "../../../assets/icons/report-selected.svg";
import { ReactComponent as IconTools } from "../../../assets/icons/tools.svg";
import { ReactComponent as IconSelectedTools } from "../../../assets/icons/tools-selected.svg";
import { ReactComponent as IconActivity } from "../../../assets/icons/activity.svg";
import { ReactComponent as IconSelectedActivity } from "../../../assets/icons/activity-selected.svg";

import AlertsDashboard from "../alerts";
import ReportsDashboard from "../reports";
import EventsDashboard from "../events";
import "./styles.css";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AvailableRoutes } from "../../../pages/Routes";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAccount } from "../../../store/Account/AccountStore";
import { Alert } from "../../../models/Alert";
import { setPerformanceTitle } from "../../../store/Reports/ReportsStore";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { selectSelectedMainMenu, setSelectedMainMenu, setStateSideBarChannel, stateSideBarChannel } from "store/Navigation/NavigationStore";

const DashboardSideBar = ({ children }: any) => {
  const dispatch = useDispatch();
  const account = useSelector(selectUserAccount);
  const navigate = useNavigate();
  //const [selectedOption, setSelectedOption] = useState("");
  const selectedOption = useSelector( selectSelectedMainMenu );
  const sideBarChannelState = useSelector(stateSideBarChannel);
  const [OpenExtraNav, setOpenExtraNav] = useState(false)
  const [activeAlerts, setActiveAlerts] = useState<Alert[]>([]);
  const today = new Date();

  useEffect(() => {
    if (account?.alerts !== undefined && account.alerts?.length !== 0)
      setActiveAlerts(
        account?.alerts?.filter((alert) => {
          return new Date(alert.alertDate) <= today;
        })
      );
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const whiteLabel = useSelector( selectWhiteLabel );

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 10,
      top: 10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      background: whiteLabel.primaryColor,
      width: "15px",
      height: "15px",
      minWidth: "15px",
    },
  }));

  return (
    <div className="content-body-sidebar res:flex res:flex-col sidebar-mobile-body res:py-3 res:px-8">
      <div className="sidebar-dashboard res:order-2 res:my-3">
        <div
          className="sidebar-dashboard-button pointer-hover res:w-full"
          onClick={() => navigate(AvailableRoutes.LOCATIONS)}
        >
          <div className="sidebar-dashboard-button-text">PLAN MY CAMPAIGN</div>
          <IconArrowRight />
        </div>
      </div>
      <div className="sidebar-dashboard-header res:order-1 res:mb-0 flex-wrap">
        <div className="sidebar-dashboard-header-icons items-end pointer-hover">
        {selectedOption === "activity" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <StyledBadge
                color="secondary"
                badgeContent=" "
                invisible={!(activeAlerts.length > 0)}
              >
                <IconSelectedActivity
                  className="active-icon"
                  onClick={() => {
                    if (sideBarChannelState === "close") {
                      setOpenExtraNav(!OpenExtraNav)  
                    }else{
                      dispatch( setStateSideBarChannel("close") );
                    }
                  }}
                />
              </StyledBadge>
              <div style={{ color: whiteLabel.primaryColor, fontWeight: "bold" }}>Activity</div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <StyledBadge
                color="secondary"
                badgeContent=" "
                invisible={!(activeAlerts.length > 0)}
              >
                <IconActivity
                  onClick={() => {
                      setOpenExtraNav(true);
                      navigate("/");
                      dispatch( setSelectedMainMenu("activity") );
                      dispatch( setStateSideBarChannel("open") );
                  }}
                />
              </StyledBadge>
              <div>Activity</div>
            </div>
          )}
          {selectedOption === "report" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <IconSelectedReport
                className="active-icon"
                onClick={() => {
                  
                }}
              />
              <div style={{ color: whiteLabel.primaryColor, fontWeight: "bold" }}>Reports</div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <IconReport
                onClick={() => {
                  setOpenExtraNav(true)
                  navigate(AvailableRoutes.PERFORMANCE_OVERVIEW);
                  dispatch( setSelectedMainMenu("report") );
                }}
              />
              <div>Reports</div>
            </div>
          )}
          
          {selectedOption === "event" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <IconSelectedTools
                className="active-icon"
                onClick={() => {
                    setOpenExtraNav(true);
                    if( selectedOption == "event" && OpenExtraNav ) setOpenExtraNav(false);
                }}
              />
              <div style={{ color: whiteLabel.primaryColor, fontWeight: "bold" }}>Tools</div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <IconTools
                onClick={() => {
                    setOpenExtraNav(true)
                    navigate(AvailableRoutes.EVENT);
                    dispatch( setSelectedMainMenu("event") );
                }}
              />
              <div>Tools</div>
            </div>
          )}
        </div>


        <div className="w-full flex justify-center mt-8">
          <div className=" w-[80%] h-[1px] bg-[#42424280] "></div>
        </div>
      </div>


      {selectedOption === "activity" ? (
        <div className={`sidebar-dashboard-body ${ OpenExtraNav ? "block" : "hidden" }`}>
          
        </div>
      ) : selectedOption === "report" ? (
        <ReportsDashboard openExtraVar={OpenExtraNav} />
      ) : (
        <EventsDashboard openExtraVar={OpenExtraNav} />
      )}
    </div>
  );
};

export default React.memo(DashboardSideBar);
