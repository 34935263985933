import { CustomCreativeData, CustomCreativeChannel } from "../models/CustomCreative";
import RestService from "./restService";

//The expected data in the custom reports file. All attributes are required.
//Used when importing rows from csv or xlsx
export interface CustomCreativeRowSchema {
  channelName: string,
  franchiseName: string,
  locationName: string,
  campaignName: string,
  creative: string,
  impressions: string,
  clicks: string, //* Setting clicks instead of ctr
  spend: string,
}

export default class CustomCreativeService {
  constructor(protected readonly api: RestService) { }

  async importCustomCreatives(customCreativeSchemaArray: CustomCreativeRowSchema[]): Promise<CustomCreativeData[]> {
    console.log(customCreativeSchemaArray);
    const apiResponse = await this.api.post<CustomCreativeData[]>("/custom-creatives/import", customCreativeSchemaArray);
    return apiResponse;
  }

  async getCustomCreativeChannels(): Promise<CustomCreativeChannel[]>{
    const apiResponse = await this.api.get<CustomCreativeChannel[]>("/custom-creatives/custom-channels");
    return apiResponse;
  }
  
}


