import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../models/Account";
import { Event } from "../../models/Event";
import services from "../../services";
import { INewEvent, IUpdateEvent, EventRowSchema} from "../../services/eventService";
import { RootState } from "../index";

export const fetchEvents = createAsyncThunk(
  "events/fetch",
  async ({ id }: Account) => {
    return services.eventService.getEvents(id);
  }
);

export const createEvent = createAsyncThunk(
  "events/create",
  async (newEvent: INewEvent) => {
    return services.eventService.createEvent(newEvent);
  }
);

export const updateEvent = createAsyncThunk(
  "events/update",
  async (updateEvent: IUpdateEvent) => {
    return services.eventService.updateEvent(updateEvent);
  }
);

export const deleteEvent = createAsyncThunk(
  "events/delete",
  async (deleteEventId: string) => {
    return services.eventService.deleteEvent(deleteEventId);
  }
);

export const importEvents = createAsyncThunk<
  Event[], 
  {
    eventSchemaArray: EventRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "events/import",
  async (eventsSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.eventService.importEvents(eventsSchemaArray.eventSchemaArray);
      return result;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message == "Validation Failed") {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;
          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message == "Entity Not Found") {
        formattedErrors.push(errorData.details);
      } else if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);

    }

  }
);

export const eventSlice = createSlice({
  name: "events",
  initialState: {
    events: [] as Event[],
    eventErrors: [] as String[] | null,
    importEventsResult: null as String | null,
  },
  reducers: {
    setEvents: (state, { payload: events }: PayloadAction<Event[]>) => {
      state.events = events;
    },
    setEventsErrors: ( state,{ payload: eventsErrors }: PayloadAction<String[] | null>) => {state.eventErrors = eventsErrors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events = action.payload;
    });
    builder.addCase(createEvent.fulfilled, (state, action) => {
      state.events.push(action.payload);
    });
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.events = state.events.map((event) => {
        if(event.id === action.payload.id){
          return action.payload;
        } else {
          return event;
        }
      });
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      state.events = state.events.filter((event) => {
        return event.id !== action.payload.toString();
      })
    });

    builder.addCase(importEvents.fulfilled, (state, action) => {
      let importedLocations = action.payload;
      state.importEventsResult = `Successfully imported ${importedLocations.length} new events.`;
    });

    builder.addCase(importEvents.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        state.eventErrors = action.payload!;
      }
    });
  },
});

export const selectEvents = (state: RootState): Event[] => state.events.events;

export const eventErrors = (state: RootState): String[] | null =>
  state.events.eventErrors;

export const importEventsResult = (state: RootState): String | null =>
  state.events.importEventsResult;

export const { setEvents } = eventSlice.actions;

export default eventSlice.reducer;
