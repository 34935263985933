import { useState } from 'react';
import style from '../mediaManager/MediaManager.module.css'
import { Audience } from '../../../models/Audience';

const AssignAudiencesList = ({ audiences }: { audiences: Audience[] }) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const toggleSelection = (optionId:string) => {

        if (!optionId) {
            return;
        }

        if (selectedOptions.includes(optionId)) {
            setSelectedOptions(selectedOptions.filter(opt => opt !== optionId));
        } else {
            setSelectedOptions([...selectedOptions, optionId]);
        }
    };

    return (
        <div>
            <p>Active audiences</p>
            <div className={style["column"]}>
                {audiences.map(option => (
                    <div 
                        key={option.hash}
                        className={`${style["item"]} ${selectedOptions.includes(option?.hash.toString()) ? style["selected"] : ''}`}
                        onClick={() => toggleSelection(option?.hash.toString())}
                    >
                        {option.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export { AssignAudiencesList };