import "./ReportsPage.scss";
import { useDispatch, useSelector } from "react-redux";
import {
    selectReportCompany,
    selectReportsLocationId,
    selectReportTitle,
    setReportCompany,
    setReportsLocationId,
    setReportTitle
} from "../../store/Reports/ReportsStore";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../pages/Routes";
import { useEffect, useState } from "react";
import { Button, Form, Input, Table } from "antd";
import DatePicker from "../datepicker/DatePicker";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { getCustomChannels, selectCustomChannels } from "../../store/CustomReport/CustomReportStore";
import { getGoogleAdsReportSingle, getGoogleAdsKeyWords, selectGoogleAdsReportSingle, selectFacebookAdsReportSingle, getFacebookAdsReportSingle, selectCustomChannelReportSingle, getCustomChannelReportSingle, selectGoogleAdsKeyWords } from '../../store/Location/LocationStore';
import type { FranchiseKeyWordPerformance } from "../../services/franchiseService";
import { DualAxes, Line } from '@ant-design/plots';
import { addDaysToDate } from "../../utility/time";
import { report } from "process";
import type { ColumnsType } from 'antd/es/table';

export const ReportsSinglePage = (): JSX.Element => {
    const reportTitle = useSelector(selectReportTitle);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const googleAdsReportSingle = useSelector(selectGoogleAdsReportSingle);
    const googleAdsReportKeyWords = useSelector(selectGoogleAdsKeyWords);
    const facebookAdsReportSingle = useSelector(selectFacebookAdsReportSingle);
    const customChannelsReportSingle = useSelector(selectCustomChannelReportSingle);
    const reportCompany = useSelector(selectReportCompany);
    const locationId = useSelector(selectReportsLocationId);
    const customChannels = useSelector(selectCustomChannels);

    useEffect(() => {
        dispatch(getGoogleAdsReportSingle({ id: locationId }));
        dispatch(getGoogleAdsKeyWords({ id: locationId }));
        dispatch(getFacebookAdsReportSingle({ id: locationId }));
        dispatch(getCustomChannelReportSingle({id: locationId}));
        
        dispatch(getCustomChannels());
        return () => {
            dispatch(setReportTitle(''));
            dispatch(setReportsLocationId(''));
        }
    }, [locationId]);

    const [form] = Form.useForm();

    const onFinish = (e: any) => {
        console.log(e);
        dispatch(getGoogleAdsReportSingle({ id: locationId, startDate: e.startDate, endDate: e.endDate }));
        dispatch(getFacebookAdsReportSingle({ id: locationId, startDate: e.startDate, endDate: e.endDate }));
        dispatch(getCustomChannelReportSingle({id: locationId, startDate: e.startDate, endDate: e.endDate }));
        dispatch(getGoogleAdsKeyWords({ id: locationId, startDate: e.startDate, endDate: e.endDate }));
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    }

    const [impres_clicksData, setImpres_clicksData] = useState([] as Object[]);
    const [ctrData, setCtrData] = useState([] as Object[]);
    const [cpcData, setCpcData] = useState([] as Object[]);
    const [costData, setCostData] = useState([] as Object[]);
    useEffect(() => {
        if (reportCompany == 'Google' && !googleAdsReportSingle.error || reportCompany == 'Facebook' && !facebookAdsReportSingle.error) {
            if (reportCompany == 'Google') {
                form.setFieldsValue({ startDate: new Date(googleAdsReportSingle.lastStartDate || googleAdsReportSingle.dates ? googleAdsReportSingle.dates[0]?.date : '') });
                form.setFieldsValue({ endDate: new Date(googleAdsReportSingle.lastEndDate || googleAdsReportSingle.dates ? googleAdsReportSingle.dates[googleAdsReportSingle.dates.length - 1]?.date : '') });
                let ic = [] as Object[];
                googleAdsReportSingle.dates?.forEach((item) => {
                    ic.push({
                        time: item.date,
                        value: item.impressions,
                        type: 'impressions',
                    });
                    ic.push({
                        time: item.date,
                        value: item.clicks,
                        type: 'clicks',
                    });
                });
                setImpres_clicksData(ic);
            } else {
                form.setFieldsValue({ startDate: new Date(facebookAdsReportSingle.lastStartDate || facebookAdsReportSingle.dates ? facebookAdsReportSingle.dates[0]?.date : '') });
                form.setFieldsValue({ endDate: new Date(facebookAdsReportSingle.lastEndDate || facebookAdsReportSingle.dates ? facebookAdsReportSingle.dates[facebookAdsReportSingle.dates.length - 1]?.date : '') });
                let ic = [] as Object[];
                facebookAdsReportSingle.dates?.forEach((item) => {
                    ic.push({
                        time: item.date,
                        value: item.impressions,
                        type: 'impressions',
                    });
                    ic.push({
                        time: item.date,
                        value: item.clicks,
                        type: 'clicks',
                    });
                });
                setImpres_clicksData(ic);
            }

            if (reportCompany == 'Google') {
                let ct = [] as Object[];
                googleAdsReportSingle.dates?.forEach((item) => {
                    ct.push({
                        time: item.date,
                        ctr: (isNaN(item.ctr) ? 0 : item.ctr) * 100,
                    });
                });
                setCtrData(ct);
            } else {
                let ct = [] as Object[];
                facebookAdsReportSingle.dates?.forEach((item) => {
                    ct.push({
                        time: item.date,
                        ctr: (isNaN(item.ctr) ? 0 : item.ctr) * 100,
                    });
                });
                setCtrData(ct);
            }

            if (reportCompany == 'Google') {
                const cp = [] as Object[];
                googleAdsReportSingle.dates?.forEach((item) => {
                    cp.push({
                        "Date": item.date,
                        "cpc": (isNaN(item.cpc) ? 0 : item.cpc) / 1000000,
                    });
                })
                setCpcData(cp);
            } else {
                const cp = [] as Object[];
                facebookAdsReportSingle.dates?.forEach((item) => {
                    cp.push({
                        "Date": item.date,
                        "cpc": (isNaN(item.cpc) ? 0 : item.cpc) / 1000000,
                    });
                })
                setCpcData(cp);
            }

            if (reportCompany == 'Google') {
                const cs = [] as Object[];
                googleAdsReportSingle.dates?.forEach((item) => {
                    cs.push({
                        "Date": item.date,
                        "cost": (isNaN(item.cost) ? 0 : item.cost) / 1000000,
                    });
                });
                setCostData(cs);
            } else {
                const cs = [] as Object[];
                facebookAdsReportSingle.dates?.forEach((item) => {
                    cs.push({
                        "Date": item.date,
                        "cost": (isNaN(item.cost) ? 0 : item.cost) / 1000000,
                    });
                });
                setCostData(cs);
            }
        }else if( !customChannelsReportSingle.error ){
            //obtains the proper channel according to the reportCompany name.
            let selectedChannel = customChannelsReportSingle.customChannels.find( entry => entry.channelName === reportCompany );
            if( selectedChannel != undefined ){
                console.log('selected channel is');
                console.log(selectedChannel);
                form.setFieldsValue({ startDate: new Date(customChannelsReportSingle.lastStartDate || selectedChannel.dates ? selectedChannel.dates[0]?.date : '') });
                form.setFieldsValue({ endDate: new Date(customChannelsReportSingle.lastEndDate || selectedChannel.dates ? selectedChannel.dates[selectedChannel.dates.length - 1]?.date : '') });
                
                //ADD IC
                let ic = [] as Object[];
                selectedChannel.dates?.forEach((item) => {
                    ic.push({
                        time: item.date,
                        value: Number(item.impressions),
                        type: 'impressions',
                    });
                    ic.push({
                        time: item.date,
                        value: Number(item.clicks),
                        type: 'clicks',
                    });
                });
                setImpres_clicksData(ic);

                //ADD CT
                let ct = [] as Object[];
                selectedChannel.dates?.forEach((item) => {
                    ct.push({
                        time: item.date,
                        ctr: (isNaN(item.ctr) ? 0 : Number(item.ctr)),
                    });
                });
                setCtrData(ct);

                //Add cp
                const cp = [] as Object[];
                selectedChannel.dates?.forEach((item) => {
                    cp.push({
                        "Date": item.date,
                        "cpc": (isNaN(item.cpc) ? 0 : Number(item.cpc)),
                    });
                })
                setCpcData(cp);

                //Add cs
                const cs = [] as Object[];
                selectedChannel.dates?.forEach((item) => {
                    cs.push({
                        "Date": item.date,
                        "cost": (isNaN(item.spend) ? 0 : Number(item.spend)),
                    });
                });
                setCostData(cs);
            }
        }
    }, [googleAdsReportSingle, facebookAdsReportSingle, customChannelsReportSingle, reportCompany]);

    const ImpressionsChart = () => {
        const config = {
            data: [impres_clicksData, ctrData],
            xField: 'time',
            xAxis: {
                label: {
                    autoRotate: true
                }
            },
            yField: ['value', 'ctr'],
            yAxis: {
                ctr: {
                    nice: true,
                    label: {
                        formatter: (value: any) => `${value}%`,
                    }
                }
            },
            geometryOptions: [
                {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: 'type',
                    marginRatio: 0,
                    columnWidthRatio: 0.8,
                },
                {
                    geometry: 'line',
                    lineStyle: {
                        lineWidth: 2,
                    },
                },
            ],
            theme: {
                colors10: [
                    '#4285F4',
                    '#30CEB3',
                    '#DB4437',
                ]
            }
        };
        return <DualAxes {...config} />;
    };  


    const columns: ColumnsType<FranchiseKeyWordPerformance> = [
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'word',
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (val => `${val.toLocaleString('en-US')}`)
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            render: (val => `${((isNaN(val) ? 0 : val) * 100).toFixed(2)}%`)
        },
        {
            title: 'Spend',
            dataIndex: 'cost',
            key: 'cost',
            render: (val => `$${((isNaN(val) ? 0 : val) / 1000000).toLocaleString('en-US')}`)
        }
    ];


    const KeyWordPerformance = () => {
        console.log(googleAdsReportKeyWords)
        return <Table className="res:overflow-x-scroll report-alltable" columns={columns} pagination={false} dataSource={googleAdsReportKeyWords.keyWords} />
    }

    //Fetch the custom channels.
    const customChannelButtons = customChannels.map(function(channel,i){
        return <Button className={reportCompany == channel.title ? 'active' : ''} shape="round" onClick={() => {
            //dispatch(setReportCompany(channel.name));
            pathflow(channel.title)
        }}>
            {channel.title}
        </Button>
    });

    let reportName;
    if( reportCompany == 'Google' ){
        reportName = googleAdsReportSingle.name;
    }else if( reportCompany == 'Facebook'){
        reportName = facebookAdsReportSingle.name;
    }else{
        let selectedChannel = customChannelsReportSingle.customChannels.find( entry => entry.channelName === reportCompany );
        reportName = selectedChannel?.name;
    }


    // 
    const pathflow = (reportCompany: any) => {
        if ( reportCompany != 'Google') {
            dispatch(setReportTitle('Impressions & clicks'));
            dispatch(setReportCompany(reportCompany));
        }else {
            dispatch(setReportCompany(reportCompany));
        }
    }

    return (
        <div className="reports-page-container res:mb-[180px]">
            <div>
                <div className="justify-start report-header">
                    <Button className="report-back-button" onClick={() => {
                        dispatch(setReportsLocationId(''));
                        dispatch(setReportTitle('Impressions & clicks'));
                        navigate(AvailableRoutes.ACTIVE_CAMPAIGNS);
                    }} />
                    <div className="res:!text-xl report-title report-location">
                        { reportName }
                    </div>
                </div>
                <div>
                    <Form
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="container-form"
                        layout="vertical"
                    >
                        <div className="report-form-container">
                            <Input.Group compact className="res:!grid res:grid-cols-2 res:gap-3">
                                <Form.Item
                                    className="res:h-0 res:m-0"
                                    hidden
                                    name='pageToken'
                                />
                                <Form.Item
                                    label="From"
                                    name="startDate"
                                    className="res:!w-full"
                                    style={{ width: "160px" }}
                                    rules={[{ required: true }]}
                                >
                                    <DatePicker
                                        use12Hours={true}
                                        showTime={false}
                                        showHour={true}
                                        showMinute={true}
                                        showSecond={false}
                                        style={{ borderRadius: "4px", width: "100%" }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="To"
                                    name="endDate"
                                    className="res:!w-full"
                                    style={{ marginLeft: "16px", width: "160px" }}
                                >
                                    <DatePicker
                                        use12Hours={true}
                                        showTime={false}
                                        showHour={true}
                                        showMinute={true}
                                        showSecond={false}
                                        style={{ borderRadius: "4px", width: "100%" }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        className="report-apply-button pointer-hover res:w-full res:!m-0"
                                    >
                                        APPLY
                                        <IconArrowRight />
                                    </Button>
                                </Form.Item>
                            </Input.Group>
                        </div>
                    </Form>
{/*                    <div className="report-company-selector res:flex-wrap res:gap-3" style={{ marginBottom: '25px' }}>
                        <Button className={reportCompany == 'Google' ? 'active' : ''} shape="round" onClick={() => {
                            //dispatch(setReportCompany('Google'));
                            pathflow('Google')
                        }}>
                            Google
                        </Button>
                        <Button className={reportCompany == 'Facebook' ? 'active' : ''} shape='round' onClick={() => {
                            //dispatch(setReportCompany('Facebook'));
                            pathflow('Facebook')
                        }}>
                            Meta
                        </Button>
                        { customChannelButtons }
                    </div>*/}
                    <div className="report-body">
                        {reportTitle === 'Impressions & clicks' ?
                            <ImpressionsChart />
                            :
                            reportTitle === 'Keyword performance' ?
                                <KeyWordPerformance/>                            
                                :
                                <></>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    );
};
