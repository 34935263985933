import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import {
  currentUser as currentUserAction,
  login as loginAction,
  register as registerAction,
  createUser as createUserAction,
  forgotPassword as forgotPasswordAction,
  changePassword as changePasswordAction,
  signOut as signOutAction,
  setError as setErrorAction,
  setResetPasswordSuccess as setResetPasswordSuccessAction,
  setRegisteredSuccess as setRegisteredSuccessAction,
  confirmRegistration as confirmRegistrationAction,
  recoveryPassword as recoveryPasswordAction,
} from "../store/Auth/action";

export const useAuth = () => {
  const {
    user,
    credentials,
    resetPasswordSuccess,
    isRegisteredSuccess,
    isConfirmedSignUp,
    loading,
    error,
  } = useSelector((state: RootState) => state.authReducer);

  const dispatch = useDispatch();
  const currentUser = () => dispatch(currentUserAction());
  const signOut = () => dispatch(signOutAction());
  const login = (values: any) => dispatch(loginAction(values));
  const register = (values: any) => dispatch(registerAction(values));

  const createUser = (values: any) => dispatch(createUserAction(values));

  const setError = (values: any) => dispatch(setErrorAction(values));
  const setResetPasswordSuccess = () =>
    dispatch(setResetPasswordSuccessAction());
  const setRegisteredSuccess = () => dispatch(setRegisteredSuccessAction());

  const changePassword = (values: any) =>
    dispatch(changePasswordAction(values));

  const forgotPassword = (values: any) =>
    dispatch(forgotPasswordAction(values));

  const confirmRegistration = (values: any) =>
    dispatch(confirmRegistrationAction(values));

  const recoveryPassword = (values: any) =>
    dispatch(recoveryPasswordAction(values));

  return {
    user,
    credentials,
    isRegisteredSuccess,
    isConfirmedSignUp,
    setRegisteredSuccess,
    confirmRegistration,
    resetPasswordSuccess,
    setResetPasswordSuccess,
    currentUser,
    login,
    register,
    createUser,
    changePassword,
    forgotPassword,
    signOut,
    loading,
    error,
    setError,
    recoveryPassword,
  };
};
