import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "../../models/Location";
import services from "../../services";
import { INewLocation, IUpdateLocation, LocationFadsSchema, LocationGadsKeyWords, LocationGadsSchema, LocationRowSchema, LocationCustomChannelSchema } from "../../services/locationService";
import { RootState } from "../index";
import { CustomReportChannel } from "../../models/CustomReport";

export const getGoogleAdsReportSingle = createAsyncThunk(
  "locations/reports/google/",
  async (data: { id: string; startDate?: string; endDate?: string; }) => {
    return await services.locationService.getGoogleAdsReportSingle(data.id, data.startDate, data.endDate);
  }
)

export const getGoogleAdsKeyWords = createAsyncThunk(
  "locations/reports/google/keywords/",
  async (data: { id: string; startDate?: string; endDate?: string; }) => {
    return await services.locationService.getGoogleAdsKeyWords(data.id, data.startDate, data.endDate);
  }
)


export const getFacebookAdsReportSingle = createAsyncThunk(
  "locations/reports/facebook/",
  async (data: { id: string; startDate?: string; endDate?: string; }) => {
    return await services.locationService.getFacebookAdsReportSingle(data.id, data.startDate, data.endDate);
  }
)

export const getCustomChannelReportSingle = createAsyncThunk(
  "locations/reports/custom-channel/",
  async (data: { id: string; startDate?: string; endDate?: string; }) => {
    return await services.locationService.getCustomChannelReportSingle(data.id, data.startDate, data.endDate);
  }
)

export const fetchLocations = createAsyncThunk("locations/fetch", async () => {
  return services.locationService.getUserLocations();
});

export const fetchFranchiseLocations = createAsyncThunk(
  "franchiseLocations/fetch",
  async (franchiseId: string) => {
    return services.locationService.getFranchiseLocations(franchiseId);
  }
);

export const createLocation = createAsyncThunk(
  "locations/create",
  async (createLocation: INewLocation) => {
    return services.locationService.createLocation(createLocation);
  }
);

export const updateLocation = createAsyncThunk(
  "locations/update",
  async (updateLocation: IUpdateLocation) => {
    return services.locationService.updateLocation(updateLocation);
  }
);

//Creates multiple locations from a csv or xlsx file
// @params
// locationSchemaArray (An array of LocationRowSchema objects representing the rows in the file)
export const importLocations = createAsyncThunk<
  Location[], //we expect this to return a list of locations
  {
    locationSchemaArray: LocationRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "locations/import",
  async (locationSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.locationService.importLocations(locationSchemaArray.locationSchemaArray);
      return result;
    } catch (error: any) {
      //console.log( error );
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message == "Validation Failed") {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;

          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message == "Entity Not Found") {
        formattedErrors.push(errorData.details);
      } else if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);

    }

  }
);

export const getFranchiseeLocationsByMarketName = createAsyncThunk(
  "location/marketName/locations",
  async (marketName: string | null) => {
    return await services.locationService.getFranchiseeLocationsByMarketName(
      marketName
    );
  }
);

export const locationSlice = createSlice({
  name: "locations",
  initialState: {
    locations: [] as Location[],
    fetchLocationsLoaded: false,
    franchiseLocations: [] as Location[],
    marketLocations: [] as Location[],
    locationToEdit: null as Location | null,
    locationErrors: [] as String[] | null,
    importLocationsResult: null as String | null,
    usesZipMode: true,
    getGoogleAdsKeyWords: {
      keyWords: [],
      error: undefined,
      lastStartDate: '',
      lastEndDate: '',
      name: ''
    } as LocationGadsKeyWords,
    googleAdsReportSingle: {
      dates: [],
      error: undefined,
      lastStartDate: '',
      lastEndDate: '',
      name: ''
    } as LocationGadsSchema,
    facebookAdsReportSingle: {
      dates: [],
      error: undefined,
      lastStartDate: '',
      lastEndDate: '',
      name: ''
    } as LocationFadsSchema,
    customChannelsReportSingle: {
      customChannels: [],
      error: undefined,
      lastStartDate: '',
      lastEndDate: '',
      name: ''
    } as LocationCustomChannelSchema,
  },
  reducers: {
    setLocations: (
      state,
      { payload: locations }: PayloadAction<Location[]>
    ) => {
      state.locations = locations;
    },
    setFranchiseLocations: (
      state,
      { payload: locations }: PayloadAction<Location[]>
    ) => {
      state.franchiseLocations = locations;
    },
    setLocationToEdit: (
      state,
      { payload: location }: PayloadAction<Location | null>
    ) => {
      state.locationToEdit = location;
    },
    setLocationErrors: (
      state,
      { payload: locationErrors }: PayloadAction<String[] | null>
    ) => {
      state.locationErrors = locationErrors;
    },
    setUsesZipMode: (
      state,
      { payload: usesZipMode }: PayloadAction<boolean>
    ) => {
      state.usesZipMode = usesZipMode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.locations = action.payload;
    });
    builder.addCase(fetchFranchiseLocations.fulfilled, (state, action) => {
      state.franchiseLocations = action.payload;
      state.fetchLocationsLoaded = true;
    });
    builder.addCase(createLocation.fulfilled, (state, action) => {
      state.locations.push(action.payload);
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.locations = state.locations.map((location) => {
        if (location.id === action.payload.id) {
          return action.payload;
        } else {
          return location;
        }
      });
    });
    builder.addCase(importLocations.fulfilled, (state, action) => {
      let importedLocations = action.payload;
      state.importLocationsResult = `Successfully imported ${importedLocations.length} new locations.`;
    });

    builder.addCase(importLocations.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.locationErrors = action.payload!;
      }
    });

    builder.addCase(getGoogleAdsKeyWords.fulfilled, (state, action) => {
      state.getGoogleAdsKeyWords = action.payload;
    });
    builder.addCase(getGoogleAdsReportSingle.fulfilled, (state, action) => {
      state.googleAdsReportSingle = action.payload;
    });
    builder.addCase(getFacebookAdsReportSingle.fulfilled, (state, action) => {
      state.facebookAdsReportSingle = action.payload;
    });
    builder.addCase(getCustomChannelReportSingle.fulfilled, (state, action) => {
      console.log( action.payload );
      state.customChannelsReportSingle = action.payload;
    });

    builder.addCase(
      getFranchiseeLocationsByMarketName.fulfilled,
      (state, action) => {
        state.marketLocations = action.payload;
      }
    );
  },
});

export const selectFacebookAdsReportSingle = (state: RootState) =>
  state.locations.facebookAdsReportSingle

export const selectGoogleAdsKeyWords = (state: RootState) =>
state.locations.getGoogleAdsKeyWords;

export const selectGoogleAdsReportSingle = (state: RootState) =>
  state.locations.googleAdsReportSingle;

export const selectCustomChannelReportSingle = (state: RootState) =>
  state.locations.customChannelsReportSingle;

export const selectLocations = (state: RootState): Location[] =>
  state.locations.locations;

export const selectFetchLocationsLoaded = (state: RootState): boolean =>
  state.locations.fetchLocationsLoaded;

export const selectFranchiseLocations = (state: RootState): Location[] =>
  state.locations.franchiseLocations;

export const selectMarketLocations = (state: RootState): Location[] =>
  state.locations.marketLocations;
  
export const selectLocationToEdit = (state: RootState): Location | null =>
  state.locations.locationToEdit;

export const selectUsesZipMode = (state: RootState): boolean => {
  return state.locations.usesZipMode;
}
  


export const locationErrors = (state: RootState): String[] | null =>
  state.locations.locationErrors;

export const importLocationsResult = (state: RootState): String | null =>
  state.locations.importLocationsResult;


export const { setLocations, setLocationToEdit, setUsesZipMode } = locationSlice.actions;

export default locationSlice.reducer;
