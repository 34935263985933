import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as IconSuccessChecked } from "../../../assets/icons/success-checked.svg";
import { useAuth } from "../../../hooks/useAuth";

import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { useSelector } from "react-redux";

import "./styles.css";
import FooterHelp from "../FooterHelp";

const ResetPassword = () => {
  const {
    forgotPassword,
    resetPasswordSuccess,
    setResetPasswordSuccess,
    setError,
    loading,
    error,
  } = useAuth();
  const [submitted, setSubmitted] = useState(true);
  const navigate = useNavigate();

  //Gets the current Whitelabel information.
  const whiteLabel = useSelector( selectWhiteLabel );

  const onFinish = (values: ResetPasswordParams) => {
    values.domain = whiteLabel.domain;
    values.appName = whiteLabel.metaTitle;
    values.mailFrom = whiteLabel.mailFrom;
    forgotPassword(values);
    setResetPasswordSuccess();
    setSubmitted(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!error && submitted && !loading) {
      console.log("Register Complete");
    } else if (error && !loading) {
      setSubmitted(false);
    }
  }, [error, loading, submitted]);

  useEffect(() => {
    if (error) {
      console.log("error", error);
    }
  }, [error]);

  const handleOnBack = () => {
    setError(null);
    navigate("/login");
  };

  return (
    <div className="container-auth res:w-full">
      <div>
        <IconArrowLeft className="primary-icon cursor-pointer" onClick={handleOnBack} />
        <div className="text-header">Reset your password</div>
        {!resetPasswordSuccess && (
          <>
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="container-form"
              layout="vertical"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "not valid email",
                  },
                ]}
                style={{ marginBottom: 2 }}
              >
                <Input
                  placeholder="Enter email"
                  className={error && "error-input"}
                />
              </Form.Item>

              <span className="input-leyend">
                Enter an email address to get a password reset link
              </span>

              <Form.Item style={{ marginTop: 60 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-submit"
                  loading={loading}
                >
                  RESET MY PASSWORD
                  <IconArrowRight style={{ height: 11 }} />
                </Button>
              </Form.Item>
            </Form>
            {error && (
              <div className="error-message">
                Sorry, we couldn’t find an account with that email address
              </div>
            )}
          </>
        )}

        {resetPasswordSuccess && (
          <>
            <div className="icon-success">
              <IconSuccessChecked />
            </div>
            <div className="success-message">
              Thank you, we’ve sent an email with a link to reset your password
            </div>
          </>
        )}
      </div>
      <FooterHelp />
    </div>
  );
};

interface ResetPasswordParams {
  email: string;
  domain: string;
  appName: string;
  mailFrom: string;
}

export default ResetPassword;
