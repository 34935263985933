import qrcode from "qrcode";

/*export async function generatePromQRCode(promotion: string, expirationDate: Date, notes: string): Promise<string> {
  const content = {
    promotion,
    expirationDate,
    notes,
  };
  const jsonContent = JSON.stringify(content);
  const url = await qrcode.toDataURL(jsonContent);

  return url
}*/

export async function generatePromQRCode(id: string): Promise<string> {
  const content = {
    id,
  };
  const jsonContent = JSON.stringify(content);
  const url = await qrcode.toDataURL(jsonContent);

  return url
}


export async function generateLandingQRCode(landingCode: string): Promise<string> {
  const jsonContent = `https://jbeccaplatforms.com/qrlanding/${landingCode}`;
  const url = await qrcode.toDataURL(jsonContent);
  return url;
}