import {Menu, MenuItem, Typography} from "@mui/material";
import React from "react";
import { UserRole } from "../../../models/User";
import IMenu from "./IMenu";
import services from "../../../services";

const menuItems = Object.keys(UserRole);

const RolesMenu = (props: IMenu) => {

    const switchRole = async (role: string) => {
        await services.userService.switchUserRole(role).then(() => {
            window.location.reload();
        }).catch(() => {
            alert('Could not switch the user role.')
        });
    }

    return (
        <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={props?.anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(props.anchorEl)}
            onClose={props.closeMenuHandler}
        >
            {menuItems.map((item) => (
                <MenuItem key={item} onClick={props.closeMenuHandler}>
                    <Typography
                        textAlign="center"
                        onClick={() => switchRole(item)}
                    >
                        {item}
                    </Typography>
                </MenuItem>
            ))}
        </Menu>
    );
}

export default RolesMenu;