export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const HOURS_IN_A_WEEK = 168;

export const STANDARD_BILLING_PERIOD_WEEKS = 2;

export const addDaysToDate = (date: Date, numberOfDays: number): Date => {
  return new Date(
    date.getTime() +
    numberOfDays *
    (MILLISECONDS_IN_SECOND *
      SECONDS_IN_MINUTE *
      MINUTES_IN_HOUR *
      HOURS_IN_DAY)
  );
};

export const dateRangesOverlap = (
  date1: { start: Date; end: Date },
  date2: { start: Date; end: Date }
): boolean => {
  return (
    date1.start.getTime() < date2.end.getTime() &&
    date2.start.getTime() < date1.end.getTime()
  );
};

export const getHoursBetween2Dates = (date1: Date, date2: Date): number => {
  const diffInMilliseconds = date1.getTime() - date2.getTime();
  return Math.abs(
    diffInMilliseconds /
    MILLISECONDS_IN_SECOND /
    SECONDS_IN_MINUTE /
    MINUTES_IN_HOUR
  );
};

export const formatTimeMinSec = (time: number): string => {
  if (isNaN(time)) return "0m 00s";
  const minutes = Math.floor(time / SECONDS_IN_MINUTE);
  const seconds = Math.floor(time % SECONDS_IN_MINUTE);
  return `${minutes}m ${seconds < 10 ? '0' : ''}${seconds}s`;
}

export const hoursToWeeks = (hours: number): string => {
  if (isNaN(hours) || hours < 0) {
    return 'Invalid input';
  }

  const weeks = hours / 168; 
  const plural = weeks !== 1 ? 's' : ''; 
  return `${weeks.toFixed(2)} week${plural}`;
};

export const daysToWeeks = (days: number): string => {
  if (isNaN(days) || days < 0) {
    return 'Invalid input';
  }

  const weeks = Math.ceil(days / 7); 
  const plural = weeks !== 1 ? 's' : '';
  return `${weeks} week${plural}`;
};

