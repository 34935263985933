import {useState, useEffect, SyntheticEvent, BaseSyntheticEvent} from "react";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "assets/icons/loader-onmiChannel.svg";
import { useSelector, useDispatch } from "react-redux";
import { Form, Select, Input, Button, Row, Col, Modal, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { setSelectedMainMenu, setSelectedSubMenu } from "../../store/Navigation/NavigationStore";
import { selectFranchiseLocations } from "../../store/Location/LocationStore";
import "./styles.css";
import LocationCard from "../purchase/locations/LocationCard";
import { RadioGroup } from "@mui/material";
import { KeywordSideBard } from "./KeywordSideBar";
import { selectFranchise } from 'store/Franchise/FranchiseStore'
import { selectWhiteLabel } from "store/WhiteLabel/WhiteLabelStore";
import CustomSwitch from "../Switch/CustomSwitch";
import CustomTooltip from "../tooltip/tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg"; 
import { InfoSideBar } from "./InfoSideBar";
import {
  selectInfoModalState,
  setInfoModalState,
  selectConfigOptions,
  setConfigOptions,
  fetchOptimizationSettings,
  updateOptimizationSettings
} from "../../store/KeywordHelper/KeywordHelperStore";
import styles from "../reports/OmniChannel/OmniChannel.module.css";
import { FormConfig } from "./FormConfig";


interface EditFormField {
    type: string;
    dataType?: string;
    name: string;
    label?: string;
}

export const KeywordHelperPage = (): JSX.Element => {

  const dispatch = useDispatch();
  const franchiseLocations = useSelector(selectFranchiseLocations);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [open, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const franchise = useSelector(selectFranchise);
  const infoModalState = useSelector(selectInfoModalState);
  const [infoModalTitle, setInfoModalTitle] = useState('');
  const [infoModalContent, setInfoModalContent] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState('');
  const [editFormFields, setEditFormFields] = useState<EditFormField[]>([]);
  const configOptions: any = useSelector(selectConfigOptions);
  const [forceDefaultStates, setForceDefaultStates] = useState({});

  /**
   * Re-pack inextensible object
   */
  const repackObject = (value: any) => {
      return JSON.parse(JSON.stringify(value));
  }
  const formConfig = repackObject(FormConfig);
  const [ settingsForm ] = Form.useForm();

  const defaultEditFormConfig = {
    fields: [
      {
          type: 'input',
          name: 'ratio_threshold',
          label: 'Ratio Threshold'
      },
      {
          type: 'frequency',
          name: 'frequency',
          label: 'Frequency',
      }
      ]
  }
  /**
   * Initial setup
   */
  useEffect( () => {
    dispatch( setSelectedMainMenu("event") );
    dispatch( setSelectedSubMenu("Keyword Helper" ));
    dispatch( setInfoModalState(false) );
    dispatch( fetchOptimizationSettings() );

  }, []);

  /**
   * Update form on config option update
   */
  useEffect(() => {

    if (selectedOption && configOptions[selectedOption] !== undefined) {
      settingsForm.setFieldsValue(configOptions[selectedOption])
    }

  }, [configOptions]);


    /**
     * Handle popup content
     *
     * @param title
     * @param content
     * @param optionName
     */
  const handleSettingsClick = (
      title: string,
      content: string,
      optionName: string,
  ) => {
      dispatch(setInfoModalState(true));
      setInfoModalTitle(title);
      setInfoModalContent(content);
      setSelectedOption(optionName);
      setEditFormFields(formConfig[optionName]?.fields || defaultEditFormConfig.fields);

      settingsForm.resetFields()
      if (configOptions && configOptions[optionName] !== undefined) {
          settingsForm.setFieldsValue(configOptions[optionName]);
      }
  }

    /**
     * Process form data saving
     *
      * @param optionConfig
     */
    const handleSettingsSave = (optionConfig: object) => {

      const config = {
        ...configOptions,
        [selectedOption]: Object.assign({...configOptions[selectedOption]}, optionConfig)
      }

      dispatch(setConfigOptions(config));
      dispatch(updateOptimizationSettings(config));
    }

    /**
     * Update Use Default
     *
     * @param e
     */
    const handleUseDefaultChange = (e: CheckboxChangeEvent) => {

        if (e.target.name === undefined) {
            return;
        }

        const newDefaultStates = {
          ...forceDefaultStates,
          [e.target.name]: e.target.checked
        };

        setForceDefaultStates(newDefaultStates);

        const newConfig = {
          ...configOptions,
          [selectedOption]: {
            ...configOptions[selectedOption],
            [e.target.name]: e.target.checked
          }
        };

      dispatch(setConfigOptions(newConfig));
    }

  /**
   * Check if use_default option is checked
   * @param fieldName
   */
  const isUseDefaultChecked = (fieldName: string): boolean => {
      return configOptions[selectedOption] && configOptions[selectedOption][fieldName] !== undefined
        ? configOptions[selectedOption][fieldName] : true;
  }

  /**
   * Check if optimization option is enabled or not
   * @param optionCode
   */
  const isOptOptionEnabled = (optionCode: string) => {
    return configOptions[optionCode] && configOptions[optionCode]['active']
      ? configOptions[optionCode] && configOptions[optionCode]['active'] : false;
  }

  /**
   * Process optimization toggle change
   * @param optionCode
   */
  const handleOptToggleChange = (optionCode: string): void => {

    const config = {
      ...configOptions,
      [optionCode]: Object.assign({...configOptions[optionCode]}, {active: !isOptOptionEnabled(optionCode)})
    }

    dispatch(setConfigOptions(config));
    dispatch(updateOptimizationSettings(config));
  }

  const locationChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLocation(ev.target.value)
  };

  const handleModalClose = () => {
    setIsOpen(false);
  }

  const scrapeKeywords =  async () => {
    try {
      setLoading(true)
      const location = franchiseLocations.find(item => item.id === selectedLocation);
      if( location == undefined ){
        throw new Error("Could not find the location");
      }
      const url = location.secondUrl

      if ( franchise?.ketsal_url == undefined)
        throw new Error("Missing a service URL");

      const response = await fetch(franchise.ketsal_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: url }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      setKeywords( data.keywords.replace(/^"|"$/g, '').split(', ') )
      setIsOpen(true);
      setLoading(false)

    } catch (error: any) {
      Modal.error({
        title: 'Error',
        content: error.message,
      });

      setLoading(false)
    }
  }

    var preferencesInit = {
        cpaGuard: {
            Email: false, 
            SMS: false,
        },
        keywordGuard: {
            Email: false, 
            SMS: false,
        },
        creativeBoost: {
            Email: false, 
            SMS: false,
        },
        budgetBoost: {
            Email: false, 
            SMS: false,
        },
        audienceCleanup: {
            Email: false, 
            SMS: false,
        },
        keywordClranup: {
            Email: false, 
            SMS: false,
        },
        geoCleanup: {
            Email: false, 
            SMS: false,
        },
        domainCleanup: {
            Email: false, 
            SMS: false,
        },
    }

    const [preferences, setPreferences] = useState(preferencesInit);
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);
    
    const handleChange = (property:any, index:any) => {
        setPreferences((prevPreferences: any) => ({
          ...prevPreferences,
          [index]: {
            ...prevPreferences[index],
            [property]: !prevPreferences[index][property],
          },
        }));

        // setConfigOptions()
    };

    const whiteLabel = useSelector( selectWhiteLabel );

  // return (
  //   <div>
  //       <div className="flex flex-wrap">
  //         <div className="basis-6/12 ">
  //           <h1 className="text-2xl mb-5">Keyword helper</h1>
  //         </div>

  //         <div className="basis-6/12 text-right">
  //           <button
  //             className={`bg-white border-[1px] w-28 cursor-pointer border-[--color-primary] font-bold text-[--color-primary] border-solid rounded-[4px] text-center h-14 hover:opacity-[1]`}
  //             onClick={scrapeKeywords}
  //           >
  //             ANALYZE
  //           </button>
  //         </div>


  //         <div className="locations-container flex flex-wrap basis-full mt-4">
  //           <RadioGroup
  //             style={{ width: "100%", flexDirection: "row" }}
  //             onChange={locationChange}
  //             value={selectedLocation}
  //           >

  //             {franchiseLocations.map((location) => (
  //               <LocationCard
  //                 key={`location card: ${location.id}`}
  //                 location={location}
  //                 canEdit={false}
  //                 defaultChecked={franchiseLocations.length == 1}
  //                 disableTooltip={true}
  //               />
  //             ))}
  //           </RadioGroup>
  //         </div>

  //         {
  //           (franchiseLocations.filter(item => item.id === selectedLocation)[0] != null) && (
  //             <KeywordSideBard keyWords={keywords} isOpen={open} closeFunc={handleModalClose} page={franchiseLocations.filter(item => item.id === selectedLocation)[0].secondUrl} />
  //           )
  //         }
          
          
  //         <Backdrop
  //           sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //           open={loading}
  //         >
  //           <LoaderIcon className="loader-icon" />
  //           {/* <CircularProgress color="inherit" /> */}
  //         </Backdrop>
  //       </div>
  //   </div>
  // );

  return (
    <div className="w-[90%] res:w-full">
        <InfoSideBar isOpen={ infoModalState } title={ infoModalTitle }>
            <div className={`container`} style={{flexGrow: 1}}>
                <Row>
                    <Col span={24} className={`text-center mb-10`}>
                        { infoModalContent }
                    </Col>
                </Row>

                <Form
                    form={ settingsForm }
                    onFinish={ handleSettingsSave }
                    autoComplete="off"
                    className="container-form"
                    layout="vertical"
                >
                    <Row>
                        <Col span={14} offset={5}>
                            { editFormFields.map((field:EditFormField) => {
                                return (
                                    <Row key={field.name}>
                                        <Col span={12}>
                                            <Form.Item name={field.name} label={field?.label}>
                                                { field.type === 'input' ?
                                                    <Input
                                                        name={field.name}
                                                        className={styles['filter-input']}
                                                        type={ 'number' }
                                                        style={{ width: '100%' }}
                                                        disabled={isUseDefaultChecked(`${field.name}_use_default`)}
                                                    />
                                                    : field.type === 'frequency' ?
                                                        <Select
                                                            showArrow
                                                            style={{ borderRadius: "4px" }}
                                                            disabled={isUseDefaultChecked(`${field.name}_use_default`)}
                                                            options={[
                                                                {value: 'weekly', label: 'Weekly'},
                                                                {value: 'monthly', label: 'Monthly'}
                                                            ]}
                                                        /> : ''
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item style={{ rowGap:0 }}>
                                                <Checkbox
                                                    name={ `${field.name}_use_default` }
                                                    className={`mt-9 ml-3`}
                                                    checked={ isUseDefaultChecked(`${field.name}_use_default`) }
                                                    onChange={ handleUseDefaultChange }
                                                /> <label>{ 'Use Default' }</label>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }) }
                        </Col>
                    </Row>

                    <Row>
                        <Col span={14} offset={5}>
                            <Form.Item>
                                <Button
                                    type={ 'primary' }
                                    htmlType={ 'submit' }
                                    className={'primary-button pointer-hover'}
                                    style={{ backgroundColor: whiteLabel.primaryColor, maxWidth:'100px', margin:'auto'}}
                                >SAVE</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </InfoSideBar>
        <div className="mb-[40px] flex items-center">
            <h1 className="text-2xl">Your optimizations</h1>
            <CustomTooltip
                open={showAlwaysOnTooltip}
                onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                title="Enable native optimzations to run automatically. Depending on your account type some of these could be set by your brand on your behalf."
                placement="right-start"
                arrow
            >
            <InfoCircleOutlined
              style={{ color: "#424242", fontSize: "20px", marginLeft: '15px', marginRight: "20px", marginBottom: "10px" }}
            />
          </CustomTooltip>
        </div>

        <div className="grid grid-cols-2 res:grid-cols-2 gap-4">


            <div className="mb-[20px] relative">
                <h2 className="font-semibold text-[19px] mb-[19px]">CPA guard</h2>
                <SettingsIcon
                    onClick={() => {
                        handleSettingsClick(
                          'CPA guard',
                          'This rule helps control your CPA and maximize budget efficiency by setting an upper threshold to adjust costs when CPAs are high. However, it may limit ad delivery and cause underspend, especially if spend pacing is slow. Use a higher CPA threshold when maximizing spend is key, and a lower threshold for stable CPA management.',
                          'cpa_guard'
                        );
                    }}
                    className={`res:ml-0 settings-icon cursor-pointer absolute top-0 right-0`}
                    style={{ color: whiteLabel.primaryColor }}
                />
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                              name={'cpa_guard'}
                              marginLeft="0px"
                              checked={ isOptOptionEnabled('cpa_guard') }
                              onChange={ () => handleOptToggleChange('cpa_guard') }
                              switchColor={isOptOptionEnabled('cpa_guard') ? whiteLabel.primaryColor : '#000000' }
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Sets a target CPA when the CPA goes above a certain threshold, then removes it if pacing
                            falls below a certain threshold.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px] relative">
                <h2 className="font-semibold text-[19px] mb-[19px]">Keyword guard</h2>
                <SettingsIcon
                    onClick={() => {
                        handleSettingsClick(
                          'Keyword guard',
                          'This rule boosts campaign efficiency by automatically adding negative keywords for high-traffic, low-converting terms, reducing wasted ad spend and lowering CPA. However, it may limit reach, especially when using broad or experimental keywords. Enable it to cut costs on irrelevant terms but consider disabling it during growth phases to explore new leads.',
                          'keyword_guard'
                        );
                    }}
                    className={`res:ml-0 settings-icon cursor-pointer absolute top-0 right-0`}/>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={ isOptOptionEnabled('keyword_guard') }
                                onChange={ () => handleOptToggleChange('keyword_guard') }
                                switchColor={isOptOptionEnabled('keyword_guard') ? whiteLabel.primaryColor : '#000000' }
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Adds negative keywords for current keywords with high traffic but low conversions.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px] relative">
                <h2 className="font-semibold text-[19px] mb-[19px]">Creative boost</h2>
                <SettingsIcon
                    onClick={() => {
                        handleSettingsClick(
                          'Creative boost',
                          'This rule boosts performance by increasing visibility for high-CTR assets, leveraging creatives that engage your audience. However, it may limit budget for testing new assets. Enable it for stable, high-performing creatives, and disable it when experimenting with new variations or gathering broader data.',
                          'creative_boost'
                        );
                    }}
                    className={`res:ml-0 settings-icon cursor-pointer absolute top-0 right-0`}/>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={ isOptOptionEnabled('creative_boost') }
                                onChange={ () => handleOptToggleChange('creative_boost') }
                                switchColor={isOptOptionEnabled('creative_boost') ? whiteLabel.primaryColor : '#000000' }
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Promotes the share of assets with low impressions but high CTR.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px] relative">
                <h2 className="font-semibold text-[19px] mb-[19px]">Budget boost</h2>
                <SettingsIcon
                    onClick={() => {
                        handleSettingsClick(
                          'Budget boost',
                          'This rule optimizes bids when your daily budget is below the average CPC, aiming to maximize clicks and traffic volume despite limited funds. However, it may prioritize quantity over quality, potentially increasing CPA. Enable it to boost traffic during low budgets but disable it if your focus is on high-quality leads or conversions.',
                          'budget_boost'
                        );
                    }}
                    className={`res:ml-0 settings-icon cursor-pointer absolute top-0 right-0`}/>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={ isOptOptionEnabled('budget_boost') }
                                onChange={ () => handleOptToggleChange('budget_boost') }
                                switchColor={isOptOptionEnabled('budget_boost') ? whiteLabel.primaryColor : '#000000' }
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Adjusts bid strategy towards clicks if the daily budget falls below the average CPC.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px] relative">
                <h2 className="font-semibold text-[19px] mb-[19px]">Keyword cleanup</h2>
                <SettingsIcon
                    onClick={() => {
                        handleSettingsClick(
                          'Keyword cleanup',
                          'This rule improves budget efficiency by removing keywords with a poor cost-to-CPA ratio, focusing spend on better-performing terms. However, it may reduce keyword variety, especially during testing phases. Enable it for campaigns with many low-converting keywords or budget issues, but use cautiously if youu are exploring new keywords or expanding reach.',
                          'keyword_cleanup'
                        );
                    }}
                    className={`res:ml-0 settings-icon cursor-pointer absolute top-0 right-0`}/>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={ isOptOptionEnabled('keyword_cleanup') }
                                onChange={ () => handleOptToggleChange('keyword_cleanup') }
                                switchColor={isOptOptionEnabled('keyword_cleanup') ? whiteLabel.primaryColor : '#000000' }
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Periodically removes keywords with a cost-to-CPA ratio below a certain threshold.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px] relative">
                <h2 className="font-semibold text-[19px] mb-[19px]">Geo cleanup</h2>
                <SettingsIcon
                    onClick={() => {
                        handleSettingsClick(
                          'Geo cleanup',
                          'This rule boosts efficiency by removing geos with a poor cost-to-CPA ratio, focusing the budget on high-converting areas. However, it may limit geographic reach and potential market discovery. Enable it for consistently low-performing regions but disable it if you are exploring new markets or targeting a diverse audience.',
                          'geo_cleanup'
                        );
                    }}
                    className={`res:ml-0 settings-icon cursor-pointer absolute top-0 right-0`}/>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={ isOptOptionEnabled('geo_cleanup') }
                                onChange={ () => handleOptToggleChange('geo_cleanup') }
                                switchColor={isOptOptionEnabled('geo_cleanup') ? whiteLabel.primaryColor : '#000000' }

                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Periodically removes geos with a cost-to-CPA ratio below a certain threshold.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px] relative">
                <h2 className="font-semibold text-[19px] mb-[19px]">Domain cleanup</h2>
                <SettingsIcon
                    onClick={() => {
                        handleSettingsClick(
                          'Domain cleanup',
                          'This rule improves display ad efficiency by removing domains with high impressions but low clicks, focusing the budget on better-engaging placements. However, it may limit reach, especially if exploring new placements or broad visibility. Enable it for low-CTR domains to boost efficiency, but disable it if prioritizing brand awareness or testing new segments.',
                          'domainCleanup'
                        );
                    }}
                    className={`res:ml-0 settings-icon cursor-pointer absolute top-0 right-0`}/>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                checked={ isOptOptionEnabled('domain_cleanup') }
                                onChange={ () => handleOptToggleChange('domain_cleanup') }
                                switchColor={isOptOptionEnabled('domain_cleanup') ? whiteLabel.primaryColor : '#000000' }
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>Periodically removes domains with high impressions but low clicks.</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
  );
};
