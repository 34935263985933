import { NewCampaignCustomPackage } from "../../../models/CampaignPackage";
import "./ReviewAndPay.scss";

interface ReviewAndPaySidebarPackageCardProps {
  newCampaignCustomPackage: NewCampaignCustomPackage;
}

const ReviewAndPaySidebarPackageCard = ({
  newCampaignCustomPackage,
}: ReviewAndPaySidebarPackageCardProps) => {
  return (
    <div className="review-pay-channel-card">
      <p className="review-pay-channel-card-title">{newCampaignCustomPackage.customPackage.title}</p>
      <p className="review-pay-channel-card-content">Starts {newCampaignCustomPackage?.startDate?.toLocaleDateString()}</p>
    </div>
  );
};

export default ReviewAndPaySidebarPackageCard;
