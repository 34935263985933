import apisauce from "apisauce";

const privateRequest = ({
  api,
  method,
  endpoint,
  params,
  options,
  contentType = "application/json",
}) => {
  const token = localStorage.getItem("idToken");
  const headers = options ? options.headers : {};
  return api[method](endpoint, params, {
    ...options,
    headers: {
      ...headers,
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
    },
  });
};

const privateGet = (api, endpoint, params, options) =>
  privateRequest({ api, method: "get", endpoint, params, options });

const addQueryParams = (endpoint, params) => {
  return Object.keys(params)
    .reduce(
      (ant, paramKey) =>
        params[paramKey] ? `${ant}${paramKey}=${params[paramKey]}&` : ant,
      `${endpoint}?`
    )
    .slice(0, -1);
};

// const privatePost = (
//   api,
//   endpoint,
//   params,
//   options,
//   contentType = "application/json"
// ) =>
//   privateRequest({
//     api,
//     method: "post",
//     endpoint,
//     params,
//     options,
//     contentType,
//   });

const privatePut = (
  api,
  endpoint,
  params,
  options,
  contentType = "application/json"
) =>
  privateRequest({
    api,
    method: "put",
    endpoint,
    params,
    options,
    contentType,
  });

const create = (baseURL = "") => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    timeout: 1000000,
  });

  const naviMonitor = (response) =>
    console.log("API MONITOR ACTIVITY:", response);
  api.addMonitor(naviMonitor);

  const register = ({ firstName, lastName, email, type, extFranchiseId }) =>
    api.post("/users", {
      firstName,
      lastName,
      email,
      // billingAddress,
      type,
      extFranchiseId: extFranchiseId ?? undefined,
    });

  const getAccount = () => privateGet(api, addQueryParams("/user", {}));

  const updateAccount = (props) => privatePut(api, "/user", { ...props });

  const validateEmailAndCode = ({email,code}) => 
    api.post("/user/validate-email", {
      email,
      code
    });

  return {
    getAccount,
    register,
    updateAccount,
    validateEmailAndCode
  };
};

const Api = create(process.env.REACT_APP_PLATFORM_API);

export default Api;
