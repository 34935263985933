import "./CalendarDayModal.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Event } from "../../../../models/Event";
import Link from "@mui/material/Link";
import { AdCampaign } from "../../../../models/AdCampaign";

interface CalendarDayEventTableProps {
  events: Event[];
  selectCalendarEvent: (calendarEvent: AdCampaign | Event) => void;
}

export const CalendarDayEventTable = ({
  events,
  selectCalendarEvent,
}: CalendarDayEventTableProps): JSX.Element => {
  return (
    <Table aria-label="simple table" style={{ width: "unset" }}>
      <TableBody>
        {events.map((event) => (
          <TableRow
            key={`event for day table:${event.id}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>{event.title}</TableCell>
            <TableCell>
              <Link onClick={() => selectCalendarEvent(event)}>
                View Details
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
