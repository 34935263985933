
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ReactComponent as DirectMailIcon } from "../../../assets/icons/DirectMailIcon.svg";
import { ReactComponent as DigitalIcon } from "../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as OttIcon } from "../../../assets/icons/oot-icon.svg";
import { ReactComponent as NativeIcon } from "../../../assets/icons/native-icon.svg";
import { ReactComponent as CtvOttIcon } from "../../../assets/icons/ctv-ott-icon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/EmailIcon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google-icon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../services";
import {
  selectPreSelectedTemplates,
  selectSelectedChannels,
  selectSelectedTemplates,
  selectHasFranchiseSetSpend,
  setSelectedChannels,
  setSelectedTemplates,
  setTemplateTitle,
  setTemplateChannel,
  selectSelectedLocation,
  //setAdjustedSpendPercent,
  setSetSpend,
  setIsSetSpend,
  selectIsBudgetModeFixed,
} from "../../../store/campaignForm/CampaignForm";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/Routes";
import { ChannelType } from "../../../models/Channel";
import { Button } from "antd";
import { CreativeTemplate } from "../../../models/CreativeTemplate";
import { selectCreativeTemplates, selectTemplatesForEachChannel, fetchChannelImages } from "../../../store/CreativeTemplates/CreativeTemplateStore";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import CustomTooltip from "../../tooltip/tooltip";

interface ChannelCardProps {
  channel: ChannelType;
  setShowMobileSidebar: (showMobileSidebar: boolean) => void;
  isChannelAlreadyAdded: boolean;
  disableMedia: boolean;
}

const ChannelCard = ({
  channel,
  setShowMobileSidebar,
  isChannelAlreadyAdded,
  disableMedia,
}: ChannelCardProps) => {
  const {
    id,
    title,
    comingSoon,
    investmentInDollarsPerTarget,
    durationWeeks,
    durationHours,
    frequency,
    setSpend,
    tooltip,
    sortOrder,
    
  } = channel;

  const hasFranchiseSetSpend = useSelector(selectHasFranchiseSetSpend);

  const isComingSoon = () => {
    return channel.comingSoon;
  }
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [mobileTooltipIsOpen, setMobileTooltipIsOpen] = useState(false);
  // const [shouldDisplay, setShouldDisplay] = useState(true);
  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const templates: CreativeTemplate[] = useSelector(selectCreativeTemplates);
  const templatesForEachChannel: CreativeTemplate[] = useSelector(selectTemplatesForEachChannel);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [channelTemplates, setChannelTemplates] = useState<CreativeTemplate[]>(
    []
  );
  const selectedTemplates: CreativeTemplate[] = useSelector(
    selectSelectedTemplates
  );
  const preSelectedTemplates: CreativeTemplate[] = useSelector(
    selectPreSelectedTemplates
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMonthlyBudgetFixed = useSelector(selectIsBudgetModeFixed);

  const addSelectedChannel = async() => {

    //Store the channelTemplates from store in a local variable.
    let availableTemplates = channelTemplates;

    //If there are no available templates at the moment, try fetching again the media templates for this channel.
    //There could be no templates for two possible reasons:
    //The channel has no templates saved yet.
    //The function to fetch has not been called yet. As of this implementation that function is only called when opening
    //the channeltemplatedrawer object.
    if( availableTemplates.length === 0 ){
      //No templates for this channel. Fetch the templates and store in the loca variable.
      const fetchedChannelTemplates = await services.creativeTemplateService.getChannelImages(
        channel.id,
        selectedLocation?.name + "\n" + selectedLocation?.proximity,
      );
      //setChannelTemplates(fetchedChannelTemplates);
      availableTemplates = fetchedChannelTemplates;
    }

    if (tooltipIsOpen) {
      setTooltipIsOpen(false);
    }
    if (mobileTooltipIsOpen) setTooltipIsOpen(false);
    if (channel.setSpend){
      dispatch(setSetSpend(channel.setSpend));
      dispatch(setIsSetSpend(true));
    } 
      //if (channel.setSpend) dispatch(setAdjustedSpendPercent(channel.setSpend));


    dispatch(
      setSelectedChannels([
        ...selectedChannels,
        {
          id,
          title,
          comingSoon,
          investmentInDollarsPerTarget,
          durationHours,
          durationWeeks,
          frequency,
          setSpend,
          tooltip,
          sortOrder,
        },
      ])
    );

    /*
      Updated these conditionals to use the variable availableTemplates instead
      of the channelTemplates from state. The functionality is the same as before, but
      a new conditional is added for when no template has been selected yet and the channel
      has 2 or more templates.
    */
    if (availableTemplates.length === 1) {
      //if this channel has only one template
      if (selectedChannels.length === 0) {
        //and there are no selected channels, we get rid of any other selected templates chosen for a package
        dispatch(setSelectedTemplates([availableTemplates[0]]));
      } else {
        //Otherwise, just add it to current selection
        dispatch(
          setSelectedTemplates([...selectedTemplates, availableTemplates[0]])
        );
      }
    } else if (
      availableTemplates.length >= 2 &&
      preSelectedTemplates.filter((template) => template.channelId === id)
        .length === 1
    ) {
      //if there are multiple templates to choose from and there is a preselected template
      if (selectedChannels.length === 0) {
        //and there are no selected channels, we get rid of any other selected templates chosen for a package
        dispatch(
          setSelectedTemplates([
            preSelectedTemplates.filter(
              (template) => template.channelId === id
            )[0],
          ])
        );
      } else {
        //Otherwise, just add it to current selection
        dispatch(
          setSelectedTemplates([
            ...selectedTemplates,
            preSelectedTemplates.filter(
              (template) => template.channelId === id
            )[0],
          ])
        );
      }
    }else if(availableTemplates.length >= 2){
      //If the channel has more than one template and no preselected templates are found yet.
      if (selectedChannels.length === 0) {
        //and there are no selected channels, we get rid of any other selected templates chosen for a package
        dispatch(setSelectedTemplates([availableTemplates[0]]));
      } else {
        //Otherwise, just add it to current selection
        dispatch(
          setSelectedTemplates([...selectedTemplates, availableTemplates[0]])
        );
      }
    }
  };

  useEffect(() => {
    setChannelTemplates(templatesForEachChannel.filter(template => template.channelId === channel.id));
  }, [templatesForEachChannel]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeSelectedChannel = () => {
    let tempSelectedChannels: ChannelType[] = [];
    selectedChannels.forEach((selectedChannel) => {
      if (selectedChannel.title !== channel.title) {
        tempSelectedChannels.push(selectedChannel);
      }
    });
    dispatch(setSelectedChannels(tempSelectedChannels));
    
    if(channel.setSpend) dispatch(setSetSpend(null));

    //Commented the following lines of code.
    //When removing a channel don't remove the selectedTemplates.
    //This allows to "preserve" the previously selected template when re-adding the channel
    //in case in was removed manually by the user.

    /*const tempSelectedTemplates = selectedTemplates.filter(
      (selectedTemplate) => selectedTemplate.channelId !== channel.id
    );
    dispatch(setSelectedTemplates(tempSelectedTemplates));*/
  };
  
  const whiteLabel = useSelector(selectWhiteLabel);

  return (
    <>
      <div className="channel tablet-and-descktop-channel">
        <div style={{ width: "45%" }}>
          <div className="channel-title">
            {channel.title === "Print" ? (
              <DirectMailIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Pre-Roll" ? (
              <CtvOttIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "OTT" ? (
              <OttIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Digital" ? (
              <NativeIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "CTV & OTT" ? (
              <OttIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Google" ? (
              <GoogleIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Meta" ? (
              <NativeIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Social" ? (
              <NativeIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Email" ? (
              <EmailIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Programmatic" ? (
              <DigitalIcon style={{ marginRight: "15px" }} />
            ) : channel.title === "Display" ? (
              <DigitalIcon style={{ marginRight: "15px" }} />  
            ) : (
              <DigitalIcon style={{ marginRight: "15px" }} />
            )}
            {title}
          </div>
        </div>

        <div>

          {isMonthlyBudgetFixed ?
          <div className="channel-data">
            <div className="channel-data-element">
              <div className="channel-data-title">CPM:</div>$
              {channel.investmentInDollarsPerTarget}
            </div>
            <div className="channel-data-element">
              <div className="channel-data-title">Cycle: monthly</div>
            </div>
          </div>
          : (
          <div className="channel-data mt-5 mb-5">
            <div className="channel-data-element">
              <div className="channel-data-title">{"Investment:"}</div>$
              {setSpend != null ? setSpend : channel.investmentInDollarsPerTarget + " / target" }
            </div>
            <div className="channel-data-element">
              <div className="channel-data-title">Duration:</div>
              {durationWeeks}
            </div>
          </div>
          )}

          <div
              className="channel-final-section"
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                marginTop: "10px",
                float: "right",
              }}
          >
          { ( isChannelAlreadyAdded ) ? (
            <Button
              className="inverted-general-button pointer-hover max-w-[149px]"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {removeSelectedChannel()}}
            >
              REMOVE
            </Button>
          ) : (
            <button
              className="channel-button pointer-hover"
              disabled={isComingSoon()}
              onClick={addSelectedChannel}
            >
              {isComingSoon() ? "Coming Soon" : "ADD"}
            </button>
          )}

        { disableMedia ? (
            <Button 
            
              className={`media-button pointer-hover ${ disableMedia ? "pointer-events-none opacity-30" : "" }  `}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                dispatch(setTemplateChannel(channel));
                dispatch(setTemplateTitle(title));
              }}
            >
              MEDIA
            </Button>
          ) :  (
            <Button
              className="media-button pointer-hover"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                dispatch(setTemplateChannel(channel));
                dispatch(setTemplateTitle(title));
              }}
            >
              MEDIA
            </Button>
          ) }

            <CustomTooltip
              open={tooltipIsOpen}
              onMouseEnter={() => setTooltipIsOpen(true)}
              onMouseLeave={() => setTooltipIsOpen(false)}
              title={(<><p> {channel.tooltip} </p></>)}
              arrow
            >
              <InfoCircleOutlined
                style={{
                  color: "#424242",
                  fontSize: "25px",
                  marginLeft: "25px",
                }}
              />
            </CustomTooltip>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div
        className="channel mobile-channel res:!items-start"
        style={{ flexDirection: "column" }}
      >
        <div
          className="channel-title res:!justify-start"
          style={{ top: "20px", width: "100%", justifyContent: "center" }}
        >
          {channel.title === "Print" ? (
            <DirectMailIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : channel.title === "OTT" ? (
            <OttIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : channel.title === "Digital" ? (
            <NativeIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : channel.title === "CTV & OTT" ? (
            <CtvOttIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : channel.title === "Google" ? (
            <GoogleIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : channel.title === "Email" ? (
            <EmailIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : channel.title === "Display" ? (
            <DigitalIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : (
            <DigitalIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          )}
          {title}
          <CustomTooltip
            open={mobileTooltipIsOpen}
            className="res:hidden"
            onClick={() => setMobileTooltipIsOpen(!mobileTooltipIsOpen)}
            title={ (<><p> {channel.tooltip} </p></>) }
            arrow
          >
            <InfoCircleOutlined
              style={{ color: "#424242", fontSize: "25px", marginLeft: "25px" }}
            />
          </CustomTooltip>
        </div>

          {isMonthlyBudgetFixed ?
          <div className="channel-data">
            <div className="channel-data-element">
              <div className="channel-data-title">CPM:</div>$
              {channel.investmentInDollarsPerTarget}
            </div>
            <div className="channel-data-element">
              <div className="channel-data-title">Cycle: monthly</div>
            </div>
          </div>
          : (
          <div className="channel-data mt-5 mb-5">
            <div className="channel-data-element">
              <div className="channel-data-title">{"Investment:"}</div>$
              {setSpend != null ? setSpend : channel.investmentInDollarsPerTarget + " / target" }
            </div>
            <div className="channel-data-element">
              <div className="channel-data-title">Duration:</div>
              {durationWeeks}
            </div>
          </div>
          )}

        {channelTemplates.length === 1 ||
          (channelTemplates.length >= 2 &&
            preSelectedTemplates.filter((template) => template.channelId === id)
              .length === 1) ? (
          <Button
            className="inverted-general-button pointer-hover"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
            onClick={() => {
              dispatch(setTemplateChannel(channel));
              dispatch(setTemplateTitle(title));
            }}
          >
            MEDIA
          </Button>
        ) : channelTemplates.length >= 2 ? (
          <Button
            className="inverted-general-button pointer-hover"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            onClick={() => {
              dispatch(setTemplateChannel(channel));
              dispatch(setTemplateTitle(title));
            }}
          >
            MEDIA
          </Button>
        ) : (
          <></>
        )}

        <div>
          <div
            className="channel-final-section"
            style={{ display: "flex", alignItems: "center", marginTop: "12px" }}
          >

            { ( isChannelAlreadyAdded ) ? (
              <Button
                className="inverted-general-button pointer-hover"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {removeSelectedChannel()}}
              >
                REMOVE
              </Button>
            ) : (
              <button
                className="channel-button pointer-hover res:w-[200px]"
                disabled={isComingSoon()}
                onClick={() => {
                  addSelectedChannel();
                  setShowMobileSidebar(true);
                }}
              >
                {isComingSoon() ? "Coming Soon" : "ADD"}
              </button>
            ) }
            <CustomTooltip

              open={tooltipIsOpen}
              className="hidden res:block"
              onMouseEnter={() => setTooltipIsOpen(true)}
              onMouseLeave={() => setTooltipIsOpen(false)}
              title={(<><p> {channel.tooltip} </p></>)}
              arrow
            >
              <InfoCircleOutlined
                style={{
                  color: "#424242",
                  fontSize: "25px",
                  marginLeft: "25px",
                }}
              />
            </CustomTooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ChannelCard);
