import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../models/Account";
import services from "../../services";
import {GoogleAdsCampaignRowSchema} from "../../services/googleAdsCampaignService"
import { RootState } from "../index";

export const googleAdsCampaignSlice = createSlice({
  name: "googleAdsCampaign",
  initialState: {
    googleAdsCampaignErrors: [] as String[] | null,
    importGoogleAdsCampaignsResult: null as String | null,
  },
  reducers: {
    setGoogleAdsCampaignErrors: (
      state,
      { payload: googleAdsCampaignErrors }: PayloadAction<String[] | null>
    ) => {
      state.googleAdsCampaignErrors = googleAdsCampaignErrors;
    },
  },
  extraReducers: (builder) => {
    
    builder.addCase(importGoogleAdsCampaigns.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.googleAdsCampaignErrors = action.payload!;
      }
    });
    builder.addCase(importGoogleAdsCampaigns.fulfilled, (state, action) => {
      let importedGoogleAdsCampaigns = action.payload;
      state.importGoogleAdsCampaignsResult = `Successfully imported the google ads campaigns.`;
    });
  },
});

export const importGoogleAdsCampaigns = createAsyncThunk<
boolean, 
{
  googleAdsCampaignSchemaArray: GoogleAdsCampaignRowSchema[]
},
{
  rejectValue: String[] //return a list of errors on failure
}
>(
"googleAdsCampaigns/import",
async (googleAdsCampaignSchemaArray, { rejectWithValue }) => {
  try {
    console.log('printing the google ads campaigns')
    console.log(googleAdsCampaignSchemaArray);
    let result = await services.googleAdsCampaignService.importGoogleAdsCampaigns(googleAdsCampaignSchemaArray.googleAdsCampaignSchemaArray);
    return result;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    let formattedErrors = [] as String[];
    let errorData = error.response.data;

    //This is the validation error returned by the Platform API using the tsoa library.
    if (errorData.message == "Validation Failed") {
      //This returns a "details" object which contains both the row number and attribute, as well as 
      //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
      let details = errorData.details;
      Object.keys(details).forEach(key => {
        //The key contains both the row# and the failed attribute
        //The attribute "message" contains the error message.
        //We attempt to format these in a single string.
        let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
        let colName = key.substring(key.indexOf(".") + 4);
        let rowNumberInt = parseInt(rowNumber) + 1;

        let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
        formattedErrors.push(errorMessage);
      })
    } else if (errorData.message == "Entity Not Found") {
      formattedErrors.push(errorData.details);
    } else if (errorData.message == "Could Not Process Request") {
      formattedErrors.push(errorData.details);
    }else{
        //Generic error
        formattedErrors.push('The service is not available at the moment');
    }

    return rejectWithValue(formattedErrors);

  }

}
); 

export const googleAdsCampaignErrors = (state: RootState): String[] | null =>
  state.googleAdsCampaigns.googleAdsCampaignErrors;

export default googleAdsCampaignSlice.reducer;

