import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomReportDate, CustomReportChannel } from "../../models/CustomReport";
import services from "../../services";
import { CustomReportRowSchema } from "../../services/customReportService";
import { RootState } from "../index";

//Creates multiple custom reports from a csv or xlsx file
// @params
// customReportSchemaArray (An array of CustomReportRowSchema objects representing the rows in the file)
export const importCustomReports = createAsyncThunk<
  CustomReportDate[], //we expect this to return a list of locations
  {
    customReportSchemaArray: CustomReportRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "custom-reports/import",
  async (customReportSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.customReportService.importCustomReports(customReportSchemaArray.customReportSchemaArray);
      return result;
    } catch (error: any) {
      //console.log( error );
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message == "Validation Failed") {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;

          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message == "Entity Not Found") {
        formattedErrors.push(errorData.details);
      } else if (errorData.message == "Could Not Process Request") {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);

    }

  }
);

export const getCustomChannels = createAsyncThunk(
  "custom-reports/custom-channels",
  async () =>{
    return await services.customReportService.getCustomChannels();
  }
);



export const customReportSlice = createSlice({
  name: "customReport",
  initialState: {
    reportErrors: [] as String[] | null,
    importReportsResult: null as String | null,
    customChannels: [] as CustomReportChannel[]
  },
  reducers: {
    setReportErrors: (
      state,
      { payload: reportErrors }: PayloadAction<String[] | null>
    ) => {
      state.reportErrors = reportErrors;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(importCustomReports.fulfilled, (state, action) => {
      let importedReports = action.payload;
      state.importReportsResult = `Successfully imported ${importedReports.length} rows.`;
    });

    builder.addCase(importCustomReports.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.reportErrors = action.payload!;
      }
    });

    builder.addCase(getCustomChannels.fulfilled, (state, action) => {
      state.customChannels = action.payload;
    });
  },
});

export const { setReportErrors } = customReportSlice.actions;

export const reportErrors = (state: RootState): String[] | null =>
  state.customReport.reportErrors

export const importReportsResult = (state: RootState): String | null =>
  state.customReport.importReportsResult

export const selectCustomChannels = (state: RootState): CustomReportChannel[] => state.customReport.customChannels

export default customReportSlice.reducer;
