import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { useAuth } from "../../../hooks/useAuth";
import FooterHelp from "../FooterHelp";
import { UserRole } from "../../../models/User";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { useSelector } from "react-redux";
import './styles.css';

const errorMessageMapping: ErrorMessage = {
  general: "We couldn't process this request, please check all fields and try again",
  emailExist: "This email already exists",
  emailNotRecognized: "We don’t recognize this email, use all lower cases if you aren't already",
  emailNotInRecords: "We couldn’t find this code in our records"
};

const SignUp = () => {
  const { register, createUser, setError, loading, error } = useAuth();
  const [formCreateUser] = Form.useForm();
  const [dataForm, setDataForm] = useState({
    firstname: null,
    lastname: null,
    email: null,
    account_code: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  //Gets the current Whitelabel information.
  const whiteLabel = useSelector( selectWhiteLabel );
  
  const onFinish = (values: any) => {
    console.log(values);
    if (
      !values.firstname ||
      !values.lastname ||
      !values.email ||
      !values.password ||
      !values.account_code
    ) {
      setError("fields are missing");
    } else {
      values.domain = whiteLabel.domain;
      values.appName = whiteLabel.metaTitle;
      values.mailFrom = whiteLabel.mailFrom;
      values.sendgridId = whiteLabel.accountRegistrationTemplate_id;

      //Before even attempting to store in cognito, run the required validations.
      //On success, procceed with the registration.
      register(values);
      setDataForm(values);
      setSubmitted(true);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!error && submitted && !loading) {
      createUser({
        firstName: dataForm.firstname,
        lastName: dataForm.lastname,
        email: dataForm.email,
        // billingAddress: dataForm.account_code,
        type: UserRole.Franchisee,
        account_code: dataForm.account_code,
      });
      formCreateUser.resetFields();
      console.log("Register Complete");
      navigate("/login");
    } else if (error && !loading) {
      setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, submitted]);

  useEffect(() => {
    if (error) {
      console.log("error", error);
    }
  }, [error]);

  const handleOnBack = () => {
    setError(null);
    navigate("/login");
  };

  return (
    <div className="container-auth res:w-full">
      <div>
        <IconArrowLeft className="cursor-pointer primary-icon" onClick={handleOnBack} />
        <div className="text-header">Register</div>
        <Form
          name="basic"
          form={formCreateUser}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="container-form"
          layout="vertical"
        >
          <Input.Group compact>
            <Form.Item
              label="First name"
              name="firstname"
              style={{ width: "46%" }}
              rules={[{ required: true }]}
            >
              <Input placeholder="Name" className={error && "error-input"} />
            </Form.Item>

            <Form.Item
              label="Last name"
              name="lastname"
              style={{ marginLeft: "8%", width: "46%" }}
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Last name"
                className={error && "error-input"}
              />
            </Form.Item>
          </Input.Group>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Not a valid email",
                required: true,
              },
            ]}
          >
            <Input
              placeholder="Company email"
              className={error && "error-input"}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            style={{ marginBottom: 2 }}
            rules={[{ required: true }]}
          >
            <Input.Password
              placeholder="Enter password"
              className={error && "error-input"}
            />
          </Form.Item>
          <span className="input-leyend">
            Password must be at least 8 characters and contain at least one number
          </span>

          <Form.Item
            label="Account code"
            name="account_code"
            style={{ marginTop: 20, marginBottom: 40 }}
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Company/franchise account code"
              className={error && "error-input"}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="button-register"
              loading={loading}
            >
              REGISTER
              <IconArrowRight style={{ height: 11 }} />
            </Button>
          </Form.Item>
        </Form>

        {error && (
          <div className="error-message">
            {
              error.message === "User already exists" ? errorMessageMapping.emailExist
              : error.message === "Email not Recognized" ? errorMessageMapping.emailNotRecognized
              : error.message === "Code not in Records" ? errorMessageMapping.emailNotInRecords
              : errorMessageMapping.general}
          </div>
        )}
      </div>
      <FooterHelp />
    </div>
  );
};
interface ErrorMessage {
  general: string;
  emailExist: string;
  emailNotRecognized: string;
  emailNotInRecords: string;
}

export default SignUp;
