import { Row, Col, Button, Tooltip } from "antd";
import React from "react";
import { connect, useSelector } from 'react-redux';
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import './FileUploader.css';
import { imageUploaderAction } from "../../../store/Image/ImageStore";
import { RootState } from "../../../store";

interface MediaUploaderProps {
    title: string,
    notifyErrors: Function,
    imageUploader: Function,
    locationErrors: string[] | null,
    importLocationsResult: string | null,
}

interface MediaUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class MediaUploader extends React.Component<MediaUploaderProps, MediaUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<MediaUploaderProps>): void {
        
        if( prevProps.locationErrors !== null){
            if( prevProps.locationErrors !== this.props.locationErrors){
                this.props.notifyErrors( 
                    this.props.locationErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.locationErrors !== null ){
            if( prevProps.importLocationsResult !== this.props.importLocationsResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importLocationsResult);
            }
        }
    }
    
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Verify if the file is not null.
        if (!event.target.files)
            return;
    
        // Verifies if the file is supported (png or jpg)
        let fileType = event.target.files[0].type;
        if (
            fileType === 'image/png' ||
            fileType === 'image/jpeg'
        ) {
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        } else {
            this.setState({
                fileTypeError: "The selected file is not valid. Only PNG and JPG files are supported."
            });
        }
    }
    
    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        let selectedFile = this.state.selectedFile;
        
        if (selectedFile) {
            this.props.imageUploader(selectedFile);
        }
    }

    render(){
        let button;
        if( this.state.selectedFile ){
            button = 
                <div>
                    <div onClick={this.onFileUpload}
                        className="primary-button pointer-hover"
                    >
                        <div className="primary-button-text">UPLOAD</div>
                        <IconArrowRight />
                    </div>
                </div>
        }else{
            button = 
                <div>
                    <input id={"file-uploader-location"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-location"}>
                        <div
                            className="primary-primary-button pointer-hover"
                        >
                            <div className="primary-button-text">SELECT FILE</div>
                        </div>
                    </label>
                </div>
        }
        
        return (
            <div className="file-upload-area">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col span={6}>
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }
                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        imageUploader: (file: File) => {
            // Dispatch the action to handle the image upload
            dispatch(imageUploaderAction(file));
        }
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        locationErrors: state.locations.locationErrors,
        importLocationsResult: state.locations.importLocationsResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaUploader);
