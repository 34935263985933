import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Demographics, DemographicsFranchise, DemographicsAudience } from "../../models/Demographics";
import { RootState } from "../index";
import services from "../../services/"

// Placeholders, here we need to call a real API
export const fetchDemographicsFranchises = createAsyncThunk(
  "demographics/fetchDemographicsFranchises",
  async (): Promise<DemographicsFranchise[]> => {
    const items = await services["franchiseService"].getAll();
    const franchises: DemographicsFranchise[] = [];
    items.forEach((item) => {
      franchises.push({
        id: item.id,
        name: item.name,
        audiences: [],
        dataSource: ''
      });
    });
    return franchises;
  }
);

export const DemographicsSlice = createSlice({
  name: "Demographics",
  initialState: {
    demographics: {
      franchises: [],
      modalContent: "",
      selectedAudience: {name: "", values: [""]},
      selectedFilters: [],
      selectedFranchiseId: "",
    } as Demographics
  },
  reducers: {
    setDemographicsFranchises: (state, { payload: DemographicsFranchises }: PayloadAction<DemographicsFranchise[]>) => {
      state.demographics.franchises = DemographicsFranchises;
    },
    setModalContent: (state, { payload: ModalContent }: PayloadAction<string>) => {
      state.demographics.modalContent = ModalContent;
    },
    setAudience: (state, {payload: selectedAudience}: PayloadAction<DemographicsAudience>) => {
      state.demographics.selectedAudience = selectedAudience;
    },
    addFilter: (state, {payload: newFilter}: PayloadAction<string>) =>{
      state.demographics.selectedFilters.push(newFilter);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDemographicsFranchises.fulfilled, (state, action) => {
      state.demographics.franchises = action.payload;
    });
  },
});

export const selectDemographicsFranchises = (state: RootState): DemographicsFranchise[] => state.demographics.demographics.franchises;
export const selectModalContent = (state: RootState): string => state.demographics.demographics.modalContent;
export const getAudienceSelected = (state: RootState): DemographicsAudience => state.demographics.demographics.selectedAudience;
export const getFiltersSelected = (state: RootState): string[] => state.demographics.demographics.selectedFilters;

 
export const {
  setModalContent,
  setAudience,
  addFilter,
  setDemographicsFranchises,
} = DemographicsSlice.actions;


export default DemographicsSlice.reducer;