import { CustomPackage } from "../models/CustomPackage";
import RestService from "./restService";

export default class CustomPackageService {
  constructor(protected readonly api: RestService) {}

  async getCustomPackages(): Promise<CustomPackage[]> {
    return await this.api.get<CustomPackage[]>("/custom-package");
  }
}
