import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AdCampaign } from "../../../models/AdCampaign";
import { selectAdCampaigns } from "../../../store/AdCampaigns/AdCampaignStore";
import { ReactComponent as DisplayMoreIcon } from "../../../assets/icons/display-more.svg";
import { ReactComponent as IconArrowBack } from "../../../assets/icons/orange-back-arrow.svg";
import "./CampaignHistory.css"
import { CampaignChannel } from "../../../models/CampaignChannel";
import { Button } from "antd";
import { CampaignCustomPackage } from "../../../models/CampaignPackage";

const CampaignHistory: React.FC = () => {
  const [campaignDetail, setCampaignDetail] = useState<AdCampaign | null>(null);
  const [, setDisplayedChannels] = useState<CampaignChannel[]>([]);
  const campaigns: AdCampaign[] = useSelector(selectAdCampaigns);
  const [displayedCampaigns, setDisplayedCampaigns] = useState(campaigns.slice(0,4));

  const renderCampaigns = (campaign: AdCampaign) => {
    let days = 0;
    if(campaign.endDate){
      let tempDays = campaign.endDate.getTime() - campaign.startDate.getTime();
      days = Math.ceil(tempDays / (1000 * 3600 * 24));
    }

    return(
      <div key={campaign.receiptNumber} className="campaign-card res:flex-wrap">
        <div className="res:basis-full campaign-card-column">
            <div><b>Order # {campaign.receiptNumber}</b></div>
            {campaign.endDate !== null &&
              <div><b>Completed:</b> {campaign.endDate?.toLocaleDateString()}</div>
            }
        </div>
        <div className="res:basis-full campaign-card-column">
            <div><b>Total Audience:</b> {campaign.audienceSize}</div>
            {campaign.endDate !== null &&
              <div><b>Duration:</b> {days} days</div>
            }
        </div>
        <div className="res:basis-full campaign-card-column">
            {/* <div><b>Response Rate:</b></div> */}
            <div><b>Total Cost: ${campaign.costInDollars}</b></div>
        </div>
        <div className="campaign-card-column campaign-details res:mt-3"
          onClick={() => {
            if(campaign !== null && campaign.campaignChannels.length > 3){
              const tempArray = campaign.campaignChannels.slice(0,3);
              setDisplayedChannels(tempArray);
            } else {
              setDisplayedChannels(campaign.campaignChannels)
            }
            setCampaignDetail(campaign);

          }}
        >
          Details
        </div>
      </div>
    )
  }

  
  
  // const renderChannel = (channel: CampaignChannel ) => {
  //   return (
  //     <div key={channel.id} className="purchased-channel-card">
  //         <div className="purchased-channel-card-title">{channel.type.title}</div>
  //         <div>
  //             <div>From {channel.startDate.toLocaleDateString()} To </div>
  //             <div><b>Duration:</b> {channel.type.standardDurationTitle}</div>
  //             <div><b>Frequency:</b></div>
  //         </div>
  //     </div>
  //   )
  // };

  // const displayMoreChannels = () => {
  //   if(campaignDetail !== null)
  //   setDisplayedChannels(campaignDetail.campaignChannels)
  // };

  // const displayLessChannels = () => {
  //   if(campaignDetail !== null)
  //   setDisplayedChannels(campaignDetail.campaignChannels.slice(0,3))
  // }

  const displayMoreCampaigns = () => {
    setDisplayedCampaigns(campaigns)
  }

  const displayLessCampaigns = () => {
    setDisplayedCampaigns(campaigns.slice(0,4))
  }

  const renderPurchasedChannels = (channel: CampaignChannel) => {
    let tempEndDate = new Date();
    if (channel.endDate !== undefined && channel.endDate) {
      tempEndDate = channel.endDate;
    } else {
      tempEndDate = new Date(
        channel.startDate?.getFullYear(),
        channel.startDate?.getMonth(),
        channel.startDate?.getDate() +
          channel.type.durationHours / 24
      );
    }

    return (
      <div
        key={`confirmation of purchased channel:${channel.type.id}`}
        className="confirmation-purchased-channel-container"
      >
        <div className="confirmation-purchased-channel-title">
          {channel.type.title}
        </div>
        <div className="confirmation-purchased-channel-dates">
          <b>
            {channel.startDate.toLocaleDateString()} -{" "}
            {tempEndDate.toLocaleDateString()}
          </b>
        </div>
      </div>
    );
  };

  const renderPurchasedPackage = (customPackage: CampaignCustomPackage) => {
    return (
      <div
        key={`confirmation of purchased package:${customPackage.customPackage.id}`}
        className="confirmation-purchased-channel-container"
      >
        <div className="confirmation-purchased-channel-title">
          {customPackage.customPackage.title}
        </div>
        <div className="confirmation-purchased-channel-dates">
          <b>
            {new Date(customPackage.startDate).toLocaleDateString()} -{" "}
            {customPackage.endDate && new Date(customPackage.endDate).toLocaleDateString()}
          </b>
        </div>
      </div>
    );
  };

  return <div className="">
    { campaignDetail === null ?
      <div>
        <h1 className="text-2xl campaign-history-header">Campaign history</h1>
        
        {displayedCampaigns.map(renderCampaigns)}
        {displayedCampaigns.length < campaigns.length &&
          <div className="show-more" onClick={displayMoreCampaigns}>
            <DisplayMoreIcon/>
          </div>
        }
        {displayedCampaigns.length === campaigns.length && displayedCampaigns.length > 4 &&
          <div className="show-more" onClick={displayLessCampaigns}>
            <DisplayMoreIcon style={{transform: 'rotate(180deg)'}}/>
          </div>
        }
      </div>
      :
      <div>
        <h1 className="campaign-history-header res:text-2xl res:!mb-3">Campaign Details</h1>
        <div className="confirmation-order res:text-xl res:mb-4">
          Order No. <u>{campaignDetail.receiptNumber}</u>
        </div>

        <div className="confirmation-data">
          <div className="history-confirmation-card">
            <div>
              <b style={{ fontSize: "20px" }}>Channels Purchased:</b>
              {campaignDetail.campaignChannels.map(renderPurchasedChannels)}
              {campaignDetail.campaignPackages.length > 0 && renderPurchasedPackage(campaignDetail.campaignPackages[0])}
            </div>
          </div>
          <div className="history-confirmation-card">
            <div className="comfirmation-resume-data">
              {!!campaignDetail.audienceSize &&<div>
                <div className="comfirmation-resume-data-item">
                  <div>
                    <b>Target Audience:</b>
                  </div>
                  <div>{campaignDetail.audienceSize}</div>
                </div>
              </div>}
              <hr style={{ width: "100%" }} />
              <br />
              <div>
                <div className="comfirmation-resume-data-item">
                  <div>
                    <b>Total:</b>
                  </div>
                  <div>
                    <b>${campaignDetail.costInDollars.toLocaleString()}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Button
          className="inverted-general-button pointer-hover"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}
          onClick={() => {
            setCampaignDetail(null);
            setDisplayedChannels([])
          }}
        >
          <IconArrowBack />
          BACK
        </Button>   
      </div>
    }
  
  </div>;
};

export default CampaignHistory;
