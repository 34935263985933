import { Button, Checkbox, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appColors } from "../../../assets/variables/colors";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { segments } from "./SegmentsList";

import {
    selectSelectedLocation,
    setDetailsLocation,
    selectSingleLocation,
    selectDetailsLocation,
    selectPrimaryZips,
    selectSecondaryZips,
    setPrimaryZips,
    setSecondaryZips,
    selectSelectedLocationPrimaryZips,
    selectSelectedLocationSecondaryZips,
    setAudienceSegment,
    setTargetZoneChanged,
    selectAudienceSegment
  } from "../../../store/campaignForm/CampaignForm";

import CustomSwitch from "../../Switch/CustomSwitch";
import "./TargetZone.css";
import styles from "./TargetZoneZipsForm.module.css";
import { TargetZoneSidbar } from "./TargetZoneSidbar";
interface TargetZoneZipsFormProps {
    setLoading: (value: boolean) => void;
  }


const TargetZoneZipsForm = ({ setLoading }: TargetZoneZipsFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedLocation = useSelector(selectSelectedLocation);
    const singleLocation = useSelector(selectSingleLocation);
    const detailsLocation = useSelector(selectDetailsLocation);

    const [showMobileSidebar, setShowMobileSidebar] = useState(false);

    const whiteLabel = useSelector(selectWhiteLabel);

    const selectedPrimaryZips = useSelector(selectPrimaryZips);
    const selectedSecondaryZips = useSelector(selectSecondaryZips);

    const locationPrimaryZips = useSelector(selectSelectedLocationPrimaryZips);
    const locationSecondaryZips = useSelector(selectSelectedLocationSecondaryZips);
    const audienceSegment = useSelector( selectAudienceSegment );

    const primaryZipsList = () => {
        const itemElements: any[] = []
        const items = locationPrimaryZips;
        items.forEach((item, i) => {
            itemElements.push(<div key={`${i}`} className={`${styles['account-manager-item']} ${ selectedPrimaryZips.indexOf(item) !== -1 ? styles['selected-zip'] : '' }`} onClick={() => { handlePrimaryZipClick(item, i) } }>{item}</div>)
        })
        return itemElements
    }

    const secondaryZipsList = () => {
        const itemElements: any[] = []
        const items = locationSecondaryZips;
        items.forEach((item, i) => {
            itemElements.push(<div key={`${i}`} className={`${styles['account-manager-item']} ${ selectedSecondaryZips.indexOf(item) !== -1 ? styles['selected-zip'] : '' }`}  onClick={() => { handleSecondaryZipClick(item, i) } }>{item}</div>)
        })
        return itemElements
    }

    function handlePrimaryZipClick( item:string, index:number ){
        const updatedPrimaryZips = [...selectedPrimaryZips];

        const itemIndex = updatedPrimaryZips.indexOf(item);

        if (itemIndex !== -1) {
            // If the item is in the array, remove it
            updatedPrimaryZips.splice(itemIndex, 1);
        } else {
            // If the item is not in the array, add it
            updatedPrimaryZips.push(item);
        }


        // Set the state with the updated array
        dispatch(setPrimaryZips(updatedPrimaryZips));
    }

    function handleSecondaryZipClick( item:string, index:number ){
        const updatedSecondaryZips = [...selectedSecondaryZips];

        const itemIndex = updatedSecondaryZips.indexOf(item);

        if (itemIndex !== -1) {
            // If the item is in the array, remove it
            updatedSecondaryZips.splice(itemIndex, 1);
        } else {
            // If the item is not in the array, add it
            updatedSecondaryZips.push(item);
        }

        // Set the state with the updated array
        dispatch(setSecondaryZips(updatedSecondaryZips));
    }

    const handlePrimaryCheckChange = (e : any) => {
        // Handle checkbox change here
        if( e.target.checked ){
            dispatch(setPrimaryZips(locationPrimaryZips))
        }else{
            dispatch(setPrimaryZips([]))
        }
    };
    const handleSecondaryCheckChange = (e : any) => {
        // Handle checkbox change here
        if( e.target.checked ){
            dispatch(setSecondaryZips(locationSecondaryZips))
        }else{
            dispatch(setSecondaryZips([]))
        }
    };

    const audienceSegmentChange = (value: any) => {
        dispatch(setAudienceSegment(value));
        dispatch(setTargetZoneChanged(true));
    };

    return (
        <div className={classNames.form + " res:!mb-0"}>
            <div className="location-info" style={{ marginBottom: '16px' }}>
                <span className="name"><b>Buying for:</b> {selectedLocation?.name}</span>
                <Button className="change-link" type="link" onClick={() => {
                if (singleLocation) {
                    if (detailsLocation) dispatch(setDetailsLocation(null));
                    else dispatch(setDetailsLocation(selectedLocation));
                } else {
                    navigate(AvailableRoutes.LOCATIONS);
                }
            }}>{singleLocation ? 'Details' : 'Change'}</Button>
            </div>

            <div style={{ marginBottom: '10px' }}>
                <h2>Target area</h2>
            </div>

            <div className="grid grid-cols-2 gap-x-6">
                <div>
                    <h3>Primary</h3>
                    <div className={`${styles['qr-select-and-btns']}`}>
                        <div className={`${styles['account-manager-list']} ${styles['qr-list']} ${styles['qr-input-width']} mt-3`}>
                            { primaryZipsList() }
                        </div>
                    </div>
                    <Checkbox  className="mt-3" onChange={handlePrimaryCheckChange}>
                        Select all
                    </Checkbox>
                </div>

                { locationSecondaryZips.length > 0 && (
                    <div>
                        <h3>Secondary</h3>
                        <div className={`${styles['qr-select-and-btns']}`}>
                            <div className={`${styles['account-manager-list']} ${styles['qr-list']} ${styles['qr-input-width']} mt-3`}>
                                { secondaryZipsList() }
                            </div>
                        </div>
                        <Checkbox  className="mt-3" onChange={handleSecondaryCheckChange}>
                            Select all
                        </Checkbox>
                    </div>
                )}
            </div>

            <div style={{ marginTop: '20px' }}>
                <h2>Audience segments</h2>
                <div>
                <Select
                    mode="multiple"
                    style={{ fontSize: "16px", width: "100%" }}
                    id="segment"
                    defaultValue={audienceSegment}
                    onChange={audienceSegmentChange}
                    placeholder="Select custom segments"
                >
                    {segments}
                </Select>
                </div>
            </div>

            <div
                className="target-zone-mobile-buttons res:bg-white res:!p-6 res:!h-auto res:!flex !hidden"
                style={{ display: "flex", alignSelf: "flex-end" }}
            >

                <Button
                    className="preview-save-zone-button target-zone-mobile-button mobile-target-zone-button-height"
                    onClick={() => {
                        navigate(AvailableRoutes.CHANNELS);
                    }}
                    disabled={
                        ((selectedPrimaryZips.length < 1 && selectedSecondaryZips.length < 1))
                    }
                >
                    CHOOSE CAMPAIGN TYPE
                </Button>
            </div>
        
            {showMobileSidebar && (
                <div className="mobile-sidebar-container">
                    <div
                        className="mobile-sidebar-oppacity"
                        onClick={() => setShowMobileSidebar(false)}
                    ></div>
                    <div className="mobile-sidebar">
                        <TargetZoneSidbar />
                    </div>
                </div>
            )}
        </div>
  );
};

const classNames = {
  form: "target-zone-form",
  switchRow: "target-zone-switch-row",
};

export default React.memo(TargetZoneZipsForm);
