import { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SideModal from "../shared/SideModal"
import styles from "./styles.module.css";
import sharedStyles from "../shared/styles.module.css";
import AccountLocationList from "./AccountLocationList";
import AccountUserAccountList from "./AccountUserAccountList";
import { selectAdminManagerFranchises, fetchAdminManagerFranchises } from "../../../store/AdminManager/AdminManagerStore";
import { AdminManagerFranchise, AdminManagerUserAccount } from "../../../models/AdminManager";

export const AccountManagerPage = () => {

  const franchises: AdminManagerFranchise[] = useSelector(selectAdminManagerFranchises);
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<AdminManagerFranchise>();
  const [selectedUserAccount, setSelectedUserAccount] = useState<AdminManagerUserAccount>();
  const [sideViewSection, setSideViewSection] = useState<string>(); 

  useEffect(() => {
    if (franchises.length > 0) {
      setSelectedItem(franchises[0]);
    }
  }, [franchises]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  }

  const franchiseItems = () => {
    return franchises.map((item, i) => (
      <div 
        key={`${item.name}${i}`} 
        className={`${sharedStyles['account-manager-item']} ${styles['franchise-item']} ${item === selectedItem ? styles['franchise-item-selected'] : ''}`} 
        onClick={() => setSelectedItem(item)}
      >
        {item.name}
      </div>
    ));
  };

  const changeSideViewSection = (section: string) => {
    setSideViewSection(section)
    toggleModal();
  }
  const viewUserAccountLocations = (userAccount: AdminManagerUserAccount) => {
    setSelectedUserAccount(userAccount)
    setSideViewSection('Locations by user')
  }

  return <div >
    <h1>Directory</h1>
    <div>
      <div className={styles['franchise-select-and-btns']}>
        <div className={`${sharedStyles['account-manager-list']} ${styles['franchise-list']} ${styles['franchise-input-width']}`}>
          {franchiseItems()}
        </div>
        <div className={`${styles['franchise-btns']} ${styles['franchise-input-width']}`}>
          <button className="primary-button" onClick={() => { changeSideViewSection('Locations') }}>View Locations</button>
          <button className="primary-button" onClick={() => { changeSideViewSection('Users') }}>View Users</button>
        </div>
      </div>
    </div>
    <SideModal title={`${selectedItem?.name} - ${sideViewSection}`} isOpen={isOpen} onClose={toggleModal} >
      {sideViewSection === 'Locations' && selectedItem ? <AccountLocationList locations={selectedItem.locations}></AccountLocationList> : null}
      {sideViewSection === 'Users' && selectedItem ? <AccountUserAccountList viewAccountLocationCallback={viewUserAccountLocations} item={selectedItem}></AccountUserAccountList> : null}
      {sideViewSection === 'Locations by user' && selectedUserAccount ? <AccountLocationList locations={selectedUserAccount.locations}></AccountLocationList> : null}
    </SideModal>
  </div>
}

export default AccountManagerPage
