import { useDispatch, useSelector } from "react-redux";
import {
  removeTargetZone,
  selectDetailsLocation,
  selectPreviewArea,
  selectPreviewAudience,
  selectSavedTargetZones,
  selectSelectedLocation,
} from "../../../store/campaignForm/CampaignForm";
import "./TargetZone.css";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import LocationDetailsSide from "../locations/LocationDetailsSide";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

export const TargetZoneSidbar = () => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectSelectedLocation);
  const detailsLocation = useSelector(selectDetailsLocation);
  const savedTargetZones = useSelector(selectSavedTargetZones);
  const previewArea = useSelector(selectPreviewArea);
  const previewAudience = useSelector(selectPreviewAudience);
  const whiteLabel = useSelector(selectWhiteLabel);

  return (
    <div className={classNames.conatiner}>
      {previewArea !== 0 && previewAudience !== 0 &&
        <div
          className={classNames.previewCard}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ marginBottom: '1em', fontSize: '14px', fontWeight: 'bold' }}>Zone Preview</h3>
          </div>
          <p style={{ fontSize: '12px' }}>
            <span style={{ fontWeight: "bold", height: '20px', marginBottom: '1em' }}>Estimated Audience:</span>{" "}
            {previewAudience.toLocaleString()}
          </p>
          <p style={{ fontSize: '12px' }}>
            <span style={{ fontWeight: "bold", height: '20px', marginBottom: '1em' }}>Estimated Area:</span>{" "}
            {previewArea.toFixed(2).toLocaleString()}  mi<sup>2</sup>
          </p>
        </div>
      }
      {savedTargetZones.map((zone, index) => (
        <div
          key={`target zone info sidebar card: ${index}`}
          className={classNames.card}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ marginBottom: '1em', fontSize: '14px', fontWeight: 'bold' }}>Audience Preview</h3>
            <TrashIcon style={{ cursor: "pointer" }} onClick={() => dispatch(removeTargetZone(zone))} />
          </div>
          <p style={{ fontSize: '12px' }}>
            <span style={{ fontWeight: "bold", height: '20px', marginBottom: '1em' }}>Estimated Count:</span>{" "}
            {zone.audience.toLocaleString()}
          </p>
          <p style={{ fontSize: '12px' }}>
            <span style={{ fontWeight: "bold", height: '20px', marginBottom: '1em' }}>Estimated Area:</span>{" "}
            {zone.area.toFixed(2).toLocaleString()} mi<sup>2</sup>
          </p>
        </div>
      ))}
    </div>
  );
};

const classNames = {
  conatiner: "target-zone-sidebar-container",
  card: "target-zone-sidebar-card pb-5",
  previewCard: "target-zone-sidebar-card preview-zone-card"
};
