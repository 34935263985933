import "./styles.css";
import { ReactNode, useEffect } from "react";
import { Button, Tooltip, Form, Input, Select, Table, Typography } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { Column } from '@ant-design/plots';
import React from "react";
import { WhiteLabelRow } from "../../../services/whiteLabelService";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectWhiteLabelTable, fetchWhiteLabelTable, setWhiteLabelToEdit } from "../../../store/WhiteLabel/WhiteLabelStore";
import { AdminAvailableRoutes } from "../../../pages/AdminRoutes";
import { useNavigate } from "react-router-dom";
import { WhiteLabel } from "../../../models/WhiteLabel";
import CustomTooltip from "../../tooltip/tooltip";

export const WhiteLabelingPage = (): JSX.Element => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch( fetchWhiteLabelTable() );
    }, [])

    const whiteLabelTable = useSelector(selectWhiteLabelTable);

    const columns: ColumnsType<WhiteLabel> = [
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
        },
        {
            title: 'Mail From',
            dataIndex: 'mailFrom',
            key: 'mailFrom',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div>
                    <CustomTooltip title="Edit experience">
                        <Button 
                            onClick={() => {
                                dispatch(setWhiteLabelToEdit(record));
                                navigate(AdminAvailableRoutes.EDIT_VERSION);
                            }}
                            shape="circle"
                            icon={<EditOutlined />} 
                        />
                    </CustomTooltip>

                    <CustomTooltip title="Delete experience">
                        <Button 
                            onClick={() => {
                                dispatch(setWhiteLabelToEdit(record));
                                navigate(AdminAvailableRoutes.DELETE_VERSION);
                            }}
                            shape="circle"
                            icon={<DeleteOutlined />} 
                        />
                    </CustomTooltip>
                </div>
                

                
            )
        },
    ];

    return (
        <>
          <div className="admin-page">
            <div className="">
                <h1>Experiences</h1>
                <div className="admin-table-container">
                    <Table 
                        className="white-label-table"
                        columns={columns}
                        pagination={false}
                        dataSource={whiteLabelTable?.table} 
                    />
                </div>
                <Button
                    className="primary-button pointer-hover"
                    style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={() => navigate(AdminAvailableRoutes.CREATE_VERSION)}
                    >
                    <div className="primary-button-text">CREATE NEW</div>
                </Button>
            </div>
          </div>
        </> 
    );
}