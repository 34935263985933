import RestService from "./restService";

export interface IMetadataItem {
    id: number;
    attribute_code: string;
    options: string[];
}

export default class AudienceSourceService {
    constructor(protected readonly api: RestService) {}

    /**
     * Retrieve source list
     */
    async getSourceList(): Promise<string[]> {
        return await this.api.get('/source/list');
    }

    /**
     * Retrieve queries by source
     * @param source
     */
    async getAudiencesBySource(source: string) {
        return await this.api.get('/query/source/$1'.replace('$1', source));
    }

    /**
     * Retrieve fields with options
     *
     * @param source
     */
    async getMetadata(source: string): Promise<IMetadataItem[]> {
        return await this.api.get('/metadata/source/$1'.replace('$1', source));
    }

    /**
     * Create new filter
     *
     * @param payload
     * @param source
     */
    async createNewFilter(payload: object, source: string) {
        return await this.api.post('/query/source/$1'.replace('$1', source), payload);
    }
}

