import FileService from "./fileService";

export interface IFileItemResource {
  id: string | null;
  name: string | null;
  description: string | null;
  createDate: Date | null,
  createdBy: string,
}

export default class FileItemService {

  /**
   * Define class dependencies
   *
   * @param api
   */
  constructor(protected readonly api: FileService) { }

  /**
   * Create file
   *
   * @param file
   * @param fileDescription
   */
  async upload(file: File, fileDescription: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', fileDescription);

    return await this.api.post(`/file-item/create`, formData, {
        'Content-Type': 'multipart/form-data'
      }
    );
  }

  /**
   * Download file
   *
   * @param fileId
   */
  async download(fileId: string) {
      return await this.api.download(`/file-item/${fileId}`)
          .then((resp) => {
            return new Blob([resp as ArrayBuffer]);
          });
  }

  /**
   * Retrieve list of files for franchise
   */
  async getFiles():Promise<IFileItemResource[]> {
    return await this.api.get(`/file-item/list`);
  }

  /**
   * Remove file by id
   *
   * @param fileId
   */
  async remove(fileId: string) {
    return await this.api.delete(`/file-item/${fileId}`);
  }
}
