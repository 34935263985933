import { Chart } from "react-google-charts";
import { CPLDate } from "services/franchiseService";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import { SpendPacingDate } from "../../models/SpendPacingReport";

interface SpendPacingChartProps{
    dates: SpendPacingDate[],
}

export const SpendPacingChart = (props: SpendPacingChartProps) =>{
    
    const whiteLabel = useSelector(selectWhiteLabel);
    
    // Format data for the chart
    let chartData = [['Date', 'Total Spend']] as any[][];
    props.dates.forEach((item) => {
        const [year, month, day] = item.date.toString().split('-');
        chartData.push([new Date(parseInt(year), parseInt(month) - 1, parseInt(day)), Number(item.totalSpend)]);
    });

    return (
        <Chart
            chartType="LineChart"
            data={chartData}
            width="100%"
            height="400px"
            options={
                {
                    hAxis: {
                        format: 'MMM dd',
                        ticks: chartData.slice(1).map((entry) => entry[0])
                    },
                    series: {
                        0: { targetAxisIndex: 0, color: whiteLabel.primaryColor },                
                    },
                    vAxes: [
                        {
                          title: "Total spend",
                          minValue: 0
                        }
                    ],
                }
                
            }   
        >
        </Chart>
    )
}

export default SpendPacingChart;
