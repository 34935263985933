import { useSelector } from "react-redux";
import { ChannelType } from "../../../models/Channel";
import {
  selectSelectedLocation,
  selectAdjustedSpendPercent,
  selectAlwaysOn,
  selectCurrentEstimatedCost,
  selectNewCampaignCustomPackage,
  selectSelectedChannels,
  selectTotalAudience,
  selectWeeklySpendEstimate,
  selectZipModeEnabled,
  selectPrimaryZips,
  selectSecondaryZips,
  selectIsBudgetModeFixed,
  selectMonthlyBudget,
  selectedMonth,
  selectNewBudget,
} from "../../../store/campaignForm/CampaignForm";
import { roundTo2DecimalPlacesAndConvertToInteger } from "../../../utility/numbers";
import { getMoneyStringFromNumber } from "../../../utility/string";
import "./ReviewAndPay.scss";
import ReviewAndPaySidebarCard from "./ReviewAndPaySidebarCard";
import ReviewAndPaySidebarPackageCard from "./ReviewAndPaySidebarPackageCard";
import AlwaysOnTag from "../schedule/AlwaysOnTag";
import { IMonthlyBudgetDetail, getMonthIndexByCode } from "../../../models/MonthlyBudget";

const creditCardFeePercent = Number(
  process.env.REACT_APP_APP_CREDIT_CARD_FEE_PERCENT
);

const ReviewAndPaySidebar = () => {
  const selectedLocation = useSelector(selectSelectedLocation);
  const selectedChannels: ChannelType[] = useSelector(selectSelectedChannels);
  const newCustomPackage = useSelector(selectNewCampaignCustomPackage);
  const estimatedCost: number = useSelector(selectCurrentEstimatedCost);
  const weeklySpend: number = useSelector(selectWeeklySpendEstimate);
  const targetZoneAudience: number = useSelector(selectTotalAudience);
  const alwaysOn: boolean = useSelector(selectAlwaysOn);
  const adjustedSpendPercent: number = useSelector(selectAdjustedSpendPercent);

  const percentSpend = estimatedCost * (adjustedSpendPercent / 100);

  const usesZipMode = useSelector(selectZipModeEnabled);
  const selectedPrimaryZips = useSelector(selectPrimaryZips);
  const selectedSecondaryZips = useSelector(selectSecondaryZips);

  const isFixedBudgetMode = useSelector(selectIsBudgetModeFixed);
  const monthCode = useSelector(selectedMonth);
  const monthlyBudget = useSelector(selectMonthlyBudget) as IMonthlyBudgetDetail[];
  const monthlyBudgetCost  = (monthlyBudget[getMonthIndexByCode(monthCode)].value || 0) as number;
  const newBudget = useSelector( selectNewBudget );

  //If the budget for this month is defined, then use the monthlyBudgetCost. Otherwise use the newBudget that was
  //defined in the Channels step.
  const chosenBudget = monthlyBudget[getMonthIndexByCode(monthCode)].isDefined ? monthlyBudgetCost : newBudget;

  const calculateSubTotal = (): number => {
    return roundTo2DecimalPlacesAndConvertToInteger(
        estimatedCost * (adjustedSpendPercent / 100)
      ) / 100;
  }
  const calculateFee = (subTotal: number): number => {
    return roundTo2DecimalPlacesAndConvertToInteger(
      subTotal * (creditCardFeePercent / 100)
    ) / 100;
  };

  const subTotal = calculateSubTotal();
  const feeAmount = calculateFee(subTotal) || 0;

  const getCalculatedTotal = (subTotal: number, fee: number): number => {
    return subTotal + fee;
  }

  return (
    <div className="sidebar-mobile-body res:pb-0">
      <div className="target-container res:hidden">
        <span className="target-title">Buying For:</span>
        <span>{selectedLocation?.name}</span>
      </div>
      
      { usesZipMode
          ?

            <div className="target-container">
              <div className="target-title">Audience Size:</div>

              { ( selectedPrimaryZips.length + selectedSecondaryZips.length ).toString() + ' zips' }
            </div>

          :
            ( !!targetZoneAudience && (
              <div className="target-container">
                <div className="target-title">Audience Size:</div>

                {targetZoneAudience.toLocaleString()}
              </div>
            ))
        }

      <hr className="res:hidden" style={{ marginTop: "30px" }} />
      <div className="flex flex-col">
        <div className="res:order-2 res:hidden">
            <div className="review-pay-channel-resume">
              {newCustomPackage && (
                <ReviewAndPaySidebarPackageCard
                  newCampaignCustomPackage={newCustomPackage}
                />
              )}
              {selectedChannels.map((channel: ChannelType) =>
                <ReviewAndPaySidebarCard
                  key={`review and pay sidebar channel:${channel.title}`}
                  channelType={channel}
                />
              )}
            </div>
          <hr />
        </div>
        <div className="res:order-1">
          <div className="target-container res:mt-0">
            <span className="target-title">Budget:</span>
                <span>${getMoneyStringFromNumber(isFixedBudgetMode ? chosenBudget : estimatedCost)}</span>
          </div>

          <div className="target-container res:mt-1">
            <span className="target-title">{alwaysOn ? 'Weekly Spend:' : 'Percent Spend:'}</span>
            <span>{adjustedSpendPercent}%</span>
          </div>
          {
            isFixedBudgetMode ? ""
                :
              <div className="target-container res:hidden">
                <span className="target-title">Subtotal:</span>
                <span>${getMoneyStringFromNumber(percentSpend)}</span>
              </div>
          }

          <div className="hidden review-pay-channel-resume res:block res:mt-0">
            <hr className="mb-4 " style={{marginTop: "12px"}}/>
            {newCustomPackage && (
                <ReviewAndPaySidebarPackageCard
                newCampaignCustomPackage={newCustomPackage}
              />
            )}
            {selectedChannels.map((channel: ChannelType) =>
              <ReviewAndPaySidebarCard
                key={`review and pay sidebar channel:${channel.title}`}
                channelType={channel}
              />
            )}
            <hr style={{ marginTop: "12px" }} />
          </div>
          
          <div className="review-pay-sidebar-footer res:mb-4">
            {isFixedBudgetMode ? "" :
                <div className="review-pay-sidebar-footer-item"
                     style={{display: "flex", justifyContent: "space-between"}}>
                  <p>CC Fee (3%):</p>
                  <p>${feeAmount ? getMoneyStringFromNumber(feeAmount) : "Failed to calculate fee"}</p>
                </div>
            }
            <div
                className="mt-5 review-pay-sidebar-footer-item grand-total res:mt-2"
                style={{display: "flex", justifyContent: "space-between"}}
            >
              <span>Grand total:</span>
              <div>
                <span>${getMoneyStringFromNumber(
                    isFixedBudgetMode ? chosenBudget : getCalculatedTotal(subTotal, feeAmount)
                )}</span>
                {alwaysOn && <AlwaysOnTag />}
              </div>
            </div>
          </div>
        </div>
      </div>
      

      
    </div>
  );
};

export default ReviewAndPaySidebar;
