import { Backdrop } from "@mui/material";
import { useState, useEffect} from "react";
import "./TargetZone.css";
import TargetZoneForm from "./TargetZoneForm";
import TargetZoneZipsForm from "./TargetZoneZipsForm";
import TargetZoneMap from "./TargetZoneMap";
import { ReactComponent as LoaderIcon } from "../../../assets/icons/loader.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectSelectedLocation, selectZipModeEnabled } from "../../../store/campaignForm/CampaignForm";

const TargetZone = () => {
  const navigate = useNavigate();
  const usesZipMode = useSelector(selectZipModeEnabled);
  const selectedLocaiton = useSelector(selectSelectedLocation);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedLocaiton === null) {
      navigate(AvailableRoutes.LOCATIONS);
    }
  }, [selectedLocaiton]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames.container + " res:!h-auto"}>
      <div className="target-zone-leftside-map">
        {window.google !== undefined && (
          <TargetZoneMap displayButtons={true} canAddPointLocation={true} />
        )}
      </div>

      {
        ( usesZipMode ) 
          ? <TargetZoneZipsForm  setLoading={(value: boolean) => setLoading(value)} />
          : <TargetZoneForm  setLoading={(value: boolean) => setLoading(value)} />
      }
    
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <LoaderIcon className="loader-icon" />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    </div>
  );
};

const classNames = {
  container: "target-zone-container",
};

export default TargetZone;
