import { Row, Col, Button, Tooltip } from "antd";
import React from "react";
import { connect, useSelector } from 'react-redux';
import './FileUploader.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ParseResult } from "papaparse";
import { ChannelRowSchema } from "../../../services/channelService";
import { importChannels, channelErrors} from "../../../store/Channels/ChannelStore"
import { RootState } from "../../../store";

interface ChannelUploaderProps {
    title: string,
    notifyErrors: Function,
    importChannels: Function,
    channelErrors: String[] | null,
    importChannelsResult: String | null
}

interface ChannelUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class ChannelUploader extends React.Component<ChannelUploaderProps, ChannelUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<ChannelUploaderProps>): void {
        
        if( prevProps.channelErrors !== null){
            if( prevProps.channelErrors !== this.props.channelErrors){
                this.props.notifyErrors( 
                    this.props.channelErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.channelErrors !== null ){
            if( prevProps.importChannelsResult !== this.props.importChannelsResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importChannelsResult);
            }
        }
        

    }
    
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        //Verify if the file is not null.
        if( !event.target.files )
            return;
        
        //Verifies if the file is supported (csv or xlsx)
        let fileType = event.target.files[0].type;
        if( 
            fileType === 'application/vnd.ms-excel' || 
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'text/csv'
        ){
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        }else{
            this.setState({
                fileTypeError: "The selected file is not valid. Only CSV and XLSX files are supported."
            });
        }
    }

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        //This process is different depending on the file type.
        let fileJson = [];

        let selectedFile = this.state.selectedFile;
        
        if( selectedFile?.type === 'text/csv'){
            this.parseChannelsCSVFile(selectedFile).then((json) => {
                console.log('Trying to import the channels csv file...');
                console.log(json);
                this.props.importChannels( json );
            }).catch( (error: Error) => {

            });
        }else{
            this.parseChannelsExcelFile(selectedFile!).then((json) => {
                console.log('Trying to import the channels xlsx file...');
                console.log(json);
                this.props.importChannels( json );
            }).catch( (error: Error) => {
                console.log(error);
            });
        }
    }


    parseChannelsExcelFile = (file: File) => {
        
        return new Promise<ChannelRowSchema[]>( (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: "array"} );
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                
                const json = XLSX.utils.sheet_to_json<ChannelRowSchema>(
                    worksheet,
                    {
                        //transforms de header names into camelCase.
                        raw: false,
                        header: [
                            'franchise',
                            'title',
                            'durationWeeks',
                            'investmentInDollarsPerTarget',
                            'setSpend',
                            'tooltip',
                            'sortOrder',
                        ],
                        range: 1,
                        defval: null,
                    }
                );
                
                //The library doesn't seem to support a method to validate
                //empty cells, so we validate this after processing.
                //empty cells are completely ignored by the library, so we need to
                //validate the existence of these missing attributes.
                let errors: string[] = [];
                //The lists of IDs, to keep track if any repeated value is found
                //within the same sheet.
                let appIds: string[] = []; 
                json.forEach( (element, index) => {
                    
                    let rowNum = index+1;


                    if( element['title']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Name cell.");
                    if( element['franchise']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Franchise cell.");
                    if( element['durationWeeks']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Duration cell.");
                    if( element['investmentInDollarsPerTarget']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Investment cell.");
                    if( element['setSpend']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Set Spend.");
                    if( element['tooltip']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Tooltip cell.");
                    if( element['sortOrder']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Sort Order cell.");

                });

                //Reject this promise and notify the parent about the errors
                //to display in the large alert window.
                if( errors.length > 0 ){
                    
                    this.props.notifyErrors( 
                        errors, 
                        "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                    );

                    reject(Error('There were some errors in the file.'));
                }


                resolve(json);
            }
            reader.readAsArrayBuffer(file);
        });

    }

    parseChannelsCSVFile = ( file: File ) => {
        return new Promise<ChannelRowSchema[]>( (resolve, reject) => {
            Papa.parse( file, {
                header: true,
                transformHeader: ((header: string) => this.camelize(header)),
                complete: (results: ParseResult<ChannelRowSchema> ) => {
                    
                    //Need to adjust the header names, and we do so while
                    //manually validating each row.
                    let json = results.data;
                    let errors: string[] = [];
                    //The lists of IDs, to keep track if any repeated value is found
                    //within the same sheet.
                    let appIds: string[] = []; 
                    json.forEach( (element, index) => {

                        let rowNum = index+1;
                 
                        if( element.title === "")
                            errors.push("<b>Row #"+rowNum+" - "+ element.title +"</b> is missing the Name cell.");
                        if( element.franchise === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Franchise cell.");
                        if( element.durationWeeks === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Duration cell.");
                        if( element.investmentInDollarsPerTarget === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Investment cell.");  
                        if( element.setSpend === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Set Spend cell.");  
                        if( element.tooltip === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Tooltip cell.");  
                        if( element.sortOrder === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Sort Order cell.");  
                    });

                    //Reject this promise and notify the parent about the errors
                    //to display in the large alert window.
                    if( errors.length > 0 ){
                        
                        this.props.notifyErrors( 
                            errors, 
                            "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                        );

                        reject(Error('There were some errors in the file.'));
                    }
                    
                    resolve(json);
                    
                },
                error: error => {
                    reject( Error( error.message ) );
                }
            });
        });
    }

    //TODO: Esto no debe estar aquí.
    camelize = (str: string) => {
        
        str = str.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
        {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    render(){
        
        let button;
        if( this.state.selectedFile ){
            button = 
                <div>
                    <div onClick={this.onFileUpload} className="primary-button pointer-hover p-[8px_25px]">
                        <div className="primary-button-text">UPLOAD</div>
                    </div>
                </div>
        }else{
            button = 
                <div>
                    <input id={"file-uploader-channel"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-channel"}>
                        <div className="primary-button pointer-hover p-[8px_25px]">
                            <div className="primary-button-text">IMPORT</div>
                        </div>
                    </label>
                </div>
        }
        
        return (
            <div className="file-upload-area mb-10">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col >
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }

                        { this.state.selectedFile == null && 
                            <a target="_blank" download={true} className="template-download" href="/templates/template_channel.xlsx">Template</a>
                        }

                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        importChannels: ( channelArray: ChannelRowSchema[] ) => dispatch(importChannels({channelSchemaArray: channelArray}) )
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        channelErrors: state.channels.channelErrors,
        importChannelsResult: state.channels.importChannelsResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelUploader);
