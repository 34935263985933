export const getLogoForSubdomain = () => {
    const subDomain = getSubdomain()
    const logo = franchisesList.find(e => e.includes(subDomain.toUpperCase()) )
    return  logo? logo : ''
}
export const getSubdomain = () => {
    const locationParts = window.location.hostname.split(".")
    let sliceTill = -2

    // for localhost
    const isLocalhost = locationParts.slice(-1)[0] === 'localhost'
    if (isLocalhost) sliceTill = -1

    return locationParts.slice(0, sliceTill).join("")
}

const franchisesList = [
    "EXPPRO_Logo.png",
    "MARANA_Logo.png",
    "HMRNLS_Logo.png",
    "DEMO_Logo.png"
]
