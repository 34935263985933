import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Drawer, Carousel, Select} from "antd";
import { CarouselRef } from "antd/lib/carousel";
import styles from "../../purchase/channels/channelTemplateDrawer/channelTemplateDrawer.module.css";
import { ReactComponent as IconArrowRightCircle } from "../../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as IconArrowRightBlack } from "../../../assets/icons/arrow-right-black.svg";
import { ReactComponent as IconArrowLeftCircle } from "../../../assets/icons/arrow-left-circle.svg";
import {selectIsOpenState, setOpen} from "../../../store/mediaTemplate/MediaTemplate";
import services from "../../../services";
import { IImageResource} from "../../../services/mediaTemplateService";
import {selectChannels} from "../../../store/Channels/ChannelStore"
import { channel } from "diagnostics_channel";

export const GalleryDrawer = () => {

    const dispatch = useDispatch();
    const isOpen: boolean = useSelector(selectIsOpenState);
    const [open, setModalOpen] = useState(false);
    const [initSetupDone, setInitSetupDone] = useState(false);
    const carouselRef = useRef<CarouselRef>(null);
    const [images, setImages] = useState<IImageResource[]>([]);
    //const [videos, setVideos] = useState<IVideoResource[]>([]);
    const channels = useSelector(selectChannels);

    useEffect(() => {
        setModalOpen(isOpen);        
    }, [isOpen]);

    const handleNextSlide = () => {
        carouselRef.current?.next()
    }
    const handlePrevSlide = () => {
        carouselRef.current?.prev()
    }
    const handleModalClose = () => {
        setModalOpen(false);
        dispatch(setOpen(false));
    };

    

    const handleChannelChange = (value: string) => {
        services.mediaTemplateService.getImages(value).then((images) => {
            setImages(images);
        });
        /*services.mediaTemplateService.getVideos(value).then((video) => {
            setVideos(videos);
        });*/
    }

    if ( initSetupDone == false && channels.length > 0) {
        handleChannelChange(channels[0].id)
        setInitSetupDone(true);
    }  


    return (
        <Drawer
            visible={open}
            width="min(100vw, 700px)"
            closable={false}
        >
            <div className={`${styles["templates-drawer-top-bar"]}  `}>
                <IconArrowRightBlack onClick={handleModalClose}/>
            </div>
            <div className={`${styles["templates-header"]} mb-5 `}>
                Media templates for:
            </div>
            <div className={`${styles["templates-channel-dropdown"]} mb-10 `}>
                <Select style={{ fontSize: "16px", width: "300px" }} defaultValue={ channels.length > 0 ? channels[0].id : "" } onChange={handleChannelChange} >
                {
                    channels.map((channel) => {
                        return(
                            <Select.Option key={channel.id} value={channel.id}>{channel.title}</Select.Option>
                        )
                    })
                }
                </Select>
            </div>
            { 
                images.length > 0 
                ?
                    <div className={` ${styles["templates-carousel-container"]} min-h-[500px]`}>
                        <Carousel ref={carouselRef} dots={{ className: styles["templates-carousel-dots-container"] }}>
                            {
                                images.map(image =>
                                    image.resource_type == "image" ? 
                                        <div key={image.asset_id}>
                                            <div className={styles["templates-carousel-slide-img-container"]}>
                                                <div className={styles["templates-carousel-slide-img"]} style={{ backgroundImage: `url('${image.secure_url}')` }}></div>
                                            </div>
                                            <div className={styles["templates-carousel-slide-footer"]}></div>
                                        </div>
                                    :
                                        <div key={image.asset_id}>
                                            <div className={styles["templates-carousel-slide-img-container"]}>
                                                <video className="max-w-xs" controls>
                                                    <source src={image.secure_url} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div className={styles["templates-carousel-slide-footer"]}></div>
                                        </div>
                                )
                            }
                        </Carousel>
                        { images.length > 1 && (
                            <>
                                <div className={styles["templates-carousel-arrow-left"]}>
                                    <IconArrowLeftCircle className="svg-color-primary" onClick={handlePrevSlide} />
                                </div>
                                <div className={styles["templates-carousel-arrow-right"]}>
                                    <IconArrowRightCircle className="svg-color-primary" onClick={handleNextSlide} />
                                </div>
                            </>
                            
                        )}
                        
                    </div >
                :
                    <div className=" pt-7 pb-8 px-4">
                        <p className="text-base text-center">There are no templates uploaded for this channel</p>
                    </div>
            }
            
        </Drawer >
    );
}

export default GalleryDrawer;
