import { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import sharedStyles from "../shared/styles.module.css";
import { selectQRCampaignToEdit, createQRCampaign, updateQRCampaign, selectCreatedQRCampaign } from "../../../store/QRCampaign/QRCampaignsStore";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../datepicker/DatePicker";
import { QRCampaign } from "../../../models/QRCampaign";
import { AdminAvailableRoutes } from "pages/AdminRoutes";
import { useAuth } from "../../../hooks/useAuth";

import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";

interface CreateQRCampaignPageProps{
    isEdit?: boolean,
}

export const CreateQRCampaignPage = ( {isEdit} : CreateQRCampaignPageProps ) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { currentUser, user } = useAuth();

    const qrCampaignToEdit = useSelector(selectQRCampaignToEdit);
    const createdQRCampaign = useSelector(selectCreatedQRCampaign);

    useEffect(() => {
        currentUser();
    }, []);

    useEffect(() =>{
        if( createdQRCampaign != null ){
            navigate(AdminAvailableRoutes.QR_MANAGER);
        }
    }, [createdQRCampaign])


    const onFinish = (values: any) => {
        if(isEdit && qrCampaignToEdit !== null) {
            dispatch(
                updateQRCampaign({
                    id: qrCampaignToEdit.id!,
                    name: values.name,
                    promotion: values.promotion,
                    startDate: values.startDate,
                    endDate: values.endDate,
                })
            );
        } else {
            dispatch(
                createQRCampaign({
                    name: values.name,
                    promotion: values.promotion,
                    startDate: values.startDate,
                    endDate: values.endDate,
                })
            );
        };
        
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return <div >
        
        <IconArrowLeft
          className="primary-icon cursor-pointer"
          onClick={() => navigate( isEdit ? AdminAvailableRoutes.DETAILS_QR_CAMPAIGN : AdminAvailableRoutes.QR_MANAGER)}
        />
        <h1 className="text-3xl mt-4">{ isEdit ? 'Edit QR Campaign' : 'New QR Campaign'}</h1>
        <div className="mt-5">
        <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="container-form"
            layout="vertical"
            initialValues={{
                name: isEdit && qrCampaignToEdit !== null ? qrCampaignToEdit.name : '',
                promotion: isEdit && qrCampaignToEdit !== null ? qrCampaignToEdit.promotion : '',
                startDate: isEdit && qrCampaignToEdit !== null ? new Date(qrCampaignToEdit.startDate) : '',
                endDate: isEdit && qrCampaignToEdit !== null ? new Date(qrCampaignToEdit.endDate) : '',
            }}
        >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="basis-4/12">
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Campaign name" />
                    </Form.Item>

                    <Form.Item
                        label="Promotion"
                        name="promotion"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Promotion Text" />
                    </Form.Item>

                    <Input.Group compact>
                        <Form.Item
                            label="Starts"
                            name="startDate"
                            style={{ width: "46%" }}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                            // value={startDate ?? undefined}
                            // onChange={(date) => updateStartDate(date)}
                            use12Hours={true}
                            showTime={false}
                            showHour={false}
                            showMinute={false}
                            showSecond={false}
                            style={{ borderRadius: "4px", width: "100%" }}
                            // disabledDate={(d) => !d || isBefore(d, minimumStart)}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Ends"
                            name="endDate"
                            style={{ marginLeft: "8%", width: "46%" }}
                            rules={[{required: true}]}
                        >
                            <DatePicker
                            // value={startDate ?? undefined}
                            // onChange={(date) => updateStartDate(date)}
                            use12Hours={true}
                            showTime={false}
                            showHour={false}
                            showMinute={false}
                            showSecond={false}
                            style={{ borderRadius: "4px", width: "100%" }}
                            // disabledDate={(d) => !d || isBefore(d, minimumStart)}
                            />
                        </Form.Item>
                        </Input.Group>

                </div>
            </div>

            <Input.Group compact>
                <Form.Item style={{ marginTop: 50 }}>
                    <Button
                        htmlType="submit"
                        className="primary-button pointer-hover"
                        style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <div className="primary-button-text">{ isEdit ? 'UPDATE' : 'CREATE' }</div>
                    </Button>
                </Form.Item>
            </Input.Group>
        </Form>
        </div>
    </div>
}

export default CreateQRCampaignPage