import { IUser } from "../models/User";
import { Location } from "../models/Location";
import { Franchise } from "../models/Franchise";

export default class DataLayerService {

    /**
     *
     * @private
     */
    private readonly _window: Window;

    /**
     *
     */
    public constructor() {
        window.dataLayer = window.dataLayer || [];
        this._window = window;
    }

    /**
     *
     * @param eventData
     */
    public registerEvent (eventData: any) {
        this._window.dataLayer.push(eventData);
    }

    /**
     *
     * @param account
     * @param locationList
     * @param franchise
     */
    public registerPageView() {
        this.registerEvent({
            "event": "PageView",
            "path": this._window.location.pathname
        })
    }

    /**
     * Pushes the User Information to the DataLayer as a standalone element of type "hostContext".
     * Any information may be added here. The only required element is the type: hostContext.
     * @param account
     * @param locationList
     * @param franchise
     */
    public registerChatServiceData( account: IUser, locationList: Location[], franchise: Franchise ){
        this._window.dataLayer.push({
            "type": "hostContext",
            "firstname": account?.firstName,
            "role": account?.type,
            "franchise": franchise?.name
            // "locations": locationList.map((item) => {
            //     return item.name;
            // }).join(',')
        });
    }
}
