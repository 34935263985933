import { CustomReportDate, CustomReportChannel } from "../models/CustomReport";
import RestService from "./restService";

//The expected data in the custom reports file. All attributes are required.
//Used when importing rows from csv or xlsx
export interface CustomReportRowSchema {
  channelName: string,
  franchiseName: string,
  locationName: string,
  campaignName: string,
  date: string,
  impressions: string,
  clicks: string,
  spend: string,
  conversions: string,
}

export default class CustomReportService {
  constructor(protected readonly api: RestService) { }

  async importCustomReports(customReportSchemaArray: CustomReportRowSchema[]): Promise<CustomReportDate[]> {
    const apiResponse = await this.api.post<CustomReportDate[]>("/custom-reports/import", customReportSchemaArray);
    return apiResponse;
  }

  async getCustomChannels(): Promise<CustomReportChannel[]>{
    const apiResponse = await this.api.get<CustomReportChannel[]>("/custom-creatives/custom-channels");
    return apiResponse;
  }
  
}


