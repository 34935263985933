import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Divider } from "antd";
import { useParams } from "react-router-dom";
import services from "../../../services";
import { QrReader } from 'react-qr-reader';

import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

import "./styles.css";
import { setScannedQRPromoCode, setCreateQRPromoCodeErrors, selectQRPromCodeRedeemed, selectQRPromCodeIsLoading, selectCreateQRPromoCodeErrors, selectScannedQRPromoCode, validatePromCode, claimPromCode } from "store/QRPromCodes/QRPromCodesStore";
import { assetImage } from "../../../utility/whiteLabelManager";

const QRScan = () => {
  
	const dispatch = useDispatch()

	const qrPromoCodeIsLoading = useSelector( selectQRPromCodeIsLoading );
    const qrPromoCodeErrors = useSelector( selectCreateQRPromoCodeErrors );
    const scannedQrPromoCode = useSelector( selectScannedQRPromoCode );
    const qrPromoCodeRedeemed = useSelector( selectQRPromCodeRedeemed);
    const [startScan, setStartScan] = useState(false);

    console.log(scannedQrPromoCode);

    const [scanResult, setScanResult] = useState<string | null>(null);

	const onFinish = (values: any) => {
        //dispatch()
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    //on load do...
    useEffect(() => {

    }, []);

    //Error message html
    //This is the html for the red container with qrPromo errors obtained from the backend.
    let errorMessages : any;

    //Handle when a error for QRPromCode is read and/or claimed.
    if( qrPromoCodeErrors.length > 0 ){
        errorMessages = <div className="w-full py-2 px-4 bg-red-400">
        <p className="text-base text-red-900">{ qrPromoCodeErrors[0] }</p>
        </div>
    }else{
        errorMessages = "";
    }

    //1. The scanner container.
    //This is the html structure that displays the camera scanner.
    let scannerHtml: any;
    if(startScan){
        scannerHtml = <QrReader
            onResult={(result, error) => {
                if (!!result) {
                    setScanResult(result.getText());
                }
                
                if (!!error) {
                    console.log(error);
                }
            }}
            scanDelay={250}
            constraints={{
                facingMode: 'environment',
                aspectRatio: 1,
            }}
        />
    }else{
        scannerHtml = "";
    }

    //2. Event when the "SCAN" button is clicked.
    const handleOpenScanner = () => {
        //Shows the Scanner HTML code.
        setStartScan(true);

        //TEST ONLY.
        //Inmediatly sets a json string with the id of an existing promocode.
        //This should happen in the "onResult" part of the QRReader.
        //setScanResult('{"id": "45ce117d-8ec3-4e30-b3b5-7731259ebef6"}');
    }

    //3. Do something when QRPromCode is actually scanned.
    //This should contain a json with the id for the promo code when not null.
    useEffect(() =>{
        if(scanResult != null ){
            setStartScan(false);
            console.log('Scan result is set to '+scanResult);
            
            const scannedObject = JSON.parse(scanResult);
            dispatch( validatePromCode({code: scannedObject.id }) )
            setScanResult(null);
        }
    },[scanResult])

    //4. Event when cancelling a result and returning to the original page.
    const handleCancel = () =>{
        dispatch( setScannedQRPromoCode(null) );
        dispatch( setCreateQRPromoCodeErrors([]) );
        setStartScan(false);
        setScanResult(null);
    }

    //5. Event when trying to redeem a code.
    const handleRedeem = ( id: string) =>{
        dispatch( claimPromCode({id: id}) );
    }

    const whiteLabel = useSelector(selectWhiteLabel);

    const ContentSelector = (props: any) => {
        

        if( qrPromoCodeRedeemed ){
            //Aquí ya se ha canjeado exitosamente el código.
            return(
                <div>
                    <div className="text-header">Code Redeemed</div>
                </div>
            )
        }

        if( scannedQrPromoCode != null ){
            //Aqui se debe validar si ya expiró o si esta disponible.
            return (
                <div>
                    <div className="text-header">Scan Results</div>
                    <div>
                        <h2 className="text-2xl font-bold text-black">Status</h2>
                        { (scannedQrPromoCode.isClaimed!) && (
                            <div>
                                <h3 className="text-xl text-orange-400 mt-4">Claimed on {new Date(scannedQrPromoCode.updateDate).toLocaleDateString() }</h3>
                                <p className="text-xl text-orange-400 mt-4">Agent: {scannedQrPromoCode.agent}</p>
                            </div>
                        )}

                        {
                            (
                                !scannedQrPromoCode.isClaimed && (
                                    new Date(scannedQrPromoCode.expirationDate).getFullYear() < new Date().getFullYear() ||
                                    (new Date(scannedQrPromoCode.expirationDate).getFullYear() === new Date().getFullYear() &&
                                    new Date(scannedQrPromoCode.expirationDate).getMonth() < new Date().getMonth()) ||
                                    (new Date(scannedQrPromoCode.expirationDate).getFullYear() === new Date().getFullYear() &&
                                    new Date(scannedQrPromoCode.expirationDate).getMonth() === new Date().getMonth() &&
                                    new Date(scannedQrPromoCode.expirationDate).getDate() < new Date().getDate())
                                  )
                            ) && (
                                <div>
                                    <h3 className="text-xl text-orange-400 mt-4">Expired {new Date(scannedQrPromoCode.expirationDate).toLocaleDateString() }</h3>
                                </div>
                            )
                        }

                        {
                            (
                                !scannedQrPromoCode.isClaimed && (
                                    new Date(scannedQrPromoCode.expirationDate).getFullYear() > new Date().getFullYear() ||
                                    (new Date(scannedQrPromoCode.expirationDate).getFullYear() === new Date().getFullYear() &&
                                    new Date(scannedQrPromoCode.expirationDate).getMonth() > new Date().getMonth()) ||
                                    (new Date(scannedQrPromoCode.expirationDate).getFullYear() === new Date().getFullYear() &&
                                    new Date(scannedQrPromoCode.expirationDate).getMonth() === new Date().getMonth() &&
                                    new Date(scannedQrPromoCode.expirationDate).getDate() > new Date().getDate())
                                  )
                            ) && (
                                <div>
                                    <h3 className="text-xl text-green-700 mt-4">Valid through {new Date(scannedQrPromoCode.expirationDate).toLocaleDateString() }</h3>
                                </div>
                            )
                        }
                    </div>

                    <div className="mt-12">
                        <h2 className="text-2xl font-bold text-black">Promotion</h2>
                        <h3 className="text-xl text-black mt-4">{ scannedQrPromoCode.promotion }</h3>
                    </div>

                    <div className="mt-12">
                        
                        {
                            (
                                !scannedQrPromoCode.isClaimed && (
                                    new Date(scannedQrPromoCode.expirationDate).getFullYear() > new Date().getFullYear() ||
                                    (new Date(scannedQrPromoCode.expirationDate).getFullYear() === new Date().getFullYear() &&
                                    new Date(scannedQrPromoCode.expirationDate).getMonth() > new Date().getMonth()) ||
                                    (new Date(scannedQrPromoCode.expirationDate).getFullYear() === new Date().getFullYear() &&
                                    new Date(scannedQrPromoCode.expirationDate).getMonth() === new Date().getMonth() &&
                                    new Date(scannedQrPromoCode.expirationDate).getDate() > new Date().getDate())
                                  )
                            ) && (
                                <Button onClick={() =>{ handleRedeem(scannedQrPromoCode.id) } } style={{backgroundColor: whiteLabel.primaryColor}} className="general-button" type="primary">REDEEM</Button>
                            )
                        }
                        <Button onClick={handleCancel} className="inverted-general-button mt-4" type="primary">CANCEL</Button>
                    </div>
                    
                </div>
            )
        }

        if( qrPromoCodeErrors.length > 0 ){
            //Error; Normalmente porque no se ha reconocido el código.
            //O algun error en el formato del id.
            return (
                <div>
                    <div className="text-header">Scan Results</div>

                    <h2 className=" text-2xl font-bold text-black">Status</h2>
                    <p className="text-base text-red-600 mt-5">
                        { qrPromoCodeErrors[0] }
                    </p>

                    <div className="mt-9">
                        <Button onClick={handleCancel} className="inverted-general-button" type="primary">CANCEL</Button>
                    </div>
                </div>
            )
        }

        //Default screen. Nothing has been scanned yet.
        return (
            <div>
              <div className="text-header">Welcome </div>
                <p className="text-base text-black mt-5">
                    Click the button below to enable your device camera and scan a promotional code.
                </p>

                <div className="mt-9">
                    <Button onClick={handleOpenScanner} style={{backgroundColor: whiteLabel.primaryColor}} className="general-button" type="primary">SCAN</Button>
                </div>
                <div className="mt-6">
                    {scannerHtml}
                </div>
                
            </div>
        )
    };

    return (
        <div>
            <div className="container">
                <div className="content res:flex res:flex-wrap res:h-[auto] ">
                <div className="auth-section res:order-2 res:flex-1 res:p-10">
                    <div className="container-auth res:w-full">
                        {<ContentSelector />}
                    </div>
                </div>
                <div className="background-section res:order-1 res:flex res:justify-start res:px-10 res:flex-[0_0_100%] res:pr-0 res:h-fit">
                    { whiteLabel.logoPath != ""
                    ? <img className="brand-logo" src={ assetImage( whiteLabel.logoPath ) } alt="logo" />
                    : <div className="text-background  res:text-[45px] res:leading-[50px] res:p-[36px]">JBecca</div>
                    }
                </div>
                </div>
            </div>
        </div>
    );
};

export default QRScan;
