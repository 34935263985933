import { Row, Col, Button, Tooltip } from "antd";
import React from "react";
import { connect, useSelector } from 'react-redux';
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import './FileUploader.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ParseResult } from "papaparse";
import { GoogleAdsCampaignRowSchema } from "../../../services/googleAdsCampaignService";
import { importGoogleAdsCampaigns, googleAdsCampaignErrors} from "../../../store/GoogleAdsCampaign/GoogleAdsCampaignStore"
import { RootState } from "../../../store";

interface GoogleAdsCampaignUploaderProps {
    title: string,
    notifyErrors: Function,
    importGoogleAdsCampaigns: Function,
    googleAdsCampaignErrors: String[] | null,
    importGoogleAdsCampaignsResult: String | null
}

interface GoogleAdsCampaignUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class GoogleAdsCampaignUploader extends React.Component<GoogleAdsCampaignUploaderProps, GoogleAdsCampaignUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<GoogleAdsCampaignUploaderProps>): void {
        
        if( prevProps.googleAdsCampaignErrors !== null){
            if( prevProps.googleAdsCampaignErrors !== this.props.googleAdsCampaignErrors){
                this.props.notifyErrors( 
                    this.props.googleAdsCampaignErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.googleAdsCampaignErrors !== null ){
            if( prevProps.importGoogleAdsCampaignsResult !== this.props.importGoogleAdsCampaignsResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importGoogleAdsCampaignsResult);
            }
        }
        

    }
    
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        //Verify if the file is not null.
        if( !event.target.files )
            return;
        
        //Verifies if the file is supported (csv or xlsx)
        let fileType = event.target.files[0].type;
        if( 
            fileType === 'application/vnd.ms-excel' || 
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'text/csv'
        ){
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        }else{
            this.setState({
                fileTypeError: "The selected file is not valid. Only CSV and XLSX files are supported."
            });
        }
    }

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        //This process is different depending on the file type.
        let fileJson = [];

        let selectedFile = this.state.selectedFile;
        
        if( selectedFile?.type === 'text/csv'){
            this.parseChannelsCSVFile(selectedFile).then((json) => {
                console.log('Trying to import the channels csv file...');
                console.log(json);
                this.props.importGoogleAdsCampaigns( json );
            }).catch( (error: Error) => {

            });
        }else{
            this.parseChannelsExcelFile(selectedFile!).then((json) => {
                console.log('Trying to import the channels xlsx file...');
                console.log(json);
                this.props.importGoogleAdsCampaigns( json );
            }).catch( (error: Error) => {
                console.log(error);
            });
        }
    }


    parseChannelsExcelFile = (file: File) => {
        
        return new Promise<GoogleAdsCampaignRowSchema[]>( (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: "array"} );
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                
                const json = XLSX.utils.sheet_to_json<GoogleAdsCampaignRowSchema>(
                    worksheet,
                    {
                        //transforms de header names into camelCase.
                        raw: false,
                        header: [
                            'franchise',
                            'locationAppId',
                            'campaignName',
                            'dailyBudget',
                            'startDate',
                            'endDate',
                        ],
                        range: 1,
                        defval: null,
                    }
                );
                
                //The library doesn't seem to support a method to validate
                //empty cells, so we validate this after processing.
                //empty cells are completely ignored by the library, so we need to
                //validate the existence of these missing attributes.
                let errors: string[] = [];
                //The lists of IDs, to keep track if any repeated value is found
                //within the same sheet.
                let appIds: string[] = []; 
                json.forEach( (element, index) => {
                    
                    let rowNum = index+1;


                    if( element['franchise']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Franchise cell.");
                    if( element['locationAppId']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Location App Id cell.");
                    if( element['campaignName']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Campaign Name cell.");
                    if( element['dailyBudget']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Daily Budget.");
                    if( element['startDate']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Start Date cell.");
                    if( element['endDate']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the End Date cell.");

                });

                //Reject this promise and notify the parent about the errors
                //to display in the large alert window.
                if( errors.length > 0 ){
                    
                    this.props.notifyErrors( 
                        errors, 
                        "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                    );

                    reject(Error('There were some errors in the file.'));
                }


                resolve(json);
            }
            reader.readAsArrayBuffer(file);
        });

    }

    parseChannelsCSVFile = ( file: File ) => {
        return new Promise<GoogleAdsCampaignRowSchema[]>( (resolve, reject) => {
            Papa.parse( file, {
                header: true,
                transformHeader: ((header: string) => this.camelize(header)),
                complete: (results: ParseResult<GoogleAdsCampaignRowSchema> ) => {
                    
                    //Need to adjust the header names, and we do so while
                    //manually validating each row.
                    let json = results.data;
                    let errors: string[] = [];
                    //The lists of IDs, to keep track if any repeated value is found
                    //within the same sheet.
                    let appIds: string[] = []; 
                    json.forEach( (element, index) => {

                        let rowNum = index+1;
                 
                        if( element.franchise === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Franchise cell.");
                        if( element.locationAppId === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Location App Id cell.");
                        if( element.campaignName === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Campaign Name cell.");  
                        if( element.dailyBudget === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Daily Budget cell.");  
                        if( element.startDate === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Start Date cell.");  
                        if( element.endDate === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the End Date cell.");  
                    });

                    //Reject this promise and notify the parent about the errors
                    //to display in the large alert window.
                    if( errors.length > 0 ){
                        
                        this.props.notifyErrors( 
                            errors, 
                            "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                        );

                        reject(Error('There were some errors in the file.'));
                    }
                    
                    resolve(json);
                    
                },
                error: error => {
                    reject( Error( error.message ) );
                }
            });
        });
    }

    //TODO: Esto no debe estar aquí.
    camelize = (str: string) => {
        
        str = str.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
        {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    render(){
        
        let button;
        if( this.state.selectedFile ){
            button = 
                <div>
                    <div onClick={this.onFileUpload} className="primary-button pointer-hover p-[8px_25px]">
                        <div className="primary-button-text">UPLOAD</div>
                        <IconArrowRight />
                    </div>
                </div>
        }else{
            button = 
                <div>
                    <input id={"file-uploader-channel"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-channel"}>
                        <div className="primary-button pointer-hover p-[8px_25px]">
                            <div className="primary-button-text">IMPORT</div>
                        </div>
                    </label>
                </div>
        }
        
        return (
            <div className="file-upload-area mb-10">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col >
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }

                        { this.state.selectedFile == null && 
                            <a target="_blank" download={true} className="template-download" href="/templates/template_channel.xlsx">Template</a>
                        }

                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        importGoogleAdsCampaigns: ( googleAdsCampaignArray: GoogleAdsCampaignRowSchema[] ) => dispatch(importGoogleAdsCampaigns({googleAdsCampaignSchemaArray: googleAdsCampaignArray}) )
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        googleAdsCampaignErrors: state.googleAdsCampaigns.googleAdsCampaignErrors,
        importGoogleAdsCampaignsResult: state.googleAdsCampaigns.importGoogleAdsCampaignsResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAdsCampaignUploader);
