import { Button, Form, Input, Checkbox} from "antd";
import { useEffect, useRef, useState } from "react";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../../assets/icons/orange-back-arrow.svg";
import { AdminAvailableRoutes } from "../../../pages/AdminRoutes";
import { Colorpicker } from 'antd-colorpicker';
import { useAuth } from "../../../hooks/useAuth";
import AWS from "aws-sdk";

import { selectWhiteLabelToEdit, updateWhiteLabel, createWhiteLabel, selectUpdateWhiteLabelResult } from "../../../store/WhiteLabel/WhiteLabelStore";

import { getLogoUploadPath, assetImage } from "../../../utility/whiteLabelManager";
import TextArea from "antd/lib/input/TextArea";

interface CreateWhiteLabelProps {
    isEdit?: boolean;
}

const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const CreateWhiteLabelPage = ( { isEdit }: CreateWhiteLabelProps ) => {
    
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [faviconImageUrl, setFaviconImageUrl] = useState<string>();
    const [secondaryImageUrl, setSecondaryImageUrl] = useState<string>();

    const { currentUser, user } = useAuth();

    const uploadToS3 = async (options : any) => {
        const { onSuccess, onError, file } = options;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY!,
            region: process.env.REACT_APP_AWS_S3_REGION!,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET!
        });
        const S3 = new AWS.S3();
        const fileName = Date.now().toString() + file.name;
        const s3Params = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
            Key: fileName,
            Body: file
        }
        try {
            S3.putObject(s3Params).send(function(err,data){
                if(err){
                    onError();
                }else{
                    setImageUrl(fileName);
                    onSuccess(data);
                }
            })
        } catch (err) {
            const error = new Error("Some error");
            onError({ err });
        }
    };

    const uploadSecondaryToS3 = async (options : any) => {
        const { onSuccess, onError, file } = options;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY!,
            region: process.env.REACT_APP_AWS_S3_REGION!,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET!
        });
        const S3 = new AWS.S3();
        const fileName = Date.now().toString() + file.name;
        const s3Params = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
            Key: fileName,
            Body: file
        }
        try {
            S3.putObject(s3Params).send(function(err,data){
                if(err){
                    onError();
                }else{
                    setSecondaryImageUrl(fileName);
                    onSuccess(data);
                }
            })
        } catch (err) {
            const error = new Error("Some error");
            onError({ err });
        }
    };

    const uploadFaviconToS3 = async (options : any) => {
        const { onSuccess, onError, file } = options;
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY!,
            region: process.env.REACT_APP_AWS_S3_REGION!,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET!
        });
        const S3 = new AWS.S3();
        const fileName = Date.now().toString() + file.name;
        const s3Params = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
            Key: fileName,
            Body: file
        }
        try {
            S3.putObject(s3Params).send(function(err,data){
                if(err){
                    onError();
                }else{
                    setFaviconImageUrl(fileName);
                    onSuccess(data);
                }
            })
        } catch (err) {
            const error = new Error("Some error");
            onError({ err });
        }
    };

    useEffect(() => {
        currentUser();
    }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const whiteLabelToEdit = useSelector(selectWhiteLabelToEdit);


    useEffect(() => {
        console.log(imageUrl);
        if( isEdit && imageUrl == undefined ){
            console.log('resetting');
            console.log(whiteLabelToEdit?.logoPath);
            setImageUrl( whiteLabelToEdit?.logoPath );
        }
        if( isEdit && secondaryImageUrl == undefined ){
            setSecondaryImageUrl( whiteLabelToEdit?.secondaryLogoPath);
        }
        if( isEdit && faviconImageUrl == undefined ){
            setFaviconImageUrl( whiteLabelToEdit?.faviconPath );
        }
    }, [isEdit, whiteLabelToEdit, imageUrl]) ;
    
    
    const onFinish = (values: any) => {
        if(isEdit && whiteLabelToEdit !== null) {
            dispatch(
                updateWhiteLabel({
                    id: whiteLabelToEdit.id!,
                    domain: values.domain,
                    primaryColor: values.primaryColor.hex,
                    secondaryColor: values.secondaryColor.hex,
                    activeColor: values.activeColor.hex,
                    gradientColor: values.gradientColor,
                    gradientOutlineColor: values.gradientOutlineColor,
                    mailFrom: values.mailFrom,
                    supportMail: values.supportMail,
                    supportText: values.supportText,
                    purchaseTerms: values.purchaseTerms,
                    orderSuccessText: values.orderSuccessText,
                    logoPath: imageUrl,
                    faviconPath: faviconImageUrl,
                    secondaryLogoPath: secondaryImageUrl,
                    metaTitle: values.metaTitle,
                    agency: values.agency,
                    ioEmail: values.ioEmail,
                    primaryContact: values.primaryContact,
                    billingContact: values.billingContact
                })
            );
        } else {
            dispatch(
                createWhiteLabel({
                    domain: values.domain,
                    primaryColor: values.primaryColor.hex,
                    secondaryColor: values.secondaryColor.hex,
                    activeColor: values.activeColor.hex,
                    gradientColor: values.gradientColor,
                    gradientOutlineColor: values.gradientOutlineColor,
                    mailFrom: values.mailFrom,
                    supportMail: values.supportMail,
                    supportText: values.supportText,
                    purchaseTerms: values.purchaseTerms,
                    orderSuccessText: values.orderSuccessText,
                    logoPath: imageUrl,
                    faviconPath: faviconImageUrl,
                    secondaryLogoPath: secondaryImageUrl,
                    metaTitle: values.metaTitle,
                    agency: values.agency,
                    ioEmail: values.ioEmail,
                    primaryContact: values.primaryContact,
                    billingConact: values.billingContact
                })
            );
        };

        navigate(AdminAvailableRoutes.VERSIONS);
        
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    //No longer used.
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          console.log(info.file.response);
          setLoading(false);
          setImageUrl(info.file.response);
          
          /*getBase64(info.file.originFileObj as RcFile, (url) => {
            console.log(url);
            setLoading(false);
            setImageUrl(url);
          });*/
        }
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

  return <div className="">
    <h1>{ isEdit ? 'Edit experience' : 'Create experience'}</h1>

    <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="container-form"
        layout="vertical"
        initialValues={{
            domain: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.domain : '',
            primaryColor: isEdit && whiteLabelToEdit !== null ? {hex: whiteLabelToEdit.primaryColor} : {hex:'#FFFFFF'},
            secondaryColor: isEdit && whiteLabelToEdit !== null ? {hex: whiteLabelToEdit.secondaryColor} : {hex:'#FFFFFF'},
            activeColor: isEdit && whiteLabelToEdit !== null ? {hex: whiteLabelToEdit.activeColor} : {hex:'#FFFFFF'},
            gradientColor: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.gradientColor : '',
            gradientOutlineColor: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.gradientOutlineColor : '',
            mailFrom: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.mailFrom : '',
            supportMail: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.supportMail : '',
            supportText: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.supportText : '',
            purchaseTerms: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.purchaseTerms : '',
            orderSuccessText: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.orderSuccessText : '',
            metaTitle: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.metaTitle : '',
            agency: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.agency : false,
            ioEmail: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.ioEmail : '',
            primaryContact: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.primaryContact : '',
            billingContact: isEdit && whiteLabelToEdit !== null ? whiteLabelToEdit.billingContact : '',
        }}
    >
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{    width: '40vw'}}>
                <p style={{fontSize: '16px'}}><b>Domain settings</b></p>

                <Form.Item
                    label="Domain name"
                    name="domain"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="sampledomain.com" />
                </Form.Item>

                <Form.Item
                    label="Hide non-branded external links"
                    name="agency"
                    valuePropName="checked"
                    rules={[{ required: true }]}
                >
                    <Checkbox></Checkbox>
                </Form.Item>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <p>Logo</p>   
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadToS3}
                            beforeUpload={beforeUpload}
                        >
                            {imageUrl ? <img src={assetImage(imageUrl)} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>

                    <div>
                        <p>Secondary</p>   
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadSecondaryToS3}
                            beforeUpload={beforeUpload}
                        >
                            {secondaryImageUrl ? <img src={assetImage(secondaryImageUrl)} alt="secondary" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>

                    <div>
                        <p>Favicon</p>   
                        <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadFaviconToS3}
                            beforeUpload={beforeUpload}
                        >
                            {faviconImageUrl ? <img src={assetImage(faviconImageUrl)} alt="favicon" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>
                </div>

                <Form.Item
                    label="Page meta title"
                    name="metaTitle"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Jbecca - Portal" />
                </Form.Item>

                <Form.Item
                    label="System mail from address"
                    name="mailFrom"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="no-reply@example.com" />
                </Form.Item>

                <Form.Item
                    label="IO internal notification email"
                    name="ioEmail"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="no-reply@example.com" />
                </Form.Item>

                <Form.Item
                    label="IO client primary email"
                    name="primaryContact"
                    rules={[{ required: true, type: "email" }]}
                >
                    <Input placeholder="primary@example.com" />
                </Form.Item>

                <Form.Item
                    label="IO client billing email"
                    name="billingContact"
                    rules={[{ required: true, type: "email" }]}
                >
                    <Input placeholder="billing@example.com" />
                </Form.Item>

                <Form.Item
                    label="Support text"
                    name="supportText"
                    rules={[{ required: true }]}
                >
                    <TextArea rows={4} placeholder="Register for the platform using your brand email address. If your Franchisor is not currently registered please conntact us to start the onboarding process."/>
                </Form.Item>

                <Form.Item
                    label="Terms of purchase"
                    name="purchaseTerms"
                    help="&lt;h2&gt;, &lt;h3&gt;, and  &lt;p&gt; HTML tags are allowed."
                    rules={[{ required: true }]}
                >
                    
                    <TextArea rows={12} placeholder="Please type the terms of purchase. HTML code is allowed."/>
                    
                </Form.Item>

                <Form.Item
                    label="Order successful text"
                    name="orderSuccessText"
                    help="&lt;b&gt; HTML tags are allowed."
                    rules={[{ required: true }]}
                >
                    
                    <TextArea rows={4} placeholder="Thank you for your purchase. Please check your email for
            confirmation and proofs for your approval. If you have any questions
            please email us at..."/>
                </Form.Item>

                <Form.Item
                    label="Support mail"
                    name="supportMail"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="support@example.com"/>
                </Form.Item>

            </div>
            <div style={{ width: '25vw'}}>
                <p style={{fontSize: '16px'}}><b>Styling options</b></p>

                <Form.Item
                    label="Primary color"
                    name="primaryColor"
                >
                    <Colorpicker
                        popup
                        blockStyles={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Secondary color"
                    name="secondaryColor"
                >
                    <Colorpicker
                        popup
                        blockStyles={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Active color"
                    name="activeColor"
                >
                    <Colorpicker
                        popup
                        blockStyles={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Gradient color"
                    name="gradientColor"
                    rules={[{ required: true }]}
                >
                    <TextArea rows={4} placeholder="linear-gradient(
                            225.4deg,
                            #ff7a00 33.64%,
                            #dd5789 67.76%,
                            #7a2c9e 93.59%
                        )" 
                    />
                </Form.Item>

                <Form.Item
                    label="Gradient outline color"
                    name="gradientOutlineColor"
                    rules={[{ required: true }]}
                >
                    <TextArea rows={4} placeholder="linear-gradient(
                            225.4deg,
                            #ff7a00 33.64%,
                            #dd5789 67.76%,
                            #7a2c9e 93.59%
                        )" 
                    />
                </Form.Item>
            </div>
        </div>

        <Input.Group compact>
            <Form.Item style={{ marginTop: 50 }}>
                <Button
                    htmlType="submit"
                    className="primary-button pointer-hover"
                    style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <div className="primary-button-text">SAVE</div>
                </Button>
            </Form.Item>
        </Input.Group>

        <Input.Group compact>
            <Form.Item style={{ marginTop: 50 }}>
                <Button
                    htmlType="submit"
                    className="inverted-general-button pointer-hover"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '25px' }}
                    onClick={() => navigate(AdminAvailableRoutes.VERSIONS)}
                >
                    <div className="primary-button-text">BACK</div>
                </Button>
            </Form.Item>
        </Input.Group>
    </Form>
  </div>;
};


export function usePrevious(value:any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export default CreateWhiteLabelPage;
