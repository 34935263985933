import { Button, Form, Input} from "antd";
import { useEffect, useRef} from "react";
import {  useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconArrowBack } from "../../../assets/icons/orange-back-arrow.svg";
import { AdminAvailableRoutes } from "../../../pages/AdminRoutes";
import { selectWhiteLabelToEdit, deleteWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

const DeleteWhiteLabelPage = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const whiteLabelToDelete = useSelector(selectWhiteLabelToEdit);

    const onFinish = (values: any) => {
        
        dispatch(
            deleteWhiteLabel({
                id: whiteLabelToDelete?.id!,
                domain: whiteLabelToDelete?.domain!,
                primaryColor: whiteLabelToDelete?.primaryColor!,
                secondaryColor: whiteLabelToDelete?.secondaryColor!,
                activeColor: whiteLabelToDelete?.activeColor!,
                gradientColor: whiteLabelToDelete?.gradientColor!,
                gradientOutlineColor: whiteLabelToDelete?.gradientOutlineColor!,
                mailFrom: whiteLabelToDelete?.mailFrom!,
                supportMail: whiteLabelToDelete?.supportMail!,
                supportText: whiteLabelToDelete?.supportText!,
                purchaseTerms: whiteLabelToDelete?.purchaseTerms!,
                orderSuccessText: whiteLabelToDelete?.orderSuccessText!,
                logoPath: whiteLabelToDelete?.logoPath,
                secondaryLogoPath: whiteLabelToDelete?.secondaryLogoPath,
                faviconPath: whiteLabelToDelete?.faviconPath,
                metaTitle: whiteLabelToDelete?.metaTitle!,
                agency: whiteLabelToDelete?.agency!,
                ioEmail: whiteLabelToDelete?.ioEmail!,
                primaryContact: whiteLabelToDelete?.primaryContact!,
                billingContact: whiteLabelToDelete?.billingContact!

            })
        );
        navigate(AdminAvailableRoutes.VERSIONS);
        
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

  return <div className="">
    <h1>Delete Whitelabel</h1>

    <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="container-form"
        layout="vertical"
    >
        <p><b>Delete</b> the white-labeling for the domain <b>{whiteLabelToDelete?.domain}</b>?</p>

        <Input.Group compact>
            <Form.Item style={{ marginTop: 50 }}>
                <Button
                    htmlType="submit"
                    className="inverted-general-button pointer-hover"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '25px' }}
                    onClick={() => navigate(AdminAvailableRoutes.VERSIONS)}
                >
                    <IconArrowBack />
                    BACK
                </Button>
            </Form.Item>
            <Form.Item style={{ marginTop: 50 }}>
                <Button
                    htmlType="submit"
                    className="general-button pointer-hover"
                    style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    DELETE
                    <IconArrowRight/>
                </Button>
            </Form.Item>
        </Input.Group>
    </Form>
  </div>;
};


export function usePrevious(value:any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export default DeleteWhiteLabelPage;
