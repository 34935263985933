import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import services from "../../services";
import { SpendPacingReport } from "../../models/SpendPacingReport";


export const fetchSpendPacingReport = createAsyncThunk(
  "spend-pacing-report/fetch",
  async (data: {monthNumber: number, year: number, locationId: string, userId?: string}) => {
    console.log('fetching spend and pacing report...');
    return services.spendPacingReportService.getSpendPacingReport(data.monthNumber, data.year, data.locationId, data?.userId);
  }
);



export const SpendPacingReportSlice = createSlice({
  name: "spendPacingReport",
  initialState: {
    report: undefined as SpendPacingReport | undefined,
    loading: true,
  },
  reducers: {
    setSpendPacingReport: (
      state,
      { payload: spendPacingReport }: PayloadAction<SpendPacingReport>
    ) => {
      state.report = spendPacingReport;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpendPacingReport.fulfilled, (state, action) => {
      state.report = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSpendPacingReport.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const selectSpendPacingReport = (state: RootState): SpendPacingReport | undefined =>
  state.spendPacingReport.report;

export const selectLoading = (state: RootState) : boolean =>
  state.spendPacingReport.loading;

export const { setSpendPacingReport } = SpendPacingReportSlice.actions;
  
export default SpendPacingReportSlice.reducer;
