import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectEvents } from "../../../store/Events/EventStore";
import { Event } from "../../../models/Event";
import "./ManageEvents.css";
import EventCard from "./EventCard";
import { ReactComponent as DisplayMoreIcon } from "../../../assets/icons/display-more.svg";
import { Button } from "antd";
import { appColors } from "../../../assets/variables/colors";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import SidePanelCreateEvent from "../../eventCalendar/modals/SidePanelCreateEvent/SidePanelCreateEvent";

const ManageEvents: React.FC = () => {
  const events: Event[] = useSelector(selectEvents);

  const [hasMoreEvents, setHasMoreEvents] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const whiteLabel = useSelector( selectWhiteLabel );


  const openCreateEventModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h1 className="text-2xl">Event manager</h1>
      <div className="">
        <Button
          className="primary-button pointer-hover"
          onClick={openCreateEventModal}
        >
        <div className="primary-button-text">NEW</div>
        </Button>
        <SidePanelCreateEvent
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          onCancel={handleCancel}
          isEdit={false}
        />

      </div>
    </div>
  );
};

export default ManageEvents;
