import "./AdCampaignModal.css";
import { ReactComponent as DigitalIcon } from "../../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as DirectMailIcon } from "../../../../assets/icons/DirectMailIcon.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/EmailIcon.svg";
import { ReactComponent as IconOpening } from "../../../../assets/icons/opening.svg";
import { ReactComponent as OotIcon } from "../../../../assets/icons/oot-icon.svg";
import { ReactComponent as NativeIcon } from "../../../../assets/icons/native-icon.svg";
import { ReactComponent as CtvOttIcon } from "../../../../assets/icons/ctv-ott-icon.svg";
import { ReactComponent as GoogleIcon } from "../../../../assets/icons/google-icon.svg";
import { ReactComponent as NewCustomerIcon } from "../../../../assets/icons/new-customer-icon.svg";
import { CampaignCustomPackage } from "../../../../models/CampaignPackage";

interface AdCampaignModalPackageRowProps {
  cPackage: CampaignCustomPackage;
}

export const AdCampaignModalPackageRow = ({
  cPackage,
}: AdCampaignModalPackageRowProps): JSX.Element => {
  const renderChannelIcon = (title: string): JSX.Element => {
    if (title === "CTV & OTT") {
      return <CtvOttIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "Facebook") {
      return <FacebookIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "Google") {
      return <GoogleIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "Print") {
      return <DirectMailIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "Grand Opening" || title === "Grand Opening Lite") {
      return <IconOpening style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "New Customer" || title === "New Customer Lite") {
      return <NewCustomerIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "Email") {
      return <EmailIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "Digital") {
      return <NativeIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "OTT") {
      return <OotIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (title === "Display") {
      return <DigitalIcon style={{ height: "100%", paddingTop: 5 }} />;
    }
    return <></>;
  };

  return (
    <div
      style={{
        height: "50px",
        width: "100%",
        display: "flex",
        margin: "20px 10px",
      }}
    >
      {cPackage.customPackage && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "35%",
          }}
        >
          {renderChannelIcon(cPackage.customPackage.title)}
          <h2 style={{ margin: "0px" }}>{cPackage.customPackage.title}</h2>
        </div>
      )}
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p style={{ margin: "0px" }}>
          Start Date: {new Date(cPackage.startDate).toLocaleDateString()}
        </p>
        {cPackage.endDate && (
          <p style={{ margin: "0px" }}>
            End Date: {new Date(cPackage.endDate).toLocaleDateString()}
          </p>
        )}
      </div>
    </div>
  );
};
