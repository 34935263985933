import "./UserPaymentMethods.css";
import { PaymentMethod } from "../../models/PaymentMethod";
import { capitalizeFirstLetter } from "../../utility/string";
import { ReactComponent as CreditCardIcon } from "../../assets/icons/CreditCardIcon.svg";
import { ReactComponent as IconCancel } from "../../assets/icons/cancel.svg";
import { useDispatch } from "react-redux";
import { removeUserPayment } from "../../store/Account/AccountStore";
import { useState } from "react";

interface SavedPaymentCardProps {
  method: PaymentMethod;
}

export const SavedPaymentCard = ({
  method,
}: SavedPaymentCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  return (
    <div className={classNames.card}>
      {showDelete ? (
        <>
          <h2>Are you sure you want to remove this card?</h2>
          <h2
            className={classNames.deleteOption}
            onClick={() => dispatch(removeUserPayment(method.cardId))}
          >
            Yes
          </h2>
          <h2
            className={classNames.deleteOption}
            onClick={() => setShowDelete(false)}
          >
            No
          </h2>
        </>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", width: "70%" }}>
            <CreditCardIcon style={{ height: "100%", marginRight: "10px" }} />
            <div>
              <h2>
                {capitalizeFirstLetter(method.cardBrand)} ending in {method.last4}
              </h2>
              <p className="hidden res:block">
                Expires {method.expirationMonth}/{method.expirationYear}
              </p>
            </div>
          </div>
          <div
            className=" res:!justify-end"
            style={{
              display: "flex",
              alignItems: "center",
              width: "30%",
              justifyContent: "space-between",
            }}
          >
            <p className="res:hidden">
              Expires {method.expirationMonth}/{method.expirationYear}
            </p>
            <IconCancel
              style={{ height: "100%", cursor: "pointer" }}
              onClick={() => setShowDelete(true)}
            />
          </div>
        </>
      )}
    </div>
  );
};

const classNames = {
  card: "user-payment-methods-saved-card",
  deleteOption: "user-payment-methods-saved-card-delete-option",
};
