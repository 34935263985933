import React, { useState } from "react";
import { ReactComponent as IconArrowDown } from "../../assets/icons/arrow-down.svg";
import "./styles.css";
import {
  Avatar,
  Box,
  IconButton,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { selectUserAccount } from "../../store/Account/AccountStore";
import {selectMenuType, setMenuType} from "../../store/Menu/MenuStore";
import { orange } from '@mui/material/colors';
import CustomTooltip from "../tooltip/tooltip";
import { MenuType } from "./Menu/MenuType";
import DefaultMenu from "./Menu/Default";
import RolesMenu from "./Menu/Roles";
import FranchiseMenu from "./Menu/franchises";

const HeaderLayout = ({ children }: any) => {
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const account = useSelector(selectUserAccount);
  const selectedMenuType = useSelector(selectMenuType);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    dispatch(setMenuType(MenuType.Default));
  };

  return (
    <div className="content-header res:h-16">
      {children}
      <div className="content-header-sidebar-welcome res:pr-6">
        <div
          className="content-header-sidebar-welcome-text res:hidden"
          style={{ marginRight: "15px" }}
        >
          {account?.firstName + " " + account?.lastName}
        </div>

        <Box sx={{ flexGrow: 0 }}>
          <CustomTooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="profile-photo"
                className="primary-icon res:!hidden"
                sx={{ bgcolor: orange[500] }}
              />
              <IconArrowDown />
            </IconButton>
          </CustomTooltip>
          {
            selectedMenuType === MenuType.Default ? <DefaultMenu closeMenuHandler={handleCloseUserMenu} anchorEl={ anchorElUser }  />
              :
                selectedMenuType === MenuType.Roles ? <RolesMenu closeMenuHandler={handleCloseUserMenu} anchorEl={ anchorElUser } />
                  : 
                    selectedMenuType === MenuType.Franchises ? <FranchiseMenu closeMenuHandler={handleCloseUserMenu} anchorEl={ anchorElUser } />
                      : ''
          }
        </Box>
      </div>
    </div>
  );
};

export default React.memo(HeaderLayout);
