export const FormConfig = {
    cpa_guard: {
        fields: [
            {
                type: 'input',
                name: 'upper_threshold',
                label: 'Upper Threshold',
            },
            {
                type: 'input',
                name: 'lower_threshold',
                label: 'Lower Threshold',
            }
        ]
    },
    keyword_guard: {
        fields: [
            {
                type: 'input',
                name: 'ratio_threshold',
                label: 'Ratio Threshold'
            },
            {
                type: 'frequency',
                name: 'frequency',
                label: 'Frequency',
            }
        ]
    },
    creative_boost: {
        fields: [
            {
                type: 'input',
                label: 'Window',
                name: 'window',
            }
        ]
    }
}
export default FormConfig;