import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./TargetZone.css";
import GoogleMapReact from "google-map-react";
import {
  addNegativeTargetZonePoint,
  addTargetZonePoint,
  removeLastNegativeTargetZonePoint,
  removeLastTargetZonePoint,
  selectDisplayCrosswalkOverlay,
  selectDisplayRedemptionsOverlay,
  selectIsCustomTargetArea,
  selectIsNegativeCustomTargetArea,
  selectIsNegativeZone,
  selectNegativeTargetZonePoints,
  selectNegativeZoneRadius,
  selectPreviewArea,
  selectPreviewAudience,
  selectSavedTargetZones,
  selectSelectedLocation,
  selectTargetZonePoints,
  selectZoneRadius,
  selectDefaultRadiusInMiles,
  setNegativeTargetZonePoints,
  setTargetZoneChanged,
  setTargetZonePoints,
  setZoneRadius,
  selectZipModeEnabled,
  selectPrimaryZips,
  selectSecondaryZips
} from "../../../store/campaignForm/CampaignForm";
import TargetZonePointMarker from "./TargetZonePointMarker";
import { appColors } from "../../../assets/variables/colors";
import heatmap1 from "../../../assets/exampleImages/HeatMap1.png";
import heatmap2 from "../../../assets/exampleImages/HeatMap2.png";
import LocationMarker from "./LocationMarker";
import { metersInAMile } from "../../../utility/geography";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

const GoogleKey = process.env.REACT_APP_GOOGLE_API_KEY;
const GoogleMapId = process.env.REACT_APP_GOOGLE_MAP_ID;

interface TargetZoneMapProps {
  displayButtons: boolean;
  canAddPointLocation: boolean;
}

const TargetZoneMap = ({
  displayButtons,
  canAddPointLocation,
}: TargetZoneMapProps) => {
  const dispatch = useDispatch();
  const points = useSelector(selectTargetZonePoints);
  const savedZones = useSelector(selectSavedTargetZones);
  const defaultRadiusInMiles = useSelector(selectDefaultRadiusInMiles);

  const displayCrosswalk = useSelector(selectDisplayCrosswalkOverlay);
  const displayRedemptions = useSelector(selectDisplayRedemptionsOverlay);
  const selectedLocation = useSelector(selectSelectedLocation);
  const isCustomTargetArea = useSelector(selectIsCustomTargetArea);
  const zoneRadius = useSelector(selectZoneRadius); // in meters
  const isNegativeZone = useSelector(selectIsNegativeZone);
  const negativeTargetZonePoints = useSelector(selectNegativeTargetZonePoints);
  const negativeZoneRadius = useSelector(selectNegativeZoneRadius);
  const isNegativeCustomTargetArea = useSelector(
    selectIsNegativeCustomTargetArea
  );

  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [previewCircle, setPreviewCircle] = useState<google.maps.Circle | null>(
    null
  );
  const [initialPoint, setInitialPoint] = useState(false);
  const [negativePolygon, setNegativePolygon] =
    useState<google.maps.Polygon | null>(null);
  const [negativePreviewCircle, setNegativePreviewCircle] =
    useState<google.maps.Circle | null>(null);
  const [mapObject, setMapObject] = useState<google.maps.Map | null>(null);
  const [zonePolygonsOnMap, setZonePolygonsOnMap] = useState<
    Array<google.maps.Polygon | google.maps.Circle>
  >([]);
  const [zoneHeatmap, setZoneHeatmap] = useState<google.maps.visualization.HeatmapLayer>(
    new google.maps.visualization.HeatmapLayer({data: []})
  );
  const [mapOverlay1, setMapOverlay1] = useState<GoogleMapOverlay | null>(null);
  const [mapOverlay2, setMapOverlay2] = useState<GoogleMapOverlay | null>(null);
  const previewArea = useSelector(selectPreviewArea);
  const previewAudience = useSelector(selectPreviewAudience);
  const usesZipMode = useSelector(selectZipModeEnabled);
  const selectedPrimaryZips = useSelector(selectPrimaryZips);
  const selectedSecondaryZips = useSelector(selectSecondaryZips);

  

  useEffect(() => {

    if(usesZipMode){
      previewCircle?.setRadius(0);
      polygon?.setPath([]);
    }else{
      if (isCustomTargetArea) {
        previewCircle?.setRadius(0);
        if (polygon !== null && points.length > 2) {
          polygon.setPath(points);
        } else {
          polygon?.setPath([]);
        }
      } else {
        polygon?.setPath([]);
        if (points.length > 0) {
          previewCircle?.setRadius(zoneRadius);
          previewCircle?.setCenter(points[0]);
        } else {
          previewCircle?.setRadius(zoneRadius);
        }
      }
    }
    
  }, [points, zoneRadius, usesZipMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if( usesZipMode ){
      negativePreviewCircle?.setRadius(0);
      negativePolygon?.setPath([]);
    }else{
      if (isCustomTargetArea) {
        negativePreviewCircle?.setRadius(0);
        if (isNegativeCustomTargetArea) {
          if (negativePolygon !== null && negativeTargetZonePoints.length > 2) {
            negativePolygon.setPath(negativeTargetZonePoints);
          } else {
            negativePolygon?.setPath([]);
          }
        } else {
          if (negativeTargetZonePoints.length > 0) {
            negativePreviewCircle?.setRadius(negativeZoneRadius);
            negativePreviewCircle?.setCenter(negativeTargetZonePoints[0]);
          } else {
            negativePreviewCircle?.setRadius(negativeZoneRadius);
          }
        }
      } else {
        negativePolygon?.setPath([]);
        if (!isNegativeCustomTargetArea) {
          if (negativeTargetZonePoints.length > 0) {
            negativePreviewCircle?.setRadius(negativeZoneRadius);
            negativePreviewCircle?.setCenter(negativeTargetZonePoints[0]);
          } else {
            negativePreviewCircle?.setRadius(negativeZoneRadius);
          }
        } else {
          if (negativePolygon !== null && negativeTargetZonePoints.length > 2) {
            negativePolygon.setPath(negativeTargetZonePoints);
          } else {
            negativePolygon?.setPath([]);
          }
        }
      }
    }
    
  }, [negativeTargetZonePoints, negativeZoneRadius]); // eslint-disable-line react-hooks/exhaustive-deps

  

  useEffect(() => {
    if( usesZipMode ){
      zonePolygonsOnMap.forEach((poly) => {
        poly.setMap(null);
      });
    }else{
      if (mapObject) {
        const currentPolygons = savedZones.map((zone) => {
          if (zone.center && zone.radiusInMiles) {
            return new google.maps.Circle({
              radius: zone.radiusInMiles * metersInAMile,
              center: zone.center,
              strokeColor: whiteLabel.primaryColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: whiteLabel.primaryColor,
              fillOpacity: 0.35,
            });
          } else if (zone.points) {
            return new google.maps.Polygon({
              paths: zone.points,
              strokeColor: whiteLabel.primaryColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: whiteLabel.primaryColor,
              fillOpacity: 0.35,
            });
          }
          return new google.maps.Polygon();
        });
  
        // remove old
        zonePolygonsOnMap.forEach((poly) => {
          poly.setMap(null);
        });
  
        // add new
        currentPolygons.forEach((poly) => {
          poly.setMap(mapObject);
        });
        setZonePolygonsOnMap(currentPolygons);
      }
    }
    
  }, [savedZones, mapObject, usesZipMode]); // eslint-disable-line react-hooks/exhaustive-deps

  /* 
    Toggles and shows the heatmap based on the selected zip codes.
  */
  useEffect( () => {
    if(!usesZipMode){
      zoneHeatmap.setMap(null);
    }else{
      if(mapObject){
        //Obtains a list of google.maps.LatLng based on the selected zips and the
        //values stored in primary_coords and secondary_coords in the selectedLocation.
        const primaryMatches = [];
        for(const zipCode of selectedPrimaryZips){
          const matchingItem = selectedLocation?.primaryCoords.find(item => item.zip === zipCode);
          if(matchingItem){
            primaryMatches.push(matchingItem);
          }
        }
        const secondaryMatches = [];
        for(const zipCode of selectedSecondaryZips){
          const matchingItem = selectedLocation?.secondaryCoords.find(item => item.zip === zipCode);
          if(matchingItem){
            secondaryMatches.push(matchingItem);
          }
        }

        const combinedMatches = primaryMatches.concat(secondaryMatches);

        const heatMapData = [] as Array<google.maps.LatLng>;
        for( const latLonPair of combinedMatches){
          heatMapData.push(new google.maps.LatLng(latLonPair.lat, latLonPair.lng));
        }

        const heatMap = new google.maps.visualization.HeatmapLayer({
          data: heatMapData,
          radius: 25,
        })
        zoneHeatmap.setMap(null);
        heatMap.setMap(mapObject)  
        setZoneHeatmap(heatMap);
      }
    }
  }, [selectedPrimaryZips, selectedSecondaryZips, usesZipMode, mapObject])

  useEffect(() => {
    if (displayCrosswalk) {
      mapOverlay1?.show();
    } else {
      mapOverlay1?.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayCrosswalk]);

  useEffect(() => {
    if (displayRedemptions) {
      mapOverlay2?.show();
    } else {
      mapOverlay2?.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayRedemptions]);

  const onClickMap = (point: GoogleMapReact.ClickEventValue): void => {
    if (canAddPointLocation) {
      if (isCustomTargetArea) {
        if (isNegativeZone) {
          if (isNegativeCustomTargetArea) {
            dispatch(
              addNegativeTargetZonePoint({ lat: point.lat, lng: point.lng })
            );
          } else {
            // Only one point for center and radius
            dispatch(
              setNegativeTargetZonePoints([{ lat: point.lat, lng: point.lng }])
            );
          }
        } else {
          dispatch(addTargetZonePoint({ lat: point.lat, lng: point.lng }));
        }
      } else {
        if (isNegativeZone) {
          if (!isNegativeCustomTargetArea) {
            dispatch(
              setNegativeTargetZonePoints([{ lat: point.lat, lng: point.lng }])
            );
          } else {
            dispatch(
              addNegativeTargetZonePoint({ lat: point.lat, lng: point.lng })
            );
          }
        } else {
          // Only one point for center and radius
          dispatch(setTargetZonePoints([{ lat: point.lat, lng: point.lng }]));
        }
      }
      dispatch(setTargetZoneChanged(true));
    }
  };

  let defaultProps = {
    center: {
      lat: 44.993485569624426,
      lng: -93.2647470528799,
    },
    zoom: 11,
  };

  if (selectedLocation && !selectedLocation.isRegion) {
    defaultProps.center.lat = Number(selectedLocation.latitude)
    defaultProps.center.lng = Number(selectedLocation.longitude)
  }

  
  const onApiLoad = (map: any) => {
    setMapObject(map);


    if (selectedLocation && selectedLocation.isRegion && selectedLocation.places.length > 0) {
      const featureLayer = map.getFeatureLayer('ADMINISTRATIVE_AREA_LEVEL_1');
      const featureStyleOptions = {
        strokeColor: whiteLabel.secondaryColor,
        strokeOpacity: 0.5,
        strokeWeight: 1.0,
        fillColor: whiteLabel.secondaryColor,
        fillOpacity: 0.25
      };

      const placeIds: string[] = selectedLocation.places.map((item) => {
        return item.placeId;
      });

      // @ts-ignore
      featureLayer.style = (options: { feature: { placeId: string; }; }) => {
        if (selectedLocation && placeIds.includes(options.feature.placeId)) {
          return featureStyleOptions;
        }
      };
    }

    const customArea = new google.maps.Polygon({
      paths: points,
      strokeColor: whiteLabel.primaryColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: whiteLabel.primaryColor,
      fillOpacity: 0.35,
    });

    const circle = new google.maps.Circle({
      radius: zoneRadius,
      strokeColor: whiteLabel.primaryColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: whiteLabel.primaryColor,
      fillOpacity: 0.35,
    });

    const negativeCustomArea = new google.maps.Polygon({
      paths: negativeTargetZonePoints,
      strokeColor: appColors.RED,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: appColors.RED,
      fillOpacity: 0.35,
    });

    const negativeCircle = new google.maps.Circle({
      radius: negativeZoneRadius,
      strokeColor: appColors.RED,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: appColors.RED,
      fillOpacity: 0.35,
    });

    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(44.853192658158235, -93.40653935024318),
      new google.maps.LatLng(45.13676714268516, -93.12295475551662)
    );

    const heatmapOverlay1: GoogleMapOverlay = new GoogleMapOverlay(
      bounds,
      heatmap1
    );
    heatmapOverlay1.setMap(map);
    setMapOverlay1(heatmapOverlay1);

    const heatmapOverlay2: GoogleMapOverlay = new GoogleMapOverlay(
      bounds,
      heatmap2
    );
    heatmapOverlay2.setMap(map);
    setMapOverlay2(heatmapOverlay2);

    if (selectedLocation && selectedLocation.isRegion) {
      const centerCoords = selectedLocation.places.length !== 1 ? defaultProps.center : {
        lat: selectedLocation.places[0].lat,
        lng: selectedLocation.places[0].lng
      };

      map.setCenter(new google.maps.LatLng(centerCoords.lat, centerCoords.lng), 10);
      map.setZoom(selectedLocation.places.length > 1 ? 4 : 6);
    }

    customArea.setMap(map);
    setPolygon(customArea);

    circle.setMap(map);
    setPreviewCircle(circle);
    setInitialPoint(true);

    negativeCustomArea.setMap(map);
    setNegativePolygon(negativeCustomArea);

    negativeCircle.setMap(map);
    setNegativePreviewCircle(negativeCircle);

  };

  useEffect(() => {
    if (initialPoint && previewCircle) {
      if (canAddPointLocation) {
        dispatch(setTargetZonePoints([{ lat: Number(selectedLocation?.latitude || defaultProps.center.lat), lng: Number(selectedLocation?.longitude || defaultProps.center.lng) }]));
        dispatch(setZoneRadius(metersInAMile * defaultRadiusInMiles));
      }
      setInitialPoint(false);
    }
  }, [initialPoint, previewCircle, selectedLocation])

  const clearMap = (): void => {
    if (isNegativeZone) {
      dispatch(setNegativeTargetZonePoints([]));
    } else {
      dispatch(setTargetZonePoints([]));
    }
  };

  const undoMapMark = () => {
    if (isNegativeZone) {
      dispatch(removeLastNegativeTargetZonePoint());
    } else {
      dispatch(removeLastTargetZonePoint());
    }
  };

  // Sorry this class is defined here, safari/ios fails to load window.google otherwise
  // mostly stolen from: https://developers.google.com/maps/documentation/javascript/customoverlays
  class GoogleMapOverlay extends google.maps.OverlayView {
    private bounds: google.maps.LatLngBounds;
    private image: string;
    private div?: HTMLElement;

    constructor(bounds: google.maps.LatLngBounds, image: string) {
      super();

      this.bounds = bounds;
      this.image = image;
    }

    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    onAdd() {
      this.div = document.createElement("div");
      this.div.style.borderStyle = "none";
      this.div.style.borderWidth = "0px";
      this.div.style.position = "absolute";

      // Create the img element and attach it to the div.
      const img = document.createElement("img");

      img.src = this.image;
      img.style.width = "100%";
      img.style.height = "100%";
      img.style.position = "absolute";
      this.div.appendChild(img);
      this.div.style.visibility = "hidden";

      // Add the element to the "overlayLayer" pane.
      const panes = this.getPanes()!;

      panes.overlayLayer.appendChild(this.div);
    }

    draw() {
      // We use the south-west and north-east
      // coordinates of the overlay to peg it to the correct position and size.
      // To do this, we need to retrieve the projection from the overlay.
      const overlayProjection = this.getProjection();

      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      const sw = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getSouthWest()
      )!;
      const ne = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getNorthEast()
      )!;

      // Resize the image's div to fit the indicated dimensions.
      if (this.div) {
        this.div.style.left = sw.x + "px";
        this.div.style.top = ne.y + "px";
        this.div.style.width = ne.x - sw.x + "px";
        this.div.style.height = sw.y - ne.y + "px";
      }
    }

    /**
     * The onRemove() method will be called automatically from the API if
     * we ever set the overlay's map property to 'null'.
     */
    onRemove() {
      if (this.div) {
        (this.div.parentNode as HTMLElement).removeChild(this.div);
        delete this.div;
      }
    }

    /**
     *  Set the visibility to 'hidden' or 'visible'.
     */
    hide() {
      if (this.div) {
        this.div.style.visibility = "hidden";
      }
    }

    show() {
      if (this.div) {
        this.div.style.visibility = "visible";
      }
    }

    toggle() {
      if (this.div) {
        if (this.div.style.visibility === "hidden") {
          this.show();
        } else {
          this.hide();
        }
      }
    }

    toggleDOM(map: google.maps.Map) {
      if (this.getMap()) {
        this.setMap(null);
      } else {
        this.setMap(map);
      }
    }
  }

  const whiteLabel = useSelector( selectWhiteLabel );

  return (
    <>
      {displayButtons && (
        <div className="map-button-container">
          <button className="map-button map-button-clear" onClick={clearMap}>
            CLEAR
          </button>
          <button className="map-button map-button-undo" onClick={undoMapMark}>
            UNDO
          </button>
        </div>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: GoogleKey ?? "", v: "3.30" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onClick={onClickMap}
        options={{mapId: GoogleMapId}}
        onGoogleApiLoaded={({ map }) => {
          onApiLoad(map);
        }}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {points.map((point) => (
          <TargetZonePointMarker
            key={`target zone point:${point.lat},${point.lng}`}
            lat={point.lat}
            lng={point.lng}
            name="My Marker"
            color={whiteLabel.primaryColor}
          />
        ))}
        {negativeTargetZonePoints.map((point) => (
          <TargetZonePointMarker
            key={`negative target zone point:${point.lat},${point.lng}`}
            lat={point.lat}
            lng={point.lng}
            name="My Marker"
            color={appColors.RED}
          />
        ))}
        { selectedLocation !== null && !selectedLocation.isRegion && (
          <LocationMarker
            lat={selectedLocation.latitude}
            lng={selectedLocation.longitude}
          />
        )}
      </GoogleMapReact>
      {previewArea !== 0 && previewAudience !== 0 && (
        <div className="mobile-preview-info-container">
          <div className="mobile-preview-info-text">
            Estimated Audience: {previewAudience.toLocaleString()}
          </div>
          <div className="mobile-preview-info-text">
            Estimated Area: {previewArea.toFixed(2).toLocaleString()} mi
            <sup>2</sup>
          </div>
        </div>
      )}
    </>
  );
};

export default TargetZoneMap;
