import React, {useEffect, useState} from "react";
import { Col } from "antd";
import { DemographicsFranchiseList } from "./DemographicsFranchiseList";
import { AssignAudiencesList } from "./AssignAudiencesList";
import { Audience } from "../../../models/Audience";
import style from "../mediaManager/MediaManager.module.css";
import { DemographicsFranchise } from "../../../models/Demographics";
import { setDemographicsFranchises } from "../../../store/Demographics/DemographicsStore";
import services from "../../../services";
import {useDispatch} from "react-redux";
//Temp
import { AssignDataSourceList } from "./AssignDataSourceList";

interface AssignDataSourcesProps {
    franchises: DemographicsFranchise[];
}

const initialFranchise = {id: 0, name: '', dataSource: '', audiences: [] };

//export const AssignAudiences = ({franchises}: AssignDataSourcesProps) => {
export const AssignAudiences = () => {

    //Temp
    const [franchises, setFranchises] = useState<string[]>(["Franchise 001", "Franchise 002", "Franchise 003", "Franchise 004"]);
    const [options, setOptions] = useState<string[]>(["Prospecting Q1", "Young age & high net worth", "Outdoor & high net worth "]);
    const [assignedFranchise, setAssignedFranchise] = useState<string>("");
    const [assignedDataSource, setAssignedDataSource] = useState<string>("");

    const dispatch = useDispatch();
    const [audiences, setAudiences] = useState<Audience[]>([]);
    const [selectedFranchise, setSelectedFranchise] = useState<DemographicsFranchise>(initialFranchise);

    useEffect(() => {
        services.franchiseService.getAll().then((franchises) => {

            const demographicFranchises = franchises.map((franchise) => {
                return Object.assign({id:'', name: '', audiences: [], dataSource: ''}, franchise);
            });

            if (franchises.length > 0) {
                dispatch(setDemographicsFranchises(demographicFranchises));
            }

            return franchises;
        });

        services.audienceSourceService.getSourceList().then(async (sources) => {
            const audienceList: Audience[] = [];
            for (let i = 0; i < sources.length; i++) {
                await services.audienceSourceService.getAudiencesBySource(sources[i]).then((resp) => {
                    if (Array.isArray(resp)) {
                        resp?.map((item) => {
                            audienceList.push({hash: item.hash, name: item.name});
                            return item;
                        });
                    }
                });
            }

            setAudiences(audienceList);
        });


    }, []);

    /**
     * Save audiences
     */
    const saveAudiences = () => {
        if (!selectedFranchise || !selectedFranchise.id) {
            return;
        }

        const franchise = services.franchiseService.saveAudiences(selectedFranchise?.id.toString(), audiences);
        setSelectedFranchise(initialFranchise);
        setAudiences(audiences);
    }

    return (
        <div>
            <div className={style["selector-container"]}>
                <div className={style["selector"]}>
                    {/*<DemographicsFranchiseList franchises={franchises} setSelectedFranchise={setSelectedFranchise} selectedFranchise={selectedFranchise}/>*/}
                    <AssignDataSourceList label="Franchises" options={franchises} setAssignedDataSource={setAssignedFranchise} />
                </div>

                <div className={style["selector"]}>
                    {/*<AssignAudiencesList audiences={audiences}></AssignAudiencesList>*/}
                    <AssignDataSourceList label="Active audiences" options={options} setAssignedDataSource={setAssignedDataSource} />
                </div>
            </div>

            <div className={style["actions"]}>
                <Col span={10}>
                    <div className="primary-button pointer-hover">
                        <div className="primary-button-text" onClick={saveAudiences}>SAVE</div>
                    </div>
                </Col>
            </div>
        </div>
    )
};

export default AssignAudiences