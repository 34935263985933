import { useState } from 'react';
import style from './Demographics.module.css';
import { useSelector } from 'react-redux';
import { DemographicsAudience } from '../../../models/Demographics';
import { getAudienceSelected } from '../../../store/Demographics/DemographicsStore';

interface DisplayAudiencesValuesProps {
    setValueSelected: (value: string) => void;
}

export const DisplayAudiencesValues = (props: DisplayAudiencesValuesProps) => {
    const audience: DemographicsAudience = useSelector(getAudienceSelected);
    const [selectedValue, setSelectedValue] = useState<string>('');

    const handleSelectedValue = (value: string) => {
        const [key, val] = value.split('=');
        props.setValueSelected(value);
        setSelectedValue(val.toString());
    }

  return (
    <div>
        <div>Options</div>

        <div>
            <div className={style["column"]}>
                {audience.values.map(value => (
                    <div
                        key={value}
                        className={`${style["item"]} ${selectedValue === value?.toString() ? style["selected"] : ''}`}
                        onClick={() => handleSelectedValue(audience.name + "=" + value)}>
                        {value}
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}
