import React from "react";
import "./styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LogIn from "../../components/auth/LogIn";
import SignUp from "../../components/auth/SignUp";
import ResetPassword from "../../components/auth/ResetPassword";
import Help from "../../components/auth/Help";
import Guides from "../../components/auth/Guides";
import Request from "../../components/auth/Request";
import QRLanding from "../../components/auth/QRLanding";
import { AvailableRoutes } from "../Routes";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import { assetImage } from "../../utility/whiteLabelManager";


const AuthPage: React.FC = () => {
  const whiteLabel = useSelector( selectWhiteLabel );

  return (
    <div className="container">
      <div className="content   res:flex res:flex-wrap res:h-[auto] ">
        <div className="auth-section res:order-2 res:flex-1 res:p-10">
            <Routes>
              <Route path="/" element={<LogIn />} />
              <Route path={AvailableRoutes.LOGIN} element={<LogIn />} />
              <Route path="/login/:type/:email/:code" element={<LogIn />} />
              <Route path={AvailableRoutes.SIGNUP} element={<SignUp />} />
              <Route
                path={AvailableRoutes.RESET_PASSWORD}
                element={<ResetPassword />}
              />
              <Route path={AvailableRoutes.HELP} element={<Help />} />
              <Route path={AvailableRoutes.GUIDES} element={<Guides />} />
              <Route path={AvailableRoutes.REQUEST} element={<Request />} />
              <Route path="*" element={<LogIn />} />
            </Routes>
        </div>
        <div className="background-section res:order-1 res:flex res:justify-start res:px-10 res:flex-[0_0_100%] res:pr-0 res:h-fit">
          <div className="text-background ">
            { whiteLabel.logoPath != ""
              ? <img className="brand-logo" src={ assetImage( whiteLabel.logoPath ) } alt="logo" />
              : <div className="text-background  res:text-[45px] res:leading-[50px] res:p-[36px]"></div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;