import styles from './TendencyCard.module.css'
import { IAmount } from './placeholderData'
import { selectTendency } from 'store/Franchise/FranchiseStore'
import { useSelector } from 'react-redux'
import CustomTooltip from '../../tooltip/tooltip'
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import { ReactComponent as UpRightArrow } from "assets/icons/arrow-up-right.svg";
import { ReactComponent as DownRightArrow } from "assets/icons/arrow-down-right.svg";

interface ITendencyCardProps {
  amount: IAmount
  setTendencyCard?: any 
  isLandingCard: boolean,
  haveTooltip?: boolean,
  titleTooltip?: string,
}

export const TendencyCard = ({ amount, setTendencyCard, isLandingCard = false, haveTooltip = false, titleTooltip }: ITendencyCardProps) => {
  const tendency = useSelector(selectTendency);
  const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);

  const Variance = () => {
    amount.varianceAmount = -10;
    if (amount.varianceAmount > 0) return <> <UpRightArrow className=" w-[15px] h-[15px]  " /> <span className='text-[#0d9051]'>15%</span> </>
    else if (amount.varianceAmount < 0) return  <> <DownRightArrow className=" w-[15px] h-[15px] " /> <span className='text-[#fd5566]'>15%</span> </>
    else return <span className={styles['tendency-card-arrow']}></span>
  }

  // return (
  //   <div className={ ` ${styles['tendency-card']} relative  ${ (tendency === amount.name && !isLandingCard) ? "outline-[--color-primary] outline outline-1" : "" } `}
  //     onClick={ () =>  setTendencyCard ? setTendencyCard(amount.name) : null }
  //   >
  //     <span>{amount.name}</span>
  //     <span className='mt-3'>{amount.prefix}{amount.amount}{amount.suffix}</span>
  //     <span className={styles['tendency-card-arrow-container'] + " "}>
  //       {/*<div className='absolute bottom-2 right-2 flex flex-col items-end'>{Variance()}</div>*/}
  //     </span>
  //   </div>
  // )

  return (
  <>
    {amount.amount !== "0" && (
      <div
        className={` ${styles['tendency-card']} relative  ${
          tendency === amount.name && !isLandingCard ? "outline-[--color-primary] outline outline-1" : ""
        } `}
        onClick={() => (setTendencyCard ? setTendencyCard(amount.name) : null)}
      >
        <span>{amount.name}</span>
        <span className='mt-3'>{amount.prefix}{amount.amount}{amount.suffix}</span>
        <span className={styles['tendency-card-arrow-container'] + " "}>
          {/*<div className='absolute bottom-2 right-2 flex flex-col items-end'>{Variance()}</div>*/}
        </span>


        {  haveTooltip == true && (
          <div>
            <CustomTooltip
              open={showAlwaysOnTooltip}
              onMouseEnter={() => setShowAlwaysOnTooltip(true)}
              onMouseLeave={() => setShowAlwaysOnTooltip(false)}
              title={amount.tooltipTitle ? amount.tooltipTitle : ""}
              arrow
            >
              <InfoCircleOutlined
                style={{ color: "#424242", fontSize: "18px", marginLeft: '0px', marginRight: "0px", position: "absolute", top: "10px", right: "10px", zIndex: "99" }}
              />
            </CustomTooltip>
          </div>
        ) }
        
      </div>


      
    )}
  </>
)
  
}

export default TendencyCard
