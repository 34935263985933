import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import services from "../../services";
import { IFranchiseOptConfig } from "../../services/OptimizationService";

/**
 * Update optimization settings
 */
export const updateOptimizationSettings = createAsyncThunk(
  'franchises/optimization/settings/save',
  async (config: IFranchiseOptConfig) => {
        return await services.optimizationService.saveOptimizationSettings(config)
  }
);

/**
 * Fetch franchise optimization settings
 */
export const fetchOptimizationSettings = createAsyncThunk(
  'franchises/optimization/settings/get',
  async () => {
      return await services.optimizationService.fetchOptimizationSettings();
    }
);

export const keywordHelperSlice = createSlice({
    name: "keywordHelper",
    initialState: {
        infoModalState: false,
        configOptions: {},
    },
    reducers: {
        setInfoModalState: (state, { payload: value }: PayloadAction<boolean>) => {
            state.infoModalState = value;
        },
        setConfigOptions: (state, { payload: value }: PayloadAction<object>)  => {
            state.configOptions = value;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateOptimizationSettings.fulfilled, (state, action) => {
            console.log(action.payload);
            state.configOptions = action.payload;
        });
        builder.addCase(fetchOptimizationSettings.fulfilled, (state, action) => {
            console.log(`Fetch`, action.payload);
            state.configOptions = action.payload;
        });
    }
});

export const { setInfoModalState } = keywordHelperSlice.actions;

export const selectInfoModalState = (state: RootState): boolean => state.keywordHelper.infoModalState

export const { setConfigOptions } = keywordHelperSlice.actions;
export const selectConfigOptions = (state: RootState): object => state.keywordHelper.configOptions;


export default keywordHelperSlice.reducer;