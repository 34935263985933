import style from './MediaManager.module.css'

const AssignList = ({ options }: { options: string[] }) => {
    return (
      <div>
        <p>Assign to: </p>
        <div className={style["column"]}>
          {options.map(option => (
            <div key={option} className={style["item"]}>
              {option}
            </div>
          ))}
        </div>
      </div>
    );
};
export { AssignList };