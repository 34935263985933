import "./AdCampaignModal.css";
import { ReactComponent as DigitalIcon } from "../../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as DirectMailIcon } from "../../../../assets/icons/DirectMailIcon.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/EmailIcon.svg";
import { ReactComponent as IconOpening } from "../../../../assets/icons/opening.svg";
import { ReactComponent as OotIcon } from "../../../../assets/icons/oot-icon.svg";
import { ReactComponent as NativeIcon } from "../../../../assets/icons/native-icon.svg";
import { ReactComponent as CtvOttIcon } from "../../../../assets/icons/ctv-ott-icon.svg";
import { ReactComponent as GoogleIcon } from "../../../../assets/icons/google-icon.svg";
import { ReactComponent as NewCustomerIcon } from "../../../../assets/icons/new-customer-icon.svg";
import { CampaignChannel } from "../../../../models/CampaignChannel";
import { ChannelType } from "../../../../models/Channel";

interface AdCampaignModalChannelRowProps {
  channel: CampaignChannel;
}

export const AdCampaignModalChannelRow = ({
  channel,
}: AdCampaignModalChannelRowProps): JSX.Element => {

  const renderChannelIcon = (type: ChannelType): JSX.Element => {
    if (type.title === "CTV & OTT") {
      return <CtvOttIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "Facebook") {
      return <FacebookIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "Google") {
      return <GoogleIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "Print") {
      return <DirectMailIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "Grand Opening" || type.title === "Grand Opening Lite") {
      return <IconOpening style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "New Customer" || type.title === "New Customer Lite") {
      return <NewCustomerIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "Email") {
      return <EmailIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "Digital") {
      return <NativeIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "OTT") {
      return <OotIcon style={{ height: "100%", paddingTop: 5 }} />;
    } else if (type.title === "Display") {
      return <DigitalIcon style={{ height: "100%", paddingTop: 5 }} />;
    }
    return <></>;
  };

  return (
    <div
      style={{
        height: "40px",
        width: "100%",
        display: "flex",
        margin: "20px 10px",
      }}
    >
      {channel.type && (
        <div>
          <h2 style={{ margin: "3px" }}>{channel.type.title}</h2>
        </div>
      )}
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p style={{ margin: "50px" }}>
          Start Date: {new Date(channel.startDate).toLocaleDateString()}
        </p>
        {channel.endDate && (
          <p style={{ margin: "50px" }}>
            End Date: {new Date(channel.endDate).toLocaleDateString()}
          </p>
        )}
      </div>
    </div>
  );
};
