import { ReactNode } from 'react';
import styles from './styles.module.css'

interface ModalProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const SideModal = ({ title, children, isOpen, onClose }: ModalProps) => {

  return (
    <div className={`${styles['modal']} ${isOpen ? styles['modal-open'] : styles['modal-closed']}`}>
      <div className={styles['modal-content']}>
        <div className={styles['modal-header']}>
          <span>{title}</span>
          <button className={styles['modal-close-btn']} onClick={onClose}>
            &times;
          </button>
        </div>
        <div className={styles['modal-content']}>{children}</div>
      </div>
    </div>
  );
};



export default SideModal;