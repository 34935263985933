import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminManager, AdminManagerFranchise } from "../../models/AdminManager";
import { RootState } from "../index";
import { getTestFranchises } from "./AdminManagerTestDataUtil"

// Placeholders, here we need to call a real API
export const fetchAdminManagerFranchises = createAsyncThunk(
  "adminManager/fetchAdminManagerFranchises",
  async (): Promise<AdminManagerFranchise[]> => {
    return await getTestFranchises();
  }
);

export const AdminManagerSlice = createSlice({
  name: "AdminManager",
  initialState: {
    adminManager: {
      franchises: getTestFranchises() as AdminManagerFranchise[],
    } as AdminManager,
  },
  reducers: {
    setAdminManager: (state, { payload: AdminManager }: PayloadAction<AdminManager>) => {
      state.adminManager = AdminManager;
    },
    setAdminManagerFranchises: (state, { payload: AdminManagerFranchises }: PayloadAction<AdminManagerFranchise[]>) => {
      state.adminManager.franchises = AdminManagerFranchises;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminManagerFranchises.fulfilled, (state, action) => {
      state.adminManager.franchises = action.payload;
    });
  },
});

export const selectAdminManagerFranchises = (state: RootState): AdminManagerFranchise[] => state.adminManagers.adminManager.franchises;

export const {
  setAdminManager,
  setAdminManagerFranchises,
} = AdminManagerSlice.actions;


export default AdminManagerSlice.reducer;
