import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import {
  setTemplateTitle,
  selectSelectedTemplates,
  setSelectedTemplates,
  selectTemplateTitle,
  selectTemplateChannel,
  selectTotalArea,
  selectSelectedLocation,
  setPreSelectedTemplates,
  selectPreSelectedTemplates
} from "../../../../store/campaignForm/CampaignForm";
import { CreativeTemplate } from "../../../../models/CreativeTemplate";
import styles from './channelTemplateDrawer.module.css';
import './styles.css'
import {
  fetchChannelImages,
  selectCreativeTemplates,
} from "../../../../store/CreativeTemplates/CreativeTemplateStore";
import { ReactComponent as IconArrowRightCircle } from "../../../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as IconArrowRightBlack } from "../../../../assets/icons/arrow-right-black.svg";
import { ReactComponent as IconArrowLeftCircle } from "../../../../assets/icons/arrow-left-circle.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check-circle.svg";
import { ChannelType } from "../../../../models/Channel";

export const ChannelTemplateModal = () => {
  const dispatch = useDispatch();
  const templateTitle: string = useSelector(selectTemplateTitle);
  const templateChannel: ChannelType | null = useSelector(selectTemplateChannel);
  const [open, setOpen] = useState(false);
  const carouselRef = useRef<CarouselRef>(null);
  const templates: CreativeTemplate[] = useSelector(selectCreativeTemplates);
  const selectedTemplates = useSelector(selectSelectedTemplates);
  const selectedLocation = useSelector(selectSelectedLocation);
  const totalArea = useSelector(selectTotalArea);
  const [selectedCarouselTemplate, setSelectedCarouselTemplate] = useState({} as CreativeTemplate);
  const [carouselTemplates, setCarouselTemplates] = useState<CreativeTemplate[]>([]);
  const preSelectedTemplates: CreativeTemplate[] = useSelector(
    selectPreSelectedTemplates
  );

  
  useEffect(() => {
    if (templateChannel?.id) {
      dispatch(fetchChannelImages({
        channelId: templateChannel?.id,
        //message: selectedLocation?.name + "\n" + totalArea.toFixed(2).toLocaleString() + ' mi',
        // message: selectedLocation?.name + "\n" + selectedLocation?.proximity,
        message: " "
      }));
    }
  }, [templateChannel, totalArea, selectedLocation]);

  /**
   * Updated this effect to support media channel updates.
   * On top of using this effect to preselect the carousel active template, it
   * is also used to preselect the preSelectedTemplates state.
   * When no template has been selected for this channel before, it sets the first slide as the selected preSelectedTemplate.
   * When a template was selected before, it keeps that value in state and updates the carousel active
   * to keep the same active slide as the preSelectedTemplates state. 
  */
  useEffect(() => {
    let templatesToShow = [] as CreativeTemplate[];
    if (!templateChannel) templatesToShow = [...templates];
    else templatesToShow = templates.filter(template => template.channelId === templateChannel?.id);
    setCarouselTemplates(templatesToShow)
    if (
        templatesToShow &&
        templatesToShow.length &&
        preSelectedTemplates.filter((template) => template.channelId === templateChannel?.id).length === 0
      ) {
      setSelectedCarouselTemplate(templatesToShow[0])
      dispatch(setSelectedTemplates([templatesToShow[0]]))

      dispatch(
        setPreSelectedTemplates([
          ...preSelectedTemplates.filter(
            (template) => template.channelId !== templatesToShow[0].channelId
          ),
          templatesToShow[0],
        ])
      );
    }else if( preSelectedTemplates.filter((template) => template.channelId === templateChannel?.id).length > 0){
      //Assigns the currently preselected template to this channel.
      const currentPreselectedTemplate = preSelectedTemplates.find(template => template.channelId === templateChannel?.id);
      setSelectedCarouselTemplate(currentPreselectedTemplate!); //This will exist for sure because of the elseif validation.
    }
  }, [templateChannel, templates]);

  useEffect(() => {
    if (templateTitle !== '') setOpen(true);
    else setOpen(false);
  }, [templateTitle]);

  useEffect(() => {
    const selectedIndex = carouselTemplates.findIndex((template) => template.id === selectedCarouselTemplate.id);
    if (selectedIndex !== -1 && carouselRef.current) carouselRef.current.goTo(selectedIndex);
  }, [selectedCarouselTemplate, carouselTemplates]);


  const handleSlideChange = (selectedIndex: number) => {
    if (selectedIndex !== -1) setSelectedCarouselTemplate(carouselTemplates[selectedIndex]);
  };
  const handleNextSlide = () => {
    carouselRef.current?.next()
  }
  const handlePrevSlide = () => {
    carouselRef.current?.prev()
  }
  const handleModalClose = () => {
    setOpen(false);
    dispatch(setTemplateTitle(''));
  };
  const isThisTemplateSelected = (template: CreativeTemplate) => {
    return selectedTemplates.find(t => t.id === template.id)
  }
  const setCurrentTemplateAsSelectedForCampaign = () => {
    dispatch(setSelectedTemplates([selectedCarouselTemplate]))
    /**
     * Updated to add the selected template to the preSelectedTemplates State
    */
    dispatch(
      setPreSelectedTemplates([
        ...preSelectedTemplates.filter(
          (template) => template.channelId !== selectedCarouselTemplate.channelId
        ),
        selectedCarouselTemplate,
      ])
    );
  }

  return (
    <Drawer
      visible={open}
      width="min(100vw, 700px)"
      closable={false} 
    >
      <div className={styles["templates-drawer-top-bar"]}>
        <IconArrowRightBlack onClick={handleModalClose}/>
      </div>
      <div className={styles["templates-header"]}>
        Media templates for:
        <div className={styles["templates-template-title"]}>
          {templateTitle}
        </div>
      </div>
      <div className={styles["templates-carousel-container"]}>
        <Carousel ref={carouselRef} dots={{ className: styles["templates-carousel-dots-container"] }} afterChange={handleSlideChange}>
          {
            carouselTemplates.map(template =>
              template.resourceType === "image" ?
                <div key={template.id}>
                  <div className={styles["templates-carousel-slide-img-container"]}>
                    {isThisTemplateSelected(template) ? <CheckIcon className={styles["templates-carousel-slide-check-icon"]} /> : null}
                    <div className={styles["templates-carousel-slide-img"]} style={{ backgroundImage: `url('${template.presignedUrl}')` }}></div>
                  </div>
                  <div className={styles["templates-carousel-slide-footer"]}></div>
                </div>
              :
                <div key={template.id}>
                  <div className={styles["templates-carousel-slide-img-container"]}>
                      {isThisTemplateSelected(template) ? <CheckIcon className={styles["templates-carousel-slide-check-icon"]} /> : null}
                      <video className="max-w-xs" controls>
                          <source src={template.presignedUrl} type="video/mp4" />
                      </video>
                  </div>
                  <div className={styles["templates-carousel-slide-footer"]}></div>
                </div>
            )
          }
        </Carousel>
        
        { carouselTemplates.length > 1 && (
          <>
            <div className={styles["templates-carousel-arrow-left"]}>
              <IconArrowLeftCircle onClick={handlePrevSlide} />
            </div>
            <div className={styles["templates-carousel-arrow-right"]}>
              <IconArrowRightCircle onClick={handleNextSlide} />
            </div>
{/*            <div className={styles["templates-button-container"]}>
              {templateChannel ? <button className={styles["templates-button-with-gradient"]} onClick={setCurrentTemplateAsSelectedForCampaign}>Use This Template</button> : null}
            </div>*/}
          </>
        )}

      </div >
    </Drawer >
  );
};

export default ChannelTemplateModal;
