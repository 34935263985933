import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { MonthlyInvoices } from "../../../models/MonthlyInvoices";

interface GraphProps{
    monthlyInvoices: MonthlyInvoices[],
}


export const BillingChart = (props: GraphProps) =>{
    
    const whiteLabel = useSelector(selectWhiteLabel);
    
    //Transforms the Invoices into an array of plain data for the graph.
    let allRowsData : any[] = [ ['Month', 'Spent'] ];
    props.monthlyInvoices.forEach(monthlyInvoice => {
        allRowsData.push([ monthlyInvoice.month, monthlyInvoice.totalSpent ]);
    });

    allRowsData = [allRowsData[0], ...allRowsData.slice(1).reverse()];
    console.log(allRowsData);

    const options = {
        title: 'Monthly Costs',
        chartArea: { width: '75%' },
        legend: 'none',
        vAxis: {
          minValue: 0,
          format: 'currency',
        },
        hAxis: {
          
        },
        orientation: 'horizontal',
        colors: [whiteLabel.primaryColor],
    };

    return (
        <Chart
            chartType="BarChart"
            data={allRowsData}
            width="100%"
            height="400px"
            options={options}   
        >
        </Chart>
    )
}

export default BillingChart;
