import styles from './styles.module.css'
import { AdminManagerFranchise, AdminManagerUserAccount  } from "../../../models/AdminManager";


interface AccountUserAccountListProps {
  item: AdminManagerFranchise;
  viewAccountLocationCallback: (userAccount: AdminManagerUserAccount) => void;
}

const AccountUserAccountList = ({ item, viewAccountLocationCallback }: AccountUserAccountListProps) => {
  return (
    <table className={styles['table-styled']}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {item.userAccounts.map((userAccount, index) => (
          <tr key={index}>
            <td>{userAccount.name}</td>
            <td>{userAccount.email}</td>
            <td>
              <span className={styles['clickeable-text']} onClick={() => viewAccountLocationCallback(userAccount)}>View Locations</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AccountUserAccountList;
