import { useSelector } from 'react-redux'
import React, {useEffect, useState} from "react";

import { ReactComponent as IconCheck } from "../../assets/icons/check-circle.svg";
import { ReactComponent as IconPlusSquare } from "../../assets/icons/plus-square-white.svg";
import { ReactComponent as IconLayers } from "../../assets/icons/layers.svg";
import "./styles.css";

import { AvailableRoutes } from "../../pages/Routes";
import { selectFranchise } from 'store/Franchise/FranchiseStore'
import { selectUserAccount } from "store/Account/AccountStore";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import { useNavigate } from "react-router-dom";
import { assetImage } from "../../utility/whiteLabelManager";

interface FileExistence {
    fileName: string;
    exists: boolean;
}


export const ActiveChannelDashBoard = ({ OpenChannelsSideBar, isOpen }: any) => {

    const franchise = useSelector(selectFranchise);
    const navigate = useNavigate();
    const account = useSelector(selectUserAccount);
    const whiteLabel = useSelector(selectWhiteLabel);
    const toggleSideBar = () => {
        OpenChannelsSideBar(isOpen)
    }

    const [fileExistenceStatus, setFileExistenceStatus] = useState<FileExistence[]>([]);

    useEffect(() => {
        // Function to check the existence of a file
        //This will verify the existence of all the files described in the active_campaigns json array
        //Since this required async calls, it has been separated from the render function.
        const checkFileExistence = async (fileName:string) => {
            try {
                if (!fileName) {
                    return { fileName: '', exists: false }; // Return a default value for undefined file names
                }
                const response = await fetch(`/icons/${fileName}.png`);
                if (!response.ok) {
                    return { fileName, exists: false }; // If the response is not ok, file doesn't exist
                }
        
                const contentType = response.headers.get('Content-Type');
                if (!contentType || !contentType.includes('image/png')) {
                    return { fileName, exists: false }; // If Content-Type is not image/png, file is not a PNG image
                }
                return { fileName, exists: true };

            } catch (error) {
                console.error(`Error checking file existence for ${fileName}:`, error);
                return { fileName, exists: false };
            }
        };

        if( account != undefined && account.activeChannels[0] != undefined ){
            // Perform file existence checks for each file
            const activeChannelsValues = Object.values(account.activeChannels[0]);
            const filesToCheck = activeChannelsValues.filter(value => typeof value === 'string' && value !== undefined) as string[];
            Promise.all(filesToCheck.map(fileName => checkFileExistence(fileName)))
                .then(results => {
                    setFileExistenceStatus(results);
                });
        }

        
    }, [account]); // Empty dependency array to execute only once on mount



    const renderActiveChannel = (
        channelName:string,
        channelValue: string | undefined,
        fallbackIcon?: string
    ) =>{
        const fileExists = fileExistenceStatus.find(file => file.fileName === channelValue)?.exists || false;

        return(
            <div className={`flex  mb-[25px] group ${ channelValue !== undefined ? 'active' : ''}`}>
                <div className="icon w-[38px] h-[34px] flex items-center justify-center">
                    { channelValue !== undefined && fileExists && (
                        <img src={`/icons/${channelValue}.png`} alt={channelValue} className='w-[30px]' />
                    )}
                    { channelValue !== undefined && !fileExists && fallbackIcon !== undefined && (
                        <img src={assetImage(fallbackIcon)} alt={channelValue} className='w-[30px]' />
                    )}
                </div>
                
                <div className="flex items-center flex-col ml-[30px]"
                    onClick={ () => {navigate(AvailableRoutes.LOCATIONS)} }
                > 
                    <div className="w-[100px]  py-[2px] rounded-[40px] text-[14px] cursor-pointer flex justify-center items-center group-[.active]:bg-[#009051] group-[.active]:opacity-[1] bg-[--color-primary] opacity-[0.40]">
                        { channelValue !== undefined ? <IconCheck width={"21px"} /> : <IconPlusSquare height={"30px"} />}
                    </div>
                    <span className=" text-[--color-primary] ">{channelName}</span>
                </div>
            </div>
        );
    }

    return <div className={`activeChannelDashBoard  px-[24px] py-[50px] overflow-hidden res:hidden` }> 

    <div className="flex  mb-[25px] group active cursor-pointer "
        onClick={toggleSideBar}
    >
        <div className="icon w-[38px] text-center active-icon">
            <IconLayers width={"30px"} />
        </div>
        
    </div>

    

    { account != null && (
        <div className="mt-10" >
            { renderActiveChannel("Search", account.activeChannels[0] ? account.activeChannels[0].search : undefined) }
            { renderActiveChannel("Social", account.activeChannels[0] ? account.activeChannels[0].social : undefined) }
            { renderActiveChannel("Display", account.activeChannels[0] ? account.activeChannels[0].display : undefined, whiteLabel.faviconPath) }
            { renderActiveChannel("CTV", account.activeChannels[0] ? account.activeChannels[0].ctv : undefined, whiteLabel.faviconPath) }
            { renderActiveChannel("Email", account.activeChannels[0] ? account.activeChannels[0].email : undefined, whiteLabel.faviconPath) }
            { renderActiveChannel("Native", account.activeChannels[0] ? account.activeChannels[0].native : undefined, whiteLabel.faviconPath) }
        </div>
    )}

    
</div>

}
