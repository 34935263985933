import "./ScheduleCampaign.scss";
import { NewCampaignCustomPackage } from "../../../models/CampaignPackage";

interface CustomPackageDetailsPopoverProps {
  campaignPackage: NewCampaignCustomPackage;
}

const CustomPackageDetailsPopover = ({
  campaignPackage,
}: CustomPackageDetailsPopoverProps) => {
  // TODO: the info here will need to be specific to the package
  return (
    <div className={classNames.popover}>
      <h2 style={{ margin: "0px", color: "#ffffff" }}>
        Schedule Overview
      </h2>
      <p>Program duration: 12 weeks</p>
      <p>Billing frequency: 2 weeks</p>
    </div>
  );
};

const classNames = {
  popover: "channel-scheduling-details-popover",
};

export default CustomPackageDetailsPopover;
