import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import services from "../../services";
import type { INewLandingCodeBatch, INewClaimLandingCode } from "../../services/QRLandingCodesService";
import { QRLandingCode } from "../../models/QRCode";
import { RootState } from "../index";

export const validateLandingCode = createAsyncThunk(
  "qrLandingCodes/fetch",
  async (code: string) => {
    return services.qrLandingCodesService.validateLandingCode(
      code,
    );
  }
);

export const createLandingCodeBatch = createAsyncThunk(
  "qrLandingCodes/create",
  async (newCodeBatch: INewLandingCodeBatch) => {
    return services.qrLandingCodesService.generateLandingCodeBatch(
      newCodeBatch,
    );
  }
);

export const claimLandingCodeBatch = createAsyncThunk(
  "qrLandingCodes/update",
  async (newClaimCode: INewClaimLandingCode) => {
    return services.qrLandingCodesService.claimLandingCode(
      newClaimCode,
    );
  }
);


export const qrLandingCodesSlice = createSlice({
  name: "qrLandingCodes",
  initialState: {
    qrLandingCodes: [] as QRLandingCode[],
    areLandingCodeValid: false
  },
  reducers: {
    setQRLandingCodes: (
      state,
      { payload: codes }: PayloadAction<QRLandingCode[]>
    ) => {
      state.qrLandingCodes = codes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createLandingCodeBatch.fulfilled, (state, action) => {
      state.qrLandingCodes = action.payload;
    });
  },
});

export const selectQRLandingCodeBatch = (state: RootState): QRLandingCode[] =>
  state.qrLandingCodes.qrLandingCodes;

export default qrLandingCodesSlice.reducer;
