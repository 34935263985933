import { useEffect } from "react";
import "./App.css";
import AuthPage from "./pages/AuthPage";
import { useAuth } from "./hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminUser, setAdminUser, selectAdminUserAccount, selectAdminUserLoaded } from "./store/AdminAccount/AdminAccountStore";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminMainApp from "./pages/Admin";
import { selectWhiteLabel } from "./store/WhiteLabel/WhiteLabelStore";
import { getGlobalStyles } from "./utility/whiteLabelManager";

const AdminApp = () => {
  const { currentUser, user } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    currentUser();
  }, []);

  useEffect(() => {
    if (user) {
      //Fetch the AdminUser and saves to storage.
      dispatch(fetchAdminUser());
    } else {
      dispatch(fetchAdminUser());
      dispatch(setAdminUser(null));
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const whiteLabel = useSelector(selectWhiteLabel);
  const GlobalStyles = getGlobalStyles(whiteLabel);

  //Obtains the AdminUser from storage.
  const adminUser = useSelector(selectAdminUserAccount);
  const adminUserLoaded = useSelector(selectAdminUserLoaded);
  return (
    <>
      <GlobalStyles />
      {
        ( adminUserLoaded ) 
        ?
          <Router>
            <Routes>
              <Route path="*" element={(adminUser !== null ) ? <AdminMainApp /> : <AuthPage />}></Route>
            </Routes>
          </Router>
        :
          <div></div>
      }
    </>
  );
};

export default AdminApp;
