import { Row, Col, Button, Tooltip } from "antd";
import React from "react";
import { connect, useSelector } from 'react-redux';
import './FileUploader.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ParseResult } from "papaparse";
import { EventRowSchema } from "../../../services/eventService";
import { importEvents, eventErrors} from "../../../store/Events/EventStore"
import { RootState } from "../../../store";

interface EventUploaderProps {
    title: string,
    notifyErrors: Function,
    importEvents: Function,
    eventErrors: String[] | null,
    importEventsResult: String | null
}

interface EventUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class EventUploader extends React.Component<EventUploaderProps, EventUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<EventUploaderProps>): void {
        
        if( prevProps.eventErrors !== null){
            if( prevProps.eventErrors !== this.props.eventErrors){
                this.props.notifyErrors( 
                    this.props.eventErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.eventErrors !== null ){
            if( prevProps.importEventsResult !== this.props.importEventsResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importEventsResult);
            }
        }
        

    }
    
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        //Verify if the file is not null.
        if( !event.target.files )
            return;
        
        //Verifies if the file is supported (csv or xlsx)
        let fileType = event.target.files[0].type;
        if( 
            fileType === 'application/vnd.ms-excel' || 
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'text/csv'
        ){
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        }else{
            this.setState({
                fileTypeError: "The selected file is not valid. Only CSV and XLSX files are supported."
            });
        }
    }

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        //This process is different depending on the file type.
        let fileJson = [];

        let selectedFile = this.state.selectedFile;
        
        if( selectedFile?.type === 'text/csv'){
            this.parseEventsCSVFile(selectedFile).then((json) => {
                console.log('Trying to import the events csv file...');
                console.log(json);
                this.props.importEvents( json );
            }).catch( (error: Error) => {
                console.log(error);
            });
        }else{
            this.parseEventsExcelFile(selectedFile!).then((json) => {
                console.log('Trying to import the events xlsx file...');
                console.log(json);
                this.props.importEvents( json );
            }).catch( (error: Error) => {
                console.log(error);
            });
        }
    }


    parseEventsExcelFile = (file: File) => {
        
        return new Promise<EventRowSchema[]>( (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;

                const workbook = XLSX.read(data, { type: "array"} );
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];  

                const json = XLSX.utils.sheet_to_json<EventRowSchema>(
                    worksheet,
                    {
                        //transforms de header names into camelCase.
                        raw: false,
                        header: [
                            'userEmail',
                            'title',
                            'details',
                            'startDate',
                            'endDate',
                            'location',
                            'isAlert'
                        ],
                        range: 1,
                        defval: null,
                        blankrows: true,
                        
                    }
                );
                let errors: string[] = [];
                let appIds: string[] = []; 
                console.log(json);
                json.forEach( (element, index) => {
                    
                    let rowNum = index+1;

                    if( element['userEmail']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the User Email cell.");
                    if( element['title']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Title cell.");
                    if( element['details']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the details cell.");
                    if( element['startDate']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Start date cell.");
                    // if( element['endDate']  === null )
                    //     errors.push("<b>Row #"+rowNum+"</b> is missing the End date cell.");
                    if( element['location']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the location cell.");
                    if( element['isAlert']  === null )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the alert cell.");
                });

                //Reject this promise and notify the parent about the errors
                //to display in the large event window.
                if( errors.length > 0 ){
                    
                    this.props.notifyErrors( 
                        errors, 
                        "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                    );

                    reject(Error('There were some errors in the file.'));
                }

                resolve(json);
            }
            reader.readAsArrayBuffer(file);
        });

    }

    parseEventsCSVFile = ( file: File ) => {
        return new Promise<EventRowSchema[]>( (resolve, reject) => {
            Papa.parse( file, {
                header: true,
                transformHeader: ((header: string) => this.camelize(header)),
                complete: (results: ParseResult<EventRowSchema> ) => {
                    
                    //Need to adjust the header names, and we do so while
                    //manually validating each row.
                    let json = results.data;
                    let errors: string[] = [];
                    //The lists of IDs, to keep track if any repeated value is found
                    //within the same sheet.
                    let appIds: string[] = []; 
                    json.forEach( (element, index) => {

                        let rowNum = index+1;
                 
                        if( element.userEmail === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the User Email cell.");                            
                        if( element.title === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Title cell.");
                        if( element.details === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the details cell.");
                        if( element.startDate === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Start date cell.");
                        // if( element.endDate === null)
                        //     errors.push("<b>Row #"+rowNum+"</b> is missing the End date cell.");
                        if( element.location === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the location cell.");
                        if( element.isAlert === null)
                            errors.push("<b>Row #"+rowNum+"</b> is missing the alert cell.");
                        
                    });
                    if( errors.length > 0 ){
                        this.props.notifyErrors( 
                            errors, 
                            "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                        );
                        reject(Error('There were some errors in the file.'));
                    }
                    resolve(json);
                },
                error: error => {
                    reject( Error( error.message ) );
                }
            });
        });
    }

    //TODO: Esto no debe estar aquí.
    camelize = (str: string) => {
        str = str.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
        {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    render(){
        let button;
        if( this.state.selectedFile ){
            button = 
                <div>
                    <div onClick={this.onFileUpload} className="primary-button pointer-hover p-[8px_25px]">
                        <div className="primary-button-text">UPLOAD</div>
                    </div>
                </div>
        }else{
            button = 
                <div>
                    <input id={"file-uploader-event"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-event"}>
                        <div className="primary-button pointer-hover p-[8px_25px]">
                            <div className="primary-button-text">IMPORT</div>
                        </div>
                    </label>
                </div>
        }
        
        return (
            <div className="file-upload-area mb-10">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col >
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }

                        { this.state.selectedFile == null && 
                            <a target="_blank" download={true} className="template-download" href="/templates/template_event.xlsx">Template</a>
                        }

                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        importEvents: ( eventArray: EventRowSchema[] ) => dispatch(importEvents({eventSchemaArray: eventArray}) )
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        eventErrors: state.events.eventErrors,
        importEventsResult: state.events.importEventsResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventUploader);
