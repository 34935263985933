import RestService from "./restService";
import { Event } from "../models/Event";

export interface INewEvent {
  title: string;
  details: string;
  startDate: Date;
  endDate?: Date;
  //address1: string | null;
  //address2: string | null;
  //city: string | null;
  //state: string | null;
  //postalCode: string | null;
  //country: string | null;
  location: string | null;
  other: string;
  imageUrl?: string;
  isBlackoutDate: boolean;
}

export interface EventRowSchema {
  userEmail: string;
  title: string;
  details: string;
  startDate: string;
  endDate?: string;
  location: string | null;
  isAlert: boolean;
}

export interface IUpdateEvent {
  id: string;
  title: string;
  details: string;
  startDate: Date;
  endDate?: Date;
  //address1: string | null;
  //address2: string | null;
  //city: string | null;
  //state: string | null;
  //postalCode: string | null;
  //country: string | null;
  location: string | null;
  other: string;
  isBlackoutDate: boolean;
}

export default class EventService {
  constructor(protected readonly api: RestService) {}

  // Will we need to get these by month eventually to limit response size?
  async getEvents(accountId: string): Promise<Event[]> {
    const apiResponse = await this.api.get<Event[]>("/events");

    console.log("Api response", apiResponse)
    return apiResponse.map((event) => {
      return {
        ...event,
        startDate: new Date(event.startDate),
        endDate: event.endDate ? new Date(event.endDate) : null,
      };
    });
  }

  async createEvent(newEvent: INewEvent): Promise<Event> {
    const responseEvent = await this.api.post<Event>("/events", {
      ...newEvent,
    });
    return {
      ...responseEvent,
      startDate: new Date(responseEvent.startDate),
      endDate: responseEvent.endDate ? new Date(responseEvent.endDate) : null,
    };
  }

  async updateEvent(updatedEvent: IUpdateEvent): Promise<Event> {
    const responseEvent = await this.api.put<Event>("/events", {
      ...updatedEvent,
    });
    return {
      ...responseEvent,
      startDate: new Date(responseEvent.startDate),
      endDate: responseEvent.endDate ? new Date(responseEvent.endDate) : null,
    };
  }

  async deleteEvent(deletedEventId: string) {
    const responseEvent = await this.api.delete<Event>(`/events/${deletedEventId}`, {
      deletedEventId,
    });
    return responseEvent;
  }

  async importEvents(eventsSchemaArray: EventRowSchema[]): Promise<Event[]> {
    console.log(eventsSchemaArray);
    const apiResponse = await this.api.post<Event[]>("/events/import", eventsSchemaArray);
    return apiResponse;
}
}
