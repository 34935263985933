import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChannelType } from "../../../models/Channel";
import { CreativeTemplate } from "../../../models/CreativeTemplate";
import {
  selectPreSelectedTemplates,
  selectTemplateTitle,
  setPreSelectedTemplates,
} from "../../../store/campaignForm/CampaignForm";
import { selectChannels } from "../../../store/Channels/ChannelStore";
import { selectCreativeTemplates } from "../../../store/CreativeTemplates/CreativeTemplateStore";
import { Radio, RadioGroup } from "@mui/material";
import "./styles.scss";
import { CustomPackage } from "../../../models/CustomPackage";
import { selectCustomPackages } from "../../../store/customPackages/CustomPackagesStore";

const ChannelTemplates = () => {
  const dispatch = useDispatch();
  const templateTitle: string = useSelector(selectTemplateTitle);
  const templates: CreativeTemplate[] = useSelector(selectCreativeTemplates);
  const channels: ChannelType[] = useSelector(selectChannels);
  const packages: CustomPackage[] = useSelector(selectCustomPackages);
  const [currentChannel, setCurrentChannel] = useState<ChannelType | undefined>(
    undefined
  );
  const [currentPackage, setCurrentPackage] = useState<
    CustomPackage | undefined
  >(undefined);
  const [packageChannels, setPackageChannels] = useState<ChannelType[]>([]);
  const [channelTemplates, setChannelTemplates] = useState<CreativeTemplate[]>(
    []
  );
  const [isPackage, setIsPackage] = useState<boolean>(false);
  const preSelectedTemplates: CreativeTemplate[] = useSelector(
    selectPreSelectedTemplates
  );

  useEffect(() => {
    let tempTemplates: CreativeTemplate[] = [];
    if (
      channels.filter((channel) => channel.title === templateTitle).length > 0
    ) {
      setIsPackage(false);
      setCurrentChannel(
        channels.filter((channel) => channel.title === templateTitle)[0]
      );
      tempTemplates = templates.filter(
        (template) => template.channelId === currentChannel?.id
      );
      setChannelTemplates(tempTemplates);
    } else {
      setIsPackage(true);
      setCurrentPackage(
        packages.filter((pack) => pack.title === templateTitle)[0]
      );
      let tempPackageChannels: ChannelType[] = [];
      packages
        .filter((pack) => pack.title === templateTitle)[0]
        .customPackageChannels.map((packageChannel) => {
          if (
            channels.filter(
              (channel) => channel.id === packageChannel.channelTypeId
            ).length > 0
          ) {
            tempPackageChannels = [
              ...tempPackageChannels,
              channels.filter(
                (channel) => channel.id === packageChannel.channelTypeId
              )[0],
            ];
          }
          return packageChannel;
        });
      setPackageChannels(tempPackageChannels);
      packageChannels.forEach((channel) => {
        templates.forEach((template) => {
          if (template.channelId === channel.id) {
            tempTemplates.push(template);
          }
        });
      });
      setChannelTemplates(tempTemplates);
    }
  }, [currentChannel, currentPackage]); // eslint-disable-line react-hooks/exhaustive-deps

  const templateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let tempSelectedTemplate: CreativeTemplate = templates.filter(
      (template) => template.id === ev.target.value
    )[0];
    dispatch(
      setPreSelectedTemplates([
        ...preSelectedTemplates.filter(
          (template) => template.channelId !== tempSelectedTemplate.channelId
        ),
        tempSelectedTemplate,
      ])
    );
  };

  const isSelected = (template: string) => {
    return preSelectedTemplates.some((temp) => temp.id === template);
  };

  const renderTemplates = (template: CreativeTemplate) => {
    if (!isPackage && template.channelId === currentChannel?.id) {
      return (
        <div
          key={template.id}
          className="res:basis-5/12"
          style={{ position: "relative", display: "inline" }}
        >
          <img
            src={template.presignedUrl}
            alt="template_image"
            className="template-img res:w-full"
          />
          {channelTemplates.length >= 2 && (
            <Radio
              value={template.id}
              checked={isSelected(template.id)}
              className="res:right-0 res:bottom-0 res:left-auto"
              style={{ position: "absolute", left: "80%" }}
              sx={{
                color: "#1976d2",
                "&.Mui-checked": {
                  color: "#1976d2",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: 35,
                },
              }}
            />
          )}
        </div>
      );
    } else if (
      isPackage &&
      packageChannels.some((channel) => template.channelId === channel.id)
    ) {
      return (
        <div key={template.id} style={{ display: "inline" }}>
          <img
            src={template.presignedUrl}
            alt="template_image"
            className="template-img"
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="channels-container">
      {channelTemplates.length >= 2 ? (
        <RadioGroup
          style={{ width: "100%", flexDirection: "row" }}
          onChange={templateChange}
          className="res:justify-between res:gap-2"
        >
          {channelTemplates.map(renderTemplates)}
        </RadioGroup>
      ) : (
        templates.map(renderTemplates)
      )}
    </div>
  );
};

export default React.memo(ChannelTemplates);
