import Auth from "@aws-amplify/auth";
import { AuthUser } from "../models/AuthUser";

export default class AuthService {
  async signIn(email: string, password: string): Promise<AuthUser> {
    const user = await Auth.signIn(email, password);

    return {
      username: user.username,
    };
  }

  async logout(): Promise<void> {
    await Auth.signOut();
  }

  async checkSession(): Promise<AuthUser> {
    const currentUser = await Auth.currentAuthenticatedUser();
    return {
      username: currentUser.username,
    };
  }

  public async getToken(): Promise<string> {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      return currentUser.getSignInUserSession().getIdToken().jwtToken;
    } catch (err) {
      console.log(err);
      // don't fail if there are unauthorized calls, just skip the header
      return "";
    }
  }
}

export interface CognitoUser {
  username: string;
}
