import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import styles from "../purchase/channels/channelTemplateDrawer/channelTemplateDrawer.module.css"
import { ReactComponent as IconArrowRightBlack } from "../../assets/icons/arrow-right-black.svg";
import { selectInfoModalState, setInfoModalState } from "../../store/KeywordHelper/KeywordHelperStore";

type InfoSidebarProps = {
    children: React.ReactNode;
    isOpen: boolean;
    title?: string;
};

export const InfoSideBar = ({children, isOpen, title}: InfoSidebarProps) => {

    const dispatch = useDispatch();
    const infoModalState = useSelector(selectInfoModalState);

    const handleModalClose = () => {
        dispatch(setInfoModalState(false));
    };

    return (
        <Drawer
            visible={ infoModalState }
            width="min(100vw, 700px)"
            closable={false}
        >
            <div className={`${styles["templates-drawer-top-bar"]}  `}>
                <IconArrowRightBlack onClick={handleModalClose}/>
            </div>
            <div className={`${styles["templates-header"]} mb-5 rule-info`}>
                {title}
            </div>
            <div className={`${styles["templates-channel-dropdown"]} mb-10 rule-info`}>
                {children}
            </div>

        </Drawer >
    );
}

export default InfoSideBar;
