import React, {useEffect, useState} from "react";
import {Row, Col, Button, Drawer} from "antd";
import { useDispatch, useSelector } from "react-redux"
import services from "../../../services"
import styles from "../../purchase/channels/channelTemplateDrawer/channelTemplateDrawer.module.css";
import style from './PlatformSupport.module.css'
import { ReactComponent as IconArrowRightBlack } from "../../../assets/icons/arrow-right-black.svg";
import { ReactComponent as IconPokerFace } from "../../../assets/icons/face-neutral.svg";
import { setOpen, selectIsOpenState } from "../../../store/PlatformSupport/Download";
import {IFileItemResource} from "../../../services/FileItemService";

const defaultSelectedFile = {
    id: '',
    name: '',
    description: '',
    createDate: null,
    createdBy: ''
}

export const FileDownload  = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsOpenState);
    const [open, setModalOpen] = useState(false);
    const [files, setFiles] = useState<IFileItemResource[]>([]);
    const [selectedFile, setSelectedFile] = useState<IFileItemResource>(defaultSelectedFile);

    const refreshFileList = () => {
        services.fileItemService.getFiles().then((fileList) => {
            setFiles(fileList);
        });
    }

    /**
     * Updating file list
     */
    useEffect(() => {
        setModalOpen(isOpen);
        if (isOpen) {
            refreshFileList();
        }
    }, [isOpen]);

    /**
     * Selecting file for download
     *
     * @param file
     */
    const handleSelectedFile = (file: IFileItemResource) => {
        setSelectedFile(file);
    }

    /**
     * Close modal window
     */
    const handleModalClose = () => {
        setModalOpen(false);
        dispatch(setOpen(false));
    };

    /**
     * Downloading selected file
     */
    const handleDownload = async () => {
        if (selectedFile.id && selectedFile.name) {
            const buffer = await services.fileItemService.download(selectedFile.id);
            const href = window.URL.createObjectURL(buffer);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', selectedFile?.name);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(href);
            services.fileItemService.remove(selectedFile.id).then(() => {
                setSelectedFile(defaultSelectedFile);
            }).then(() => {
                refreshFileList();
            });
        }
    }

    /**
     * Formatting date
     *
     * @param dateValue
     */
    const formatDate = (dateValue: Date | undefined) => {
        if (!dateValue) {
            return '';
        }
        const date = new Date(dateValue);
        let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        return `${month} ${day}, ${year}`;
    }

    return (
        <Drawer
            visible={open}
            width="min(100vw, 700px)"
            closable={false}
        >
            <div className={`${styles["templates-drawer-top-bar"]}  `}>
                <IconArrowRightBlack onClick={handleModalClose}/>
            </div>

            {files.length > 0 ? (
                <>
                    <div className={`${styles["templates-header"]} mb-5 `}>
                        Files available for download
                    </div>
                    <div className="file-upload-area">
                        <Row className="justify-center">
                            <Col span={10} className={`res:flex-[0_0_50%] res:max-w-[50%] ${style["files-main-container"]}`}>
                                {files.map(file => (
                                    <div
                                        key={file.id}
                                        className={`${style["item"]} ${selectedFile.id === file?.id?.toString() ? style["selected"] : ''}`}
                                        onClick={() => handleSelectedFile(file)}>
                                        {file.description}
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        {selectedFile.id ?
                            <Row className="justify-center mt-6">
                                <Col span={8} className="res:flex-[0_0_50%] res:max-w-[50%]">
                                    <p>Uploaded { formatDate(selectedFile.createDate ?? undefined) }</p>
                                    <p>by {selectedFile.createdBy}</p>
                                </Col>
                            </Row>
                            : ''
                        }
                        <Row className="justify-center">
                            <Col span={8} className="res:flex-[0_0_50%] res:max-w-[50%]">
                                <div
                                    className="primary-button  w-[auto] pointer-hover"
                                    onClick={handleDownload}
                                >
                                    <div className="primary-button-text w-full text-center">DOWNLOAD</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            ) : (
                <>
                    <div className={`${styles["templates-header"]} mb-5 `}>
                        No files available for download
                    </div>
                    <div className="flex justify-center mt-5">
                        <IconPokerFace width={60} height={60} className={`${style["no-files-icon"]}`} />
                    </div>
                </>
                
            )}

            
        </Drawer>
    );
}

export default FileDownload;

