import RestService from "./restService";
import { Alert } from "../models/Alert";

export default class AlertService {
  constructor(protected readonly api: RestService) {}

  async importAlerts(AlertSchemaArray: AlertRowSchema[]): Promise<Alert[]> {
    const apiResponse = await this.api.post<Alert[]>("/alerts/import", AlertSchemaArray);

    return apiResponse;
  }
}



export interface AlertRowSchema {
  userEmail: string,
  title: string,
  message: string,
  alertDate: string,
}

