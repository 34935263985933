import RestService from "./restService";

export default class GoogleAdsCampaignService {
  constructor(protected readonly api: RestService) {}

  async importGoogleAdsCampaigns(googleAdsCampaignSchemaArray: GoogleAdsCampaignRowSchema[]): Promise<boolean> {
    const apiResponse = await this.api.post<boolean>("/google-ads-campaigns/import", googleAdsCampaignSchemaArray);

    return apiResponse;
  }
}



export interface GoogleAdsCampaignRowSchema {
  franchise: string,
  locationAppId: string,
  campaignName: string,
  dailyBudget: string,
  startDate: string,
  endDate: string,
}