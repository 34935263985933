import { IAdminUser } from "../models/AdminUser";
import RestService from "./restService";

export default class AdminUserService {
  constructor(protected readonly api: RestService) {}

  async getAdminUser(): Promise<IAdminUser> {
    return await this.api.get<IAdminUser>("/admin-user");
  }

}
