import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface AlertMessageProps {
  successMessage: string;
  openScsMsg: boolean;
  onScsClose: () => void;
  openErrMsg: boolean;
  onErrClose: () => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = ({ successMessage, openScsMsg, onScsClose, openErrMsg, onErrClose }: AlertMessageProps) => {
  
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      onScsClose();
      onErrClose();
    };
  
    return (
      <div>
        <Snackbar open={openScsMsg} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={openErrMsg} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            An unexpected error has occurred.
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  export default AlertMessage;
  