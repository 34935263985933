import EmailUploader from "../fileuploader/EmailUploader";
import GoogleAdsCampaignUploader from "../fileuploader/GoogleAdsCampaignUploader";
import AlertUploader from "../fileuploader/AlertUploader";
import BudgetsUploader from "../fileuploader/BudgetsUploader";
import React from "react";

interface EmailManagerState {
  errors: string[] | null,
  errorMessage: string | null,
}

class EmailManager extends React.Component<{}, EmailManagerState> {

  constructor(props: any){
    super(props);
    this.state = {
      errors: null,
      errorMessage: null,
    }
  }

  notifyErrors = (errors: string[], message: string) => {
    this.setState({
      errors: errors,
      errorMessage: message
    });
  }

  closeErrorWindow = () => {
    this.setState({
      errors: null,
      errorMessage: null,
    })
  }

  render() {
    return (
        <>
          <div className="admin-page">
            <GoogleAdsCampaignUploader notifyErrors={this.notifyErrors} title="New campaigns"></GoogleAdsCampaignUploader>
            <BudgetsUploader notifyErrors={this.notifyErrors} title="New budgets"></BudgetsUploader>
            <EmailUploader notifyErrors={this.notifyErrors} title="Email blasts"></EmailUploader>
            <AlertUploader notifyErrors={this.notifyErrors} title="Alert blasts"></AlertUploader>
          </div>
        </>)
  }
};

export default EmailManager
