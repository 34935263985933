import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";
import "./styles.css";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

const Help = () => {
  const navigate = useNavigate();
  const whiteLabel = useSelector(selectWhiteLabel);

  return (
    <div className="container-auth res:w-full">
      <div>
        <IconArrowLeft
          className="primary-icon cursor-pointer"
          onClick={() => navigate("/login")}
        />
        <div className="text-header">FAQs</div>
        <div 
          dangerouslySetInnerHTML={{ __html: whiteLabel.supportText }} 
          className="text-content"
          style={{ 
            marginBottom: 60,
            maxHeight: '70vh', // Max height of the viewport
            overflow: 'auto'    // Enables scrolling if content exceeds maxHeight
          }}
        ></div>
      </div>
    </div>
  );
};

export default Help;
