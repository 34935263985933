import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, Tooltip } from "antd";
import { AssignDataSourceList } from './AssignDataSourceList';
import { AssignAudiencesList } from './AssignAudiencesList';
import { Audience } from '../../../models/Audience';
import style from "../mediaManager/MediaManager.module.css";
import { setModalContent } from '../../../store/Demographics/DemographicsStore';
import { useDispatch } from 'react-redux';
import services from "../../../services";
import { SourceContext } from "./Demographics";

interface AudienceResultInterface {
    id: number;
    name: string;
    source: string;
    hash: string;
    filter_query: string;
    fields: [];
    condition: [];
}

export const ManageAudiences = () => {
    const dispatch = useDispatch();

    const [options, setOptions] = useState<string[]>([]);
    const [audiences, setAudiences] = useState<Audience[]>([]);
    // const [selectedSource, setCurrentSource] = useState<string>('');
    const {selectedSource, setSelectedSource} = useContext(SourceContext);

    const handleModalContent = (content: string) => {
        dispatch(setModalContent(content));
    };

    const changeModal = (modal: string) => {
        setModalContent(modal);
    }
    
    useEffect(() => {
        services.audienceSourceService.getSourceList().then((sources) => {
            if (sources.length > 0) {
                setSelectedSource(sources[0]);
            }
            setOptions(sources);
        });
    }, []);

    useEffect(() => {
        if (!selectedSource || selectedSource.length <= 0) {
            setAudiences([]);
            return;
        }
        services.audienceSourceService.getAudiencesBySource(selectedSource).then((resp) => {
            let audiences:Audience[] = [];
            if (Array.isArray(resp)) {
                audiences = resp?.map((item:AudienceResultInterface) => {
                    return Object.assign({id:'', name:''}, item);
                });
            }

            setAudiences(audiences);
      });
    }, [options]);

    const setAssignedDataSource = (value: string) => {
        if (value.length <= 0) {
            setSelectedSource('');
        }
        setSelectedSource(value);
    }

    return (
        <div>
        <div className={style["selector-container"]}>
            <div className={style["selector"]}>
                <AssignDataSourceList label="Data sources" options={options} setAssignedDataSource={setAssignedDataSource}/>
            </div>

            <div className={style["selector"]}>
                <AssignAudiencesList audiences={audiences} />
            </div>
        </div>

        <div className={style["actions-between"]}>
            <Col span={5}>
                <div className="admin-primary-button pointer-hover" onClick={() => handleModalContent("New audience")}>
                    <div className="primary-button-text">NEW AUDIENCE</div>
                </div>
            </Col>
            <Col span={5}>
                <div className="admin-primary-button pointer-hover" onClick={() => handleModalContent("Manage audiences")}>
                    <div className="primary-button-text">DETAILS</div>
                </div>
            </Col> 
        </div>
    </div>
    )
}
