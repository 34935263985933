import React from "react";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "assets/icons/loader-media-template.svg";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "antd";
import MediaUploader from "./MediaUploader";
import {setOpen} from "../../../store/mediaTemplate/MediaTemplate";
import GalleryDrawer from "./GalleryDrawer";
import services from "../../../services";
import { IImageResource} from "../../../services/mediaTemplateService";
import {selectChannels} from "../../../store/Channels/ChannelStore"
import {selectIsUploadingState, selectImagesFetchedState, setImagesFetched} from "../../../store/PlatformSupport/Upload";
import { setSelectedMainMenu, setSelectedSubMenu } from "../../../store/Navigation/NavigationStore";

const MediaTemplates: React.FC = () => {
    const dispatch = useDispatch();
    const [allImages, setAllImages] = useState<IImageResource[]>([]);
    //const [allVideos, setAllVideos] = useState<IVideoResource[]>([]);

    //const [imagesFetched, setImagesFetched] = useState(false);
    const imagesFetched = useSelector(selectImagesFetchedState);
    const channels = useSelector(selectChannels);
    const isUploading = useSelector(selectIsUploadingState);

    

    const getImagesForValue = (currentValue: string) => {
        services.mediaTemplateService.getImages(currentValue).then((imagesForValue) => {
          // Puedes ajustar cómo deseas manejar las imágenes para cada valor
          setAllImages((prevImages) => [...prevImages, ...imagesForValue]);
        });
    };

    useEffect(() => {
        if( channels.length > 0 ){
            console.log('fetching resources');
            dispatch(setImagesFetched(true));
            // Iterar sobre cada valor en el arreglo y llamar a la función
            for( let i=0; i<channels.length; i++){
                getImagesForValue( channels[i].id );
                //getVideosForValue( channels[i].id);
            }
        }
        
    }, [channels]);

    useEffect(() => {
        dispatch( setSelectedMainMenu("event") );
        dispatch(setSelectedSubMenu("Media Library"));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps



    return (
        <div>
            <Row>
                <Col span={24}>
                    <h1 className="text-2xl mb-5">Manage media templates</h1>
                    <Row className="">
                        <div className="res:flex-[0_0_50%] res:max-w-[50%] flex-[0_0_25%] max-w-[25%]" >
                            <div
                                className={` ${ allImages.length == 0 ? "disabled" : "" } [&.disabled]:opacity-75 [&.disabled]:pointer-events-none primary-button w-[auto] pointer-hover`}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    dispatch(setOpen(true));
                                }}
                            >
                                <div 
                                className="primary-button-text">
                                    VIEW LIBRARY
                                </div>
                            </div>
                        </div>
                    </Row>
                    {
                        allImages.length == 0 && 
                        <div className="mt-2"> Your library is currently empty </div>
                    }

                    <div className="mt-8">
                        <MediaUploader title={'UPLOAD MEDIA'}/>
                    </div>
                    

                    
                </Col>
                <GalleryDrawer  />
            </Row>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUploading}
            >
                <LoaderIcon className="loader-icon" />
                {/* <CircularProgress color="inherit" /> */}
            </Backdrop>
        </div>
    );
};

export default MediaTemplates;
