import { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import sharedStyles from "../shared/styles.module.css";
import { selectQRCampaigns, fetchQRCampaigns, setQRCampaignToEdit, selectQRCampaignToEdit } from "../../../store/QRCampaign/QRCampaignsStore";
import { useNavigate } from "react-router-dom";
import { QRCampaign } from "../../../models/QRCampaign";
import { AdminAvailableRoutes } from "pages/AdminRoutes";

import { ReactComponent as IconArrowLeft } from "../../../assets/icons/arrow-left.svg";

export const DetailsQRCampaignPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const qrCampaignToEdit = useSelector(selectQRCampaignToEdit);
    console.log(qrCampaignToEdit);

    return <div >
        
        { qrCampaignToEdit != null && (
            <div>
                <IconArrowLeft
                    className="primary-icon cursor-pointer"
                    onClick={() => navigate( AdminAvailableRoutes.QR_MANAGER )}
                />
                <h1 className="text-3xl mt-4">{ qrCampaignToEdit.name }</h1>
                <div className="grid grid-cols-2 gap-x-2 mt-8">
                    <div>
                        <h1 className="text-orange-500 text-base mb-4">Details</h1>
                        <p className="text-black text-base mb-4"><b>Status:</b> Active since { new Date(qrCampaignToEdit.startDate).toLocaleDateString()}</p>
                        <p className="text-black text-base mb-4"><b>Campaign ID:</b> { qrCampaignToEdit.campaignId }</p>
                        <p className="text-black text-base mb-4"><b>Promotion:</b> { qrCampaignToEdit.promotion }</p>
                        <p className="text-black text-base"><b>Ends:</b> { new Date(qrCampaignToEdit.endDate).toLocaleDateString() }</p>
                    </div>

                    <div>
                        <h1 className="text-orange-500 text-base mb-4">Performance</h1>
                        <p className="text-black text-base mb-4"><b>Pageviews:</b> {qrCampaignToEdit.pageviews}</p>
                        <p className="text-black text-base mb-4"><b>Codes generated:</b> {qrCampaignToEdit.codesGenerated}</p>
                        <p className="text-black text-base mb-4"><b>Scans:</b> {qrCampaignToEdit.scans}</p>
                        <p className="text-black text-base"><b>Redemptions:</b> {qrCampaignToEdit.redemptions}</p>
                    </div>
                    
                </div>

                <Input.Group compact>
                    <Form.Item style={{ marginTop: 50 }}>
                        <Button
                            htmlType="submit"
                            className="primary-button pointer-hover"
                            onClick={() => {
                                navigate( AdminAvailableRoutes.EDIT_QR_CAMPAIGN );
                            } }
                            //style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '25px' }}
                        >
                            <div className="primary-button-text">EDIT</div>
                        </Button>
                    </Form.Item>
                </Input.Group>   

                <Input.Group compact>
                    <Form.Item style={{ marginTop: 50 }}>
                        <Button
                            htmlType="submit"
                            className="inverted-general-button pointer-hover"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '25px' }}
                            onClick={() => {
                                navigate(AdminAvailableRoutes.DELETE_QR_CAMPAIGN)
                            } }
                        >
                            <div className="primary-button-text">DELETE</div>
                        </Button>
                    </Form.Item>
                </Input.Group>   

            </div>
        )}
        
    </div>
}

export default DetailsQRCampaignPage
