import React, { useEffect, useRef, useState } from "react";
import "./ScheduleCampaign.scss";
import { ReactComponent as DigitalIcon } from "../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as IconOpening } from "../../../assets/icons/opening.svg";
import { ReactComponent as NewCustomerIcon } from "../../../assets/icons/new-customer-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { Popover} from "@mui/material";
import AlwaysOnTag from "./AlwaysOnTag";
import { selectAlwaysOn, setNewCampaignCustomPackage } from "../../../store/campaignForm/CampaignForm";
import { NewCampaignCustomPackage } from "../../../models/CampaignPackage";
import { HOURS_IN_DAY, addDaysToDate } from "../../../utility/time";
import CustomPackageDetailsPopover from "./CustomPackageDetailsPopover";
import DatePicker from "../../datepicker/DatePicker";
import { isBefore } from "date-fns";
import { Button } from "antd";

interface PackageCardProps {
  campaignPackage?: NewCampaignCustomPackage;
  onSelectedDateChange?: Function;
  minimumStart: Date;
}

const PackageCard = ({
  campaignPackage,
  onSelectedDateChange,
  minimumStart,
}: PackageCardProps) => {
  //Custom Package
  const customPackage = campaignPackage?.customPackage;

  //Channel
  const alwaysOn = useSelector(selectAlwaysOn);
  const [endDate, setEndDate] = useState<Date>(campaignPackage?.endDate || addDaysToDate(minimumStart, 7));
  const [minimumEnd, setMinimumEnd] = useState<Date | null>(null);
  useEffect(() => {
    if (!alwaysOn && (!campaignPackage?.endDate || (minimumEnd && endDate < minimumEnd))) updateEndDate(minimumEnd);
  }, [minimumEnd, endDate, alwaysOn]);

  const updateEndDate = (newEnd: Date | null): void => {
    if (newEnd !== null && campaignPackage) {
      const newDate = new Date(newEnd.toDateString());
      newDate.setHours(0, 0, 0, 0);
      setEndDate(newDate);
      dispatch(
          setNewCampaignCustomPackage({
          ...campaignPackage,
          endDate: newDate,
          readyToDisplay: startDate !== null,
        })
      );
    }
  };

  //Both
  const dispatch = useDispatch();
  const title = customPackage?.title || "";
  const [startDate, setStartDate] = useState<Date>(minimumStart);
  const detailsRef = useRef<HTMLButtonElement>(null);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {
    if (startDate < minimumStart) {
      setStartDate(minimumStart);
    }
  }, [minimumStart]);

  const updateStartDate = (newStart: Date | null): void => {
    if (newStart !== null) {
      const newDate = new Date(newStart.toDateString());
      newDate.setHours(0, 0, 0, 0);
      setStartDate(newDate);
      if (campaignPackage) {
        dispatch(
          setNewCampaignCustomPackage({
            ...campaignPackage,
            startDate: newDate,
            endDate: addDaysToDate(
              newStart,
              campaignPackage.customPackage.standardDurationInHours / HOURS_IN_DAY
            ),
            readyToDisplay: true,
          })
        );
      }

      if( onSelectedDateChange != undefined){
        onSelectedDateChange( newDate );
      }
    }
  };

  useEffect(() => {
    if (!minimumEnd) {
      setMinimumEnd(addDaysToDate(startDate, 7));
    }
  }, [startDate, minimumEnd]);

  return (
    <div className={`channel-card campaign-package`}>
      <div className="title">
          {title === "Grand Opening" ||
            title === "Grand Opening Lite" ? (
            <IconOpening
              style={{
                marginRight: "10px",
                height: "50px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : title === "New Customer" ||
            title === "New Customer Lite" ? (
            <NewCustomerIcon
              style={{
                marginRight: "43px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : (
            <DigitalIcon
              style={{
                marginRight: "10px",
                height: "50px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          )}
        <div className="text package-link">
          <span>{title}</span>
          <Button className="details-link" type="link" ref={detailsRef} onClick={() => {
            setShowDetails(true)
          }}>Details</Button>
          <Popover
            open={showDetails}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            anchorEl={detailsRef.current}
            onClose={() => setShowDetails(false)}
            disableRestoreFocus={true}
          >
              {campaignPackage && <CustomPackageDetailsPopover campaignPackage={campaignPackage} />}
          </Popover>
        </div>
      </div>

      <div className="channel-date">
        <div className="input-container">
          <div className="channel-datepicker-label">
            Start Date
          </div>
          <div className="channel-data-element">
            <DatePicker
              value={startDate ?? undefined}
              onChange={(date) => updateStartDate(date)}
              style={{ borderRadius: "4px" }}
              disabledDate={(d) => !d || isBefore(d, minimumStart)}
            />
          </div>
        </div>
      </div>

      {(alwaysOn && campaignPackage) ?
        <div className="channel-end">
          <AlwaysOnTag />
        </div>
        :
        <div className="channel-date">
          <div className="input-container">
            <div className="channel-datepicker-label">
              End Date
            </div>
            <div className="channel-data-element">
              <DatePicker
                value={endDate ?? undefined}
                onChange={(date) => updateEndDate(date)}
                style={{ borderRadius: "4px" }}
                disabledDate={(d) => !d || isBefore(d, minimumEnd ?? addDaysToDate(minimumStart, 1))}
              />
            </div>
          </div>
        </div>
      }
    </div >
  );
};

export default React.memo(PackageCard);
