import style from './MediaManager.module.css'

const FranchiseList = ({ franchises }: { franchises: string[] }) => {
    return (
      <div>
        <p>Select franchise: </p>
        <div className={style["column"]}>
          {franchises.map(franchise => (
            <div key={franchise} className={style["item"]}>
              {franchise}
            </div>
          ))}
        </div>
      </div>
    );
  };

  export { FranchiseList };