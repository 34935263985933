import "./AdCampaignModal.css";
import { Modal } from "antd";
import { AdCampaign } from "../../../../models/AdCampaign";
import { AdCampaignModalChannelRow } from "./AdCampaignModalChannelRow";
import Link from "@mui/material/Link";
import { AdCampaignModalPackageRow } from "./AdCampaignModalPackageRow";

interface AdCampaignModalProps {
  campaign: AdCampaign;
  closeModal: () => void;
}

export const AdCampaignModal = ({
  closeModal,
  campaign,
}: AdCampaignModalProps): JSX.Element => {
  const today = new Date();
  const activeChannels = campaign.campaignChannels.filter((channel) => {
    if (channel.endDate !== undefined && channel.endDate !== null) {
      return (
        channel.endDate.getTime() > today.getTime() &&
        channel.startDate.getTime() <= today.getTime()
      );
    }
    return channel.startDate.getTime() <= today.getTime();
  });

  const completedChannels = campaign.campaignChannels.filter((channel) => {
    if (channel.endDate !== undefined && channel.endDate !== null) {
      return channel.endDate.getTime() < today.getTime();
    }
    return false;
  });

  const futureChannels = campaign.campaignChannels.filter((channel) => {
    return channel.startDate.getTime() > today.getTime();
  });

  const activePackages = campaign.campaignPackages.filter((cPackage) => {
    return (
      cPackage.endDate.getTime() > today.getTime() &&
      cPackage.startDate.getTime() <= today.getTime()
    );
  });

  const completedPackages = campaign.campaignPackages.filter((cPackage) => {
    return cPackage.endDate.getTime() < today.getTime();
  });

  const futurePackages = campaign.campaignPackages.filter((cPackage) => {
    return cPackage.startDate.getTime() > today.getTime();
  });

  return (
    <Modal
      title={<h2>Campaign</h2>}
      visible={true}
      onCancel={closeModal}
      footer={null}
      width={"60vw"}
    >
      <div>
        <h2>Active Channels</h2>
        {activeChannels.map((channel) => (
          <AdCampaignModalChannelRow
            key={`channel row for campaign modal: ${channel.id}`}
            channel={channel}
          />
        ))}
        {activePackages.map((cPackage) => (
          <AdCampaignModalPackageRow
            key={`cPackage row for campaign modal: ${cPackage.id}`}
            cPackage={cPackage}
          />
        ))}
        {campaign.campaignChannels.length === completedChannels.length &&
          campaign.campaignPackages.length === completedPackages.length && (
            <p>
              This campaign is complete. Please plan
              another campaign or visit the Reports
              section to see historical campaign performance.
            </p>
          )}
      </div>

      {futureChannels.length > 0 ||
        (futurePackages.length > 0 && (
          <div style={{ marginTop: "60px" }}>
            <h2>Scheduled Channels</h2>
            {futureChannels.map((channel) => (
              <AdCampaignModalChannelRow
                key={`channel row for campaign modal: ${channel.id}`}
                channel={channel}
              />
            ))}
            {futurePackages.map((cPackage) => (
              <AdCampaignModalPackageRow
                key={`cPackage row for campaign modal: ${cPackage.id}`}
                cPackage={cPackage}
              />
            ))}
          </div>
        ))}
    </Modal>
  );
};
