import "./UserPaymentMethods.css";
import { useSelector } from "react-redux";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { SavedPaymentCard } from "./SavedPaymentCard";
import { Button } from "antd";
import { appColors } from "../../assets/variables/colors";
import { useState } from "react";
import { AddCardForm } from "./AddCardForm";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";

export const UserPaymentMethodsPage = (): JSX.Element => {
  const account = useSelector(selectUserAccount);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const whiteLabel = useSelector( selectWhiteLabel );

  return (
    <div className={classNames.container}>
      {!isFormVisible ? <h1 className="text-2xl mb-3">Saved Credit Cards</h1> : <h1 className="text-2xl mb-3">Add New Card</h1>}
      {account && (
        <>
          {!isFormVisible ? (
            <>
              {account.paymentMethods?.map((method) => (
                <SavedPaymentCard
                  method={method}
                  key={`saved method card: ${method.id}`}
                />
              ))}
              <Button
                style={{ backgroundColor: whiteLabel.primaryColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                className="primary-button pointer-hover"
                onClick={() => setIsFormVisible(true)}
              >
                <div className="primary-button-text">ADD</div>
              </Button>
            </>
          ) : (
            <AddCardForm onClose={() => setIsFormVisible(false)} showBackButton={true}/>
          )}
        </>
      )}
    </div>
  );
};

const classNames = {
  container: "user-payment-methods-page-container",
};
