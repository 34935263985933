import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "../index";
import services from "../../services";
const initialState = {
    isOpen: false,
};

export const mediaTemplateSlice = createSlice({
    name: "mediaTemplate",
    initialState,
    reducers: {
        setOpen: (state, {payload: currentState}: PayloadAction<boolean>) => {
            state.isOpen = currentState;
        }
    }
});

export const selectIsOpenState = (state: RootState): boolean => state.mediaTemplate.isOpen;

export const {
    setOpen
} = mediaTemplateSlice.actions;

export default mediaTemplateSlice.reducer;