//Each entry in the monthlyBudget list, each entry contains the numeric value and a
//boolean value that determines if this budget is actually defined or not.
export interface IMonthlyBudgetDetail{
    value: number,
    isDefined: boolean
}
export interface IMonthlyBudget {
    budgetMode: string;
    budgets: IMonthlyBudgetDetail[]
}

export interface IMonth {
    code: string;
    label: string;
}


const months = [
    {
        code: "jan",
        label: "January"
    },
    {
        code: "feb",
        label: "February"
    },
    {
        code: "mar",
        label: "March"
    },
    {
        code: "apr",
        label: "April"
    },
    {
        code: "may",
        label: "May"
    },
    {
        code: "jun",
        label: "June"
    },
    {
        code: "jul",
        label: "July"
    },
    {
        code: "aug",
        label: "August"
    },
    {
        code: "sept",
        label: "September"
    },
    {
        code: "oct",
        label: "October"
    },
    {
        code: "nov",
        label: "November"
    },
    {
        code: "dec",
        label: "December"
    }
];

/**
 * JBEC434, updated to return only the current month and the next month. 
*/
export const MonthlyBudgetOptions = (): IMonth[] => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Returns a number from 0 to 11

    let remainingMonths = [];
    remainingMonths.push(months[currentMonth]);
    if( currentMonth === 11 ){
        remainingMonths.push(months[0]);
    }else{
        remainingMonths.push(months[currentMonth + 1]);
    }
    
    return remainingMonths;
}

export const getMonthIndexByCode = (code: string) => {
    return months.findIndex((item) => item.code === code );
}

export const getMonthLastDate = (month: number) => {
    const currentYear = (new Date()).getFullYear();
    const resultDate = new Date(currentYear, month + 1, 1);
    resultDate.setDate(0);
    return resultDate;
}