import { LatLonPair } from "../store/campaignForm/CampaignForm";

export const metersInAMile: number = 1609.34;

// in Meters
const radiusOfEarth: number = 6378137;

// Adapted from https://stackoverflow.com/questions/2861272/polygon-area-calculation-using-latitude-and-longitude-generated-from-cartesian-s
// The array input to this function will have "n + 1" elements. The last element will have same values as that of first one.
export const CalculatePolygonArea = (coordinates: LatLonPair[]): number => {
  let area = 0;

  if (coordinates.length > 2) {
    for (let i = 0; i < coordinates.length - 1; i++) {
      const p1: LatLonPair = coordinates[i];
      const p2: LatLonPair = coordinates[i + 1];
      area +=
        ConvertToRadian(p2.lng - p1.lng) *
        (2 +
          Math.sin(ConvertToRadian(p1.lat)) +
          Math.sin(ConvertToRadian(p2.lat)));
    }

    area = (area * radiusOfEarth * radiusOfEarth) / 2;
  }

  // area in Meters squared
  return Math.abs(area);
};

export const CalculateCircleArea = (radius: number): number => {
  return Math.PI * radius ** 2;
};

const ConvertToRadian = (input: number) => {
  return (input * Math.PI) / 180;
};

export const polygonsHaveMatchingPoints = (
  poly1: LatLonPair[],
  poly2: LatLonPair[]
): boolean => {
  return poly1.every(
    (point) =>
      poly2.some(
        (comparePoint) =>
          comparePoint.lat === point.lat && comparePoint.lng === point.lng
      ) && poly1.length === poly2.length
  );
};
