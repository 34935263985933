import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ReactComponent as IconOpening } from "../../../assets/icons/opening.svg";
import { ReactComponent as DigitalIcon } from "../../../assets/icons/DigitalIcon.svg";
import { ReactComponent as NewCustomerIcon } from "../../../assets/icons/new-customer-icon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "@mui/material";
import { CustomPackage } from "../../../models/CustomPackage";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHasFranchiseSetSpend,
  setPreSelectedTemplates,
  setSelectedCustomPackage,
  setSelectedTemplates,
  setSetSpend,
  setTemplateTitle,
  selectIsBudgetModeFixed
} from "../../../store/campaignForm/CampaignForm";
import { Button } from "antd";
import { AvailableRoutes } from "../../../pages/Routes";
import { useNavigate } from "react-router-dom";
import { ChannelType } from "../../../models/Channel";
import { selectChannels } from "../../../store/Channels/ChannelStore";
import { selectCreativeTemplates } from "../../../store/CreativeTemplates/CreativeTemplateStore";
import { CreativeTemplate } from "../../../models/CreativeTemplate";
import CustomTooltip from "../../tooltip/tooltip";

interface PackageCardProps {
  customPackage: CustomPackage;
  setShowMobileSidebar: (showMobileSidebar: boolean) => void;
}

const PackageCard = ({
  customPackage,
  setShowMobileSidebar,
}: PackageCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, costPerTarget, standardDurationTitle, setSpend, tooltip } = customPackage;
  const channels: ChannelType[] = useSelector(selectChannels);
  const templates: CreativeTemplate[] = useSelector(selectCreativeTemplates);
  const hasFranchiseSetSpend = useSelector(selectHasFranchiseSetSpend);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [packageComingSoon, setPackageComingSoon] = useState<boolean>(false);
  const [mobileTooltipIsOpen, setMobileTooltipIsOpen] = useState(false);

  const isMonthlyBudgetFixed = useSelector(selectIsBudgetModeFixed);

  useEffect(() => {
    let comingSoon = false;
    if (hasFranchiseSetSpend && !setSpend) {
      comingSoon = true;
    }
    customPackage.customPackageChannels.forEach((cpc) => {
      const foundChannel = channels.find(
        (channel) => channel.id === cpc.channelTypeId
      );
      if (!foundChannel || foundChannel.comingSoon) {
        comingSoon = true;
      }
    });
    setPackageComingSoon(comingSoon);
  }, [channels, customPackage, hasFranchiseSetSpend, setSpend]);

  const addSelectedChannel = () => {
    if (tooltipIsOpen) {
      setTooltipIsOpen(false);
    }
    if (mobileTooltipIsOpen) setTooltipIsOpen(false);
    if (customPackage.setSpend) dispatch(setSetSpend(customPackage.setSpend));
    dispatch(setSelectedCustomPackage(customPackage));
    dispatch(setPreSelectedTemplates([]));

    let tempPackageChannels: ChannelType[] = [];
    let tempTemplates: CreativeTemplate[] = [];
    customPackage.customPackageChannels.map((packageChannel) => {
      if (
        channels.find((channel) => channel.id === packageChannel.channelTypeId)
      ) {
        tempPackageChannels.push(
          channels.filter(
            (channel) => channel.id === packageChannel.channelTypeId
          )[0]
        );
      }
      return packageChannel;
    });
    tempPackageChannels.forEach((channel) => {
      templates.forEach((template) => {
        if (template.channelId === channel.id) {
          tempTemplates.push(template);
        }
      });
    });
    dispatch(setSelectedTemplates(tempTemplates));
  };

  const renderTooltip = () => {
    // TODO: probably move these descriptions to the db
    switch (title) {
      case "Grand Opening":
        return (
          <p>
            Make a splash in your new neighborhood with this comprehensive grand
            opening plan. Using digital, social media, email and direct mail to
            get the word out and drive maximum traffic to your new location.
            This campaign should be scheduled to begin 2 weeks prior to your
            expected open date so new customers can prepare to visit during your
            grand opening event. Email and Direct mail target customers with
            your call to action to encourage maximum results.
          </p>
        );
      case "Grand Opening Lite":
        return (
          <p>
            An all-digital campaign designed to connect you with your local
            trade area. Campaigns should be scheduled to begin 2 weeks prior to
            your location opening to build excitement and awareness toward you
            opening day. Email is added to both digital and Social advertising
            as an added layer to communicate your call to action.
            <br />
            Consider this plan when opening in close proximity to another
            location or when a soft grand opening is preferred. Grand Opening
            Lite is best when used in conjunction with your visual noise/parking
            lot promotion plan.
          </p>
        );
      case "New Customer Lite":
        return (
          <p>
            A targeted program designed to introduce you to new customers in
            your trade area while suppressing your existing customers from the
            offer list. This all digital campaign runs for 4 weeks - uses email,
            digital and social ads to drive traffic.
          </p>
        );
      case "New Customer":
        return (
          <p>
            Drive new traffic to your locations using this omni-channel campaign
            and save discounting dollars by suppressing your known customers.
            This campaign using email, social, digital and direct mail to
            communicate you offer and drive maximum response to your store.
          </p>
        );
      case "Tier 1: Search and Social":
        return (
          <p>
            Campaign designed to target your ideal franchisee prospects across both search and social
            channels. Breakdown: 33% of budget search campaigns, 66% of budget social.
          </p>
        );
      case "Tier 2: Search, Social, Digital":
        return (
          <p>
            Campaign designed to target your ideal franchisee prospects across Search, Social and Digital
            Channels. Increasing the amount of channels you reach your prospects on increases engagement of
            the campaign as a whole. Breakdown: 25% of budget search, 50% of budget social, 25% of budget
            digital.
          </p>
        );
      case "Tier 3: Search, Social, Digital, Email":
        return (
          <p>
            Campaign designed to target your ideal franchisee prospects across Search, Social and Digital
            Channels while adding Email as a retargeting campaign. Increasing the amount of channels you
            reach your prospects on, increases engagement of the campaign as a whole. Adding email for
            retargeting will convert leads who had already shown interest in your brand's message.
            Breakdown: 30% of budget search, 30% of budget social, 20% of budget digital, 20% of budget
            Email.
          </p>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="package-card-container tablet-and-descktop-channel">
        <div>
          <div
            className="channel-title"
          >
            {title === "Grand Opening" || title === "Grand Opening Lite" ? (
              <IconOpening
                style={{
                  marginRight: "15px",
                  minWidth: "51px",
                  minHeight: "51px",
                }}
              />
            ) : title === "New Customer" || title === "New Customer Lite" ? (
              <NewCustomerIcon
                style={{
                  marginRight: "15px",
                  minWidth: "51px",
                  minHeight: "51px",
                }}
              />
            ) : (
              <DigitalIcon
                style={{
                  marginRight: "15px",
                  minWidth: "51px",
                  minHeight: "51px",
                }}
              />
            )}
            {title}
          </div>
        </div>

        <div className="res:w-full">
          {isMonthlyBudgetFixed ? "" : (
            <div className="channel-data">
              <div className="channel-data-element">
                <div className="channel-data-title">{"Investment:"}</div>
                  $
                    {setSpend || `${costPerTarget.toFixed(3)} / target`}
                  </div>
                  <div className="channel-data-element">
                    <div className="channel-data-title">Duration:</div>
                    {standardDurationTitle}
                  </div>
            </div>
          ) }
          <div style={{ marginTop: "30px", display: "flex" }}>

            <Button
              className="inverted-general-button pointer-hover"
              style={{
                marginRight: '8px'
              }}
              onClick={() => {
                navigate(AvailableRoutes.TEMPLATES);
                dispatch(setTemplateTitle(title));
              }}
            >
              VIEW TEMPLATES
            </Button>

            <button
              className="channel-button"
              onClick={addSelectedChannel}
              disabled={packageComingSoon}
            >
              {packageComingSoon ? "Coming Soon" : "ADD"}
            </button>
            <CustomTooltip
              open={tooltipIsOpen}
              className="res:hidden"
              onMouseEnter={() => setTooltipIsOpen(true)}
              onMouseLeave={() => setTooltipIsOpen(false)}
              title={(<><p> {customPackage.tooltip} </p></>)}
              arrow
            >
              <InfoCircleOutlined
                style={{
                  color: "#424242",
                  fontSize: "25px",
                  marginLeft: "25px",
                }}
              />
            </CustomTooltip>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div
        className="package-card-container mobile-channel"
        style={{ flexDirection: "column" }}
      >
        <div
          className="channel-title"
          style={{ top: "20px", width: "100%", justifyContent: "center" }}
        >
          {title === "Grand Opening" || title === "Grand Opening Lite" ? (
            <IconOpening
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : title === "New Customer" || title === "New Customer Lite" ? (
            <NewCustomerIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          ) : (
            <DigitalIcon
              style={{
                marginRight: "15px",
                minWidth: "51px",
                minHeight: "51px",
              }}
            />
          )}
          {title}
          <CustomTooltip
            open={mobileTooltipIsOpen}
            className="res:hidden"
            onClick={() => setMobileTooltipIsOpen(!mobileTooltipIsOpen)}
            title={(<><p> {customPackage.tooltip} </p></>)}
            arrow
          >
            <InfoCircleOutlined
              style={{ color: "#424242", fontSize: "25px", marginLeft: "25px" }}
            />
          </CustomTooltip>
        </div>

        <div className="res:w-full">
          (isMonthlyBudgetFixed ? "" :
            <div className="channel-data">
              <div className="channel-data-element">
                <div className="channel-data-title">{"Investment:"}</div>$
                {setSpend || `${costPerTarget.toFixed(3)} / target`}
              </div>
              <div className="channel-data-element">
                <div className="channel-data-title">Duration:</div>
                {standardDurationTitle}
              </div>
            </div>
            )
          <Button
            className="inverted-general-button pointer-hover"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
            onClick={() => {
              navigate(AvailableRoutes.TEMPLATES);
              dispatch(setTemplateTitle(title));
            }}
          >
            VIEW TEMPLATES
          </Button>
          <div className="res:flex items-center" style={{ marginTop: "30px" }}>
            <button
              className="channel-button"
              onClick={() => {
                addSelectedChannel();
                setShowMobileSidebar(true);
              }}
              style={{ width: "200px" }}
              disabled={packageComingSoon}
            >
              {packageComingSoon ? "Coming Soon" : "ADD"}
            </button>
            <CustomTooltip
              open={tooltipIsOpen}
              className="res:block hidden"
              onMouseEnter={() => setTooltipIsOpen(true)}
              onMouseLeave={() => setTooltipIsOpen(false)}
              title={(<><p> {customPackage.tooltip} </p></>)}
              arrow
            >
              <InfoCircleOutlined
                style={{
                  color: "#424242",
                  fontSize: "25px",
                  marginLeft: "25px",
                }}
              />
            </CustomTooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(PackageCard);
