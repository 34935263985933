import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/Routes";
import { selectUserAccount } from "../../../store/Account/AccountStore";
import {
  selectNewAdCampaign,
  setNewAdCampaign,
} from "../../../store/AdCampaigns/AdCampaignStore";
import {
  resetNewCampaignForm,
  selectPaymentMethod,
} from "../../../store/campaignForm/CampaignForm";
import "./Confirmation.css";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import ReviewAndPaySidebar from "../reviewAndPay/ReviewAndPaySidebar";

const Confirmation = () => {
  const navigate = useNavigate();
  const paymentMethod: string = useSelector(selectPaymentMethod);
  const dispatch = useDispatch();
  const newCampaign = useSelector(selectNewAdCampaign);
  const account = useSelector(selectUserAccount);
  const usedPaymentMethod = account?.paymentMethods?.find(
    (m) => m.cardId === paymentMethod
  );

  useEffect(() => {
    return () => {
      dispatch(resetNewCampaignForm());
      dispatch(setNewAdCampaign(null));
    };
  }, []);

  const whiteLabel = useSelector(selectWhiteLabel);

  return (
    <>
      {newCampaign && (
        <>
          <div dangerouslySetInnerHTML={{__html: whiteLabel.orderSuccessText }} className="confirmation-text res:text-xl">
            
          </div>
          <div className="confirmation-container" style={{ marginTop: "40px" }}>
            <div className="confirmation-order">
              <span>Order No. <u>{newCampaign.receiptNumber}</u></span>
              <ReviewAndPaySidebar />
            </div>
{/*             <div className="confirmation-payment-info">
              <div className="confirmation-billed-and-payment">
                <div className="confirmation-billed-and-payment-title">
                  <b style={{ fontSize: "20px" }}>Billed To</b>
                </div>
                {account?.firstName} {account?.lastName}
                <br />
                {account?.address1}
                <br />
                {account?.city}, {account?.state} {account?.postalCode}
                <br />
                {account?.country}
              </div>
              <div
                className="confirmation-billed-and-payment confirmation-billed-and-payment-title"
                style={{ marginTop: "20px" }}
              >
                <b style={{ fontSize: "20px" }}>Payment Method</b>
              </div>
              <div className="confirmation-billed-and-payment">
                {usedPaymentMethod?.cardBrand} ending in{" "}
                {usedPaymentMethod?.last4}
              </div>
            </div> */}
          </div>
        </>
      )}
      <p
        className="confirmation-back"
        onClick={() => {
          navigate(AvailableRoutes.ALERT);
        }}
      >
        <u>
          <b>Back to my dashboard</b>
        </u>
      </p>
    </>
  );
};

export default Confirmation;
