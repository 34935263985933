import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreativeTemplate } from "../../models/CreativeTemplate";
import services from "../../services";
import { RootState } from "../index";

export const fetchTemplates = createAsyncThunk(
  "creativeTemplates/fetch",
  async () => {
    return services.creativeTemplateService.getCreativeTemplates();
  }
);

export const fetchTemplatesForEachChannel = createAsyncThunk(
  "channelTemplates/fetch",
  async () => {
    return services.creativeTemplateService.getCreativeTemplates();
  }
);

interface ImageRequestProps {
  channelId: string,
  message: string,
}

export const fetchChannelImages = createAsyncThunk(
    "creativeTemplates/fetch",
    async (props: ImageRequestProps) => {
      return services.creativeTemplateService.getChannelImages(props.channelId, props.message);
    }
);

export const templateSlice = createSlice({
  name: "templates",
  /** 
   * JBEC 234 update
   * Added a new state attr templatesForChannel as a fix that stores at least one template for each channel.
   * These values used to be stored in the same "templates" attr which also stored all the templates when opening a drawer.
   * The same attribute was being used and this caused loss of data because it was being overriden.
  */

  initialState: {
    templates: [] as CreativeTemplate[],
    templatesForEachChannel: [] as CreativeTemplate[],
  },
  reducers: {
    setCreativeTemplates: (
      state,
      { payload: templates }: PayloadAction<CreativeTemplate[]>
    ) => {
      state.templates = templates;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChannelImages.fulfilled, (state, action) => {
      state.templates = action.payload;
    });
    builder.addCase(fetchTemplatesForEachChannel.fulfilled, (state, action) => {
      state.templatesForEachChannel = action.payload;
    });
  },
});

export const selectCreativeTemplates = (state: RootState): CreativeTemplate[] =>
  state.templates.templates;

export const selectTemplatesForEachChannel = (state: RootState): CreativeTemplate[] =>
  state.templates.templatesForEachChannel;

export const { setCreativeTemplates } = templateSlice.actions;

export default templateSlice.reducer;
