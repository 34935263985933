import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePromQRCode } from "../../utility/qrcodes";
import { Form, Input, Button } from "antd";
import { QRPromCode } from "../../models/QRCode";
import { selectQRPromCodeBatch, createPromCodeBatch } from "../../store/QRPromCodes/QRPromCodesStore";

const QRGenerator: React.FC = () => {

  const dispatch = useDispatch();
  const qrPromCodeBatch: QRPromCode[] = useSelector(selectQRPromCodeBatch);
  const [testQRs, setTestQR] = useState<string[]>([]);
  const [areLoadingQRCodes, setAreLoadingQRCodes] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setAreLoadingQRCodes(true)
    const askQR = async () => {
      const { size, promotion, expirationDate, notes } = values;
      dispatch(createPromCodeBatch({ size, promotion, expirationDate, notes }));
    };

    askQR();
  };
  useEffect(() => {
    const updateImgsInDOM = async () => {
      const newPromCodes: string[] = []
      for (let { promotion, expirationDate, notes } of qrPromCodeBatch) {
        const stringQrPromCode = await generatePromQRCode(promotion)
        newPromCodes.push(stringQrPromCode)
      }
      setTestQR(newPromCodes);
      setAreLoadingQRCodes(false)
    }
    updateImgsInDOM();
  }, [qrPromCodeBatch])

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h1>QR Code batch generator</h1>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="container-form"
        layout="vertical">
        <Form.Item
          label="Size"
          name="size"
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Promo" name="promotion"
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Expiration Date" name="expirationDate"
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Input type="date" />
        </Form.Item>
        <Form.Item
          label="Notes" name="notes"
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item style={{ marginTop: 0 }}>
          <Button
            loading={areLoadingQRCodes}
            htmlType="submit"
            className="general-button pointer-hover"
            style={{ backgroundColor: "#ff7b00", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Generate new PROM batch
          </Button>
        </Form.Item>
      </Form>
      {
        testQRs.map((qrURL) => {
          return <img src={qrURL} alt="" style={{ marginRight: 10 }} />
        })
      }
    </div >
  );
};

export default QRGenerator;