import React, { useState, useEffect } from "react";
import HeaderLayout from "../../Layout/HeaderLayout";
import { AvailableRoutes } from "../../../pages/Routes";
import SideBar from "../../navbar/SideBar";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as IconMore } from "../../../assets/icons/more-vertical.svg";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { assetImage } from "../../../utility/whiteLabelManager";
import {getLogoForSubdomain} from "../../../utility/subdomain";
import services from "../../../services/index";
import { selectUserAccount } from "../../../store/Account/AccountStore"
import { selectFranchiseLocations} from "../../../store/Location/LocationStore";
import { selectFranchise } from "../../../store/Franchise/FranchiseStore";

const AccountLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const [currentSelectedMenu, setCurrentSelectedMenu] = useState("Account Information")
  const whiteLabel = useSelector( selectWhiteLabel );

  const account = useSelector(selectUserAccount);
  const locations = useSelector(selectFranchiseLocations);
  const franchise = useSelector(selectFranchise);

  function handleMenuChange(newMenu: string){
    setCurrentSelectedMenu(newMenu);
    setShowMobileSidebar(false);
  }

  useEffect(() => {
    services.dataLayerService.registerPageView();
  });

  return (
    <div style={{ height: "100%" }}>
      <HeaderLayout>
        <img
          className="header-logo"
          onClick={() => navigate(AvailableRoutes.ALERT)}
          src={ assetImage( whiteLabel.secondaryLogoPath ) }
          alt=""
        />
      </HeaderLayout>

      <div className="content-body res:pb-28">
        <div className="content-body-content">{children}</div>

        <div className="dashboard-sidebar res:!hidden">
          <SideBar selectedOption={currentSelectedMenu} handleSelectedOptionChange={handleMenuChange} />
        </div>

        <div className="dashboard-mobile-sidebar z-50">
          <div className="mobile-sidebar-button">
            <div className="mobile-button-background res:!bg-white res:flex-wrap">
              

              {showMobileSidebar && (
                <SideBar selectedOption={currentSelectedMenu} handleSelectedOptionChange={handleMenuChange} />
              )}

              {!showMobileSidebar && (
                <div
                  className="res:flex items-center res:mt-0 res:h-12 res:basis-full relative hidden report-sidebar-item pointer-hover"
                  style={{
                    backgroundColor:whiteLabel.activeColor
                  }}
                >
                  <p>
                    { currentSelectedMenu }
                  </p>
                  <IconMore
                    className="mobile-more-icon res:!absolute res:!top-1 res:!right-4 res:rotate-90"
                    onClick={() => setShowMobileSidebar(!showMobileSidebar)}
                  />
                </div> 
              )}
              
              
              <div
                className="res:basis-full sidebar-dashboard-button pointer-hover"
                onClick={() => navigate("/")}
              >
                <div className="sidebar-dashboard-button-text">
                  BACK TO DASHBOARD
                </div>
                <IconArrowRight />
              </div>
              
              
            </div>
          </div>
        </div>

        { showMobileSidebar && (
          <div
            className="res:w-full z-40 mobile-sidebar-oppacity"
            onClick={() => setShowMobileSidebar(false)}
          ></div>
        )}
        
      </div>
    </div>
  );
};

export default React.memo(AccountLayout);
