import styles from './SidePanelCreateEvent.module.css'
import closeIcon from '../../../../assets/icons/cancel.svg'
import { Button, Form, Input, Modal, Select, Checkbox } from "antd"
import DatePicker from "../../../datepicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";

import { createEvent, updateEvent } from "../../../../store/Events/EventStore";
import { Event } from "../../../../models/Event";
import { useEffect, useState } from "react";
import { UsStates } from "../../../account/BillingAddress/UsStates";
import { CountryEnum } from "../../../../models/Location";
import { selectWhiteLabel } from "../../../../store/WhiteLabel/WhiteLabelStore";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onCancel: () => void;
  isEdit: boolean;
  event?: Event;
}

const SidePanelCreateEvent: React.FC<Props> = ({
  visible,
  setVisible,
  onCancel,
  isEdit,
  event }) => {

  const [viewState, setViewState] = useState(false);
  const { Option } = Select;
  const dispatch = useDispatch();
  // const account = useSelector(selectUserAccount);

  const whiteLabel = useSelector(selectWhiteLabel);
  const onFinish = (values: any) => {

    if (isEdit && event !== undefined) {
      dispatch(
        updateEvent({
          id: event.id,
          title: values.eventName,
          details: values.eventDetails,
          startDate: values.eventStartDate,
          endDate: values.eventEndDate,
          location: values.eventLocation,
          other: "",
          isBlackoutDate: values.isBlackoutDate,
        })
      );
    } else {
      dispatch(
        createEvent({
          title: values.eventName,
          details: values.eventDetails,
          startDate: values.eventStartDate,
          endDate: values.eventEndDate,
          location: values.eventLocation,
          other: "",
          isBlackoutDate: values.isBlackoutDate,
        })
      );
    }
    onCancel();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const renderOptions = (option: string) => {
    return (
      <Option key={option} value={option}>
        {option}
      </Option>
    );
  };

  const closeModal = () => {
    setVisible(false);
  }

  return (
    <div className={styles[visible ? 'side-panel-container' : 'side-panel-container-hidden']+ " z-[100] res:fixed res:top-0 res:left-0 res:bottom-0 res:right-0"}>
      <div className={styles['side-panel']+ " res:w-full res:basis-full"}>
        <img src={closeIcon} width={30} height={30} alt='close icon' className={styles['close-img']} onClick={closeModal} />
        {visible}

        <h2 className=' res:text-xl' style={{marginBottom: "32px" }}>{isEdit ? 'Edit Event' : 'New Event'}</h2>

        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={styles["container-form"]}
          layout="vertical"
          initialValues={{
            eventName: event?.title,
            eventStartDate: event?.startDate,
            eventEndDate: event?.endDate,
            eventDetails: event?.details,
            eventLocation: event?.location,
          }}
        >
          <Form.Item
            label="Event Name"
            name="eventName"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Input.Group compact>
            <Form.Item
              label="Starts"
              name="eventStartDate"
              style={{ width: "46%" }}
              rules={[{ required: true }]}
            >
              <DatePicker
                // value={startDate ?? undefined}
                // onChange={(date) => updateStartDate(date)}
                use12Hours={false}
                showTime={false}
                showHour={false}
                showMinute={false}
                showSecond={false}
                style={{ width: "100%" }}
              // disabledDate={(d) => !d || isBefore(d, minimumStart)}
              />
            </Form.Item>

            <Form.Item
              label="Ends"
              name="eventEndDate"
              style={{ marginLeft: "8%", width: "46%" }}
            >
              <DatePicker
                // value={startDate ?? undefined}
                // onChange={(date) => updateStartDate(date)}
                use12Hours={false}
                showTime={false}
                showHour={false}
                showMinute={false}
                showSecond={false}
                style={{ width: "100%" }}
              // disabledDate={(d) => !d || isBefore(d, minimumStart)}
              />
            </Form.Item>
          </Input.Group>

          <Form.Item
            label="Details"
            name="eventDetails"
            rules={[{ required: true }]}
          >
            <Input placeholder="Event details" />
          </Form.Item>

{/*          <Input.Group compact>
            <Form.Item
              label="Event Location"
              name="eventLocation"
              style={{ width: "100%" }}
              rules={[{ required: false }]}
            >
              <Input placeholder="Location" />
            </Form.Item>
          </Input.Group>*/}

          <Form.Item
            label="Blackout date"
            name="isBlackoutDate"
            valuePropName="checked"
            rules={[{ required: false }]}
            initialValue={ isEdit ? event?.isBlackoutDate : false }
            className="checkbox-inline"
          >
            <Checkbox></Checkbox>
          </Form.Item>

          <Form.Item
            label="Optimization"
            name="isOptimization"
            valuePropName="checked"
            initialValue={false}
            className="checkbox-inline"
          >
            <Checkbox></Checkbox>
          </Form.Item>

          <Form.Item
            label="Optimization type"
            name="optimizationType"
            rules={[{ required: false, message: 'Please select the optimization type' }]}
          >
            <Select placeholder="Select an type">
              <Option value="cpaGuard">CPA guard</Option>
              <Option value="keywordGuard">Keyword guard</Option>
              <Option value="creativeBooks">Creative boost</Option>
              <Option value="budgetBoost">Budget boost</Option>
              <Option value="audienceCleanup">Audience cleanup</Option>
              <Option value="keywordCleanup">Keyword cleanup</Option>
              <Option value="geoCleanup">Geo cleanup</Option>
              <Option value="domainCleanup">Domain cleanup</Option>
            </Select>
          </Form.Item>

          <Form.Item style={{ marginTop: 20 }}>
            <Button
              htmlType="submit"
              className="general-button pointer-hover"
              style={{ backgroundColor: whiteLabel.primaryColor, width: "100%" }}
            >
              {isEdit ? 'SAVE' : 'CREATE EVENT'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default SidePanelCreateEvent
