import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip  {...props} classes={{ popper: className }}   />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
          backgroundColor: theme.palette.common.white,
          border: "1px solid #fff"
        }
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ffffff',
      color: '#000000',
      maxWidth: 340,
      fontSize: theme.typography.pxToRem(13),
      filter: "drop-shadow(0px 0px 8px rgba(21, 7, 37, 0.4))",
      fontFamily: "Mulish",
      padding: "12px"
    },
}));


export default CustomTooltip;