import RestService from "./restService";
export default class FileService extends RestService{

    private _createUrl(urlPath: string): string {
        return this.baseUri + urlPath;
    }

    private async _getHeaders(): Promise<any> {

        const Authorization = await this.authService.getToken();
        return Authorization ? { Authorization: `Bearer ${Authorization}` } : {};
    }

    /**
     * Download file
     *
     * @param urlPath
     * @param headers
     */
    public async download<T>(urlPath: string, headers: any = {}): Promise<T> {
        const url = this._createUrl(urlPath);
        Object.assign(headers, await this._getHeaders());
        const response = await this.http.get<T>(url, { responseType: 'arraybuffer', headers});
        return response.data;
    }
}