/**
 * This file contains methods that provide the basic functionality for
 * white-labeling within the application. 
*/
import { GlobalStyleComponent, createGlobalStyle, DefaultTheme } from 'styled-components';


import { WhiteLabel } from '../models/WhiteLabel';

/**
 * Requests the API to fetch de WhiteLabel configuration for the current user/domain,
 * or fetchs existing configurations from root state.
*/
export async function getCurrentDomain() : Promise<string>{
    return window.location.hostname;
}

/**
 * Sets the css-variables globally, as defined by the provided
 * WhiteLabel parameter.
 * Returns the configuration as a GlobalStyles component.
*/
export function getGlobalStyles( whiteLabel: WhiteLabel ) : GlobalStyleComponent<{}, DefaultTheme>{
    
    const GlobalStyles = createGlobalStyle`
        html {
            --color-primary: ${whiteLabel.primaryColor};
            --color-secondary: ${whiteLabel.secondaryColor};
            --color-active: ${whiteLabel.activeColor};
            --color-gradient: ${whiteLabel.gradientColor};
            --color-gradient-outline: ${whiteLabel.gradientOutlineColor}; 
        }
    `;

    return GlobalStyles;
}


/** 
 * La ruta utilizada para el Método POST que sube un logo directamente con
 * el file uploader integrado de antd.
 * Dependiendo del entorno esta ruta es diferente.
*/
export function getLogoUploadPath(){
    //Api path
    let path = process.env.REACT_APP_PLATFORM_API ?? "http://localhost:5000";
    return path+'/white-label/logo';
}

/**
 * La ruta utlizada para obtener un recurso de imagen desde el platform-api.
 * Regresa el stream de bits de ese recurso. 
*/
export function assetImage(imageName : string){
    //Api path
    let path = process.env.REACT_APP_AWS_S3_BUCKET_URL!;
    return path + imageName;

}