export const AvailableRoutes = {

  // TEMP/TEST ROUTES
  QR_PROM_GEN: "/qr-prom-generation",
  QR_URL_GEN: "/qr-url-generation",
  QR_LAND: "/qr-landing",
  QR_SCAN: "/qr-scan",

  // ACCOUNT ROUTES
  ACCT_INFO: "/account-information",
  BILLING_ADDRESS: "/billing-address",
  CAMPAIGN_HISTORY: "/campaign-history",
  BILLING: "/billing",
  CUSTOM_EVENTS: "/notifications",
  CHANGE_PASSWORD: "/change-password",
  CREATE_LOCATION: "/create-location",
  EDIT_LOCATION: "/edit-location",
  MANAGE_EVENTS: "/manage-events",
  MEDIA: "/media",
  PAYMENT_METHODS: "/payment-methods",
  SYSTEM_TOOLS: "/system-tools",
  ACTIVE_ORDERS: "/active-orders",

  // DASHBOARD ROUTES
  ALERT: "/",
  EVENT: "/event", 
  REPORT: "/report",
  //REPORTS: "/reports",
  ACTIVE_CAMPAIGNS: "/active-campaigns",
  // OMNI_CHANNEL: "/omni-channel",
  PERFORMANCE_OVERVIEW: "/performance-overview",
  REPORTS_LANDING: "/reports-landing",
  KEYWORDS_CREATIVE: "/keywords-creative",
  //KEYWORD_HELPER: "/keyword-helper",
  KEYWORD_HELPER: "/optimization",
  SPEND_PACING: "/spend-and-pacing",
  BUDGET_TRACKER: "/budget-tracker",
  BUDGET_TRACKER_APPROVALS: '/budget-approvals',
  LOCATION_MANAGER: "/location-manager",

  // PURCHASE ROUTES
  CHANNELS: "/channels",
  CONFIRMATION: "/confirmation",
  LOCATIONS: "/locations",
  SCHEDULE: "/schedule",
  REVIEW: "/review",
  TARGET_ZONE: "/target-zone",
  TEMPLATES: "/templates",
  
  // REGISTRATION ROUTES
  HELP: "/help",
  GUIDES: "/guides",
  REQUEST: "/request",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  SIGNUP: "/signup",

};
