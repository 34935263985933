import RestService from "./restService";

interface IFranchiseOptConfigOption {
    [key:string]: number | boolean;
}

export interface IFranchiseOptConfig {
    [key:string]: IFranchiseOptConfigOption;
}

export default class OptimizationService {

    constructor(protected readonly api: RestService) { }

    /**
     * Save updates to franchise config
     *
     * @param config
     */
    async saveOptimizationSettings(config: IFranchiseOptConfig): Promise<IFranchiseOptConfig> {
        return await this.api.post('/franchises/optimization/settings', config);
    }

    /**
     * Retrieve current optimization settings from server
     */
    async fetchOptimizationSettings(): Promise<IFranchiseOptConfig> {
        return await this.api.get('/franchises/optimization/settings');
    }
}