import "./CalendarDayModal.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { selectTotalAudience } from "../../../../store/campaignForm/CampaignForm";
import { NewCampaignCustomPackage } from "../../../../models/CampaignPackage";

interface CalendarDayNewPackageTableProps {
  newPackages: NewCampaignCustomPackage[];
}

export const CalendarDayNewPackageTable = ({
  newPackages,
}: CalendarDayNewPackageTableProps): JSX.Element => {
  const audienceSize = useSelector(selectTotalAudience);
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Package</TableCell>
          <TableCell>Duration</TableCell>
          <TableCell>Audience Size</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {newPackages.map((newPackage) => {
          return (
            <TableRow
              key={`new package for day table:${newPackage.customPackage.id}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {newPackage.customPackage.title}
              </TableCell>
              <TableCell>
                {newPackage.customPackage.standardDurationTitle}
              </TableCell>
              <TableCell>{audienceSize?.toLocaleString() ?? ""}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
