import React, { useState } from "react";
import MediaUploader from "../fileuploader/MediaUploader"
import SideModal from "../shared/SideModal";
import { Row, Col, Button, Tooltip } from "antd";
import style from "./MediaManager.module.css";
import { FranchiseList } from './FranchiseList';
import { AssignList } from "./AssingList";


export const MediaManagerPage = () => {

  const [errors, setErrors] = useState<string[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [franchises, setFranchises] = useState<string[]>(["FRAN01", "FRAN02", "FRAN03", "FRAN04", "FRAN05", "FRAN06", "FRAN07"]);
  const [options, setOptions] = useState<string[]>(["Option 1", "Option 2", "Option 3", "Option 4", "Option 5", "Option 6", "Option 7"]);

  const notifyErrors = (errorList: string[], message: string) => {
    setErrors(errorList);
    setErrorMessage(message);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return <div>
    <MediaUploader notifyErrors={notifyErrors} title="Upload Media"></MediaUploader>

    <h1>Manage Media</h1>
    <Col span={6}>
      <div className="admin-primary-button pointer-hover" onClick={toggleModal}>
        <div className="primary-button-text">BROWSE</div>
      </div>
    </Col>

    <SideModal title="Asign to Franchise"
      isOpen={isOpen} onClose={toggleModal} >
      <div>
        Uploading: <span className={style["file-name"]} >express_facebook2.png</span>

        <div className={style["selector-container"]}>
          <div className={style["selector"]}>
            <FranchiseList franchises={franchises} />
          </div>

          <div className={style["selector"]}>
            <AssignList options={options} />
          </div>
        </div>

        <div className={style["actions"]}>
          <Col span={5}>
            <div className="admin-primary-button pointer-hover">
              <div className="primary-button-text">SAVE</div>
            </div>
          </Col>
        </div>
      </div>
    </SideModal>
  </div>
}

export default MediaManagerPage