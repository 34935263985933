import { Row, Col, Button, Tooltip } from "antd";
import React from "react";
import { connect, useSelector } from 'react-redux';
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import './FileUploader.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ParseResult } from "papaparse";
import { FranchiseRowSchema } from "../../../services/franchiseService";
import { importFranchises, franchiseErrors } from "../../../store/Franchise/FranchiseStore"
import { RootState } from "../../../store";

interface FranchiseUploaderProps {
    title: string,
    notifyErrors: Function,
    importFranchises: Function,
    franchiseErrors: String[] | null,
    importFranchisesResult: String | null
}

interface FranchiseUploaderState {
    selectedFile: File | null,
    fileTypeError: string | null,
}

class FranchiseUploader extends React.Component<FranchiseUploaderProps, FranchiseUploaderState>{
    constructor(props: any){
        super(props);
        this.state = {
            selectedFile: null,
            fileTypeError: null,
        }
    }

    componentDidUpdate(prevProps: Readonly<FranchiseUploaderProps>): void {
        if( prevProps.franchiseErrors !== null){
            if( prevProps.franchiseErrors !== this.props.franchiseErrors){
                this.props.notifyErrors( 
                    this.props.franchiseErrors,
                    "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                );
            }
        }
        if( this.props.franchiseErrors !== null ){
            if( prevProps.importFranchisesResult !== this.props.importFranchisesResult ){
                this.setState({
                    selectedFile: null,
                });
                alert(this.props.importFranchisesResult);
            }
        }
    }
    
    onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        //Verify if the file is not null.
        if( !event.target.files )
            return;
        
        //Verifies if the file is supported (csv or xlsx)
        let fileType = event.target.files[0].type;
        if( 
            fileType === 'application/vnd.ms-excel' || 
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'text/csv'
        ){
            this.setState({
                selectedFile: event.target.files[0],
                fileTypeError: null,
            });
        }else{
            this.setState({
                fileTypeError: "The selected file is not valid. Only CSV and XLSX files are supported."
            });
        }
    }

    onFileRemove = () => {
        this.setState({
            selectedFile: null,
        });
    }

    onFileUpload = () => {
        //This process is different depending on the file type.
        let fileJson = [];

        let selectedFile = this.state.selectedFile;
        
        if( selectedFile?.type === 'text/csv'){
            this.parseFranchisesCSVFile(selectedFile).then((json) => {
                console.log('Trying to import the franchises csv file...');
                console.log(json);
                this.props.importFranchises(json);
            }).catch( (error: Error) => {

            });
        }else{
            this.parseFranchisesExcelFile(selectedFile!).then((json) => {
                console.log('Trying to import the franchises xlsx file...');
                console.log(json);
                this.props.importFranchises(json);
            }).catch( (error: Error) => {
                
            });
        }
    }

    //Validates and obtains the data as JSON for a XLSX file
    parseFranchisesExcelFile = (file: File) => {
        
        return new Promise<FranchiseRowSchema[]>( (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: "array"} );
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json<FranchiseRowSchema>(
                    worksheet,
                    {
                        //transforms de header names into camelCase.
                        raw: false,
                        header: ['name', 'appId', 'active', 'gadsCustomerId', 'fadsCampaignId', 'gAnalyticsId'],
                        range: 1,
                    }
                );
                
                //The library doesn't seem to support a method to validate
                //empty cells, so we validate this after processing.
                //empty cells are completely ignored by the library, so we need to
                //validate the existence of these missing attributes.
                let errors: string[] = [];
                json.forEach( (element, index) => {
                    let rowNum = index+1;
                    let length = Object.keys(element).length;
                    if( 'active' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Active cell.");
                    if( 'appId' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the App ID cell.");
                    if( 'name' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Name cell.");
                    if( 'gadsCustomerId' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Gads Customer ID cell.");
                    if( 'fadsCampaignId' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the Fads Campaign ID cell.");
                    if( 'gAnalyticsId' in element === false )
                        errors.push("<b>Row #"+rowNum+"</b> is missing the GAnalytics ID cell.");
                });

                //Reject this promise and notify the parent about the errors
                //to display in the large alert window.
                if( errors.length > 0 ){
                    
                    this.props.notifyErrors( 
                        errors, 
                        "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                    );

                    reject(Error('There were some errors in the file.'));
                }

                resolve(json);
            }
            reader.readAsArrayBuffer(file);
        });

    }

    //Validates and obtains data as JSON from a CSV file
    parseFranchisesCSVFile = ( file: File ) => {
        return new Promise<FranchiseRowSchema[]>( (resolve, reject) => {
            Papa.parse( file, {
                header: true,
                transformHeader: ((header: string) => this.camelize(header)),
                complete: (results: ParseResult<FranchiseRowSchema> ) => {
                    //Need to adjust the header names, and we do so while
                    //manually validating each row.
                    let json = results.data;
                    let errors: string[] = [];
                    json.forEach( (element, index) => {
                        let rowNum = index+1;

                        //Check if each row has fields with actual values.
                        //the csv library fills the nonexistent fields with empty strings
                        if( element.active === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Active cell.");
                        if( element.appId === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the App ID cell.");
                        if( element.name === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Name cell.");
                        if( element.gadsCustomerId === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Gads Customer ID cell.");
                        if( element.fadsCampaignId === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the Fads Campaign ID cell.");
                        if( element.gAnalyticsId === "")
                            errors.push("<b>Row #"+rowNum+"</b> is missing the GAnalytics ID cell.");
                    });

                    //Reject this promise and notify the parent about the errors
                    //to display in the large alert window.
                    if( errors.length > 0 ){
                        
                        this.props.notifyErrors( 
                            errors, 
                            "The file <b>"+this.state.selectedFile?.name+"</b> could not be uploaded because of the following errors:"
                        );

                        reject(Error('There were some errors in the file.'));
                    }
                    
                    resolve(json);
                    
                },
                error: error => {
                    reject( Error( error.message ) );
                }
            });
        });
    }

    //TODO: Esto no debe estar aquí.
    camelize = (str: string) => {
        str = str.toLowerCase();
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
        {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    render(){
        
        let button;
        if( this.state.selectedFile ){
            button = 
                <div>
                    <div onClick={this.onFileUpload} className="primary-button pointer-hover p-[8px_25px]">
                        <div className="primary-button-text">UPLOAD</div>
                    </div>
                </div>
        }else{
            button = 
                <div>
                    <input id={"file-uploader-franchise"} type="file" onChange={this.onFileChange} />
                    <label htmlFor={"file-uploader-franchise"}>
                        <div className="primary-button pointer-hover p-[8px_25px]">
                            <div className="primary-button-text">IMPORT</div>
                        </div>
                    </label>
                </div>
        }
        
        return (
            <div className="file-upload-area mb-10">
                <h1>{this.props.title}</h1>
                <Row>
                    <Col >
                        { button }
                    </Col>
                    <Col offset={1} span={6}>
                        { this.state.selectedFile !== null &&
                            <div>
                                <p className="file-upload-file-name">{this.state.selectedFile.name}</p>
                                <span onClick={this.onFileRemove} className="file-upload-remove-button">REMOVE</span>
                            </div>
                        }

                        { this.state.selectedFile == null && 
                            <a target="_blank" download={true} className="template-download" href="/templates/template_franchise.xlsx">Template</a>
                        }

                        {
                            this.state.fileTypeError !== null &&
                            <div>
                                <p className="error">{this.state.fileTypeError}</p>
                            </div>
                        }

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        importFranchises: ( franchiseArray: FranchiseRowSchema[] ) => dispatch(importFranchises({franchiseSchemaArray: franchiseArray}) )
    }
}

const mapStateToProps = (state: RootState) => {
    return{
        franchiseErrors: state.franchises.franchiseErrors,
        importFranchisesResult: state.franchises.importFranchisesResult
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FranchiseUploader);