import {Menu, MenuItem, Typography} from "@mui/material";
import React from "react";
import { UserRole } from "../../../models/User";
import IMenu from "./IMenu";
import { useDispatch, useSelector } from "react-redux";
import services from "../../../services";
import { selectAllFranchises } from "../../../store/Franchise/FranchiseStore";
import { Franchise } from "../../../models/Franchise";

const FranchiseMenu = (props: IMenu) => {
    
    const franchises = useSelector(selectAllFranchises);

    console.log("franchises:")
    console.log(franchises)

    const switchFranchise = async (franchise: string) => {
        const whiteLabelDomain = await services.userService.switchUserFranchise(franchise);

        if (whiteLabelDomain == "") {
            window.location.reload();
        }
        window.location.replace("https://"+whiteLabelDomain)
    }

    return (
        <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={props?.anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(props.anchorEl)}
            onClose={props.closeMenuHandler}
        >
            {franchises.map((item: Franchise) => (
                <MenuItem key={item.id} onClick={props.closeMenuHandler}>
                    <Typography
                        textAlign="center"
                        onClick={() => switchFranchise(item.id)}
                    >
                        {item.name}
                    </Typography>
                </MenuItem>
            ))}
        </Menu>
    );
}

export default FranchiseMenu;