import { CreativeTemplate } from "../models/CreativeTemplate";
import RestService from "./restService";
const buffer = require('buffer').Buffer;

export default class CreativeTemplateService {
  constructor(protected readonly api: RestService) {}

  async getCreativeTemplates(): Promise<CreativeTemplate[]> {
    return await this.api.get<CreativeTemplate[]>("/creative-templates");
  }

  /**
   * Retrieve templates for channel id
   *
   * @param message
   * @param channelId
   */
  async getChannelImages(channelId: string, message: string): Promise<CreativeTemplate[]> {
    return await this.api.get<CreativeTemplate[]>(
        `/media-template/images/${channelId}/${buffer.from(message).toString('base64')}`
    );
  }
}
