import TendencyCard from './TendencyCard'
import { IAmount } from "./placeholderData"
import styles from "./OmniStats.module.scss";
import {  setTendency, selectBenchmark, selectCredentialValidation } from 'store/Franchise/FranchiseStore'
import { selectUserAccount } from "../../../store/Account/AccountStore";
import { UserRole } from "../../../models/User";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';

interface OmniStatsProps {
    impressions: number;
    pageViews: number;
    conversions: number;
    pmConversions: number;
    clicks: number;
    ctr: number;
    cpc: number;
    cpl: number;
    spend: number;
    channelSelected?: string;
    daily_budget?: number | null;
    daily_spend?: number | null;
}


function OmniStats(props: OmniStatsProps) {

    const dispatch = useDispatch();
    const benchMark = useSelector(selectBenchmark);
    const credentialValidation = useSelector(selectCredentialValidation);
    const user = useSelector(selectUserAccount);

    const tendecy = (_tendency: string) => {
        if (benchMark === "my-trends") {
            dispatch(setTendency(_tendency));
        }
    }

    useEffect(()=>{
        if (benchMark === "my-trends") {
            dispatch(setTendency("Impressions"));
        }else{ 
            dispatch(setTendency(""));
        }
    }, [benchMark])

  

    const mediaData: IAmount[] = [
        {
            name: 'Impressions',
            amount: props.impressions.toLocaleString('en-US'),
            suffix: '',
            prefix: '',
            varianceAmount: 0
        },
        {
            name: 'Clicks',
            amount: props.clicks.toLocaleString('en-US'),
            suffix: '',
            prefix: '',
            varianceAmount: 0
        },
        {
            name: 'CTR',
            amount: Number(((isNaN(props.ctr) ? 0 : props.ctr) * 100).toFixed(2)).toString(),
            suffix: '%',
            prefix: '',
            varianceAmount: 0
        }, {
            name: 'CPC',
            amount: Number(((isNaN(props.cpc) ? 0 : props.cpc) / 1000000).toFixed(2)).toString(),
            suffix: '',
            prefix: '$',
            varianceAmount: 0
        }, {
            name: 'Spend',
            amount: Number(((isNaN(props.spend) ? 0 : props.spend) / 1000000).toFixed(2)).toLocaleString('en-US'),
            suffix: '',
            prefix: '$',
            varianceAmount: 0
        },{
            name: 'Conversions',
            amount: Number(props.pmConversions.toFixed(2)).toLocaleString('en-US'),
            suffix: '',
            prefix: '',
            varianceAmount: 0
        }, {
            name: 'CPA',
            amount: Number(((isNaN(props.cpl) ? 0 : props.cpl) / 1000000).toFixed(2)).toString(),
            suffix: '',
            prefix: '$',
            varianceAmount: 0
        }
    ];

    const landingPagesData: IAmount[] = [
        {
            name: 'Page views',
            amount: props.pageViews.toLocaleString('en-US'),
            suffix: '',
            prefix: '',
            varianceAmount: 0
        }, {
            name: 'Goals',
            amount: props.conversions.toLocaleString('en-US'),
            suffix: '',
            prefix: '',
            varianceAmount: 0
        // }, {
        //     name: 'Call clicks',
        //     amount: props.conversions.toLocaleString('en-US'),
        //     suffix: '',
        //     prefix: '',
        //     varianceAmount: 0
        // }, {
        //     name: 'Form submits',
        //     amount: props.conversions.toLocaleString('en-US'),
        //     suffix: '',
        //     prefix: '',
        //     varianceAmount: 0
        },
    ]

    const dailyBudget: IAmount[] = [
        {
            name: 'Daily budget',
            amount: props.daily_budget ? props.daily_budget.toLocaleString('en-US') : "0", // return "0" if is falsy to make sure that TendencyCard renders or not the Card
            suffix: '',
            prefix: '$',
            varianceAmount: 0,
            tooltipTitle: "The average amount set to spend daily on the selected channel, this value is used to limit the total spend over the course of the current month.",
        }
    ]

    const dailySpend: IAmount[] = [
        {
            name: 'Daily spend',
            amount: props.daily_spend ? props.daily_spend.toLocaleString('en-US') : "0", // return "0" if is falsy to make sure that TendencyCard renders or not the Card
            suffix: '',
            prefix: '$',
            varianceAmount: 0,
            tooltipTitle: "The total spend over the date range selected divided by the amount of days in the date range selected, this value could differ from the daily budget.",
        }
    ]

    const MediaCards = mediaData.map(amount => {
        return <TendencyCard isLandingCard={false} amount={amount} key={amount.name + amount.varianceAmount} setTendencyCard={tendecy}  />
    })
    
    const LandingPagesCards = landingPagesData.map(amount => {
        return <TendencyCard isLandingCard={true} amount={amount} key={amount.name + amount.varianceAmount} />
    })


    const DailyBudgetCard = dailyBudget.map(data => {
        return <TendencyCard isLandingCard={true} amount={data} key={data.name + data.varianceAmount} haveTooltip={true} titleTooltip={data.tooltipTitle} />      
    })

    const DailySpendCard = dailySpend.map(data => {
        return <TendencyCard isLandingCard={true} amount={data} key={data.name + data.varianceAmount} haveTooltip={true} titleTooltip={data.tooltipTitle} />      
    })
    

    return (
        <div className={styles['omnistats-col'] + " res:order-2 w-full grid grid-cols-2 res:content-start gap-6"}>
            <div className={'col-span-2 '+ styles['omnistats-horizontal-rule-container']}>
                <label>Paid Media</label>
                <div></div>
            </div>
            {MediaCards}
            { credentialValidation.googleAnalytics && (
                <>
                    <div className={'col-span-2 mt-4 '+styles['omnistats-horizontal-rule-container']}>
                        <label>Landing Pages</label>
                        <div></div>
                    </div>
                    {LandingPagesCards}
                </>
            )}

            {(user?.type != UserRole.Franchisor && user?.type != UserRole.Agency) && (
                <>
                    <div className={'col-span-2 mt-4 '+styles['omnistats-horizontal-rule-container']}>
                        <label>Budget</label>
                        <div></div>
                    </div>
                    { (props.channelSelected === "Search" || props.channelSelected === "Social") && (
                        <>
                            {DailyBudgetCard}
                        </>
                    )}
                    {(DailySpendCard)}
                </>
            )}
            
        </div>
    )
}

export default OmniStats
