import { QRCampaign } from "../models/QRCampaign";
import RestService from "./restService";

export interface INewQRCampaign{
  name: string,
  promotion: string,
  startDate: Date,
  endDate: Date,
}

export interface IUpdateQRCampaign{
  id: string,
  name: string,
  promotion: string,
  startDate: Date,
  endDate: Date,
}

export interface ISendSmsCodeRequest{
  countryCode: number,
  phoneNumber: string,
  qrCampaignId: string
}

export default class QRCampaignService {
  constructor(protected readonly api: RestService) {}

  async getQRCampaigns(): Promise<QRCampaign[]> {
    return await this.api.get<QRCampaign[]>("/qr-campaigns");
  }

  async createQRCampaign( createQRCampaign: INewQRCampaign ): Promise<QRCampaign>{
    console.log(createQRCampaign);
    return await this.api.post<QRCampaign>("/qr-campaigns", createQRCampaign);
  }

  async updateQRCampaign( updateQRCampaign: IUpdateQRCampaign ): Promise<QRCampaign>{
    console.log(updateQRCampaign);
    return await this.api.put<QRCampaign>("/qr-campaigns", updateQRCampaign);
  }

  async deleteQRCampaign(updateQRCampaign: IUpdateQRCampaign): Promise<boolean>{
    return await this.api.delete<boolean>("/qr-campaigns", updateQRCampaign);
  }

  async validateCampaignCode(
    code: string,
  ):Promise<QRCampaign>{
    console.log('Code is '+ code);
    return await this.api.get<QRCampaign>("/qr-campaigns/validate/"+code);
  }


  async sendSmsCode(
    countryCode: number,
    phoneNumber: string,
    qrCampaignId: string
  ):Promise<boolean>{
    const newSendSmsRequest : ISendSmsCodeRequest = {countryCode: countryCode, phoneNumber: phoneNumber, qrCampaignId: qrCampaignId}
    return await this.api.post<boolean>("/qr-campaigns/send-sms", newSendSmsRequest);
  }

}
