import CustomSwitch from "../../Switch/CustomSwitch";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomTooltip from "../../tooltip/tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";

export const CustomEvents = (): JSX.Element => {

    var preferencesInit = {
        invoicing: {
            Email: false, 
            SMS: false,
        },
        pacing: {
            Email: false, 
            SMS: false,
        },
        ctr: {
            Email: false, 
            SMS: false,
        },
        cpc: {
            Email: false, 
            SMS: false,
        },
        cpl: {
            Email: false, 
            SMS: false,
        },
        conversions: {
            Email: false, 
            SMS: false,
        },
    }

    const [preferences, setPreferences] = useState(preferencesInit);
    const [showAlwaysOnTooltip, setShowAlwaysOnTooltip] = useState(false);
    
    
    const handleChange = (property:any, index:any) => {
        setPreferences((prevPreferences: any) => ({
          ...prevPreferences,
          [index]: {
            ...prevPreferences[index],
            [property]: !prevPreferences[index][property],
          },
        }));
    };
    
    const whiteLabel = useSelector( selectWhiteLabel );

    console.log(preferences.invoicing.Email)

    return <div className="w-[75%] res:w-full">

        <div className="mb-[40px] flex items-center">
            <h1 className="text-2xl">Event notifications</h1> 
            <CustomTooltip
                open={showAlwaysOnTooltip}
                onMouseEnter={() => setShowAlwaysOnTooltip(true)}
                onMouseLeave={() => setShowAlwaysOnTooltip(false)}
                title="Define custom events based on your performance and attribution data to receive personalized notifications"
                placement="right-start"
                arrow
            >
            <InfoCircleOutlined
              style={{ color: "#424242", fontSize: "20px", marginLeft: '15px', marginRight: "20px", marginBottom: "10px" }}
            />
          </CustomTooltip>
        </div>


        <div className="grid grid-cols-2 res:grid-cols-2 gap-4">


            <div className="mb-[20px]">

                <h2 className="font-semibold text-[19px] mb-[19px]">Invoicing</h2>

                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                onChange={ () => handleChange('Email','invoicing')  }
                                checked={ preferences.invoicing.Email }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>New invoices for one-time or recurring purchases.</p>
                    </div>
                </div>
            </div>


            <div className="mb-[20px]"  >

                <h2 className="font-semibold text-[19px] mb-[19px]">Pacing</h2>

                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                onChange={ () => handleChange('Email','pacing')  }
                                checked={ preferences.pacing.Email }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <p>When the budget being spent tends above or below the your pacing requirements.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]"  >

                <h2 className="font-semibold text-[19px] mb-[19px]"> CTR </h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                onChange={ () => handleChange('Email','ctr')  }
                                checked={ preferences.ctr.Email }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>
                    <div className="pr-4">
                        <p>When your clickthrough rate goes above or below the standard range.</p>
                    </div>
                </div>
            </div>


            <div className="mb-[20px]"  >

                <h2 className="font-semibold text-[19px] mb-[19px]"> CPC </h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                onChange={ () => handleChange('Email','cpc')  }
                                checked={ preferences.cpc.Email }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>
                    <div className="pr-4">
                        <p>When your cost per click goes above the standard range.</p>
                    </div>
                </div>
            </div>

            <div className="mb-[20px]"  >

                <h2 className="font-semibold text-[19px] mb-[19px]"> CPA </h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                onChange={ () => handleChange('Email','cpl')  }
                                checked={ preferences.cpl.Email }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>
                    <div className="pr-4">
                        <p>When your cost per action goes above or below the standard range.</p>
                    </div>
                </div>
            </div>
            
            <div className="mb-[20px]"  >

                <h2 className="font-semibold text-[19px] mb-[19px]"> Conversions </h2>
                <div className="flex flex-wrap md:flex-nowrap">
                    <div className="min-w-[60px]">
                        <div className="flex mb-4">
                            <CustomSwitch
                                marginLeft="0px"
                                onChange={ () => handleChange('Email','conversions')  }
                                checked={ preferences.conversions.Email }
                                switchColor={whiteLabel.primaryColor}
                            />
                            <div className="ml-3"></div>
                        </div>
                    </div>
                    <div className="pr-4">
                        <p>When your landing page conversions fell below the standard range.</p>
                    </div>
                </div>
            </div>

        </div>

    </div>


}