import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminLayout from "../../components/admin/dashboard";
import OnboardingPage from "../../components/admin/onboarding/OnboardingPage";
import AccountManagerPage from "../../components/admin/accountManager/AccountManagerPage";
import MediaManagerPage from "../../components/admin/mediaManager/MediaManagerPage";
import EmailManagerPage from "../../components/admin/emailManager/emailManager";
import DemographicsPage from "../../components/admin/demographics/Demographics";
import OtherReportsPage from "../../components/admin/other-reports/OtherReportsPage";
import { AdminAvailableRoutes } from "../AdminRoutes";
import "./../Account/styles.css";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {WhiteLabelingPage} from "../../components/admin/whitelabeling/WhiteLabelingPage";
import CreateWhiteLabelPage from "../../components/admin/whitelabeling/CreateWhiteLabelPage";
import DeleteWhiteLabelPage from "../../components/admin/whitelabeling/DeleteWhiteLabelPage";
import QrManagerPage from "components/admin/qrManager/QrManagerPage";
import CreateQRCampaignPage from "components/admin/qrManager/CreateQRCampaignPage";
import DetailsQRCampaignPage from "components/admin/qrManager/DetailsQRCampaignPage";
import DeleteQRCampaignPage from "components/admin/qrManager/DeleteQRCampaignPage";

const AdminMainApp: React.FC = () => {
  return (
    <div className="container">
      
      <Routes>
        <Route
          path={AdminAvailableRoutes.ONBOARDING}
          element={
            <AdminLayout>
              <OnboardingPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.DIRECTORY}
          element={
            <AdminLayout>
              <AccountManagerPage />
            </AdminLayout>
          }
        />              
        <Route              
          path={AdminAvailableRoutes.VERSIONS}
          element={
            <AdminLayout>
              <WhiteLabelingPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.MEDIA_MANAGER}
          element={
            <AdminLayout>
              <MediaManagerPage />
            </AdminLayout>
          }
        />
        <Route              
          path={AdminAvailableRoutes.CREATE_VERSION}
          element={
            <AdminLayout>
              <CreateWhiteLabelPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.BULK_EMAILS}
          element={
            <AdminLayout>
              <EmailManagerPage />
            </AdminLayout>
          }
        />
        <Route                 
          path={AdminAvailableRoutes.EDIT_VERSION}
          element={
            <AdminLayout>
              <CreateWhiteLabelPage isEdit={true} />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.DEMOGRAPHICS}
          element={
            <AdminLayout>
              <DemographicsPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.PERFORMANCE_IMPORT}
          element={
            <AdminLayout>
              <OtherReportsPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.DELETE_VERSION}
          element={
            <AdminLayout>
              <DeleteWhiteLabelPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.QR_MANAGER}
          element={
            <AdminLayout>
              <QrManagerPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.CREATE_QR_CAMPAIGN}
          element={
            <AdminLayout>
              <CreateQRCampaignPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.EDIT_QR_CAMPAIGN}
          element={
            <AdminLayout>
              <CreateQRCampaignPage isEdit={true} />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.DELETE_QR_CAMPAIGN}
          element={
            <AdminLayout>
              <DeleteQRCampaignPage />
            </AdminLayout>
          }
        />
        <Route
          path={AdminAvailableRoutes.DETAILS_QR_CAMPAIGN}
          element={
            <AdminLayout>
              <DetailsQRCampaignPage />
            </AdminLayout>
          }
        />
        <Route
          path="*"
          element={
            <AdminLayout>
              <AccountManagerPage />
            </AdminLayout>
          }
        />
      </Routes>
      
    </div>
  );
};

export default AdminMainApp;
