import { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import sharedStyles from "../shared/styles.module.css";
import { setCreatedQRCampaign, selectQRCampaigns, fetchQRCampaigns, setQRCampaignToEdit } from "../../../store/QRCampaign/QRCampaignsStore";
import { useNavigate } from "react-router-dom";
import { QRCampaign } from "../../../models/QRCampaign";
import { AdminAvailableRoutes } from "pages/AdminRoutes";

export const QrManagerPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect( () => {
        dispatch( setCreatedQRCampaign(null) );
        dispatch( fetchQRCampaigns() );
    }, [])

    const qrCampaigns: QRCampaign[] = useSelector(selectQRCampaigns);
    const [selectedItem, setSelectedItem] = useState<QRCampaign>();
    const [selectedIndex, setSelectedIndex] = useState(-1);

    function handleItemClick( item:QRCampaign, index:number ){
        setSelectedItem(item)
        setSelectedIndex(index);
    }

    const qrCampaignItems = () => {
        const itemElements: any[] = []
        const items = qrCampaigns;
        items.forEach((item, i) => {
            itemElements.push(<div key={`${item.name}${i}`} className={sharedStyles['account-manager-item'] + (selectedIndex == i ? '  bg-orange-200' : '' )} onClick={() => { handleItemClick(item, i) } }>{item.name}</div>)
        })
        return itemElements
    }


    // determines whether the box with the campaigns will appear on the screen, if there are no campaigns the box will not appear
    var hiddenCampaignBox  = (qrCampaigns.length >= 1) ? "flex" : "hidden"; 

    return <div>
        <h1 className="text-3xl">QR manager</h1>
        <div>            

            <div >
                <div onClick={() => { navigate(AdminAvailableRoutes.CREATE_QR_CAMPAIGN) }}
                    className="primary-button pointer-hover p-[8px_25px]"
                >
                    <div className="primary-button-text">NEW CAMPAIGN</div>
                </div>
            </div>
            
            <div className={` ${styles['qr-select-and-btns']} ${hiddenCampaignBox} `}>
                <div className={`${sharedStyles['account-manager-list']} ${styles['qr-list']} ${styles['qr-input-width']} mt-7`}>
                    { qrCampaignItems() }
                </div>
            </div>

            { selectedItem != undefined && (
                <div className="mt-5 ">
                    <button className="px-7 primary-button" onClick={() => {
                        dispatch(setQRCampaignToEdit(selectedItem));
                        navigate( AdminAvailableRoutes.DETAILS_QR_CAMPAIGN );
                        console.log('Click to '+selectedItem.name ) 
                    }}>
                        <div className="primary-button-text">DETAILS</div>
                    </button>

                </div>
            )}
        </div>
    </div>
}

export default QrManagerPage