import { Email } from "../models/Email";
import RestService from "./restService";

export interface EmailRowSchema {
  to: string;
  cc: string | null;
  firstname: string;
  location: string;
  channel: string;
}

export default class EmailService {
  constructor(protected readonly api: RestService) { }


  async importEmails(emailSchemaArray: EmailRowSchema[]): Promise<Email[]> {
    const apiResponse = await this.api.post<Email[]>("/email/import", emailSchemaArray);
    return apiResponse;
  }

}


