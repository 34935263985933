import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../pages/Routes";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { appColors } from "../../assets/variables/colors";
import { useSelector } from "react-redux";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { UserRole } from "../../models/User";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";

interface SideBarProps{
  handleSelectedOptionChange: (values: any) => void;
  selectedOption?: string;
}

const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
  const navigate = useNavigate();
  const user = useSelector(selectUserAccount);

  const whiteLabel = useSelector( selectWhiteLabel );

  return (
    <div className="res:w-full res:!p-0 res:!mt-3" style={{ marginTop: "30px", padding: "0px 25px" }}>
      <div
        className="sidebar-dashboard-button pointer-hover mobile-hide"
        onClick={() => navigate("/")}
        style={{ marginBottom: "30px" }}
      >
        <div className="sidebar-dashboard-button-text">
          {"Back to Dashboard".toUpperCase()}
        </div>
        <IconArrowRight />
      </div>

      <div
        onClick={() => {
          props.handleSelectedOptionChange("Account information");
          navigate(AvailableRoutes.ACCT_INFO);
        }}
        className="report-sidebar-item pointer-hover"
        style={{
          backgroundColor:
            props.selectedOption === "Account information"
              ? whiteLabel.activeColor
              : "transparent",
        }}
      >
        <p>Account information</p>
      </div>

      <div
        onClick={() => {
          props.handleSelectedOptionChange("Payment methods");
          navigate(AvailableRoutes.PAYMENT_METHODS);
        }}
        className="report-sidebar-item pointer-hover"
        style={{
          backgroundColor:
            props.selectedOption === "Payment methods"
              ? whiteLabel.activeColor
              : "transparent",
        }}
      >
        <p>Payment methods</p>
      </div>

      <div
        onClick={() => {
          props.handleSelectedOptionChange("Billing");
          navigate(AvailableRoutes.BILLING);
        }}
        className="report-sidebar-item pointer-hover"
        style={{
          backgroundColor:
            props.selectedOption === "Billing"
              ? whiteLabel.activeColor
              : "transparent",
        }}
      >
        <p>Billing history</p>
      </div>

{/*       <div
        onClick={() => {
          //setSelectedOption("Locations");
          props.handleSelectedOptionChange("Locations");
          navigate(AvailableRoutes.ACCOUNT_LOCATIONS);
        }}
        className="report-sidebar-item pointer-hover"
        style={{
          backgroundColor:
            props.selectedOption === "Locations"
              ? whiteLabel.activeColor
              : "transparent",
        }}
      >
        <p>Location manager</p>
      </div> 
*/}
{/*      <div
        onClick={() => {
          //setSelectedOption("Campaign History");
          props.handleSelectedOptionChange("Campaign History");
          navigate(AvailableRoutes.CAMPAIGN_HISTORY);
        }}
        className="report-sidebar-item pointer-hover"
        style={{
          backgroundColor:
            props.selectedOption === "Campaign History"
              ? whiteLabel.activeColor
              : "transparent",
        }}
      >
        <p>Campaign history</p>
      </div>
*/}

      {(user?.type === UserRole.Agency) && (
        <div
          onClick={() => {
            props.handleSelectedOptionChange("Active orders");
            navigate(AvailableRoutes.ACTIVE_ORDERS);
          }}
          className="report-sidebar-item pointer-hover"
          style={{
            backgroundColor:
                props.selectedOption === "Active orders"
                ? whiteLabel.activeColor
                : "transparent",
          }}
        >
          <p>Active orders</p>
        </div>
      )}
      
      {(user?.type === UserRole.Agency) && (
      <div
        onClick={() => {
          props.handleSelectedOptionChange("Notifications");
          navigate(AvailableRoutes.CUSTOM_EVENTS);
        }}
        className="report-sidebar-item pointer-hover"
        style={{
          backgroundColor:
            props.selectedOption === "Notifications"
              ? whiteLabel.activeColor
              : "transparent",
        }}
      >
        <p>Notifications</p>
      </div>
      )}

      {(user?.type === UserRole.Agency) && (
        <div
          onClick={() => {
            props.handleSelectedOptionChange("System tools");
            navigate(AvailableRoutes.SYSTEM_TOOLS);
          }}
          className="report-sidebar-item pointer-hover"
          style={{
            backgroundColor:
                props.selectedOption === "System tools"
                ? whiteLabel.activeColor
                : "transparent",
          }}
        >
          <p>System tools</p>
        </div>
      )}
    </div>
  );
};

export default SideBar;
