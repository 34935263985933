import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Email } from "../../models/Email";
import services from "../../services";
import { EmailRowSchema } from "../../services/EmailService";
import { RootState } from "../index";



// Imports multiple emails from a csv or xlsx file
// @params
// emailSchemaArray (An array of FranchiseRowSchema objects representing the rows in the file)
export const importEmails = createAsyncThunk<
  Email[], //we expect this to return a list of locations
  {
    emailSchemaArray: EmailRowSchema[]
  },
  {
    rejectValue: String[] //return a list of errors on failure
  }
>(
  "email/import",
  async (emailSchemaArray, { rejectWithValue }) => {
    try {
      let result = await services.emailService.importEmails(emailSchemaArray.emailSchemaArray);
      return result;
    } catch (error: any) {
      //console.log( error );
      if (!error.response) {
        throw error;
      }
      let formattedErrors = [] as String[];
      let errorData = error.response.data;

      //This is the validation error returned by the Platform API using the tsoa library.
      if (errorData.message === 'Validation Failed') {
        //This returns a "details" object which contains both the row number and attribute, as well as 
        //the error identified by tsoa. IE: locationRowSchema.$0.country : {message: "Some error" }
        let details = errorData.details;
        Object.keys(details).forEach(key => {
          //The key contains both the row# and the failed attribute
          //The attribute "message" contains the error message.
          //We attempt to format these in a single string.
          let rowNumber = key.substring(key.indexOf(".") + 2, key.indexOf(".") + 3);
          let colName = key.substring(key.indexOf(".") + 4);
          let rowNumberInt = parseInt(rowNumber) + 1;

          let errorMessage = `<b>Row #${rowNumberInt} ${colName}</b>: ${details[key].message}`;
          formattedErrors.push(errorMessage);
        })
      } else if (errorData.message === 'Entity Not Found') {
        formattedErrors.push(errorData.details);
      } else if (errorData.message === 'Could Not Process Request') {
        formattedErrors.push(errorData.details);
      }

      return rejectWithValue(formattedErrors);
    }
  }
);

export const emailSlice = createSlice({
  name: "emails",
  initialState: {
    importErrors: [] as String[] | null,
    importResult: null as String | null
  },
  reducers: {
    setImportErrors: (
      state,
      { payload: importErrors }: PayloadAction<String[] | null>
    ) => {
      state.importErrors = importErrors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importEmails.fulfilled, (state, action) => {
      let importedEmails = action.payload;
      state.importResult = `Emails successfully sent ${importedEmails.length}.`;
    });

    builder.addCase(importEmails.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        //Can't be undefined because we verify it has an actual payload.
        state.importErrors = action.payload!;
      }
    });
  },
});

export const { setImportErrors} = emailSlice.actions;

export const importErrors = (state: RootState): String[] | null => state.emails.importErrors;

export const importEmailsResult = (state: RootState): String | null => state.emails.importResult;


export default emailSlice.reducer;
