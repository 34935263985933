import { styled, Switch, SwitchProps } from "@mui/material";
import React from "react";

interface CustomSwitchProps extends SwitchProps {
  checked: boolean;
  onChange: () => void;
  switchColor: string;
  marginLeft?: string;
}

const CustomSwitch = ({
  disabled,
  checked,
  onChange,
  switchColor,
  marginLeft = "14px",
}: CustomSwitchProps) => {
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 48,
    height: 24,
    padding: 0,
    marginLeft: marginLeft,
    borderRadius: "12px",
    border: "2px solid " + switchColor,

    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 12,
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      color: switchColor,
      transform: "translateX(2px) translateY(2px)",

      "&.Mui-checked": {
        color: switchColor,

        transform: "translateX(26px) translateY(2px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#FFF" : "#FFF",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 99,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#FFF" : "#FFF",
      boxSizing: "border-box",
    },
  }));

  return (
    <CustomSwitch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      name="loading"
      color="primary"
    />
  );
};

export default React.memo(CustomSwitch);