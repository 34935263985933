import "./TargetZone.css";

const TargetZonePointMarker = (props: any) => {
  const { color, name } = props;
  return (
    <div
      className="marker"
      style={{ backgroundColor: color, cursor: "pointer" }}
      title={name}
    />
  );
};

export default TargetZonePointMarker;
