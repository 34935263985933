import { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/arrow-right.svg";
import {
  selectUserAccount,
  updateUser,
} from "../../../store/Account/AccountStore";
import { useDispatch, useSelector } from "react-redux";
import { UsStates } from "./UsStates";
import AlertMessage from "../../alertMessage/AlertMessage";
import { CountryEnum } from "../../../models/Location";

interface BillingAddressProps {
  showTitle: boolean;
}

const BillingAddress = ({ showTitle }: BillingAddressProps) => {
  const [usSelected, setUsSelected] = useState(false);
  const dispatch = useDispatch();
  const account = useSelector(selectUserAccount);
  const { Option } = Select;
  const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
  const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);

  useEffect(() => {
    if(account?.country === CountryEnum.USA)
    setUsSelected(true);
    else
    setUsSelected(false);
  }, [account]);

  const onFinish = (values: any) => {
    if (account !== null && account.firstName !== undefined && account.lastName !== undefined && account.email !== undefined)
    dispatch(updateUser({
      firstName: account?.firstName,
      lastName: account?.lastName,
      email: account?.email,
      phoneNumber: account?.phoneNumber,
      communicationPreference: account?.communicationPreference,
      address1: values.address1,
      address2: values.address2,
      state: values.state,
      postalCode: values.postalCode,
      city: values.city,
      country: values.country,
    }));
    setOpenSuccessMsg(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    setOpenErrorMsg(true);
    console.log("Failed:", errorInfo);
  };

  const handleCountryChange = (value: string) => {
    if(value === CountryEnum.USA)
    setUsSelected(true);
    else
    setUsSelected(false);
  }

  const renderOptions = (option: string) => {
    return <Option key={option} value={option}>{option}</Option>
  }

  return (
    <div>
      {showTitle && <h1 className="text-2xl">Billing Address</h1>}
      {account && (
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="res:!w-full res:mt-4 container-form"
          layout="vertical"
          style={{ width: "80%" }}
          initialValues={{
            address1: account.address1,
            address2: account.address2,
            state: account.state,
            postalCode: account.postalCode,
            city: account.city,
            country: account.country ? account.country : CountryEnum.USA,
          }}
        >
          <Form.Item
            label="Address 1"
            name="address1"
            style={{ marginTop: 20, marginBottom: showTitle ? 40 : 15 }}
          >
            <Input className="res:py-3" placeholder="Address 1" />
          </Form.Item>

          <Form.Item
            label="Address 2"
            name="address2"
            required={false}
            style={{ marginTop: showTitle ? 20 : 0, marginBottom: showTitle ? 40 : 15 }}
          >
            <Input className="res:py-3" placeholder="Address 2" />
          </Form.Item>

          <Input.Group compact>
            <Form.Item
              label="Country"
              name="country"
              style={{width: "46%" }}
            >
              <Select onChange={handleCountryChange}>
                {Object.keys(CountryEnum).map(renderOptions)}
              </Select>
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              style={{ marginLeft: "8%", width: "46%" }}
            >
              {usSelected ?
                <Select >
                  {UsStates.map(renderOptions)}
                </Select>
                :
                <Input className=" res:py-3 " placeholder="State"/>
              }
            </Form.Item>
          </Input.Group>

          <Input.Group compact>
            <Form.Item label="City" name="city" style={{ width: "46%" }}>
              <Input className="res:py-3" placeholder="City" />
            </Form.Item>

            <Form.Item
              label="Postal Code"
              name="postalCode"
              style={{ marginLeft: "8%", width: "46%" }}
            >
              <Input className="res:py-3" placeholder="Postal Code" />
            </Form.Item>
          </Input.Group>

          <Form.Item style={{ marginTop: showTitle ? 50 : 0 }}>
            <Button
              htmlType="submit"
              className="res:!w-full primary general-button pointer-hover"
              style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              SAVE
              <IconArrowRight/>
            </Button>
          </Form.Item>
          <AlertMessage successMessage="Billing Address was saved successfully!" openScsMsg={openSuccessMsg} onScsClose={() => setOpenSuccessMsg(false)} openErrMsg={openErrorMsg} onErrClose={() => setOpenErrorMsg(false)}/>
        </Form>
      )}
    </div>
  );
};

export default BillingAddress;