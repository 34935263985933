import { useNavigate } from "react-router-dom";
import "./styles.css";
import { getSubdomain } from "../../../utility/subdomain";

const FooterHelp = () => {
  const navigate = useNavigate();
  const subDomain = getSubdomain();

  return (
    <div>
      <div className="footer-support" onClick={() => navigate("/help")}>
        Support
      </div>
      {subDomain === "exppro" && (
        <div className="footer-guides" onClick={() => navigate("/guides")}>
          Video Guides
        </div>
      )}
    </div>
  );
};

export default FooterHelp;