import React, { useEffect, useState } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteAlert, selectFranchisees, selectUserAccount, getFranchisees } from "../../store/Account/AccountStore";
import { Alert } from "../../models/Alert";
import { ReactComponent as IconCancel } from "../../assets/icons/cancel.svg";
import { selectFranchise } from "store/Franchise/FranchiseStore";

const FeedAlert = ({alertType}: any) => {
    const dispatch = useDispatch();
    const account = useSelector(selectUserAccount);
    const [activeAlerts, setActiveAlerts] = useState<Alert[]>([]);
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);
    const sevenDaysFuture = new Date();
    sevenDaysFuture.setDate(today.getDate() + 7);


    useEffect( () => {
        dispatch(getFranchisees());
    }, [] ) 
    
    useEffect(() => {
        if( account ){
            let userAlerts = account.alerts ?? [];
            setActiveAlerts(userAlerts.filter((alert) => {
                const alertDate = new Date(alert.alertDate);
    
                if (alertType === "recent") {
                    return alertDate >= sevenDaysAgo && alertDate <= sevenDaysFuture;
                }else {
                    return alertDate > sevenDaysFuture ;
                }
            }))
        }
    }, [account])  


    const alertCard = (alert: Alert) => {
        return (
            <div className={`px-[17px] py-[11px] min-h-[120px] w-full shadow-[0px_0px_8px_0px_#15072566] bg-white rounded-[8px] mb-[26px] relative ` } key={alert.id}>
{/*                 <div className="absolute top-2 right-2 ">
                    <IconCancel className="pointer-hover" onClick={() => removeAlert(alert)} />
                </div> */}
                <h3 className="text-[14px] font-bold"> {alert.title} </h3>
                <div >
                    {alert.message}
                </div>
            </div>
        )
    };

    const removeAlert = (alert: Alert) => {
        if( alert.eventId ){
            dispatch(
                deleteAlert(alert.eventId)
            )
        }
    };

    const defaultAlert = () => {
        var title
        var message
        if (alertType == "recent" ){
            title = "Welcome to your local marketing platform"
            message = "In this space you will see notifications related to your billing account and active campaigns"
        }else{
            title = "Plan your next campaign" 
            message = "Upcoming events will display here" 
        }

        return (
            <div className={`px-[17px] py-[11px] min-h-[120px] w-full shadow-[0px_0px_8px_0px_#15072566] bg-white rounded-[8px] mb-[26px] relative ` }>
                <h3 className="text-[14px] font-bold"> {title}  </h3>
                <div > {message} </div>
            </div>
        ) 
    }

    return <>
        { activeAlerts.length > 0 ? activeAlerts.map(alertCard) : defaultAlert() }
        
    </>
}


export default React.memo(FeedAlert);
