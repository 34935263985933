import { QRLandingCode } from "../models/QRCode";
import RestService from "./restService";

export interface INewLandingCodeBatch {
  size: number;
}

export interface INewClaimLandingCode {
  userEmail: string;
  qrLandingCode: string;
}

export default class QRLandingCodesService {
  constructor(protected readonly api: RestService) { }

  async validateLandingCode(
    code: string,
  ): Promise<QRLandingCode> {

    const response = await this.api.get<QRLandingCode>("/qrLandingCodes/" + code);
    return {
      ...response
    };
  }

  async generateLandingCodeBatch(
    newCodeBatch: INewLandingCodeBatch
  ): Promise<QRLandingCode[]> {

    const response = await this.api.post<QRLandingCode[]>("/qrLandingCodes/", newCodeBatch);
    return [
      ...response
    ];
  }

  async claimLandingCode(
    newClaimCode: INewClaimLandingCode,
  ): Promise<QRLandingCode> {

    const response = await this.api.put<QRLandingCode>("/qrLandingCodes/", newClaimCode);
    return {
      ...response
    };
  }
}
