import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getEstimatedNewCampaignChannelCost,
  NewCampaignChannel,
} from "../../../models/CampaignChannel";
import { ChannelType } from "../../../models/Channel";
import {
  selectAlwaysOn,
  selectAdjustedSpendPercent,
  selectSetSpend,
  selectNewCampaignChannels,
  selectTotalAudience,
} from "../../../store/campaignForm/CampaignForm";
import {
  getHoursBetween2Dates,
  HOURS_IN_A_WEEK,
  HOURS_IN_DAY,
  daysToWeeks,
  STANDARD_BILLING_PERIOD_WEEKS,
} from "../../../utility/time";
import "./ReviewAndPay.scss";

interface ReviewAndPaySidebarCardProps {
  channelType: ChannelType;
}

const ReviewAndPaySidebarCard = ({
  channelType,
}: ReviewAndPaySidebarCardProps) => {
  const [startDay, setStartDay] = useState<Date>();
  const [endDay, setEndDay] = useState<Date>();
  const targetZoneAudience: number = useSelector(selectTotalAudience);
  const newCampaignChannels: NewCampaignChannel[] = useSelector(
    selectNewCampaignChannels
  );
  const alwaysOn: boolean = useSelector(selectAlwaysOn);
  const adjustedSpendPercent = useSelector(selectAdjustedSpendPercent);
  const setSpend: number | null = useSelector(selectSetSpend);

  useEffect(() => {
    newCampaignChannels.forEach((campaignChannel) => {
      if (campaignChannel.channelType.id === channelType.id) {
        setStartDay(campaignChannel.startDate);
        if (campaignChannel.endDate !== undefined) {
          setEndDay(campaignChannel.endDate);
        } else {
          const tempDate = new Date(
            campaignChannel.startDate?.getFullYear(),
            campaignChannel.startDate?.getMonth(),
            campaignChannel.startDate?.getDate() +
            channelType.durationHours / 24
          );

          setEndDay(tempDate);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let investment = 0;
  let durationDays = 0;
  if (setSpend) {
    investment = setSpend;
  } 
  
  if (alwaysOn) {
    const investmentFor2Weeks =
      (channelType.investmentInDollarsPerTarget /
        channelType.durationHours) *
      HOURS_IN_A_WEEK *
      STANDARD_BILLING_PERIOD_WEEKS *
      targetZoneAudience;
    investment = (investmentFor2Weeks * adjustedSpendPercent) / 100;
    const newCampaignChannel = newCampaignChannels.find(
      (sncc) => sncc.channelType.id === channelType.id
    );
    if (newCampaignChannel) {
      const channelHours = newCampaignChannel.endDate
        ? getHoursBetween2Dates(
          newCampaignChannel.startDate,
          newCampaignChannel.endDate
        )
        : channelType.durationHours;
      durationDays = channelHours / HOURS_IN_DAY;
    }
  } else {
    const newCampaignChannel = newCampaignChannels.find(
      (sncc) => sncc.channelType.id === channelType.id
    );
    if (newCampaignChannel) {
      investment = getEstimatedNewCampaignChannelCost(
        newCampaignChannel,
        targetZoneAudience,
        adjustedSpendPercent
      );
      const channelHours = newCampaignChannel.endDate
        ? getHoursBetween2Dates(
          newCampaignChannel.startDate,
          newCampaignChannel.endDate
        )
        : channelType.durationHours;
      durationDays = channelHours / HOURS_IN_DAY;
    } else {
      durationDays = channelType.durationHours / HOURS_IN_DAY;
      investment =
        (channelType.investmentInDollarsPerTarget * targetZoneAudience) * (adjustedSpendPercent / 100);
    }
  }

  return (
    <div className="review-pay-channel-card">
      <p className="review-pay-channel-card-title">{channelType.title}</p>
      <p className="review-pay-channel-card-content">
        Starts: {startDay?.toLocaleDateString()} <br />
        Duration: {alwaysOn ? channelType.durationWeeks + " weeks" : daysToWeeks(durationDays)}
      </p>
    </div >
  );
};

export default ReviewAndPaySidebarCard;
