import { Location } from "../../../models/Location"

export default function LocationDetailsSide({ location, disableTitle }: { location: Location, disableTitle?: boolean }) {
    return <div className="details-location">
{/*         {!disableTitle && <p className="details-title">Location Details</p>}
        <p>Location ID: <b>{location.userReadableId}</b></p>
        <div>{location.address1}</div>
        <div>{location.proximity}</div>
        <div>
            {location.city}, {location.state}
        </div>
        <div>{location.postalCode}</div>
        <p>{location.phoneNumber}</p>
        <div>
            {location.hoursOfOperationMon &&
                <div>
                    <b style={{ marginRight: "4px" }}>Mon:</b>
                    {location.hoursOfOperationMon}
                </div>
            }
            {location.hoursOfOperationTue &&
                <div>
                    <b style={{ marginRight: "4px" }}>Tue:</b>
                    {location.hoursOfOperationTue}
                </div>
            }
            {location.hoursOfOperationWed &&
                <div>
                    <b style={{ marginRight: "4px" }}>Wed:</b>
                    {location.hoursOfOperationWed}
                </div>
            }
            {location.hoursOfOperationThu &&
                <div>
                    <b style={{ marginRight: "4px" }}>Thu:</b>
                    {location.hoursOfOperationThu}
                </div>
            }
            {location.hoursOfOperationFri &&
                <div>
                    <b style={{ marginRight: "4px" }}>Fri:</b>
                    {location.hoursOfOperationFri}
                </div>
            }
            {location.hoursOfOperationSat &&
                <div>
                    <b style={{ marginRight: "4px" }}>Sat:</b>
                    {location.hoursOfOperationSat}
                </div>
            }
            {location.hoursOfOperationSun &&
                <div>
                    <b style={{ marginRight: "4px" }}>Sun:</b>
                    {location.hoursOfOperationSun}
                </div>
            }
        </div> */}
    </div>
}
