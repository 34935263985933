import styles from './styles.module.css'
import { AdminManagerLocation  } from "../../../models/AdminManager";

interface AccountLocationListProps {
  locations: AdminManagerLocation[];
}

const AccountLocationList = ({ locations }: AccountLocationListProps) => {
  return (
    <table className={styles['table-styled']}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Location</th>
          <th>Owner</th>
        </tr>
      </thead>
      <tbody>
        {locations.map((location, index) => (
          <tr key={index}>
            <td>{location.id}</td>
            <td>{location.name}</td>
            <td>{location.owner}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AccountLocationList;
